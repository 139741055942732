<template>
    <div class="businesspromotion_page">
        <div class="businesspromotion_main">
          <div class="bm_top">
            <div class="bm_top_title">Business</div>
          </div>
          <div class="searchpage">
            <div style="background: #fff;padding-left: calc(30 * 100vw / 1920)">
              <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="keyword">
                    <el-input v-model="keyword" placeholder="keyword" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getKeywordSearchManufacturers" size="mini">查询</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="search_main">
                <div
                    class="goodlist flex-row Manufacturers"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="detailfun(item.id)"
                >
                    <div class="goodlist_image flex-col justify-center">
                        <img referrerpolicy="no-referrer" :src="$baseUrl + (item.logoImage?item.logoImage:item.image)" />
                    </div>
                    <div class="text flex-col justify-between">
                        <span class="title">{{ item.businessName }}</span>
                        <div class="price flex-col justify-between">
                            <span class="price3"><img src="@/assets/img/phone.png" v-if="item.phone" />{{ item.phone }}</span>
                            <span class="price3"><img src="@/assets/img/youxiang.png" v-if="item.email" />{{ item.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="search_pagenation" style="text-align: center">
                    <el-pagination
                    background
                    :total="total"
                    @current-change="currentChange"
                    :current-page="1"
                    :page-size="8"
                    layout="prev, pager, next"
                    ></el-pagination>
                </div>
            </div>
        </div>
        </div>
        <el-dialog
            title="Apply"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">
            <el-form :model="form" :rules="formRules" ref="formRef">
                <el-form-item label="userName" :label-width="formLabelWidth" prop="userName">
                    <el-input v-model="form.userName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="phone" :label-width="formLabelWidth" prop="phone">
                    <el-input type="number" v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="email" :label-width="formLabelWidth" prop="email">
                    <el-input type="email" v-model="form.email"></el-input>
                </el-form-item>
                <el-form-item label="description" :label-width="formLabelWidth" prop="description">
                    <el-input v-model="form.description"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">Cancel</el-button>
                <el-button type="primary" @click="handleSubmit">Submit</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getKeywordSearchManufacturers, consumerPromotionBusinessApi } from '@/api/news'
export default {
    data() {
        return {
            list: [],
            total: 0,
            pageNum: 1,
            pageSize: 8,
            keyword: "",
            dialogVisible: false,
            formLabelWidth: '100px',
            formRules: {
                userName: [
                    { required: true, message: 'Please enter userName', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: 'Please enter phone', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'Please enter email', trigger: 'blur' },
                ],
                description: [
                    { required: true, message: 'Please enter description', trigger: 'blur' },
                ],
            },
            form: {
                businessId: '',
                userName: '',
                phone: '',
                email: '',
                description: ''
            }
        }
    },
    mounted() {
        this.getKeywordSearchManufacturers()
    },
    methods: {
        detailfun(id) {
            this.form.businessId = id
            this.dialogVisible = true
        },
        currentChange(e) {
            this.pageNum = e
            this.getKeywordSearchManufacturers()
        },
        getKeywordSearchManufacturers() {
            getKeywordSearchManufacturers({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keyword: this.keyword
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data.vos
                    this.total = res.data.total
                }
            })
        },
        handleClose() {
            this.form.businessId = ''
            this.form.userName = ''
            this.form.phone = ''
            this.form.email = ''
            this.form.description = ''
            this.dialogVisible = false            
        },
        handleSubmit() {
            this.$refs.formRef.validate(valid => {
                if (valid) {
                    consumerPromotionBusinessApi(this.form).then(res => {
                        if (res.code == 200) {
                            this.$message({ message: res.msg, type: "success" });
                            this.handleClose()
                        } else if (res.code == 401) {
                            this.$message({ message: res.msg, type: "error" });
                            //未登录
                            this.$router.push({
                                path: "/login",
                                query: {
                                box8: false,
                                phoneHomeImg: false,
                                bool: false,
                                // token: true,
                                },
                            });
                        } else {
                            this.$message({ message: res.msg, type: "error" });
                        }
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
    .businesspromotion_page {
        width: 100%;
        background-color: #f1f1f1;
        min-height: 70vh;
    }
    .businesspromotion {
        /*width: calc(1200 * 100vw / 1920);*/
        width: 100%;
        margin: 0 auto;
    }
    .businesspromotion .directory {
        background-color: #f1f1f1;
        margin: 0;
        margin-top: 1.2vw;
        padding-bottom: 1.2vw;
        font-size: 0.93vw;
        font-family: Roboto;
        font-weight: 400;
        color: #999999;
    }
    .businesspromotion_main {
        background-color: #fff;
        padding-bottom: calc(20 * 100vw / 1920);
    }
    .bm_top {
        width: 100%;
        background-color: #fff;
    }
    .bm_top_title {
        margin-left: calc(40 * 100vw / 1920);
        width: calc(140 * 100vw / 1920);
        height: calc(75 * 100vw / 1920);
        white-space: nowrap;
        line-height: calc(75 * 100vw / 1920);
        border-bottom: 5px solid rgba(19, 175, 135, 1);
        font-weight: bolder;
        font-size: 1.28vw;
    }
    @media screen and (max-width: 1080px) {
        ::v-deep .el-upload-list__item {
            display: none;
        }
        .businesspromotion_page {
            width: 100vw;
            background-color: #f1f1f1;
            position: relative;
        }
        .businesspromotion {
            width: 100%;
            margin: 0;
        }
        .businesspromotion .directory,
        .bm_list_delete,
        .pagenation {
            display: none;
        }
        .businesspromotion_main {
            margin-top: calc(20 * 100vw / 1080);
            margin-bottom: 0;
            background-color: #fff;
        }
        .bm_top {
        width: 100%;
        height: calc(114 * 100vw / 1080);
        background-color: #fff;
        border-bottom: calc(1 * 100vw / 1080) solid rgb(240, 239, 239);
        padding-top: calc(32 * 100vw / 1080);
        }
        .bm_top_title {
        margin-left: calc(40 * 100vw / 1080);
        width: calc(280 * 100vw / 1080);
        height: calc(82 * 100vw / 1080);
        line-height: calc(82 * 100vw / 1080);
        white-space: nowrap;
        border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
        font-weight: bolder;
        font-size: calc(36 * 100vw / 1080);
        }
    }
     @media screen and (max-width: 1080px) {
    .el-message-box {
      width: 101%;
    }
  }
  
  .searchpage {
  width: 100%;
  background-color: #f1f1f1;
}

.directory {
  /*width: calc(1200 * 100vw / 1920);*/
  margin: calc(20 * 100vw / 1920) auto 0;
  width: 100%;
  background-color: #f1f1f1;
  padding-bottom: 1.2vw;
  font-size: 0.93vw;
  font-family: Roboto;
  font-weight: 400;
  color: #999999;
}
.search_main {
  /*width: calc(1200 * 100vw / 1920);*/
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* padding: calc(30 * 100vw / 1920) calc(40 * 100vw / 1920); */
  padding: calc(30 * 100vw / 1920) calc(30 * 100vw / 1920);
}
.search_main_else {
  width: calc(1200 * 100vw / 1920);
  height: calc(500 * 100vw / 1920);
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: calc(30 * 100vw / 1920) calc(40 * 100vw / 1920);
}
.goodlist {
  /* width: calc(530 * 100vw / 1920); */
  width: calc(25% - calc(20 * 100vw / 1920));
  height: calc(160 * 100vw / 1920);
  border: 1px solid #ddd;
  /* margin-bottom: calc(20 * 100vw / 1920); */
  margin: 0 calc(10 * 100vw / 1920) calc(20 * 100vw / 1920) calc(10 * 100vw / 1920);
  padding: calc(20 * 100vw / 1920);
}
.goodlist_1{
  height: calc(170 * 100vw / 1920);
}
.goodlist_image {
  width: calc(120 * 100vw / 1920);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(10 * 100vw / 1920);
}
.goodlist_image img {
  max-height: 99%;
  max-width: 99%;
}
.text {
  flex: 1;
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto-Light;
  word-wrap: break-word;
  overflow: hidden;
}
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /*控制文字行数*/
  text-overflow: ellipsis;
  /*显示省略符号来代表被修剪的文本*/
  overflow: hidden;
  width: 100%;
}
.price {
  width: 100%;
  margin-top: calc(18 * 100vw / 1920);
}
.goodlist_1 .price {
  width: 100%;
  margin-top: calc(10 * 100vw / 1920);
}
.price1 {
  width: 100%;
  text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
  overflow: hidden;
  white-space: nowrap; /*不换行 控制单行文本*/
  color: rgba(170, 170, 170, 1);
  text-decoration: line-through;
}
.price2 {
  width: 100%;
  text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
  overflow: hidden;
  white-space: nowrap; /*不换行 控制单行文本*/
  color: rgba(255, 0, 0, 1);
}
.price3 {
  width: 100%;
  text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
  overflow: hidden;
  white-space: nowrap; /*不换行 控制单行文本*/
  color: rgba(170, 170, 170, 1);
  display: flex;
  align-items: center;
}
.price3 img{
  width: calc(20 * 100vw / 1920);
  margin-right: calc(10 * 100vw / 1920);
}
.search_pagenation {
  width: 100%;  
  overflow-x: scroll;
}
@media screen and (max-width: 1080px) {
  .price3 img{
    width: calc(30 * 100vw / 1080);
    margin-right: calc(20 * 100vw / 1080);
  }
  .searchpage {
    width: 100vw;
    background-color: #f1f1f1;
    padding-bottom: calc(30 * 100vw / 1920);
    border-top: 4px solid #ddd;
  }

  .directory {
    display: none;
  }
  .search_main {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    /* justify-content: center; */
    padding: calc(30 * 100vw / 1080) calc(40 * 100vw / 1080);
    min-height: 70vh;
  }
  .goodlist {
    width: calc(900 * 100vw / 1080);
    height: calc(350 * 100vw / 1080);
    border: 1px solid #ddd;
    margin-bottom: calc(30 * 100vw / 1080);
    padding: 0;
    padding-right: calc(20 * 100vw / 1080);
  }
  .goodlist_1{
    height: calc(250 * 100vw / 1080);
  }
  .goodlist_image {
    width: calc(350 * 100vw / 1080);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: calc(20 * 100vw / 1080);
  }

  .text {
    padding: calc(20 * 100vw / 1080) 0;
    font-size: calc(36 * 100vw / 1080);
    word-wrap: break-word;
    font-family: Roboto-Light;
  }
  .price {
    margin-top: calc(30 * 100vw / 1080);
  }
  .price1 {
    color: rgba(170, 170, 170, 1);
    text-decoration: line-through;
  }
  .price2 {
    color: rgba(255, 0, 0, 1);
  }
}
  


</style>