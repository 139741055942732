<template>
  <div class="swiper">
    <!-- 轮播内容 -->
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <!-- 分页 -->
    <div class="swiper-pagination"></div>

    <!-- 翻页按钮 -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>

    <!-- 滚条 -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>
</template>
<script>
  import "swiper/css/bundle";
  // import { Swiper } from "swiper/bundle";

  export default {
    name: "SwiperBox",
    data() {
      return {};
    },
    components: {},
    computed: {},
    watch: {},
    methods: {},
  };
</script>

<style scoped>
  .swiper {
    width: 100%;
    height: 100%;
  }
</style>