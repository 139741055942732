
<template>
  <div id="maps"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "22f2e3b502f5852aff92be85475d1b54",
};
export default {
  name: "maps",
  props: {
    longitude: {
      type: Number,
      default: 0,
    },
    latitude: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
    };
  },

  mounted() {
    // this.$set(this.longitude, this.latitude);
    this.initMap();

    // console.log(this.longitude, this.latitude);
  },

  methods: {
    initMap() {
      AMapLoader.load({
        key: "fb04ddbc03dd7760829987d632169d68",
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.ControlBar",
          "AMap.MouseTool",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // 初始化地图
          this.map = new AMap.Map("maps", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 13, // 初始化地图级别
            center: [this.longitude, this.latitude], //中心点坐标  郑州
            resizeEnable: true,
          });
          this.mouseTool = new AMap.MouseTool(this.map);
          this.mouseTool.on(
            "draw",
            function (e) {
              this.overlays.push(e.obj);
            }.bind(this)
          );
          this.setMarker();
        })
        .catch((e) => {});
    },
    setMarker() {
      let lnglat = [this.longitude, this.latitude];
      let marker = new AMap.Marker({
        position: lnglat,
      });
      marker.setMap(this.map);
      this.markers.push(marker);
    },
  },
};
</script>

<style scoped>
#maps {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "微软雅黑";
}
</style>

<style>
/* 隐藏高德logo  */
.amap-logo {
  display: none !important;
}
/* 隐藏高德版权  */
.amap-copyright {
  display: none !important;
}
