<template>
  <div class="swiper-slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SweiperItem",
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.swiper-slide {
  /* width: 100%; */
  /* height: 100%; */
}
.swiper-slide img {
  /* width: 100%; */
  /* height: 100%; */
}
</style>
