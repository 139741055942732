import request from '../utils/request'

// 手机号码注册
export function registerApi(data) {
  return request({
    url: '/other/register',
    method: 'post',
    data
  })
}
// 商家入驻
export function merchantSettlementApi(data) {
  return request({
    url: '/other/merchantSettlement',
    method: 'post',
    data
  })
}
// 商家支付入驻费
export function payMerchantSettlement(data) {
  return request({
    url: '/other/payMerchantSettlement/',
    method: 'post',
    data
  })
}
// 判断商家是否支付
export function searchOrderPay(orderNo) {
  return request({
    url: '/other/searchOrderPay/'+orderNo,
    method: 'post'
  })
}
// 将二维码地址转为流输出
export function outCodeUrl(data) {
  return request({
    url: '/other/outCodeUrl',
    method: 'post',
    responseType: 'blob',
    data: data
  })
}
// 邮箱登录
export function loginByEmailApi(data) {
  return request({
    url: '/other/loginByEmail',
    method: 'post',
    data
  })
}
// 发送邮箱验证码
export function getCode(email) {
  return request({
    url: '/other/getCode/'+email,
    method: 'post'
  })
}

// 邮箱注册
export function registerEmail(data) {
  return request({
    url: '/other/registerEmail',
    method: 'post',
    data
  })
}