<template>
  <div class="essential_page">
    <div class="essential_title">基本信息</div>
    <div class="essential_main">
      <div class="essential_router_parent">
        <div class="essential_router flex-row">
          <div class="essential_router_merchant" @click="merchantClick">
            贸易商信息
          </div>
          <div class="essential_router_employee" @click="employeeClick">
            员工管理
          </div>
          <div class="essential_router_coupon" @click="couponClick">
            优惠券管理
          </div>
          <div class="essential_router_password" @click="passwordClick">
            修改密码
          </div>
        </div>
      </div>
      <keep-alive><router-view></router-view></keep-alive>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BusinessEssential",
    data() {
      return {
        path: "/businesspersonal/businessessential/merchant",
      };
    },
    components: {},
    computed: {},
    watch: {},
    methods: {
      activeClick(e) {
        let childrens = document.querySelector(".essential_router").children;
        for (let index = 0; index < childrens.length; index++) {
          childrens[index].classList.remove("active");
        }
        e.currentTarget.classList.add("active");
      },
      merchantClick(e) {
        this.activeClick(e);
        this.$router.push({
          path: "/businesspersonal/businessessential/merchant",
          query: {
            noBusiness: false,
            businesspersonalPath: "/businesspersonal/businessessential/merchant",
            phoneHomeImg: false,
          },
        });
      },
      passwordClick(e) {
        this.activeClick(e);
        this.$router.push({
          path: "/businesspersonal/businessessential/updatepwd",
          query: {
            noBusiness: false,
            businesspersonalPath: "/businesspersonal/businessessential/updatepwd",
            phoneHomeImg: false,
          },
        });
      },
      employeeClick(e) {
        this.activeClick(e);
        this.$router.push({
          path: "/businesspersonal/businessessential/employee",
          query: {
            noBusiness: false,
            businesspersonalPath: "/businesspersonal/businessessential/employee",
            phoneHomeImg: false,
          },
        });
      },
      couponClick(e) {
        this.activeClick(e);
        this.$router.push({
          path: "/businesspersonal/businessessential/coupon",
          query: {
            noBusiness: false,
            businesspersonalPath: "/businesspersonal/businessessential/coupon",
            phoneHomeImg: false,
          },
        });
      },
    },
    mounted() {
      document
        .querySelector(".essential_router_merchant")
        .classList.add("active");
    },
    // 组件内部导航守卫
    beforeRouteLeave(to, from, next) {
      this.path = this.$route.path;
      next();
    },
    activated() {
      // if (!(this.$route.query.isbottomnav === "true")) {
      //   this.$router.push({
      //     path: this.path,
      //     query: {
      //       phoneHomeImg: false,
      //       noBusiness: false,
      //       businesspersonalPath: this.path,
      //       phoneHomeImg: false,
      //     },
      //   });
      // }
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      document.querySelector(".bottomnav_me").style.color = "#13AF87";
    },
  };
</script>

<style scoped>
  .essential_page {
    /*width: calc(890 * 100vw / 1920);*/
    width: 100%;
    /* height: calc(750 * 100vw / 1920); */
    height: 100%;
    background: #ffffff;
  }

  .essential_title {
    margin-left: calc(38 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .essential_main {
    border-top: 1px solid #ddd;
    /* padding-top: calc(58 * 100vw / 1920); */
    margin-bottom: calc(55 * 100vw / 1920);
  }

  .essential_router {
    width: 100%;
    margin-top: calc(30 * 100vw / 1920);
    /*justify-content: space-between;*/
    padding: 0 calc(10 * 100vw / 1920);
  }

  .essential_router div[class^="essential_router_"] {
    width: calc(200 * 100vw / 1920);
    height: calc(38 * 100vw / 1920);
    font-size: calc(16 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #13af87;
    line-height: calc(38 * 100vw / 1920);
    text-align: center;
    border: 1px solid #13af87;
    border-radius: calc(19 * 100vw / 1920);
    margin-right: calc(30 * 100vw / 1920);
    /* margin-right: calc(50 * 100vw / 1920); */
  }

  .active {
    background: #13af87 !important;
    color: #ffffff !important;
  }

  @media screen and (max-width: 1080px) {
    .essential_page {
      width: 100vw;
      height: calc(1600 * 100vw / 1080);
      background-color: #fff;
    }

    .essential_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(150 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(170 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .essential_main {
      border-top: 1px solid #ddd;
      /* padding-left: calc(40 * 100vw / 1080); */
      /* padding-right: calc(50 * 100vw / 1080); */
      margin-bottom: calc(590 * 100vw / 1080);
    }

    .essential_router_parent {
      height: calc(150 * 100vw / 1080);
      overflow: hidden;
    }

    .essential_router {
      width: 100%;
      height: calc(170 * 100vw / 1080);
      margin-top: 0;
      justify-content: start;
      align-items: center;
      overflow: hidden;
      overflow-x: scroll;
      background: #f6f6f6;
      padding-left: calc(40 * 100vw / 1080);
    }

    .essential_router div[class^="essential_router_"] {
      width: calc(380 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #13af87;
      line-height: calc(50 * 100vw / 1080);
      text-align: center;
      border: 1px solid #13af87;
      border-radius: calc(30 * 100vw / 1080);
      margin-right: calc(60 * 100vw / 1080);
      margin-bottom: calc(10 * 100vw / 1080);
    }
  }
</style>