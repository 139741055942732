import request from '../utils/request'

// 推荐商品
export function getboutiqueCommodity(data) {
  return request({
    url: '/index/boutiqueCommodity',
    method: 'post',
    data
  })
}
// 商家推荐商品
export function getShopBoutiqueCommodity(data) {
  return request({
    url: '/shop/boutiqueCommodity',
    method: 'post',
    data
  })
}
// 热门商品
export function gethotCommodity(data) {
  return request({
    url: '/index/hotCommodity',
    method: 'post',
    data
  })
}
// 商家热门商品
export function getShopHotCommodity(data) {
  return request({
    url: '/shop/hotCommodity',
    method: 'post',
    data
  })
}
// 商家其他商品
export function getShopOhterCommodity(data) {
  return request({
    url: '/shop/otherCommodity',
    method: 'post',
    data
  })
}
// 新商品
export function getnewCommodity(data) {
  return request({
    url: '/index/newCommodity',
    method: 'post',
    data
  })
}
// 商家新商品
export function getShopNewCommodity(data) {
  return request({
    url: '/shop/newCommodity',
    method: 'post',
    data
  })
}
// 合作伙伴
export function getcooperativePartner(data) {
  return request({
    url: '/index/cooperativePartner',
    method: 'post',
    data
  })
}

// 地址簿列表
export function getaddressBookList(data) {
  return request({
    url: '/consumer/addressBookList',
    method: 'post',
    data
  })
}

// 基本信息
export function getmyInfo(data) {
  return request({
    url: '/consumer/myInfo',
    method: 'post',
    data
  })
}

// 修改基本信息
export function editMyInfo(data) {
  return request({
    url: '/consumer/editMyInfo',
    method: 'post',
    data
  })
}

// 公共商品分类
export function getcommonCategory(data) {
  return request({
    url: '/index/commonCategory',
    method: 'post',
    data
  })
}
// 商家商品分类
export function getShopCommonCategory(data) {
  return request({
    url: '/shop/commonCategory',
    method: 'post',
    data
  })
}
// 消费者商品列表
export function getconsumerCommodity(data) {
  return request({
    url: '/index/consumerCommodity',
    method: 'post',
    data
  })
}
// 商家商品列表
export function getshopCommodityList(data) {
  return request({
    url: '/index/shopCommodityList',
    method: 'post',
    data
  })
}
// 消费者商品详情
export function getconsumerCommodityDetail(data) {
  return request({
    url: '/index/consumerCommodityDetail/' + data,
    method: 'post'
  })
}

// 商品加入购物车
export function shopAddCarApi(data) {
  return request({
    url: '/car/shopAddCar',
    method: 'post',
    data
  })
}

// 获取购物车列表
export function getShopCarList(data) {
  return request({
    url: '/car/getShopCarList',
    method: 'post',
    data
  })
}

// 删除购物车商品
export function deleteCar(data) {
  return request({
    url: '/car/deleteCar/' + data,
    method: 'post',
  })
}

// 修改购物车商品数量
export function updateCarNum(data) {
  return request({
    url: '/car/updateCarNum',
    method: 'post',
    data
  })
}

// 订单列表
export function getmyOrderList(data) {
  return request({
    url: '/consumer/myOrderList',
    method: 'post',
    data
  })
}

// 展示购物车所选中的结算列表
export function showPayCarList(data) {
  return request({
    url: '/car/showPayCarList/' + data,
    method: 'post',
  })
}

// 添加地址簿
export function addAddressBook(data) {
  return request({
    url: '/consumer/addAddressBook',
    method: 'post',
    data
  })
}

// 删除地址簿
export function delAddressBook(data) {
  return request({
    url: '/consumer/delAddressBook/' + data,
    method: 'post',
  })
}

// 修改地址簿
export function editAddressBook(data) {
  return request({
    url: '/consumer/editAddressBook',
    method: 'post',
    data
  })
}

//商品详情购买
export function buyGoods(data) {
  return request({
    url: '/consumer/buyGoods',
    method: 'post',
    data
  })
}

//推荐用户详情购买
export function buyPromotionGoodsApi(data) {
  return request({
    url: '/consumer/buyPromotionGoods',
    method: 'post',
    data
  })
}

//购买单个商品支付
export function buyGoodsPay(data) {
  return request({
    url: '/consumer/buyGoodsPay',
    method: 'post',
    data
  })
}

// 取消订单
export function cancelOrder(data) {
  return request({
    url: '/consumer/cancelOrder/' + data,
    method: 'post',
  })
}

//删除订单
export function delOrder(data) {
  return request({
    url: '/consumer/delOrder/' + data,
    method: 'post',
  })
}

// 用户领取优惠券
export function userGetCoupon(data) {
  return request({
    url: '/coupon/userGetCoupon/' + data,
    method: 'post',
  })
}

// 购物车商品支付
export function addCarOrder(data) {
  return request({
    url: '/car/addCarOrder',
    method: 'post',
    data
  })
}

//购买购物车商品支付
export function carOrderPay(data) {
  return request({
    url: '/car/carOrderPay',
    method: 'post',
    data
  })
}

// 取消购物车订单
export function cancelCarOrder(data) {
  return request({
    url: '/car/cancelCarOrder/' + data,
    method: 'post',
  })
}

// 查询订单详情
export function ordersGet(data) {
  return request({
    url: '/v1/order/ordersGet',
    method: 'post',
    data
  })
}

// 执行扣款
export function capturesOrder(data) {
  return request({
    url: '/v1/payPal/capturesOrder',
    method: 'post',
    data
  })
}


// 查询扣款详情
export function capturesGet(data) {
  return request({
    url: '/v1/payPal/capturesGet',
    method: 'post',
    data
  })
}

// 优惠券列表
export function consumerCouponList(data) {
  return request({
    url: '/consumer/consumerCouponList/' + data,
    method: 'post',
  })
}
// 合作伙伴
export function cooperativePartner(data) {
  return request({
    url: '/index/cooperativePartner',
    method: 'post',
    data
  })
}

// 获取商家收款码信息
export function getBusinessQrcodeCollection(data) {
  return request({
    url: '/consumer/getBusinessQrcodeCollection/' + data,
    method: 'post',
  })
}

// 获取商家银行卡收款账号信息
export function getBusinessBrandCollection(data) {
  return request({
    url: '/consumer/getBusinessBrandCollection/' + data,
    method: 'post',
  })
}

// 再来一单
export function againAddOrder(orderNo, orderType) {
  return request({
    url: `/consumer/againAddOrder/${orderNo}/${orderType}`,
    method: 'post',
  })
}

// 消费者询盘列表
export function consumerEnquiriesList(data) {
  return request({
    url: '/consumer/consumerEnquiriesList',
    method: 'post',
    data
  })
}

// 消费者删除询盘
export function consumerDelEnquiries(data) {
  return request({
    url: '/consumer/consumerDelEnquiries/' + data,
    method: 'post',
  })
}


//关键字搜索商家
export function keywordSearchApi(data) {
  return request({
    url: '/index/keywordSearch',
    method: 'post',
    data
  })
}

//getAddOrderUserEmail
export function getAddOrderUserEmail(data) {
  return request({
    url: '/shop/getAddOrderUserEmail',
    method: 'post',
    data
  })
}

//获取商品下的评论
export function consumerAppraiseListApi(data) {
  return request({
    url: '/index/consumerAppraiseList',
    method: 'post',
    data
  })
}

//推广提成记录
export function consumerCommissionList(data) {
  return request({
    url: '/promotion/consumer/consumerCommissionList',
    method: 'post',
    data
  })
}

//推广提成记录
export function consumerPromotionCashList(data) {
  return request({
    url: '/promotion/consumer/consumerPromotionCashList',
    method: 'post',
    data
  })
}

//查看绑定的推广商家
export function consumerLookPromotionBusiness(data) {
  return request({
    url: '/promotion/consumer/consumerLookPromotionBusiness',
    method: 'post',
    data
  })
}

//申请推广商家记录
export function consumerPromotionList(data) {
  return request({
    url: '/promotion/consumer/consumerPromotionList',
    method: 'post',
    data
  })
}

//查看绑定推广提成银行卡信息
export function consumerLookBindBankInfo(data) {
  return request({
    url: '/promotion/consumer/consumerLookBindBankInfo',
    method: 'post',
    data
  })
}

//绑定或修改推广提成银行卡信息
export function consumerBindOrUpdateBankInfo(data) {
  return request({
    url: '/promotion/consumer/consumerBindOrUpdateBankInfo',
    method: 'post',
    data
  })
}


//申请提现推广佣金
export function consumerPromotionCash(data) {
  return request({
    url: '/promotion/consumer/consumerPromotionCash',
    method: 'post',
    data
  })
}

//解绑推广商家
export function consumerUnbindPromotionBusiness(data) {
  return request({
    url: '/promotion/consumer/consumerUnbindPromotionBusiness',
    method: 'post',
    data
  })
}









