<template>
  <swiper-box class="fineCeramicsSwiper">
    <!-- <swiper-item class="swiper-item">
      <img src="../../../../assets/img/图层2.png" alt="" />
    </swiper-item>
    <swiper-item class="swiper-item">
      <img src="../../../../assets/img/图层2.png" alt="" />
    </swiper-item>
    <swiper-item class="swiper-item">
      <img src="../../../../assets/img/图层2.png" alt="" />
    </swiper-item> -->
    <swiper-item class="swiper-item">
      <img :src="$baseUrl + list.image" alt="" />
    </swiper-item>
    <swiper-item
      class="swiper-item"
      v-for="(item, index) in list.detailsImage"
      :key="index"
    >
      <img :src="$baseUrl + item" alt="" />
    </swiper-item>
  </swiper-box>
</template>

<script>
import { Swiper } from "swiper/bundle";
import "swiper/css/bundle";

import { SwiperBox, SwiperItem } from "@/components/swiper";
export default {
  name: "FineCeramicsSwiper",
  data() {
    return {
      swiper1: {},
    };
  },
  props: ["list"],
  components: {
    SwiperBox,
    SwiperItem,
  },
  computed: {},
  watch: {
    // $route(to, from) {
    //   if (document.querySelector(".fineCeramicsSwiper")) {
    //     // console.log(this.list);
    //     // this.$$nextTick(() => {
    //     //   this.initSwiper();
    //     // });
    //   }
    // },
  },
  methods: {
    // 创建轮播图
    initSwiper() {
      return new Swiper(".fineCeramicsSwiper", {
        // 轮播图方向，"vertical" 垂直，"horizontal" 水平
        direction: "horizontal",
        // 是否循环
        loop: true,
        //修改swiper自己或子元素时，自动初始化swiper
        observer: true,
        //修改swiper的父元素时，自动初始化swiper
        observerParents: true,
        // 环绕,在最后一张点击next，回到第一张，在第一张点击prev回到最后一张，不能与loop同用
        rewind: true,
        // 自动播放
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        // 切换持续时间
        speed: 1000,
        // 是否需要轮播图小圆点
        pagination: {
          el: ".swiper-pagination",
        },

        // 切换上下的箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // 滚条
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        // },

        // 可视窗口内，slide的数量
        slidesPerView: 1,

        // 一次性跳几个slide，当slidesPerView>1时配用很合适
        // slidesPerGroup: 1,

        // 设置间距
        // spaceBetween: 10,
        // 断点，自适应
      });
    },
  },
  // 数据变化时
  updated() {
    // 销毁原轮播图
    if (this.swiper1) {
      this.swiper1.destroy();
    }
    // 创建新轮播图
    this.swiper1 = this.initSwiper();
  },
  mounted() {
    // 初始化
    this.swiper1 = this.initSwiper();
  },
};
</script>

<style scoped>
.swiper-item {
  /* padding: calc(138 * 100vw / 1080) calc(283 * 100vw / 1080); */
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  max-width: 95%;
  max-height: 95%;
}
</style>
