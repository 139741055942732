<template>
  <div class="address_page">
    <div class="address_top">
      <div class="address_title">Ship To Address</div>
      <div class="pcadd">
        <div class="addNew">
          <button @click="addClick">Add new address</button>
        </div>
      </div>
    </div>

    <div class="address_main">
      <address-list :list="list" :deletefun="deletefun"></address-list>
      <div class="addNew2">
        <button @click="addClick">Add new address</button>
      </div>
    </div>
    <div class="pageNation" style="text-align: center" v-if="total > 0">
      <!-- <page-nation></page-nation> -->
      <el-pagination background :total="total" :current-page="pageNum" :page-size="pageSize"
        @current-change="currentChange" layout="prev, pager, next, jumper"></el-pagination>
    </div>
    <router-view :key="$route.address"></router-view>
  </div>
</template>

<script>
  import PageNation from "@/components/pagenation/PageNation.vue";
  import AddressList from "./addresslist/AddressList.vue";
  import { getaddressBookList, delAddressBook } from "../../../api/index";
  export default {
    name: "Address",
    data() {
      return {
        pageSize: 2,
        pageNum: 1,
        list: [],
        total: 0,
      };
    },
    components: { PageNation, AddressList },
    computed: {},
    watch: {},
    created() {
      this.getaddressBookList();
    },
    methods: {
      deletefun(e) {
        delAddressBook(e).then((res) => {
          if (res.code == 200) {
            this.getaddressBookList();
          }
        });
      },
      getaddressBookList() {
        getaddressBookList({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        }).then((res) => {
          if (res.code == 200) {
            this.list = res.data.vos;
            this.total = res.data.total;
          }
        });
      },
      currentChange(e) {
        this.pageNum = e;
        this.getaddressBookList();
      },
      addClick() {
        this.$router.push({
          path: "/addaddress",
          query: {
            bool: false,
            box8: false,
            phoneHomeImg: false,
          },
        });
      },
    },
    mounted() {
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      this.getaddressBookList();
      // let bottomnav = document.querySelector(".bottomnav");
      // for (let i = 0; i < bottomnav.children.length; i++) {
      //   bottomnav.children[i].style.color = "#aaa";
      // }
      let childrens = document.querySelector(
        ".personalcenter_nav_router"
      ).children;
      for (let i = 0; i < childrens.length; i++) {
        // 给兄弟元素添加属性
        // childrens[i].classList.remove("nav_active");
        childrens[i].style.backgroundColor = "#fff";
      }
      childrens[2].style.backgroundColor = "#d3f5ec";
    },
    activated() {
      this.getaddressBookList();
      // let bottomnav = document.querySelector(".bottomnav");
      // for (let i = 0; i < bottomnav.children.length; i++) {
      //   bottomnav.children[i].style.color = "#aaa";
      // }
      let childrens = document.querySelector(
        ".personalcenter_nav_router"
      ).children;
      for (let i = 0; i < childrens.length; i++) {
        // 给兄弟元素添加属性
        // childrens[i].classList.remove("nav_active");
        childrens[i].style.backgroundColor = "#fff";
      }
      childrens[2].style.backgroundColor = "#d3f5ec";
    },
  };
</script>

<style scoped>
  .address_page {
    /* width: calc(890 * 100vw / 1920); */
    /* width: calc(100% - calc(310 * 100vw / 1920)); */
    width: 100%;
    height: calc(620 * 100vw / 1920);
    background: #ffffff;
  }

  .address_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .addNew {
    margin-right: calc(38 * 100vw / 1920);
  }

  .addNew button {
    width: calc(170 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    text-align: center;
    border: 1px solid #13af87;
    background-color: #13af87;
    border-radius: calc(15 * 100vw / 1920);
    color: #fff;
    line-height: calc(20 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
  }

  .address_title {
    margin-left: calc(38 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .address_main {
    border-top: 1px solid #ddd;
    padding-left: calc(35 * 100vw / 1920);
    /* padding-top: calc(23 * 100vw / 1920);
    margin-bottom: calc(38 * 100vw / 1920); */
  }

  .address-list-default {
    display: block;
    width: calc(160 * 100vw / 1920);
    height: calc(36 * 100vw / 1920);
    background: #13af87;
    border-radius: calc(18 * 100vw / 1920);
    color: #ffffff;
    font-size: calc(18 * 100vw / 1920);
  }

  .addNew2 {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    .address_page {
      width: 100vw;
      height: calc(2030 * 100vw / 1080);
      background-color: #fff;
      border-top: 1px solid #ddd;
      overflow: auto;
    }

    .address_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(150 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(170 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .address_main {
      height: calc(1830 * 100vw / 1080);
      border-top: 1px solid #ddd;
      padding-left: calc(40 * 100vw / 1080);
      padding-right: calc(40 * 100vw / 1080);
      padding-top: calc(40 * 100vw / 1080);
      margin-bottom: 0;
      background-color: #f6f6f6;
    }

    .address-list-default {
      display: block;
      width: calc(290 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #13af87;
      border-radius: calc(30 * 100vw / 1080);
      color: #ffffff;
      font-size: calc(36 * 100vw / 1080);
      margin-left: calc(635 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
    }

    .pageNation {
      /* display: none; */
      /* position: fixed;
      bottom: 100px; */
      margin-bottom: calc(20* 100vw / 1080);
    }

    .pcadd {
      display: none;
    }

    .addNew,
    .addNew2 {
      /* position: absolute;
    bottom: 0; */
      display: flex;
      width: 100%;
      height: calc(150 * 100vw / 1080);
      box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
      background-color: #fff;
      align-items: center;
      justify-content: center;
    }

    .addNew,
    .addNew2 button {
      width: calc(500 * 100vw / 1080);
      height: calc(100 * 100vw / 1080);
      border: 1px solid #13af87;
      background: #13af87;
      color: #fff;
      border-radius: calc(50 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      line-height: calc(60 * 100vw / 1080);
      text-align: center;
    }

    /* .addNew span {
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  } */
  }
</style>