<template>
  <div class="updatepwd_page">
    <div class="updatepwd_title">Change the password</div>
    <div class="updatepwd">
      <div class="updatepwd_main" v-if="isUpdatepwd">
        <div class="updatepwd_address flex-row">
          <div class="title">Email</div>
          <input
            type="text"
            placeholder="Please enter your email"
            v-model="UpdatePwd.email"
          />
        </div>
        <div class="updatepwd_address flex-row">
          <div class="title">Original password</div>
          <input
            type="text"
            placeholder="Please enter your original password"
            v-model="UpdatePwd.oldPwd"
          />
        </div>
        <div class="updatepwd_address flex-row">
          <div class="title">New password</div>
          <input
            type="text"
            placeholder="Please enter your New password"
            v-model="UpdatePwd.pwd"
          />
        </div>
        <div class="updatepwd_address flex-row">
          <div class="title">Confirm password</div>
          <input
            type="text"
            placeholder="Please confirm the password you entered"
            v-model="UpdatePwd.confirmPwd"
          />
        </div>
        <button class="password_button" @click="ForgetPwdfn">
          Forgot the password
        </button>
        <button class="confirm" @click="updatepwdfn">Submit</button>
      </div>
      <div class="updatepwd_main" v-else>
        <div class="updatepwd_address flex-row">
          <div class="title">Email</div>
          <input
            type="text"
            placeholder="Please enter your email"
            v-model="ForgetPwd.email"
          />
        </div>
        <div class="updatepwd_address flex-row">
          <input class="code" type="text" v-model="ForgetPwd.code" />
          <button class="code_button" @click="codefn">Verify Mailbox</button>
        </div>
        <div class="updatepwd_address flex-row">
          <div class="title">New password</div>
          <input
            type="text"
            placeholder="Please enter your New password"
            v-model="ForgetPwd.pwd"
          />
        </div>
        <div class="updatepwd_address flex-row">
          <div class="title">Confirm password</div>
          <input
            type="text"
            placeholder="Please confirm the password you entered"
            v-model="ForgetPwd.confirmPwd"
          />
        </div>
        <button class="password_button" @click="ForgetPwdfn">
          Change the password
        </button>
        <button class="confirm" @click="ForgetPwdsubmit">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getmyInfo } from "@/api/index";
import { userUpdatePwd, pwdGetCode, userForgetPwd } from "@/api/other";

export default {
  name: "Password",
  data() {
    return {
      isUpdatepwd: true,
      UpdatePwd: {
        // 更新密码
        email: "",
        oldPwd: "",
        pwd: "",
        confirmPwd: "",
      },
      ForgetPwd: {
        // 忘记密码
        email: "",
        code: "",
        pwd: "",
        confirmPwd: "",
      },
    };
  },
  components: {},
  computed: {},
  watch: {},
  mounted() {
    this.getShopInfo();
    this.$bus.$on("email", (res) => {
      if (res) {
        this.UpdatePwd.email = res;
        this.ForgetPwd.email = res;
      }
    });
    let bottomnav = document.querySelector(".bottomnav");
    for (let i = 0; i < bottomnav.children.length; i++) {
      bottomnav.children[i].style.color = "#aaa";
    }
    let childrens = document.querySelector(
      ".personalcenter_nav_router"
    ).children;
    for (let i = 0; i < childrens.length; i++) {
      // 给兄弟元素添加属性
      // childrens[i].classList.remove("nav_active");
      childrens[i].style.backgroundColor = "#fff";
    }
    childrens[4].style.backgroundColor = "#d3f5ec";
  },
  created() {
    this.getShopInfo();
    this.$bus.$on("email", (res) => {
      if (res) {
        this.UpdatePwd.email = res;
        this.ForgetPwd.email = res;
      }
    });
  },
  methods: {
    getShopInfo() {
      getmyInfo().then((res) => {
        if (res.code == 200) {
          this.UpdatePwd.email = res.data.email;
          this.ForgetPwd.email = res.data.email;
        }
      });
    },
    ForgetPwdfn() {
      this.isUpdatepwd = !this.isUpdatepwd;
      this.UpdatePwd.oldPwd = "";
      this.UpdatePwd.pwd = "";
      this.UpdatePwd.confirmPwd = "";
      this.ForgetPwd.code = "";
      this.ForgetPwd.pwd = "";
      this.ForgetPwd.confirmPwd = "";
    },
    // 修改提交
    updatepwdfn() {
      if (!this.UpdatePwd.email) {
        this.$message({ message: "Please enter your email ", type: "warning" });
        return;
      }
      if (!this.UpdatePwd.oldPwd) {
        this.$message({
          message: "Please enter the original password",
          type: "warning",
        });
        return;
      }
      if (!this.UpdatePwd.pwd) {
        this.$message({
          message: "Please enter new password",
          type: "warning",
        });
        return;
      }
      if (!this.UpdatePwd.confirmPwd) {
        this.$message({
          message: "Please confirm your password",
          type: "warning",
        });
        return;
      }
      if (this.UpdatePwd.confirmPwd != this.UpdatePwd.pwd) {
        this.$message({
          message:
            "The confirmation password is not the same as the new password",
          type: "warning",
        });
        return;
      } else {
        userUpdatePwd(this.UpdatePwd).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "The modification was successful",
              type: "success",
            });
            this.UpdatePwd.oldPwd = "";
            this.UpdatePwd.pwd = "";
            this.UpdatePwd.confirmPwd = "";
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      }
    },
    // 获取验证码
    codefn() {
      pwdGetCode(this.ForgetPwd.email).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "The verification code was sent successfully.",
            type: "success",
          });
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    // 忘记密码提交
    ForgetPwdsubmit() {
      if (!this.ForgetPwd.email) {
        this.$message({ message: "Please enter your email", type: "warning" });
        return;
      }
      if (!this.ForgetPwd.code) {
        this.$message({
          message: "Please obtain a verification code",
          type: "warning",
        });
        return;
      }
      if (!this.ForgetPwd.pwd) {
        this.$message({
          message: "Please enter a new password",
          type: "warning",
        });
        return;
      }
      if (!this.ForgetPwd.confirmPwd) {
        this.$message({
          message: "Please confirm your password",
          type: "warning",
        });
        return;
      }
      if (this.ForgetPwd.confirmPwd != this.ForgetPwd.pwd) {
        this.$message({
          message:
            "The confirmation password is not the same as the new password",
          type: "warning",
        });
        return;
      } else {
        userForgetPwd(this.ForgetPwd).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "The modification was successful",
              type: "success",
            });
            this.ForgetPwd.code = "";
            this.ForgetPwd.pwd = "";
            this.ForgetPwd.confirmPwd = "";
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.updatepwd_page {
  /* width: calc(890 * 100vw / 1920); */
  width: 100%;
  height: calc(620 * 100vw / 1920);
  background: #ffffff;
}
.updatepwd_title {
  margin-left: calc(38 * 100vw / 1920);
  width: calc(140 * 100vw / 1920);
  height: calc(75 * 100vw / 1920);
  white-space: nowrap;
  line-height: calc(75 * 100vw / 1920);
  border-bottom: 5px solid rgba(19, 175, 135, 1);
  font-weight: bolder;
  font-size: 1.28vw;
}
.updatepwd {
  border-top: 1px solid #ddd;
  padding-left: calc(38 * 100vw / 1920);
  margin-bottom: calc(55 * 100vw / 1920);
}
.active {
  background: #13af87 !important;
  color: #ffffff !important;
}
.updatepwd_main {
  margin-top: calc(42 * 100vw / 1920);
  padding-left: calc(38 * 100vw / 1920);
}

.password_button {
  display: block;
  padding: calc(10 * 100vw / 1920) 0;
  border-bottom: 1px solid #13af87;
  margin-left: calc(480 * 100vw / 1920);
  margin-bottom: calc(20 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
}
.updatepwd_address {
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: bolder;
  color: #333333;
  align-items: center;
  margin-bottom: calc(30 * 100vw / 1920);
}
.title {
  width: calc(165 * 100vw / 1920);
  word-wrap: break-word !important;
  word-break: normal !important;
}
.code_button {
  height: calc(50 * 100vw / 1920);
  padding: calc(15 * 100vw / 1920);
  border: 1px solid #ddd;
  background-color: #13af87;
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: calc(10 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
}
input {
  width: calc(480 * 100vw / 1920);
  height: calc(50 * 100vw / 1920);
  background: #ffffff;
  border: 1px solid #dddddd;
  margin-left: calc(18 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: bolder;
  color: #333333;
  padding-left: calc(24 * 100vw / 1920);
}
.code {
  width: calc(325 * 100vw / 1920);
  margin-left: calc(182 * 100vw / 1920);
}
.merchant input::-webkit-input-placeholder {
  color: #aaaaaa;
}
.confirm {
  width: calc(200 * 100vw / 1920);
  height: calc(50 * 100vw / 1920);
  background: #13af87;
  border-radius: calc(2 * 100vw / 1920);
  font-size: calc(22 * 100vw / 1920);
  font-family: Roboto;
  font-weight: bold;
  color: #ffffff;
  margin-left: calc(285 * 100vw / 1920);
}
@media screen and (max-width: 1080px) {
  .updatepwd_page {
    width: 100vw;
    height: calc(1624 * 100vw / 1080);
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  .updatepwd_title {
    margin-left: calc(40 * 100vw / 1080);
    width: calc(280 * 100vw / 1080);
    height: calc(150 * 100vw / 1080);
    white-space: nowrap;
    line-height: calc(170 * 100vw / 1080);
    border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: calc(48 * 100vw / 1080);
  }
  .updatepwd {
    border-top: 1px solid #ddd;
    padding-left: calc(40 * 100vw / 1080);
    padding-left: calc(40 * 100vw / 1080);
    padding-right: calc(50 * 100vw / 1080);
    margin-bottom: calc(590 * 100vw / 1080);
  }
  .updatepwd_main {
    margin-top: calc(42 * 100vw / 1080);
    padding-left: calc(38 * 100vw / 1080);
  }

  .password_button {
    display: block;
    padding: calc(10 * 100vw / 1080) 0;
    border-bottom: 1px solid #13af87;
    margin-left: calc(540 * 100vw / 1080);
    margin-bottom: calc(20 * 100vw / 1080);
    font-size: calc(36 * 100vw / 1080);
  }
  .updatepwd_address {
    font-size: calc(36 * 100vw / 1080);
    margin-bottom: calc(30 * 100vw / 1080);
  }
  .title {
    width: calc(180 * 100vw / 1080);
  }
  .code_button {
    height: calc(90 * 100vw / 1080);
    padding: calc(15 * 100vw / 1080);
    border: 1px solid #ddd;
    background-color: #13af87;
    display: flex;
    align-items: center;
    color: #fff;
    margin-left: calc(10 * 100vw / 1080);
    font-size: calc(36 * 100vw / 1080);
  }
  input {
    width: calc(700 * 100vw / 1080);
    height: calc(90 * 100vw / 1080);
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-left: calc(18 * 100vw / 1080);
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: bolder;
    color: #333333;
    padding-left: calc(24 * 100vw / 1080);
  }
  .code {
    width: calc(430 * 100vw / 1080);
    margin-left: calc(198 * 100vw / 1080);
  }
  .merchant input::-webkit-input-placeholder {
    color: #aaaaaa;
  }
  .confirm {
    width: calc(300 * 100vw / 1080);
    height: calc(100 * 100vw / 1080);
    background: #13af87;
    border-radius: calc(2 * 100vw / 1080);
    font-size: calc(44 * 100vw / 1080);
    font-family: Roboto;
    font-weight: bold;
    color: #ffffff;
    margin-left: calc(285 * 100vw / 1080);
    margin-top: calc(400 * 100vw / 1080);
  }
}
</style>
