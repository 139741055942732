// import Vue from 'vue'

export const messagebox=({message,type})=>{
    return this.$message({
        message,
        type
    })
}
// function message(message,type){
// 	type?type:'info'
//     this.$message({
//         message:message,
//         type:type
//     });
// }
// module.exports={
// 	message
// }