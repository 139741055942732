<template>
  <div class="businesspromotion_page">
    <div class="businesspromotion">
      <p class="directory">
        <span @click="businesshomeClick" class="directory_home">个人中心 </span>> 推广人员
      </p>
      <div class="businesspromotion_main">
        <div class="bm_top">
          <div class="bm_top_title">推广人员</div>
        </div>
        <div class="tabsbox">
          <el-tabs v-model="activeName">
            <el-tab-pane label="推广人员" name="first">
              <div class="bm_nav flex-row" v-if="isPc">
                <span style="font-size: calc(18 * 100vw / 1920);line-height: calc(30 * 100vw / 1920);">请选择人员类型：</span>
                <div class="bm_nav_states">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{
                      total.status == null
                      ? "状态"
                      : total.status == 0
                      ? "查看已绑定的推广人员"
                      : "查看绑定推广申请"
                      }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="0">查看已绑定的推广人员</el-dropdown-item>
                      <el-dropdown-item :command="1">查看绑定推广申请</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="bm_nav_phone" v-else>
                <button class="active" @click="onShelfClick">查看已绑定的推广人员</button>
                <button @click="offShelfClick">查看绑定推广申请</button>
              </div>
              <div v-if="total.status == 0">
                <el-table :data="promotionList" tooltip-effect="dark">
                  <el-table-column label="名称" prop="userName"></el-table-column>
                  <el-table-column label="图像" prop="image"></el-table-column>
                  <el-table-column label="电话" prop="phone"></el-table-column>
                  <el-table-column label="邮箱" prop="email"></el-table-column>
                  <el-table-column label="推广商家提成" prop="promotionBalance"></el-table-column>
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button type="danger" @click="unBindUser(scope.row.userId,scope.row.userName)"
                        size="mini">解绑</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="page_nation" style="width: 100%; text-align: center">
                  <el-pagination background :total="total.pageTotal" @current-change="currentChange"
                    :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper"></el-pagination>
                </div>
              </div>
              <div v-else>
                <el-table :data="promotionApplyList" tooltip-effect="dark">
                  <el-table-column label="名称" prop="userName"></el-table-column>
                  <el-table-column label="图像" prop="image"></el-table-column>
                  <el-table-column label="电话" prop="phone"></el-table-column>
                  <el-table-column label="邮箱" prop="email"></el-table-column>
                  <el-table-column label="审核回复" prop="remark"></el-table-column>
                  <el-table-column label="状态" prop="status">
                    <template slot-scope="scope">
                      <div v-if="scope.row.status == 1">审核中</div>
                      <div v-else-if="scope.row.status == 2">已拒绝</div>
                      <div v-else-if="scope.row.status == 3">已通过</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="申请时间" prop="createTime"></el-table-column>
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button type="success" size="mini" v-if="scope.row.status == 1"
                        @click="toExamine(scope.row)">审核</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="page_nation" style="width: 100%; text-align: center">
                  <el-pagination background :total="pageTotal" @current-change="currentApplyChange"
                    :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper"></el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="推广记录" name="second">
              <!-- <el-form v-model="tgTotal" :inline="true">
                    <el-form-item label="类别">
                        <el-select v-model="tgTotal.type" @change="typeChange" size="mini">
                            <el-option label="用户" :value="0"></el-option>
                            <el-option label="商品" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input v-show="tgTotal.type == 0" v-model.number="tgTotal.userId" placeholder="请输入用户Id" size="mini"/>
                        <el-input v-show="tgTotal.type == 1" v-model.number="tgTotal.commodityId" placeholder="请输入商品Id" size="mini"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="businessLookCommissionList" size="mini" type="success">搜索</el-button>
                    </el-form-item>
                </el-form> -->
              <el-table :data="promotionTgList" tooltip-effect="dark">
                <el-table-column label="提成推广用户名称" prop="promotionUserName"></el-table-column>
                <el-table-column label="下单商品名称" prop="commodityName"></el-table-column>
                <el-table-column label="下单商品图片" prop="image">
                  <template slot-scope="scope">
                    <img style="width: 60px;height: 60px;" :src="$baseUrl + scope.row.image" />
                  </template>
                </el-table-column>
                <el-table-column label="商品支付价格" prop="payPrice"></el-table-column>
                <el-table-column label="提成比例" prop="commissionProportion"></el-table-column>
                <el-table-column label="提成价格" prop="commissionPrice"></el-table-column>
                <el-table-column label="提成时间" prop="createTime"></el-table-column>
              </el-table>
              <div class="page_nation" style="width: 100%; text-align: center">
                <el-pagination background :total="tgTotal.pageTotal" @current-change="currentTgChange"
                  :current-page="tgTotal.pageNum" :page-size="tgTotal.pageSize"
                  layout="prev, pager, next, jumper"></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="提现申请" name="third">
              <el-table :data="applyList" tooltip-effect="dark">
                <el-table-column label="受益人姓名" prop="beneficiaryName"></el-table-column>
                <el-table-column label="受益人地址" prop="beneficiaryAddress"></el-table-column>
                <el-table-column label="受益人账号" prop="beneficiaryAccNo"></el-table-column>
                <el-table-column label="银行地址" prop="addressOfBank">
                </el-table-column>
                <el-table-column label="SWIFT码" prop="swiftCode"></el-table-column>
                <el-table-column label="银行代码" prop="postCode"></el-table-column>
                <el-table-column label="分行代码" prop="branchCode"></el-table-column>
                <el-table-column label="受益人银行" prop="beneficiaryBank"></el-table-column>
                <el-table-column label="国家/地区" prop="address"></el-table-column>
                <el-table-column label="提现金额" prop="promotionBalance"></el-table-column>
                <el-table-column label="申请提现时间" prop="createTime"></el-table-column>
                <el-table-column label="审核回复" prop="remark"></el-table-column>
                <el-table-column label="状态" prop="status">
                  <template slot-scope="scope">
                    <div v-if="scope.row.status == 1">审核中</div>
                    <div v-else-if="scope.row.status == 2">已拒绝</div>
                    <div v-else-if="scope.row.status == 3">已通过</div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button type="success" size="mini" v-if="scope.row.status == 1"
                      @click="toApplyExamine(scope.row)">审核</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page_nation" style="width: 100%; text-align: center">
                <el-pagination background :total="pageTotal" @current-change="currentSqChange"
                  :current-page="applyForm.pageNum" :page-size="applyForm.pageSize"
                  layout="prev, pager, next, jumper"></el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <el-dialog title="审核" :visible.sync="dialogFormVisible" :close="examineClose" :close-on-click-modal="false">
        <el-form :model="examineForm" :rules="examineFormRule" ref="examineRef">
          <el-form-item label="审核状态" :label-width="formLabelWidth" prop="status">
            <el-select style="width: 80%" v-model="examineForm.status" placeholder="请选择审核状态">
              <el-option label="已拒绝" :value="2"></el-option>
              <el-option label="已通过" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核备注" :label-width="formLabelWidth">
            <el-input style="width: 80%" v-model="examineForm.remark" placeholder="请输入审核备注"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="examineClose">取 消</el-button>
          <el-button type="primary" @click="examineSubmit">提 交</el-button>
        </div>
      </el-dialog>
      <el-dialog title="审核" :visible.sync="dialogApplyFormVisible" :close="applyClose" :close-on-click-modal="false">
        <el-form :model="applyShForm" :rules="applyFormRule" ref="applyRef">
          <el-form-item label="审核状态" :label-width="formLabelWidth" prop="status">
            <el-select style="width: 80%" v-model="applyShForm.status" placeholder="请选择审核状态">
              <el-option label="已拒绝" :value="2"></el-option>
              <el-option label="已通过" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核备注" :label-width="formLabelWidth">
            <el-input style="width: 80%" v-model="applyShForm.remark" placeholder="请输入审核备注"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="applyClose">取 消</el-button>
          <el-button type="primary" @click="ApplySubmit">提 交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import PageNation from "@/components/pagenation/PageNation.vue";
  import { importCommodity } from "@/api/business";
  import { getCookie } from "@/utils/auth";
  import { downModel, shopUpAndDownCommodity } from "@/api/business";
  import {
    shopCommodityDetailList,
    shopAddCategory,
    shopDelCommodityApi,
    shopCategoryListApi,
    businessLookPromotionUserApi,
    unBindPromotionUser,
    businessLookPromotionApplyApi,
    businessLookCommissionListApi,
    businessAuditApplyApi,
    businessPromotionCashListApi,
    businessAuditCashApi,
  } from "@/api/business";

  export default {
    name: "businesspromotion",
    data() {
      return {
        selectitemsActive: [], // 单选框的状态集合
        selectAllActive: false, // 全选框状态
        deleteChoose: false,
        isPc: true,
        fileList: [],
        head: {
          Authorization: getCookie("token"),
        },
        promotionList: [],
        promotionApplyList: [],
        baseUrl: this.$baseUrl,
        options: [],
        props: {
          label: "categoryName",
          value: "id",
        },
        activeName: 'first',
        pageTotal: 0,
        pageNum: 1,
        pageSize: 6,
        tgTotal: {
          pageNum: 1,
          pageSize: 6,
          pageTotal: 0,
          userId: null,
          commodityId: null,
          type: 0
        },
        promotionTgList: [],
        examineForm: {
          id: null,
          status: null,
          remark: null
        },
        examineFormRule: {
          status: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ]
        },
        dialogFormVisible: false,
        formLabelWidth: '120px',
        applyList: [],
        applyForm: {
          pageNum: 1,
          pageSize: 6,
          status: 0,
          userId: 0
        },
        applyTotal: 0,
        dialogApplyFormVisible: false,
        applyShForm: {
          id: null,
          status: null,
          remark: null
        },
        applyFormRule: {
          status: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ]
        },
      };
    },
    props: {
      total: {
        type: Object,
        default: () => {
          return {
            pageTotal: 0,
            pageNum: 1,
            pageSize: 6,
            // categoryId: 0,
            // productName: null,
            status: 0,
            // id: null,
          };
        },
      },
      numChange: {
        numChange(e) {
          return e;
        },
      },
    },
    components: {
      PageNation,
    },
    computed: {},
    watch: {
      $route: {
        handler: function (val) {
          //这里的路由是页面上的路由
          if (val.path == "/businesspersonal/businesspromotion") {
            //调用你想用的方法
            this.businessLookPromotionUser();
          } else {
            this.router_show = false;
          }
        },
        // 深度观察监听，普通的watch方法无法监听到对象内部属性的改变，只有data中的数据才能够监听到变化，此时就需要deep属性对对象进行深度监听。
        deep: true,
      },
    },
    methods: {
      unBindUser(userId, userName) {
        this.$confirm("您确定要解绑推广人员" + userName + "吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            unBindPromotionUser(userId).then((res) => {
              if (res.code == 200) {
                this.$message({ message: res.msg, type: "success" });
                this.businessLookPromotionUser();
              } else {
                this.$message({ message: res.msg, type: "error" });
                this.businessLookPromotionUser();
              }
            });
          })
          .catch(() => { });
      },
      businesshomeClick() {
        this.$router.push({
          path: "/businesspersonal/businesspromotion",
          query: {
            noBusiness: false,
            isbottomnav: false,
          },
        });
      },
      //跳转发布页
      businessLaunchClick() {
        this.$router.push({
          path: "/businesslaunch",
          query: {
            bool: false,
            phoneHomeImg: false,
            box8: false,
            noBusiness: false,
          },
        });
      },
      //下拉框点击
      handleCommand(command) {
        console.log(command)
        this.total.status = command;
        if (command) {
          this.businessLookPromotionApply()
        } else {
          this.businessLookPromotionUser();
        }
      },
      //搜索
      search() {
        if (this.total.status) {
          this.businessLookPromotionApply()
        } else {
          this.businessLookPromotionUser();
        }
      },
      //搜索充值
      searchRest() {
        this.total = {
          pageTotal: 0,
          pageNum: 1,
          pageSize: 6,
          categoryId: 0,
          productName: null,
          status: 0,
          id: null,
        };
        this.businessLookPromotionUser();
      },
      //改变页数
      currentChange(e) {
        this.total.pageNum = e;
        this.businessLookPromotionUser();
      },
      currentApplyChange(e) {
        this.pageNum = e;
        this.businessLookPromotionApply();
      },
      currentTgChange(e) {
        this.tgTotal.pageNum = e;
        this.businessLookCommissionList()
      },
      currentSqChange(e) {
        this.applyForm.pageNum = e
        this.businessPromotionCashList()
      },
      //查看推广人员列表
      businessLookPromotionUser() {
        businessLookPromotionUserApi(this.total).then((res) => {
          if (res.code == 200) {
            this.promotionList = res.data.promotionUserLists.map(item => {
              if (item.image) {
                item.image = this.$baseUrl + item.image
              }
              return item
            })
            this.total.pageTotal = res.data.total;
          } else if (res.code == 401) {
            this.$message({ message: res.msg, type: "error" });
            //未登录
            this.$router.push({
              path: "/login",
              query: {
                box8: false,
                phoneHomeImg: false,
                bool: false,
                // token: true,
              },
            });
          }
        });
      },
      //查看推广人员申请列表
      businessLookPromotionApply() {
        businessLookPromotionApplyApi({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userId: 0,
          status: 0
        }).then((res) => {
          if (res.code == 200) {
            this.promotionApplyList = res.data.applyPromotionLists
            this.pageTotal = res.data.total;
          } else if (res.code == 401) {
            this.$message({ message: res.msg, type: "error" });
            //未登录
            this.$router.push({
              path: "/login",
              query: {
                box8: false,
                phoneHomeImg: false,
                bool: false,
                // token: true,
              },
            });
          }
        });
      },
      //查看推广记录列表
      businessLookCommissionList() {
        var { pageNum, pageSize, userId, commodityId, type } = JSON.parse(JSON.stringify(this.tgTotal))
        commodityId = commodityId || 0
        userId = userId || 0
        var obj = {}
        if (type) {
          obj.commodityId = commodityId,
            obj.userId = 0
        } else {
          obj.commodityId = 0
          obj.userId = userId
        }
        businessLookCommissionListApi({ pageNum, pageSize, ...obj }).then((res) => {
          if (res.code == 200) {
            this.promotionTgList = res.data.promotionCommissionLists
            this.tgTotal.pageTotal = res.data.total;
          } else if (res.code == 401) {
            this.$message({ message: res.msg, type: "error" });
            //未登录
            this.$router.push({
              path: "/login",
              query: {
                box8: false,
                phoneHomeImg: false,
                bool: false,
                // token: true,
              },
            });
          }
        });
      },
      businessPromotionCashList() {
        businessPromotionCashListApi(this.applyForm).then(res => {
          if (res.code == 200) {
            this.applyList = res.data.promotionCashListList
            this.applyTotal = res.data.total
          }
        })
      },
      //下载批量上传模板
      downLoadModelApi() {
        downModel().then((res) => {
          let blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          let fileName = "Commodity template" + ".xlsx";
          if (window.navigator.msSaveOrOpenBlob) {
            // console.log(2)
            navigator.msSaveBlob(blob, fileName);
          } else {
            // console.log(3)
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            //释放内存
            window.URL.revokeObjectURL(link.href);
          }
        });
      },
      handleChange(file, fileList) {
        this.fileList = fileList.slice(-3);
      },
      uploadSuccess(response, file, fileList) {
        if (response.code == 200) {
          this.$message({ message: "上传成功", type: "success" });
        } else if (response.code == 401) {
          this.$message({ message: response.msg, type: "error" });
          //未登录
          this.$router.push({
            path: "/login",
            query: {
              box8: false,
              phoneHomeImg: false,
              bool: false,
              // token: true,
            },
          });
        } else {
          this.$message({ message: response.msg, type: "error" });
        }
      },
      uploadError(err, file, fileList) {
        this.$message({ message: "上传失败", type: "error" });
      },
      handleRemove(file, fileList) { },
      handlePreview(file) { },
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
          } 个文件`
        );
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
      // 单选
      selectItemClick(i, e) {
        // console.log(this.$refs["cartList" + i + ""]);
        // 状态取反
        this.selectitemsActive[i] = !this.selectitemsActive[i];

        let selectall = document.querySelectorAll(".selectAll");

        // 如果为true，说明选中
        if (this.selectitemsActive[i]) {
          e.currentTarget.style.backgroundColor = "#13af87";
        } else {
          e.currentTarget.style.backgroundColor = "#fff";
        }

        // 如果所有的单选都被选中，则给全选赋true
        if (this.selectitemsActive.every((a) => a === true)) {
          this.selectAllActive = true;
          for (let i = 0; i < selectall.length; i++) {
            selectall[i].style.backgroundColor = "#13af87";
          }
        } else {
          this.selectAllActive = false;
          for (let i = 0; i < selectall.length; i++) {
            selectall[i].style.backgroundColor = "#fff";
          }
        }
      },
      // 全选
      selectAllClick(e) {
        // 全选状态取反
        this.selectAllActive = !this.selectAllActive;
        let selectall = document.querySelectorAll(".selectAll");
        let selectitems = document.querySelectorAll(".selectitem");

        // 如果为true说明被选中
        if (this.selectAllActive) {
          // 全选为true，所有全选颜色改为选中颜色
          for (let i = 0; i < selectall.length; i++) {
            selectall[i].style.backgroundColor = "#13af87";
          }
          // 全选被选中，则给所有单选赋true，颜色改为选中颜色
          for (let i = 0; i < selectitems.length; i++) {
            this.selectitemsActive[i] = true;
            selectitems[i].style.backgroundColor = "#13af87";
          }
        } else {
          // 全选为false，所有全选颜色改为未选中颜色
          for (let i = 0; i < selectall.length; i++) {
            selectall[i].style.backgroundColor = "#fff";
          }
          // 全选为false，则给所有单选赋false，颜色改为未选中颜色
          for (let i = 0; i < selectitems.length; i++) {
            this.selectitemsActive[i] = false;
            selectitems[i].style.backgroundColor = "#fff";
          }
        }
      },
      // 确定是否删除
      deleteChooseClick() {
        this.deleteChoose = !this.deleteChoose;
        if (this.deleteChoose) {
          document.querySelector(".deleteChoose").style.backgroundColor =
            "#13af87";
        } else {
          document.querySelector(".deleteChoose").style.backgroundColor = "#fff";
        }
      },
      // 删除
      deleteClick(item) {
        this.$confirm("您确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            shopDelCommodityApi({
              id: item.id,
            }).then((res) => {
              if (res.code == 200) {
                this.shopCommodityDetailList();
              }
              this.$message(res.msg);
            });
          })
          .catch(() => { });
      },
      onDetails(item) {
        this.$router.push({
          path: "/businesspromotiondetail",
          query: {
            bool: false,
            phoneHomeImg: false,
            box8: false,
            noBusiness: false,
            id: item.id,
          },
        });
      },
      // 选中状态为on shelf
      onShelfClick(e) {
        let childs = document.querySelector(".bm_nav_phone").children;
        for (let i = 0; i < childs.length; i++) {
          childs[i].classList.remove("active");
        }
        e.currentTarget.classList.add("active");
        this.total.status = 0;
        this.businessLookPromotionUser();
      },
      // 选中状态为off shelf
      offShelfClick(e) {
        let childs = document.querySelector(".bm_nav_phone").children;
        for (let i = 0; i < childs.length; i++) {
          childs[i].classList.remove("active");
        }
        e.currentTarget.classList.add("active");
        this.total.status = 1;
        this.businessLookPromotionApply();
      },
      typeChange(e) {
        if (e) {
          this.tgTotal.userId = null
          this.tgTotal.commodityId = null
        } else {
          this.tgTotal.userId = null
          this.tgTotal.commodityId = null
        }
      },
      toExamine(row) {
        this.examineForm.id = row.id
        this.dialogFormVisible = true
      },
      examineSubmit() {
        this.$refs.examineRef.validate(valid => {
          if (valid) {
            businessAuditApplyApi(this.examineForm).then(res => {
              if (res.code == 200) {
                this.$message({ message: "审核成功", type: "success" });
                this.examineClose()
                this.businessLookPromotionApply()
              } else if (res.code == 401) {
                this.$message({ message: res.msg, type: "error" });
                //未登录
                this.$router.push({
                  path: "/login",
                  query: {
                    box8: false,
                    phoneHomeImg: false,
                    bool: false,
                    // token: true,
                  },
                });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
          }
        })
      },
      examineClose() {
        this.examineForm.id = ''
        this.examineForm.remark = ''
        this.examineForm.status = ''
        this.dialogFormVisible = false
      },
      toApplyExamine(row) {
        this.applyShForm.id = row.id
        this.dialogApplyFormVisible = true
      },
      ApplySubmit() {
        this.$refs.applyRef.validate(valid => {
          if (valid) {
            businessAuditCashApi(this.applyShForm).then(res => {
              if (res.code == 200) {
                this.$message({ message: "审核成功", type: "success" });
                this.applyClose()
                this.businessPromotionCashList()
              } else if (res.code == 401) {
                this.$message({ message: res.msg, type: "error" });
                //未登录
                this.$router.push({
                  path: "/login",
                  query: {
                    box8: false,
                    phoneHomeImg: false,
                    bool: false,
                    // token: true,
                  },
                });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
          }
        })
      },
      applyClose() {
        this.applyShForm.id = ''
        this.applyShForm.remark = ''
        this.applyShForm.status = ''
        this.dialogApplyFormVisible = false
      },
    },
    mounted() {
      this.businessLookPromotionUser();
      this.businessLookCommissionList()
      this.businessPromotionCashList()
      if (document.documentElement.clientWidth > 1080) {
        this.isPc = true;
      } else {
        this.isPc = false;
      }
      let selectitems = document.querySelectorAll(".selectitem");
      for (let i = 0; i < selectitems.length; i++) {
        this.selectitemsActive[i] = false;
      }
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      // 屏幕变化
      window.addEventListener("resize", () => {
        if (document.documentElement.clientWidth > 1080) {
          this.isPc = true;
        } else {
          this.isPc = false;
        }
      });
    },
  };
</script>

<style>
  @media screen and (max-width: 1080px) {
    .el-message-box {
      width: 101%;
    }

  }

  @media screen and (max-width: 1080px) {
    .el-dialog {
      width: 90% !important;
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
</style>

<style scoped>
  .businesspromotion_page {
    width: 100%;
    background-color: #f1f1f1;
    min-height: 70vh;
    padding: calc(20 * 100vw / 1920);
  }

  .businesspromotion {
    /*width: calc(1200 * 100vw / 1920);*/
    width: 100%;
    margin: 0 auto;
  }

  .businesspromotion .directory {
    background-color: #f1f1f1;
    margin: 0;
    margin-top: 1.2vw;
    padding-bottom: 1.2vw;
    font-size: 0.93vw;
    font-family: Roboto;
    font-weight: 400;
    color: #999999;
  }

  .businesspromotion_main {
    background-color: #fff;
    padding-bottom: calc(20 * 100vw / 1920);
  }

  .bm_top {
    width: 100%;
    background-color: #fff;
  }

  .bm_top_title {
    margin-left: calc(40 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .bm_nav {
    padding-top: calc(20 * 100vw / 1920);
    padding-left: calc(36 * 100vw / 1920);
    border-top: 1px solid #ddd;
    flex-wrap: wrap;
  }

  .bm_nav_classification {
    /* display: block; */
    width: calc(248 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    /* border: 1px solid #dddddd; */
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    /* padding-left: calc(16 * 100vw / 1920); */
    /* line-height: calc(30 * 100vw / 1920); */
  }

  ::v-deep .el-cascader {
    line-height: 0;
  }

  ::v-deep .el-icon-arrow-down {
    display: flex;
    align-items: center;
  }

  .bm_nav_classification span::after {
    content: "";
    width: 0;
    height: 0;
    border-color: #000 transparent transparent transparent;
    border-width: calc(8 * 100vw / 1920) calc(8 * 100vw / 1920) 0;
    border-style: solid;
    float: right;
    margin-top: calc(11 * 100vw / 1920);
    margin-right: calc(12 * 100vw / 1920);
  }

  .bm_nav_name {
    width: calc(246 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-left: calc(15 * 100vw / 1920);
    padding-left: calc(17 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    line-height: calc(30 * 100vw / 1920);
    margin-right: calc(15 * 100vw / 1920);
  }

  .bm_nav input::-webkit-input-placeholder {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    line-height: calc(30 * 100vw / 1920);
  }

  .bm_nav_states span {
    display: block;
    width: calc(240 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    border: 1px solid #dddddd;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    padding-left: calc(15 * 100vw / 1920);
    line-height: calc(30 * 100vw / 1920);
  }

  .bm_nav_states span::after {
    content: "";
    width: 0;
    height: 0;
    border-color: #000 transparent transparent transparent;
    border-width: calc(8 * 100vw / 1920) calc(8 * 100vw / 1920) 0;
    border-style: solid;
    float: right;
    margin-top: calc(11 * 100vw / 1920);
    margin-right: calc(12 * 100vw / 1920);
  }

  .bm_nav_search {
    width: calc(246 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-left: calc(15 * 100vw / 1920);
    margin-right: calc(15 * 100vw / 1920);
    font-size: 0;
    align-items: center;
  }

  .bm_nav_search input {
    width: calc(218 * 100vw / 1920);
    height: 100%;
    padding-left: calc(17 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    line-height: calc(30 * 100vw / 1920);
  }

  .bm_nav_search .icon-31sousuo {
    color: #aaaaaa;
    font-size: calc(20 * 100vw / 1920);
  }

  .bm_nav button {
    line-height: calc(30 * 100vw / 1920);
    text-align: center;
    font-size: calc(18 * 100vw / 1920);
    font-weight: 400;
    width: calc(80 * 100vw / 1920);
    margin-right: calc(12 * 100vw / 1920);
  }

  .bm_nav_button {
    display: flex;
    justify-content: start;
  }

  .bm_nav_button button {
    width: calc(180 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    margin-right: calc(18 * 100vw / 1920);
    margin-top: calc(23 * 100vw / 1920);
  }

  .bm_nav button:nth-child(2n-1) {
    background: #13af87;
    color: #ffffff;
  }

  .bm_nav button:nth-of-type(2n) {
    border: 1px solid #13af87;
    color: #13af87;
  }

  ::v-deep .el-upload-list__item {
    display: flex;
  }

  .bm_list {
    padding: calc(40 * 100vw / 1920) calc(36 * 100vw / 1920) calc(26 * 100vw / 1920);
  }

  .bm_list_top {
    width: 100%;
    height: calc(36 * 100vw / 1920);
    padding-left: calc(16 * 100vw / 1920);
    background: #f1fffb;
    align-items: center;
  }

  .selectAll,
  .selectitem,
  .deleteChoose {
    width: calc(18 * 100vw / 1920);
    height: calc(18 * 100vw / 1920);
    border: 1px solid #aaa;
    border-radius: calc(3 * 100vw / 1920);
    color: #fff;
    font-size: calc(14 * 100vw / 1920);
    text-align: center;
    line-height: calc(18 * 100vw / 1920);
    margin-right: calc(43 * 100vw / 1920);
  }

  .bm_list .bm_list_top p {
    margin: 0;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
  }

  .bm_list .bm_list_top p:nth-of-type(2) {
    margin-left: calc(43 * 100vw / 1920);
  }

  .bm_list .bm_list_top p:nth-of-type(3) {
    margin-left: calc(160 * 100vw / 1920);
  }

  .bm_list .bm_list_top p:nth-of-type(4) {
    margin-left: calc(190 * 100vw / 1920);
  }

  .bm_list .bm_list_top p:nth-of-type(5) {
    margin-left: calc(70 * 100vw / 1920);
  }

  .bm_list .bm_list_top p:nth-of-type(6) {
    margin-left: calc(119 * 100vw / 1920);
  }

  .bm_list .bm_list_top p:nth-of-type(7) {
    margin-left: calc(100 * 100vw / 1920);
  }

  /* .bm_list .bm_list_top p:nth-of-type(8) {
    margin-left: calc(95 * 100vw / 1920);
  }
  .bm_list .bm_list_top p:nth-of-type(9) {
    margin-left: calc(36 * 100vw / 1920);
  } */
  .bm_list_delete {
    width: 100%;
    height: calc(60 * 100vw / 1920);
    background: #f0f0f0;
    padding-left: calc(16 * 100vw / 1920);
    align-items: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    margin-bottom: calc(56 * 100vw / 1920);
  }

  .pagenation {
    margin-left: calc(155 * 100vw / 1920);
  }

  .bm_bottom_button {
    display: none;
  }

  .tabsbox {
    padding: 0 calc(20 * 100vw / 1920);
  }

  @media screen and (max-width: 1080px) {
    ::v-deep .el-upload-list__item {
      display: none;
    }

    .businesspromotion_page {
      width: 100vw;
      background-color: #f1f1f1;
      position: relative;
    }

    .businesspromotion {
      width: 100%;
      margin: 0;
    }

    .businesspromotion .directory,
    .bm_list_delete,
    .pagenation {
      display: none;
    }

    .businesspromotion_main {
      margin-top: calc(20 * 100vw / 1080);
      margin-bottom: 0;
      background-color: #fff;
    }

    .bm_top {
      width: 100%;
      height: calc(114 * 100vw / 1080);
      background-color: #fff;
      border-bottom: calc(1 * 100vw / 1080) solid rgb(240, 239, 239);
      padding-top: calc(32 * 100vw / 1080);
    }

    .bm_top_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(82 * 100vw / 1080);
      line-height: calc(82 * 100vw / 1080);
      white-space: nowrap;
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(36 * 100vw / 1080);
    }

    .bm_nav_phone {
      width: 100%;
      height: calc(150 * 100vw / 1080);
      display: flex;
      align-items: center;
      padding-left: calc(56 * 100vw / 1080);
    }

    .bm_nav_phone button {
      min-width: calc(240 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      border-radius: calc(30 * 100vw / 1080);
      font-size: calc(32 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      text-align: center;
      line-height: calc(50 * 100vw / 1080);
      color: #aaaaaa;
      border: 1px solid #dddddd;
      margin-right: calc(60 * 100vw / 1080);
    }

    .active {
      background: #13af87;
      color: #ffffff !important;
    }

    .bm_list {
      width: 100%;
      height: calc(1088 * 100vw / 1080);
      background-color: #f6f6f6;
      padding: calc(26 * 100vw / 1080) calc(40 * 100vw / 1080) 0;
      overflow: hidden;
      overflow-y: scroll;
    }

    .bm_bottom_button {
      width: 100vw;
      height: calc(126 * 100vw / 1080);
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: end;
    }

    .bm_bottom_button button {
      width: calc(360 * 100vw / 1080);
      height: calc(80 * 100vw / 1080);
      line-height: calc(80 * 100vw / 1080);
      text-align: center;
      font-size: calc(36 * 100vw / 1080);
      font-weight: 500;
      border-radius: calc(40 * 100vw / 1080);
      border: 1px solid #13af87;
    }

    .bm_bottom_button button:first-child {
      background: #d0fff3;
      color: #13af87;
    }

    .bm_bottom_button button:last-child {
      background: #13af87;
      color: #ffffff;
      margin: 0 calc(40 * 100vw / 1080);
    }
  }

  .bmlist {
    width: 100%;
    height: calc(120 * 100vw / 1920);
    border-bottom: 1px solid #ddd;
    padding-bottom: calc(10 * 100vw / 1920);
    align-items: center;
    padding-left: calc(16 * 100vw / 1920);
  }

  .bmlist_phone {
    display: none;
  }

  .bmlist_id {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
  }

  .bmlist_product {
    min-height: calc(50 * 100vw / 1920);
    margin-left: calc(46 * 100vw / 1920);
  }

  .bmlist_product .bmlist_product_img {
    width: calc(50 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(28 * 100vw / 1920) calc(39 * 100vw / 1920);
    border: 1px solid #ddd;
    margin-right: calc(12 * 100vw / 1920);
  }

  .bmlist_product .bmlist_product_img img {
    width: 100%;
    height: 100%;
  }

  .bmlist_product_text,
  .bmlist_product_price {
    width: calc(113 * 100vw / 1920);
    font-size: calc(16 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: calc(5 * 100vw / 1920);
  }

  .bmlist_product_price {
    color: #ff0000;
  }

  .bmlist_classification {
    width: calc(180 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    margin-left: calc(40 * 100vw / 1920);
    margin-right: calc(37 * 100vw / 1920);
  }

  .bmlist_state_on {
    width: calc(108 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #13af87;
  }

  .bmlist_state_off {
    width: calc(108 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaa;
  }

  .bmlist_time {
    width: calc(145 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    line-height: calc(24 * 100vw / 1920);
  }

  .bmlist_sort {
    width: calc(80 * 100vw / 1920);
    height: calc(36 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    text-align: center;
    font-size: calc(18 * 100vw / 1920);
    color: #333;
  }

  .bmlist_stock {
    width: calc(130 * 100vw / 1920);
    margin-left: calc(75 * 100vw / 1920);
    margin-right: calc(10 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bmlist_operation {
    width: calc(135 * 100vw / 1920);
  }

  .bmlist_operation div {
    width: calc(18 * 100vw / 1920);
    height: calc(18 * 100vw / 1920);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .bmlist_operation .preview {
    background-size: calc(18 * 100vw / 1920) calc(14 * 100vw / 1920);
    background-image: url(@/assets/img/预览.png);
  }

  .bmlist_operation .edit {
    background-size: calc(18 * 100vw / 1920) calc(18 * 100vw / 1920);
    background-image: url(@/assets/img/编辑.png);
    margin: 0 calc(10 * 100vw / 1920);
  }

  .bmlist_operation .delete {
    background-size: calc(18 * 100vw / 1920) calc(18 * 100vw / 1920);
    background-image: url(@/assets/img/删除.png);
  }

  .bmlist_operation .above,
  .bmlist_operation .down {
    font-size: calc(18 * 100vw / 1920);
    color: #aaa;
  }

  .bmlist_operation .above:hover {
    color: #13af87;
  }

  .bmlist_operation .down:hover {
    color: #ff0000;
  }

  @media screen and (max-width: 1080px) {
    .bmlist {
      display: none;
    }

    .bmlist_phone {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(330 * 100vw / 1080);
      background: #ffffff;
      box-shadow: 0px 0px calc(9 * 100vw / 1080) 0px rgba(124, 124, 124, 0.45);
      flex-wrap: wrap;
      margin-bottom: calc(30 * 100vw / 1080);
    }

    .bmlist_phone_img {
      width: calc(262 * 100vw / 1080);
      height: calc(330 * 100vw / 1080);
    }

    .bmlist_phone_img img {
      width: calc(120 * 100vw / 1080);
      height: calc(172 * 100vw / 1080);
      margin-top: calc(64 * 100vw / 1080);
      margin-left: calc(66 * 100vw / 1080);
    }

    .bmlist_phone_text {
      width: calc(650 * 100vw / 1080);
      font-size: calc(32 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      margin-top: calc(36 * 100vw / 1080);
      line-height: calc(36 * 100vw / 1080);
      display: flex;
      justify-content: space-between;
    }

    .bmlist_phone_stock {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaa;
      margin-top: calc(20 * 100vw / 1080);
    }

    .bmlist_phone_price {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #ff0000;
      margin-top: calc(20 * 100vw / 1080);
    }

    .bmlist_phone_button button {
      width: calc(180 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      border-radius: calc(30 * 100vw / 1080);
      font-size: calc(30 * 100vw / 1080);
      line-height: calc(60 * 100vw / 1080);
      text-align: center;
      font-family: Roboto;
      font-weight: 400;
      margin-top: calc(15 * 100vw / 1080);
      line-height: calc(36 * 100vw / 1080);
    }

    .bmlist_phone_button button:nth-of-type(1) {
      background: #ffffff;
      border: 1px solid #dddddd;
      color: #aaaaaa;
      margin-left: calc(139 * 100vw / 1080);
    }

    .bmlist_phone_button button:nth-of-type(2) {
      background: #d0fff3;
      border: 1px solid #13af87;
      color: #13af87;
      margin-left: calc(20 * 100vw / 1080);
    }

    .bmlist_phone_button button:nth-of-type(3) {
      background: #13af87;
      border: 1px solid #dddddd;
      color: #ffffff;
      margin-left: calc(20 * 100vw / 1080);
    }

    .page_nation {
      background-color: #f6f6f6;
      padding-bottom: calc(30 * 100vw / 1080);
    }
  }
</style>
<style>
  @media screen and (max-width: 1080px) {

    .businesspromotion_page .el-pager li,
    .businesspromotion_page .btn-next,
    .businesspromotion_page .btn-prev {
      min-width: calc(50 * 100vw / 1080) !important;
    }
  }
</style>