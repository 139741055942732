<template>
  <div class="order_page">
    <div class="order_title">Order Information</div>
    <div class="order_main">
      <div class="order_nav flex-row">
        <div @click="navfun(index)" v-for="(item, index) in nav" :key="index" :style="{
            background: index == navcurrent ? '#13af87' : '',
            color: index == navcurrent ? '#fff' : '',
          }">
          {{ item }}
        </div>
      </div>
      <div class="order_list">
        <!-- <order-list></order-list> -->
        <div class="orderlist flex-row" v-for="(item, index) in list" :key="index">
          <!-- <div class="orderlist_img">
            <img :src="item.image" alt="" />
          </div> -->
          <div class="orderlist_info">
            <div class="orderlist_id flex-row">
              <span class="orderlist_no">No.:{{ item.orderNo }}</span>
              <div class="order_list_price">
                <div class="orderlist_totalPrice" v-if="item.freightCharge && item.freightCharge > 0">
                  Freight charge:<span>${{ item.freightCharge }}</span>
                </div>
                <div class="orderlist_totalPrice">
                  Total Price:<span>${{ item.totalPrice }}</span>
                </div>
                <div class="orderlist_totalPrice" v-if="item.discountPrice && item.discountPrice > 0">
                  Favorable Price:<span>${{ item.discountPrice }}</span>
                </div>
                <div class="orderlist_totalPrice">
                  Pay Price:<span>${{ item.payPrice }}</span>
                </div>
                <div name="orderlist_state" id="pcstatus">
                  <span class="order_list1_state" v-if="item.status == 0">Unpaid</span>
                  <span class="order_list2_state" v-else>paid</span>
                </div>
              </div>
            </div>
            <div class="orderlist_main">
              <div class="orderlist_li" v-for="(item2, index2) in item.orderDetailVos" :key="index2">
                <div class="orderlist_img">
                  <img :src="$baseUrl + item2.image" alt="" />
                </div>
                <div class="orderlist_name">
                  <div class="orderlist_name_text">
                    {{ item2.commodityName }}
                  </div>
                  <div class="orderlist_name_text2" v-if="item2.specifications && item2.specifications.length > 0">
                    {{ item2.specifications }}
                  </div>
                  <div class="orderlist_price">
                    <div class="orderlist_info">
                      <span class="span3" v-if="item2.price > 0">
                        Price&nbsp;:&nbsp;${{ item2.price }}
                      </span>
                      <span class="span3" v-if="item2.quantity > 0">
                        *{{ item2.quantity }}
                      </span>
                    </div>
                    <div>
                      <button type="danger" class="evaluate_btn" @click="evaluateClick(item,item2)"
                        v-if="item.status == 1&&item2.isAppraise!=2">
                        Rating Reviews
                      </button>
                      <button type="danger" class="inquiry_button"
                        @click="addInquiryClick(item2.commodityId,item2.price,item2.commodityName,item2.image,false,item2.quantity)">
                        Add to Inquiry
                      </button>
                      <!-- <button type="wrang" class="inquiry_button2"
                        style="border: 1px solid #ebb810 !important;color: #ebb810 !important;"
                        v-if="item.status == 1 && item&&item.logisticsInfo && item.logisticsCode && item.deliveryOrderNo"
                        @click="showGps(item.logisticsInfo,item.deliveryOrderNo,item.logisticsCode)">
                        Map GPS Track
                      </button>
                      <button type="wrang" class="inquiry_button2"
                        style="border: 1px solid #ebb810 !important;color: #ebb810 !important;"
                        v-if="item.status == 1 && item&&item.logisticsInfo && item.logisticsCode && item.deliveryOrderNo"
                        @click="queryHandle(item.deliveryOrderNo,item.logisticsCode)">
                        Logistics
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="orderlist_li flex-row">
                <div class="orderlist_img" style="text-align: center">
                  <img src="@/assets/img/图层2.png" />
                </div>
                <div class="orderlist_name">
                  <div class="orderlist_name_text">Fine Ceramics</div>
                  <div class="orderlist_price">$2000.00</div>
                </div>
              </div> -->
            </div>
            <div class="open_orderlist iconfont" @click="switchOrderlist" v-if="item.orderDetailVos.length > 1">
              <!-- Expand remaining products -->
              Click to see more
            </div>
            <div class="orderlist_info_bottom flex-row">
              <div class="orderlist_time">Order time:{{ item.createTime }}</div>
              <!-- <slot name="orderlist_info_bottom"> -->
              <div class="allBtn">
                <div v-if="item.status == 0" class="buttomBtn">
                  <div name="orderlist_state" id="ydstatus">
                    <span class="order_list1_state" v-if="item.status == 0">Unpaid</span>
                    <span class="order_list2_state" v-else>paid</span>
                  </div>
                  <button @click="cancelfun(item.orderNo,item.orderType)">Cancel</button>
                  <button class="order_list1_button" @click="payfun(item.orderNo, item.payPrice, item)">
                    Pay
                  </button>
                  <!-- <button type="danger" class="evaluate_btn"
                  @click="evaluateClick(item)"
                >
                  Evaluate
                </button> -->
                </div>
                <div v-else class="buttomBtn">
                  <div name="orderlist_state" id="ydstatus">
                    <span class="order_list1_state" v-if="item.status == 0">Unpaid</span>
                    <span class="order_list2_state" v-else>paid</span>
                  </div>
                  <button @click="delOrder(item.orderNo)">Delete</button>
                  <button class="order_list2_button" @click="another(item)">
                    Another
                  </button>
                  <!-- <button type="danger" class="evaluate_btn"
                  @click="evaluateClick(item)"
                >
                  Evaluate
                </button> -->
                </div>
                <div class="logistics_info">
                  <button type="wrang" class="inquiry_button3"
                    style="border: 1px solid #ebb810 !important;color: #ebb810 !important;"
                    v-if="item.status == 1 && item&&item.logisticsInfo && item.logisticsCode && item.deliveryOrderNo"
                    @click="showGps(item.logisticsInfo,item.deliveryOrderNo,item.logisticsCode)">
                    Map GPS Track
                  </button>
                  <button type="wrang" class="inquiry_button3"
                    style="border: 1px solid #ebb810 !important;color: #ebb810 !important;"
                    v-if="item.status == 1 && item&&item.logisticsInfo && item.logisticsCode && item.deliveryOrderNo"
                    @click="queryHandle(item.deliveryOrderNo,item.logisticsCode)">
                    Logistics
                  </button>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
      <div class="page_nation" style="width: 100%;text-align: center" v-if="list?.length > 0">
        <!-- <page-nation></page-nation> -->
        <el-pagination background :total="total" @current-change="currentChange" :current-page="pageNum"
          @size-change="handleSizeChange" :page-size="pageSize" :page-sizes="[2, 5, 10]"
          layout="sizes,prev, pager, next, jumper"></el-pagination>
      </div>
      <div class="page_nation2" style="width: 100%;text-align: center" v-if="list?.length > 0">
        <!-- <page-nation></page-nation> -->
        <el-pagination small background :total="total" @current-change="currentChange" :current-page="pageNum"
          @size-change="handleSizeChange" :page-size="pageSize" :page-sizes="[2, 5, 10]" :pager-count=2
          layout="sizes,prev, pager, next, jumper"></el-pagination>
      </div>
      <!-- 立即评价 -->
      <el-dialog title="Appraise" :visible.sync="evaluateVisible" @close="evaluateClose">
        <div class="addDig addDig_2">
          <div class="orderlist_img" style="text-align: center">
            <img :src="$baseUrl + goodInfo.image" />
          </div>
          <div class="orderlist_info">
            <div class="orderlist_name">{{ goodInfo.commodityName }}</div>
            <div style="margin-top:10px;">
              <el-checkbox v-model="isAnonymous">Anonymous</el-checkbox>
            </div>
          </div>

        </div>
        <div class="addDig addDig_1">
          <span>Supplier service:</span>
          <el-rate v-model="evaluateForm.supplierServiceScore"></el-rate>
        </div>
        <div class="addDig addDig_1">
          <span>On-time shipment:</span>
          <el-rate v-model="evaluateForm.timeShipmentScore"></el-rate>
        </div>
        <div class="addDig addDig_1">
          <span>Product quality:</span>
          <el-rate v-model="evaluateForm.productQuality"></el-rate>
        </div>
        <div class="addDig addDig_1" style="align-items: self-start;">
          <span>Appraise:</span>
          <el-input v-model="evaluateForm.userAppraise" type="textarea" :rows="4" style="width: 200px;"></el-input>
        </div>
        <div style="text-align: center">
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading"
            @click="evaluatefun(evaluateForm)">Send evaluation</el-button>
        </div>
      </el-dialog>
    </div>


    <el-dialog title="logistics information" :visible.sync="dialogVisible" width="60%" :modal="false" class="dialogbox"
      :before-close="handleClose">
      <div>
        <el-timeline :reverse="true">
          <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.timestamp"
            v-if="activity.content.indexOf('$TRACK718SHIPPINGDATA') == -1">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import OrderList from "./orderlist/OrderList.vue";
  import PageNation from "@/components/pagenation/PageNation.vue";
  import { addAppraiseOrder } from "@/api/news"
  import { searchTrackInfoApi } from '@/api/other'

  import {
    getmyOrderList,
    cancelOrder,
    cancelCarOrder,
    delOrder,
    againAddOrder,
  } from "../../../api/index";
  export default {
    name: "Order",
    data() {
      return {
        nav: ["ALL", "Unpaid", "Paid"],
        navcurrent: 0,
        pageSize: 2,
        pageNum: 1,
        list: [],
        total: 0,
        fullscreenLoading: false,
        evaluateVisible: false,
        evaluateForm: {
          orderId: "",
          orderDetailId: "",
          supplierServiceScore: 0,
          timeShipmentScore: 0,
          productQuality: 0,
          userAppraise: "",
          isAnonymous: 2
        },
        isAnonymous: true,
        goodInfo: {},
        activities: [],
        dialogVisible: false,
      };
    },
    components: {
      OrderList,
      PageNation,
    },
    computed: {},
    watch: {
      $route() {
        if (this.$route.path == '/personalcenter/order') {
          this.getmyOrderList();
        }
      },
    },
    created() {
      this.getmyOrderList();
    },
    methods: {
      showGps(logisticsInfo, deliveryOrderNo, logisticsCode) {
        if (logisticsInfo && logisticsCode && deliveryOrderNo) {
          var url = "https://www.track718.com/zh-CN/gpsmaps?trackNum=" + deliveryOrderNo + "&code=" + logisticsCode;
          window.open(url, "_blank");
        }
      },
      queryHandle(deliveryOrderNo, logisticsCode) {
        this.searchTrackInfo(deliveryOrderNo, logisticsCode)
        this.dialogVisible = true
      },
      // 查看物流轨迹
      searchTrackInfo(deliveryOrderNo, logisticsCode) {
        searchTrackInfoApi([{
          trackNum: deliveryOrderNo,
          logisticsCode: logisticsCode,
        }]).then(res => {
          if (res?.code == 200) {
            let activities = []
            res?.data?.data?.list[0].fromDetail.forEach(item => {
              activities.push({
                timestamp: item.date,
                content: item.status
              })
            })
            this.activities = activities
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
      },
      handleClose() {
        this.activities = []
        this.dialogVisible = false
      },
      // 跳转到inquiry详情-发询盘页面
      addInquiryClick(id, price, name, image, bool, num) {
        this.$router.push({
          path: "/inquiry",
          query: {
            id,
            price,
            name,
            num: num,
            image,
            bool,
            box8: false,
            phoneHomeImg: false,
            noBusiness: false,
          },
        });
        // console.log(totalPrice);
      },
      payfun(e, b, item) {
        this.$router.push({
          path: "/pay",
          query: {
            orderid: e,
            price: b,
            businessId: item.businessId,
            orderType: item.orderType,
            bool: false,
            box8: false,
            phoneHomeImg: false,
          },
        });
      },
      //获取订单列表
      getmyOrderList() {
        getmyOrderList({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          orderStatus: this.navcurrent,
        }).then((res) => {
          if (res.code == 200) {
            this.list = res.data.vos;
            this.total = res.data.total;
            function str_substr(start, end, str) {
              let temp = str.split(start, 2);
              let content = temp[1].split(end, 2);
              return content[0];
            }
            this.list.forEach((item) => {
              let index = item.createTime.indexOf("T");
              let time = str_substr("T", ".", item.createTime);
              item.createTime =
                item.createTime.substring(0, index) + `   ` + time;
            });
          }
        });
      },
      //分页
      currentChange(e) {
        this.pageNum = e;
        this.getmyOrderList();
      },
      //改变分页数量
      handleSizeChange(e) {
        this.pageSize = e;
        this.getmyOrderList();
      },
      //取消订单
      cancelfun(orderNo, orderType) {
        this.$alert("cancel?", {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          showCancelButton: true,
        })
          .then(() => {
            if (orderType === 1) {
              cancelOrder(orderNo).then((res) => {
                if (res.code == 200) {
                  this.getmyOrderList();
                  this.$message({ message: res.msg, type: "success" });
                } else {
                  this.$message({ message: res.msg, type: "error" });
                }
              });
            } else if (orderType === 2) {
              cancelCarOrder(orderNo).then((res) => {
                if (res.code == 200) {
                  this.getmyOrderList();
                  this.$message({ message: res.msg, type: "success" });
                } else {
                  this.$message({ message: res.msg, type: "error" });
                }
              });
            }

          })
          .catch((actions) => { });
      },
      //删除订单
      delOrder(e) {
        this.$alert("Delete?", {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          showCancelButton: true,
        })
          .then(() => {
            delOrder(e).then((res) => {
              if (res.code == 200) {
                this.$message({ message: res.msg, type: "success" });
                this.getmyOrderList();
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            });
          })
          .catch((actions) => { });
      },
      // 再来一单
      another(e) {
        againAddOrder(e.orderNo, e.orderType).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "Added to cart", type: "success" });
            this.$router.push({
              path: "/cart",
              query: {
                phoneHomeImg: false,
                box8: false,
                bool: false,
              },
            });
          }
        });
      },
      navfun(e) {
        this.navcurrent = e;
        this.getmyOrderList();
      },
      // 点击收放
      switchOrderlist(e) {
        this.openOrderlist = !this.openOrderlist;
        if (this.openOrderlist) {
          e.currentTarget.classList.replace("open_orderlist", "close_orderlist");
          e.currentTarget.previousElementSibling.classList.remove(
            "orderlist_main"
          );
        } else {
          e.currentTarget.classList.replace("close_orderlist", "open_orderlist");
          e.currentTarget.previousElementSibling.classList.add("orderlist_main");
        }
      },
      // 立即评价
      evaluateClick(e, e2) {
        this.evaluateForm.orderId = e.id
        this.goodInfo = e2
        this.evaluateForm.orderDetailId = e2.id
        // if(e.orderDetailVos&&e.orderDetailVos.length>0){
        //   this.evaluateForm.orderDetailId=e.orderDetailVos[0].id
        //   this.goodInfo=e.orderDetailVos[0]
        // }
        this.evaluateVisible = true
      },
      // 评价弹窗关闭
      evaluateClose() {
        Object.keys(this.evaluateForm).forEach((key) => (this.evaluateForm[key] = ""));
        this.evaluateVisible = false
      },
      // 评价提交
      evaluatefun(e) {
        e.isAnonymous = this.isAnonymous ? 2 : 1
        this.fullscreenLoading = true;
        addAppraiseOrder({ ...e }).then(res => {
          if (res.code == 200) {
            this.$message({ message: "提交成功", type: "success" });
            this.evaluateVisible = false;
            this.fullscreenLoading = false;
            this.shopOrderListApi();
          } else {
            this.$message({ message: res.msg, type: "error" });
            this.evaluateVisible = false;
            this.fullscreenLoading = false;
          }
        })
      },
    },
    mounted() {
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      let childrens = document.querySelector(
        ".personalcenter_nav_router"
      ).children;
      for (let i = 0; i < childrens.length; i++) {
        // 给兄弟元素添加属性
        // childrens[i].classList.remove("nav_active");
        childrens[i].style.backgroundColor = "#fff";
      }
      childrens[1].style.backgroundColor = "#d3f5ec";
    },
    activated() {
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      let childrens = document.querySelector(
        ".personalcenter_nav_router"
      ).children;
      for (let i = 0; i < childrens.length; i++) {
        // 给兄弟元素添加属性
        // childrens[i].classList.remove("nav_active");
        childrens[i].style.backgroundColor = "#fff";
      }
      childrens[1].style.backgroundColor = "#d3f5ec";
    },
  };
</script>

<style scoped>
  .orderlist_main {
    height: calc(140 * 100vw / 1920);
    overflow: hidden;
  }

  .orderlist_li {
    margin: 0 calc(40 * 100vw / 1920);
    /* padding: calc(20 * 100vw / 1920) 0; */
    border-bottom: 1px solid #ddd;
    display: flex;
  }

  .orderlist_img {
    width: calc(130 * 100vw / 1920);
    height: calc(130 * 100vw / 1920);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: calc(20 * 100vw / 1920);
  }

  .orderlist_img img {
    max-width: 99%;
    max-height: 99%;
  }

  .open_orderlist {
    width: 100%;
    height: calc(72 * 100vw / 1920);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .open_orderlist::after {
    content: "\ec0b";
    margin-left: calc(11 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    margin-top: calc(5 * 100vw / 1920);
  }

  .close_orderlist {
    width: 100%;
    height: calc(72 * 100vw / 1920);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .close_orderlist::after {
    content: "\ec0c";
    margin-left: calc(11 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    margin-top: calc(5 * 100vw / 1920);
  }

  #pcstatus {
    display: block;
  }

  #ydstatus {
    display: none;
  }

  .order_page {
    /*width: calc(890 * 100vw / 1920);*/
    width: 100%;
    /*  */
    background: #ffffff;
  }

  .order_title {
    margin-left: calc(38 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .order_main {
    border-top: 1px solid #ddd;
    padding-top: calc(40 * 100vw / 1920);
    margin-bottom: calc(20 * 100vw / 1920);
    min-height: calc(650 * 100vw / 1920);
  }

  .order_nav {
    margin-left: calc(22 * 100vw / 1920);
  }

  .order_nav>div {
    width: calc(120 * 100vw / 1920);
    height: calc(40 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: calc(20 * 100vw / 1920);
    margin-right: calc(20 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
    line-height: calc(35 * 100vw / 1920);
  }

  .order_active {
    background: #13af87 !important;
    color: #ffffff !important;
  }

  .order_list {
    /* width: calc(820 * 100vw / 1920); */
    width: calc(100% - calc(70 * 100vw / 1920));
    min-height: calc(500 * 100vw / 1920);
    margin: calc(40 * 100vw / 1920) calc(35 * 100vw / 1920);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .phoneShow {
    display: none;
  }

  .order_list1_state {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 600;
    color: #fda911;
  }

  .order_list2_state {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 600;
    color: #aaaaaa;
  }

  .order_list1_button {
    background: #13af87 !important;
    color: #ffffff !important;
  }

  .order_list2_button {
    border: 1px solid #13af87 !important;
    color: #13af87 !important;
  }

  .evaluate_btn {
    background: #ffffff !important;
    border: 1px solid #ff0000 !important;
    color: #ff0000 !important;
  }

  .inquiry_button {
    background: #ffffff !important;
    border: 1px solid #13af87 !important;
    color: #13af87 !important;
  }

  .trakc_button {
    background: #ffffff !important;
  }

  .page_nation {
    display: inline-block;
  }

  .page_nation2 {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    .open_orderlist {
      width: 100%;
      height: calc(144 * 100vw / 1080);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
    }

    .open_orderlist::after {
      content: "\ec0b";
      margin-left: calc(22 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      margin-top: calc(10 * 100vw / 1080);
    }

    .close_orderlist {
      width: 100%;
      height: calc(144 * 100vw / 1080);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
    }

    .close_orderlist::after {
      content: "\ec0c";
      margin-left: calc(22 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      margin-top: calc(10 * 100vw / 1080);
    }

    .orderlist_main {
      height: calc(285 * 100vw / 1080);
      overflow: hidden;
    }

    .orderlist_li {
      margin: 0 calc(40 * 100vw / 1080);
      /* padding: calc(20 * 100vw / 1920) 0; */
      border-bottom: 1px solid #ddd;
      display: flex;
    }

    .orderlist_img {
      width: calc(250 * 100vw / 1080);
      height: calc(250 * 100vw / 1080);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: calc(20 * 100vw / 1080);
    }

    .order_page {
      width: 100vw;
      /* height: calc(1624 * 100vw / 1080); */
      background-color: #fff;
      border-top: 1px solid #ddd;
      overflow: hidden;
    }

    .order_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(150 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(170 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .order_main {
      border-top: 1px solid #ddd;
      padding-top: calc(70 * 100vw / 1080);
      margin-bottom: calc(0);
    }

    .order_nav {
      margin-left: calc(58 * 100vw / 1080);
    }

    .order_nav>div {
      width: calc(240 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: calc(30 * 100vw / 1080);
      margin-right: calc(60 * 100vw / 1080);
      font-size: calc(32 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
      text-align: center;
      line-height: calc(60 * 100vw / 1080);
    }

    .order_list {
      width: 100%;
      height: calc(1285 * 100vw / 1080);
      margin: 0;
      margin-top: calc(60 * 100vw / 1080);
      padding: calc(70 * 100vw / 1080) calc(40 * 100vw / 1080) 0;
      background-color: #f6f6f6;
      display: block;
      overflow: hidden;
      overflow-y: scroll;
    }

    .phoneShow {
      display: block;
    }

    .order_list1_state,
    .order_list2_state {
      display: flex;
      width: calc(180 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      font-size: calc(30 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #fda911;
      border-radius: calc(30 * 100vw / 1080);
      border: 1px solid #aaaaaa;
      justify-content: center;
      align-items: center;
    }

    .order_list2_state {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #aaaaaa;
    }


    .page_nation {
      display: none;
      background: #f6f6f6;
    }

    .page_nation2 {
      display: inline-block;
    }
  }

  /* .order_list1_state {
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 600;
  color: #fda911;
} */
  .orderlist {
    width: 100%;
    /* height: calc(255 * 100vw / 1920); */
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
    padding: 0 calc(23 * 100vw / 1920);
    display: flex;
    margin-bottom: calc(25 * 100vw / 1920);
    padding-bottom: calc(10 * 100vw / 1920);
  }

  /* .orderlist_img {
  width: calc(192 * 100vw / 1920);
  height: calc(210 * 100vw / 1920);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: calc(112 * 100vw / 1920) calc(160 * 100vw / 1920);
  margin-right: calc(30 * 100vw / 1920);
}
.orderlist_img img {
  width: 100%;
  height: 100%;
} */
  .orderlist_info {
    flex: 1;
  }

  .orderlist_info .orderlist_id {
    height: calc(55 * 100vw / 1920);
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }

  .orderlist_info .order_list_price {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  .orderlist_id .orderlist_no {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .orderlist_id .orderlist_totalPrice {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
  }

  .orderlist_id .orderlist_totalPrice span {
    font-weight: 600;
    color: #ff0000;
    margin-left: calc(10 * 100vw / 1920);
  }

  .orderlist_id .orderlist_state {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 600;
  }

  .orderlist_name {
    /* margin-top: calc(18 * 100vw / 1920);
  margin-bottom: calc(20 * 100vw / 1920); */
    padding: calc(15 * 100vw / 1920) 0;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 600;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .orderlist_count button {
    width: calc(26 * 100vw / 1920);
    height: calc(26 * 100vw / 1920);
    background: #defdf5;
    border-radius: 50%;
    font-size: calc(25 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #aaaaaa;
    line-height: calc(23 * 100vw / 1920);
  }

  .orderlist_count button:hover {
    background: #13af87;
    color: #ffffff;
  }

  .orderlist_count .num {
    width: calc(26 * 100vw / 1920);
    height: calc(26 * 100vw / 1920);
    margin: 0 calc(15 * 100vw / 1920);
    font-size: calc(16 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: calc(26 * 100vw / 1920);
  }

  .orderlist_name_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .orderlist_name_text2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .orderlist_price {
    /* margin-bottom: calc(20 * 100vw / 1920); */
    width: 100%;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 600;
    color: #ff0000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* .span1 {
      font-size: x-large;
    }

    .span2 {
      color: rgb(51 51 51);
      font-size: medium;
    }

    .span3 {
      font-size: medium;
    } */
  }

  .orderlist_price .evaluate_btn,
  .orderlist_price .inquiry_button,
  .orderlist_price .inquiry_button2,
  .logistics_info .inquiry_button3 {
    width: calc(150 * 100vw / 1920);
    height: calc(36 * 100vw / 1920);
    border-radius: calc(18 * 100vw / 1920);
    margin-left: calc(16 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
  }

  .orderlist_price .inquiry_button,
  .orderlist_price .inquiry_button2 {
    width: calc(170 * 100vw / 1920);
  }

  .logistics_info .inquiry_button3 {
    display: inline-block;
    width: calc(200 * 100vw / 1920);
  }

  .orderlist_info_bottom {
    justify-content: space-between;
    align-items: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .orderlist_info_bottom button {
    width: calc(100 * 100vw / 1920);
    height: calc(36 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #aaaaaa;
    border-radius: calc(18 * 100vw / 1920);
    margin-left: calc(16 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .orderlist_info_bottom .allBtn {
    display: flex;
  }

  .orderlist_info_bottom .buttomBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /deep/.el-dialog {
    width: 35%;
  }

  .addDig {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .addDig_1 span {
    width: calc(200 * 100vw / 1920);
  }

  .addDig_2 {
    align-items: flex-start;
  }

  .addDig_2 .orderlist_info {
    width: calc(100% - calc(130 * 100vw / 1920));
  }

  @media screen and (max-width: 1080px) {
    #pcstatus {
      display: none;
    }

    #ydstatus {
      display: block;
    }

    .orderlist {
      width: 100%;
      /* height: calc(400 * 100vw / 1080); */
      border: none;
      background-color: #fff;
      box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
      margin-bottom: calc(30 * 100vw / 1080);
    }

    /* .orderlist_img {
    width: calc(273 * 100vw / 1080);
    height: calc(400 * 100vw / 1080);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(152 * 100vw / 1080) calc(218 * 100vw / 1080);
    margin-right: calc(17 * 100vw / 1080);
  } */
    /* .orderlist_img img {
    width: 100%;
    height: 100%;
  } */
    .orderlist_info .orderlist_id {
      height: auto;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ddd;
      flex-wrap: wrap;
      padding: calc(50* 100vw / 1920);
    }

    .orderlist_info .order_list_price {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }

    .orderlist_id .orderlist_no {
      font-size: calc(26 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #aaaaaa;
    }

    .orderlist_id .orderlist_totalPrice {
      font-size: calc(26 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
    }

    .orderlist_id .orderlist_totalPrice span {
      font-weight: 600;
      color: #ff0000;
      margin-left: calc(10 * 100vw / 1080);
    }

    .orderlist_id .orderlist_state {
      font-size: calc(22 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
    }

    .orderlist_name {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #333;
    }

    .orderlist_count button {
      width: calc(36 * 100vw / 1080);
      height: calc(36 * 100vw / 1080);
      background: #defdf5;
      border-radius: 50%;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #aaaaaa;
      line-height: calc(25 * 100vw / 1080);
    }

    .orderlist_count .num {
      width: calc(36 * 100vw / 1080);
      height: calc(36 * 100vw / 1080);
      margin: 0 calc(15 * 100vw / 1080);
      font-size: calc(28 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #333333;
      text-align: center;
      line-height: calc(36 * 100vw / 1080);
    }

    .orderlist_name_text2 {
      -webkit-line-clamp: 1;
    }

    .orderlist_price {
      margin-bottom: calc(17* 100vw / 1080);
      font-size: calc(36* 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #ff0000;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .orderlist_price .orderlist_info {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    /* .orderlist_price .span2 {
      color: rgb(51 51 51);
      font-size: calc(36 * 100vw / 1080);
    }

    .orderlist_price .span3 {
      font-size: calc(36* 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #333;
    } */

    .orderlist_price .evaluate_btn,
    .orderlist_price .inquiry_button,
    .orderlist_price .inquiry_button2 {
      width: calc(280 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      border-radius: calc(30 * 100vw / 1080);
      margin-left: calc(40 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
    }

    .orderlist_price .inquiry_button2 {
      display: none;
    }

    .orderlist_price .evaluate_btn {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    .orderlist_price .inquiry_button {
      width: calc(300 * 100vw / 1080);
      margin-top: 0;
    }

    .orderlist_info_bottom {
      /* width: calc(684 * 100vw / 1080); */
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
      padding-bottom: calc(10 * 100vw / 1080);
    }

    .orderlist_info_bottom button {
      width: calc(180 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #aaaaaa;
      border-radius: calc(30 * 100vw / 1080);
      margin-left: calc(40 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
    }

    .orderlist_info_bottom button:first-child {
      margin-left: calc(280 * 100vw / 1080);
    }

    .logistics_info .inquiry_button3 {
      display: inline-block;
      width: calc(400 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      border-radius: calc(30 * 100vw / 1080);
      margin-left: calc(40 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
    }

    .orderlist_info_bottom .allBtn {
      display: inline-block;
    }

    .inquiry_button3 {
      margin: 0 !important;
      margin-top: calc(20* 100vw / 1080) !important;
    }

    /deep/.el-dialog {
      width: 90%;
    }

    .addDig_1 span {
      width: calc(335 * 100vw / 1080);
    }

    .addDig_2 .orderlist_info {
      width: calc(100% - calc(250 * 100vw / 1080));
    }
  }
</style>