<template>
  <div class="other_system">
    <iframe style="width: 100%;height: 100%;" :src="loginParam" frameborder="0" />
  </div>
</template>

<script>
  // const frameSrc='https://my.data10086.com/autologin?loginParam=${loginParam}';
  export default {
    name: "OtherSystem",
    data() {
      return {
        loginParam: null,
      };
    },
    props: {
      numChange: {
        numChange(e) {
          return e;
        },
      },
    },
    created() {
      // this.loginParam = `https://my.data10086.com/autologin?loginParam=` + this.b64encrypt('{"username": "13923503011","password":"b302zu"}');
      this.loginParam = `https://my.data10086.com/autologin` + this.b64encrypt({ "username": "", "password": "" });
    },
    methods: {
      pad(num, n) {
        return Array(n - ("" + num).length + 1).join(0) + num;
      },
      MyEncrypt(txt) {
        let sb = "";
        let rand = 0;
        for (let i = 0; i < txt.length; i++) {
          let item = txt.charCodeAt(i);
          rand = Math.floor(Math.random() * item);
          sb += this.pad((item - rand).toString(16), 2) + this.pad(rand.toString(16), 2);
        }
        return sb;
      },
      b64encrypt(txt) {
        let b64txt = window.btoa(txt);
        let myb64txt = this.MyEncrypt(b64txt);
        return myb64txt; // 对数据进行加密
      }
    },
    mounted() {
    },
    activated() { },
  };
</script>
<style>
  .other_system {
    height: 900px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
</style>