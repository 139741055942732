import VueRouter from "vue-router"
import Vue from "vue"
// 路由懒加载
const Home = () => import('../pages/home/Home')
const Login = () => import('../pages/login/Login')
const Register = () => import('../pages/register/Register')
const Ceramics = () => import('../pages/ceramics/Ceramics')
const CeramicsList = () => import('../pages/ceramics/ceramicslist/CeramicsList')
const CeramicsDetail = () => import('../pages/ceramics/ceramicsdetail/CeramicsDetail')
const Cart = () => import('../pages/ceramics/fineceramics/cart/Cart')
const Settlement = () => import('../pages/settlement/Settlement')
const Pay = () => import('../pages/pay/Pay')
const Search = () => import('../pages/search/Search')

import FineCeramics from "../pages/ceramics/fineceramics/FineCeramics"
import FinePromotionCeramics from "../pages/ceramics/fineceramics/FinePromotionCeramics"
import Inquiry from "../pages/ceramics/fineceramics/inquiry/Inquiry"
import CompanyProfile from "../pages/companyprofile/CompanyProfile"
const Residence = () => import('../pages/residence/Residence')
const Privacy = () => import('../pages/privacy/Privacy')
const AgreementBusiness = () => import('../pages/agreementBusiness/agreementBusiness')
const AgreementUser = () => import('../pages/agreementUser/agreementUser')
const PersonalCenter = () => import('../pages/personalcenter/PersonalCenter')
import Essential from "../pages/personalcenter/essential/Essential"
import Address from "../pages/personalcenter/address/Address"
import AddAddress from "../pages/personalcenter/address/addaddress/AddAddress"
import Password from "../pages/personalcenter/password/Password"
import Merchants from '../pages/personalcenter/merchants/index.vue'
import Business from '../pages/personalcenter/business/index.vue'
import Order from "../pages/personalcenter/order/Order"
const BusinessHome = () => import('../pages/businesshome/BusinessHome')
// const BusinessProfile = () => import('../pages/businessprofile/BusinessProfile')
import BusinessProfile from '../pages/businessprofile/BusinessProfile'
const BusinessReviseProfile = () => import('../pages/reviseprofile/ReviseProfile')
const BusinessCeramics = () => import('../pages/businessceramics/BusinessCeramics')
const BusinessCeramicsList = () => import('../pages/businessceramics/businessceramicslist/BusinessCeramicsList')
const BusinessAddClassify = () => import('../pages/businessaddclassify/BusinessAddClassify')

const BusinessPersonal = () => import('../pages/businesspersonal/BusinessPersonal')
import BusinessEssential from "../pages/businesspersonal/essential/Essential"
const Merchant = () => import('../pages/businesspersonal/essential/merchant/Merchant')
const UpdatePwd = () => import('../pages/businesspersonal/essential/updatepwd/UpdatePwd')
const Employee = () => import('../pages/businesspersonal/essential/employee/Employee')
const AddEmployee = () => import('../pages/businesspersonal/essential/addemployee/AddEmployee')
const Coupon = () => import('../pages/businesspersonal/essential/coupon/Coupon')
const AddCoupon = () => import('../pages/businesspersonal/essential/addcoupon/AddCoupon')

import BusinessOrder from "../pages/businesspersonal/order/Order"
import BusinessPayment from "../pages/businesspersonal/payment/Payment"
const OpenBank = () => import('../pages/businesspersonal/payment/openbank/OpenBank')
import ApplicationMarket from "../pages/businesspersonal/applicationmarket/ApplicationMarket"
import BusinessOrderDetail from "../pages/businesspersonal/order/OrderDetail"

const BusinessLaunch = () => import('../pages/businesslaunch/BusinessLaunch')
const BusinessPromotionDetail = () => import('../pages/businesspersonal/businesspromotion/BusinessPromotionDetail')
const BusinessManagement = () => import('../pages/businessmanagement/BusinessManagement')
const orderDetail = () => import('../pages/orderDetail/orderDetail.vue')
const UpdateEmployee = () => import('../pages/businesspersonal/essential/updateemployee/UpdateEmployee')
const UpdateCoupon = () => import('../pages/businesspersonal/essential/updatecoupon/UpdateCoupon')
const businesscommodity = () => import('../pages/businesscommodity/businesscommodity')
const inquirycenter = () => import('../pages/inquirycenter/inquirycenter')
const MerchantHelp = () => import('../pages/businesspersonal/merchanthelp/MerchantHelp')
const Help = () => import('../pages/help/Help')
const ContactUs = () => import('../pages/contactus/ContactUs')
const Licence = () => import('../pages/licence/Licence')
const businessContactUs = () => import('../pages/businessContactUs/businessContactUs')
const EnquiryInfo = () => import('../pages/EnquiryInfo/EnquiryInfo')
const CartManagement = () => import('../pages/CartManagement/CartManagement')

const Quotations = () => import('../pages/businesspersonal/quotations')
const ArticleList = () => import('../pages/articleList/ArticleList')
const ArticleListDetail = () => import('../pages/articleList/ArticleListDetail')
import BusinessFeedback from '../pages/businessfeedback/BusinessFeedback'
import BusinessPromotion from '../pages/businesspersonal/businesspromotion/BusinessPromotion'
import OtherSystem from '../pages/businesspersonal/OtherSystem'
import RatingsReviews from '../pages/businessprofile/RatingsReviews'
const NewsList = () => import('../pages/news/NewsList')
const NewsDetail = () => import('../pages/news/NewsDetail')


Vue.use(VueRouter)
// 重复点击当前路由不再报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
// 配置路由
const router = new VueRouter({
    mode: "hash",
    routes: [
        // {
        //     path: "",
        //     redirect: "/home"
        // },
        // home
        {
            path: "/", // 跳转路径
            name: "Home",  // 名称
            component: Home,  //组件
        },
        {
            path: "/EnquiryInfo",
            name: "EnquiryInfo",
            component: EnquiryInfo
        },
        {
            path: "/CartManagement",
            name: "CartManagement",
            component: CartManagement
        },
        {
            path: "/orderDetail",
            name: "orderDetail",
            component: orderDetail
        },
        {
            path: "/businessContactUs",
            name: "businessContactUs",
            component: businessContactUs
        },
        {
            path: "/licence",
            name: "'licence",
            component: Licence,
        },
        {
            path: "/businesscommodity",
            name: "businesscommodity",
            component: businesscommodity
        },
        {
            path: "/inquirycenter",
            name: 'inquirycenter',
            component: inquirycenter
        },
        // ceramics
        {
            path: "/ceramics",
            name: "Ceramics",
            component: Ceramics,
            children: [
                // {
                //     path: "",
                //     redirect: "ceramicslist"
                // },
                {
                    path: "ceramicslist",
                    name: "CeramicsList",
                    component: CeramicsList,
                },
                {
                    path: "ceramicsDetail",
                    name: "ceramicsDetail",
                    component: CeramicsDetail,
                },
            ]
        },
        // newslist
        {
            path: "/newslist",
            name: "NewsList",
            component: NewsList,
        },
        // newsdetail
        {
            path: "/newsdetail",
            name: "NewsDetail",
            component: NewsDetail,
        },
        // ceramics/fineceramics
        {
            path: "/ceramics/fineceramics",
            name: "FineCeramics",
            component: FineCeramics,
            children: [
                {
                    path: "ceramicsDetail",
                    name: "ceramicsDetail",
                    component: CeramicsDetail,
                },
            ]
        },
        // ceramics/fineceramics
        {
            path: "/ceramics/finePromotionCeramics",
            name: "FinePromotionCeramics",
            component: FinePromotionCeramics,
        },
        // cart
        {
            path: "/cart",
            name: "Cart",
            component: Cart,
        },
        // search
        {
            path: "/search",
            name: "Search",
            component: Search,
        },
        // settlement
        {
            path: "/settlement",
            name: "Settlement",
            component: Settlement,
        },
        // pay
        {
            path: "/pay",
            name: "Pay",
            component: Pay,
        },
        // inquiry
        {
            path: "/inquiry",
            name: "Inquiry",
            component: Inquiry,
        },
        // login
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
        // register
        {
            path: "/register",
            name: "Register",
            component: Register,
        },
        // companyprofile
        {
            path: "/companyprofile",
            name: "CompanyProfile",
            component: CompanyProfile,
        },
        // residence
        {
            path: "/residence",
            name: "Residence",
            component: Residence,
        },
        // privacy
        {
            path: "/privacy",
            name: "Privacy",
            component: Privacy,
        },
        // agreementBusiness
        {
            path: "/agreementBusiness",
            name: "agreementBusiness",
            component: AgreementBusiness,
        },
        // agreementUser
        {
            path: "/agreementUser",
            name: "agreementUser",
            component: AgreementUser,
        },
        // personalcenter
        {
            path: "/personalcenter",
            // name: "PersonalCenter",
            component: PersonalCenter,
            children: [
                {
                    path: "",
                    redirect: "essential"
                },
                {
                    path: "essential",
                    name: "Essential",
                    component: Essential,
                },
                {
                    path: "address",
                    name: "Address",
                    component: Address,
                },
                {
                    path: "order",
                    name: "Order",
                    component: Order,
                },
                {
                    path: "password",
                    name: "Password",
                    component: Password,
                },
                {
                    path: "merchants",
                    name: "merchants",
                    component: Merchants,
                },
                {
                    path: "business",
                    name: "business",
                    component: Business,
                },
            ]
        },
        // addaddress
        {
            path: "/addaddress",
            name: "AddAddress",
            component: AddAddress,
        },
        // businesshome
        {
            path: "/businesshome",
            name: "BusinessHome",
            component: BusinessHome,
        },
        // businessprofile
        {
            path: "/businessprofile",
            name: "BusinessProfile",
            component: BusinessProfile,
        },

        // BusinessFeedback
        {
            path: "/businessfeedback",
            name: "BusinessFeedback",
            component: BusinessFeedback,
        },
        // RatingsReviews
        {
            path: "/ratingsreviews",
            name: "RatingsReviews",
            component: RatingsReviews,
        },

        // businessreviseprofile
        {
            path: "/businessreviseprofile",
            name: "BusinessReviseProfile",
            component: BusinessReviseProfile,
        },
        // businessceramics
        {
            path: "/businessceramics",
            // name: "BusinessCeramics",
            component: BusinessCeramics,
            children: [
                {
                    path: "",
                    redirect: "businessceramicslist"
                },
                {
                    path: "businessceramicslist",
                    name: "BusinessCeramicsList",
                    component: BusinessCeramicsList,
                }
            ]
        },
        // businessaddclassify
        {
            path: "/businessaddclassify",
            name: "BusinessAddClassify",
            component: BusinessAddClassify,
        },
        // BusinessPersonal
        {
            path: "/businesspersonal",
            name: "BusinessPersonal",
            component: BusinessPersonal,
            children: [
                {
                    path: "businessessential",
                    // name: "BusinessEssential",
                    component: BusinessEssential,
                    children: [
                        {
                            path: "",
                            redirect: "merchant"
                        },
                        {
                            path: "merchant",
                            name: "Merchant",
                            component: Merchant,
                        },
                        {
                            path: "updatepwd",
                            name: "UpdatePwd",
                            component: UpdatePwd,
                        },
                        {
                            path: "employee",
                            name: "Employee",
                            component: Employee,
                        },
                        {
                            path: "addemployee",
                            name: "AddEmployee",
                            component: AddEmployee,
                        },
                        {
                            path: "coupon",
                            name: "Coupon",
                            component: Coupon,
                        },
                        {
                            path: "addcoupon",
                            name: "AddCoupon",
                            component: AddCoupon,
                        },
                        {
                            path: "updatecoupon",
                            name: "UpdateCoupon",
                            component: UpdateCoupon,
                        },
                        {
                            path: "updateemployee",
                            name: "UpdateEmployee",
                            component: UpdateEmployee,
                        },
                    ]
                },
                {
                    path: "businessorder",
                    name: "BusinessOrder",
                    component: BusinessOrder,
                },
                {
                    path: "applicationmarket",
                    name: "ApplicationMarket",
                    component: ApplicationMarket,
                },
                {
                    path: "businessordedetail",
                    name: "BusinessOrderDetail",
                    component: BusinessOrderDetail,
                },
                {
                    path: "businesspayment",
                    name: "BusinessPayment",
                    component: BusinessPayment,
                },
                {
                    path: "businessfeedback",
                    name: "BusinessFeedback",
                    component: BusinessFeedback,
                },
                {
                    path: "businesspromotion",
                    name: "BusinessPromotion",
                    component: BusinessPromotion,
                },
                {
                    path: "otherSystem",
                    name: "OtherSystem",
                    component: OtherSystem,
                },
            ]
        },
        // openbank
        {
            path: "/openbank",
            name: "OpenBank",
            component: OpenBank,
        },
        // businesslaunch
        {
            path: "/businesslaunch",
            name: "BusinessLaunch",
            component: BusinessLaunch,
        },
        // businesspromotiondetail
        {
            path: "/businesspromotiondetail",
            name: "BusinessPromotionDetail",
            component: BusinessPromotionDetail,
        },
        // businessmanagement
        {
            path: "/businessmanagement",
            name: "BusinessManagement",
            component: BusinessManagement,
        },
        // merchanthelp
        {
            path: "/merchanthelp",
            name: "MerchantHelp",
            component: MerchantHelp,
        },
        // quotations
        {
            path: "/quotations",
            name: "Quotations",
            component: Quotations,
        },
        // articlelist
        {
            path: "/articlelist",
            name: "ArticleList",
            component: ArticleList,
        },
        // ArticleListDetail
        {
            path: "/articlelistdetail",
            name: "ArticleListDetail",
            component: ArticleListDetail,
        },
        // help
        {
            path: "/help",
            name: "Help",
            component: Help,
        },
        // contactus
        {
            path: "/contactus",
            name: "ContactUs",
            component: ContactUs,
        },
        // Licence
        // {
        //     path: "/licence",
        //     name: "Licence",
        //     component: Licence,
        // },
    ],
})

// 全局导航前置守卫，路由跳转时触发
router.beforeEach((to, from, next) => {

    // 必须调用内置next()函数
    next()


    // if (!localStorage.getItem('token')) {
    //   if (to.name === 'login') {
    //     next();
    //   } else {
    //     router.push('/login');
    //   }
    // } else {
    //   next();
    // }
})



export default router