import { render, staticRenderFns } from "./FineCeramics.vue?vue&type=template&id=b6e6cf0e&scoped=true"
import script from "./FineCeramics.vue?vue&type=script&lang=js"
export * from "./FineCeramics.vue?vue&type=script&lang=js"
import style0 from "./FineCeramics.vue?vue&type=style&index=0&id=b6e6cf0e&prod&scoped=true&lang=css"
import style1 from "./FineCeramics.vue?vue&type=style&index=1&id=b6e6cf0e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6e6cf0e",
  null
  
)

export default component.exports