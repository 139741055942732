<template>
  <div class="orderlist flex-row">
    <div class="orderlist_img"></div>
    <div class="orderlist_info">
      <div class="orderlist_id flex-row">
        <span class="orderlist_no">No.:000000000000000000</span>
        <div class="orderlist_totalPrice">
          Total Price:<span>${{ totalPriceData }}</span>
        </div>
        <slot name="orderlist_state">
          <span class="orderlist_state">Unpaid</span>
        </slot>
      </div>
      <div class="orderlist_name flex-row">
        <div class="orderlist_name_text">Fine Ceramics</div>
        <div class="orderlist_count flex-row">
          <button @click="numCount(-1)">-</button>
          <div class="num">{{ num }}</div>
          <button @click="numCount(1)">+</button>
        </div>
      </div>
      <div class="orderlist_price">${{ price }}</div>
      <div class="orderlist_info_bottom flex-row">
        <div class="orderlist_time">Order time:2022-10-10 16:36:24</div>
        <slot name="orderlist_info_bottom">
          <div>
            <button>Cancel</button>
            <button>Pay</button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderList",
  data() {
    return {
      price: 2000, // 单价
      num: 1, // 数量
      totalPrice: 0, // 总价
    };
  },
  components: {},
  computed: {
    totalPriceData() {
      return (this.totalPrice = this.price * this.num);
    },
  },
  mounted() {},
  watch: {},
  methods: {
    numCount(number) {
      this.num += number;
      if (this.num <= 0) {
        this.num = 0;
      }
    },
  },
};
</script>

<style scoped>
.order_list1_state {
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 600;
  color: #fda911;
}
.orderlist {
  width: 100%;
  height: calc(210 * 100vw / 1920);
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
  padding-right: calc(23 * 100vw / 1920);
  display: flex;
  margin-bottom: calc(25 * 100vw / 1920);
}
.orderlist_img {
  width: calc(192 * 100vw / 1920);
  height: calc(210 * 100vw / 1920);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: calc(112 * 100vw / 1920) calc(160 * 100vw / 1920);
  background-image: url(../../../../assets/img/图层2.png);
  margin-right: calc(30 * 100vw / 1920);
}
.orderlist_info {
  flex: 1;
}
.orderlist_info .orderlist_id {
  height: calc(55 * 100vw / 1920);
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}
.orderlist_id .orderlist_no {
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #aaaaaa;
}
.orderlist_id .orderlist_totalPrice {
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
}
.orderlist_id .orderlist_totalPrice span {
  font-weight: 600;
  color: #ff0000;
  margin-left: calc(10 * 100vw / 1920);
}
.orderlist_id .orderlist_state {
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 600;
}
.orderlist_name {
  margin-top: calc(18 * 100vw / 1920);
  margin-bottom: calc(20 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 600;
  color: #333;
  justify-content: space-between;
}

.orderlist_count button {
  width: calc(26 * 100vw / 1920);
  height: calc(26 * 100vw / 1920);
  background: #defdf5;
  border-radius: 50%;
  font-size: calc(25 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 500;
  color: #aaaaaa;
  line-height: calc(23 * 100vw / 1920);
}
.orderlist_count button:hover {
  background: #13af87;
  color: #ffffff;
}
.orderlist_count .num {
  width: calc(26 * 100vw / 1920);
  height: calc(26 * 100vw / 1920);
  margin: 0 calc(15 * 100vw / 1920);
  font-size: calc(16 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: calc(26 * 100vw / 1920);
}
.orderlist_price {
  margin-bottom: calc(20 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 600;
  color: #ff0000;
}
.orderlist_info_bottom {
  justify-content: space-between;
  align-items: center;
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #aaaaaa;
}
.orderlist_info_bottom button {
  width: calc(100 * 100vw / 1920);
  height: calc(36 * 100vw / 1920);
  background: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: calc(18 * 100vw / 1920);
  margin-left: calc(16 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #aaaaaa;
}
@media screen and (max-width: 1080px) {
  .orderlist {
    width: 100%;
    height: calc(400 * 100vw / 1080);
    border: none;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
    margin-bottom: calc(30 * 100vw / 1080);
  }
  .orderlist_img {
    width: calc(273 * 100vw / 1080);
    height: calc(400 * 100vw / 1080);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(152 * 100vw / 1080) calc(218 * 100vw / 1080);
    background-image: url(../../../../assets/img/图层2.png);
    margin-right: calc(17 * 100vw / 1080);
  }
  .orderlist_info .orderlist_id {
    height: calc(83 * 100vw / 1080);
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  .orderlist_id .orderlist_no {
    font-size: calc(24 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }
  .orderlist_id .orderlist_totalPrice {
    font-size: calc(26 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
  }
  .orderlist_id .orderlist_totalPrice span {
    font-weight: 600;
    color: #ff0000;
    margin-left: calc(10 * 100vw / 1080);
  }
  .orderlist_id .orderlist_state {
    font-size: calc(22 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 600;
  }
  .orderlist_name {
    margin-top: calc(28 * 100vw / 1080);
    margin-bottom: calc(20 * 100vw / 1080);
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 600;
    color: #333;
  }
  .orderlist_count button {
    width: calc(36 * 100vw / 1080);
    height: calc(36 * 100vw / 1080);
    background: #defdf5;
    border-radius: 50%;
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 500;
    color: #aaaaaa;
    line-height: calc(25 * 100vw / 1080);
  }
  .orderlist_count .num {
    width: calc(36 * 100vw / 1080);
    height: calc(36 * 100vw / 1080);
    margin: 0 calc(15 * 100vw / 1080);
    font-size: calc(28 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: calc(36 * 100vw / 1080);
  }
  .orderlist_price {
    margin-bottom: calc(17 * 100vw / 1080);
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 600;
    color: #ff0000;
  }
  .orderlist_info_bottom {
    width: calc(684 * 100vw / 1080);
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }
  .orderlist_info_bottom button {
    width: calc(180 * 100vw / 1080);
    height: calc(60 * 100vw / 1080);
    background: #ffffff;
    border: 1px solid #aaaaaa;
    border-radius: calc(30 * 100vw / 1080);
    margin-left: calc(40 * 100vw / 1080);
    margin-top: calc(20 * 100vw / 1080);
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }
  .orderlist_info_bottom button:first-child {
    margin-left: calc(280 * 100vw / 1080);
  }
}
</style>
