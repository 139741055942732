<!--
* @description Promote merchants
* @fileName index.vue
* @author 
* @date 2024/02/26 21:24:58
!-->
<template>
  <div class="merchants">
    <div class="merchants-flex">
      <div class="tabs">
        <div class="essential_title" @click="onclick('bind')" :class="{ hover: active === 'bind' }">Bind Merchants</div>
        <div class="essential_title" @click="onclick('record')" :class="{ hover: active === 'record' }">Promotion Record</div>
        <div class="essential_title" @click="onclick('widhtrawal')" :class="{ hover: active === 'widhtrawal' }">Withdrawal Application</div>
      </div>
    </div>
    <div class="flex-right">
      <div v-if="active === 'bind'"> <el-button type="primary" class="btn" @click="records">View bound merchant records</el-button></div>
      <div class="right-view" v-if="active === 'widhtrawal'">
        <el-button type="primary" class="btn" @click="information">View withdrawal bank card information</el-button>
        <div class="flex mt10">
          <div class="title">Promotion commission: {{ info.promotionBalance }}</div>
          <el-button type="primary" class="btn" @click="withdrawal">Withdrawal</el-button>
        </div>
      </div>
      <div class="tabs-right" v-if="active === 'record'">
        <el-input type="text" v-model="businessId" placeholder="Please enter the promoted merchant id" class="inputs"></el-input>
        <el-button type="primary" class="search" @click="search">Search</el-button>
      </div>
    </div>
    <Bind v-if="active === 'bind'" :info="info" @withdrawal="withdrawal" @onrach="init"/>
    <Record v-if="active === 'record'" ref="record" :businessId="businessId" />
    <Widhtrawal v-if="active === 'widhtrawal'" ref="widhtrawal" />

    <MerchantRecords  v-if="visible" :visible="visible" @close="visible = false" />
    <BankCard v-if="visible1" :visible="visible1" @close="clsoe" :cuitem="cuitem" :title="title" :emit="emit" />
    <WithdrawalOfCash v-if="visible2" :visible="visible2" @close="close2" :cuitem="cuitem" :info="info" />
  </div>
</template>

<script>
import { consumerLookPromotionBusiness, consumerLookBindBankInfo } from '../../../api/index'
export default {
  components: {
    Bind: () => import('./bind.vue'),
    Record: () => import('./record.vue'),
    Widhtrawal: () => import('./widhtrawal.vue'),
    MerchantRecords: () => import('./merchant-records.vue'),
    BankCard: () => import('./bank-card.vue'),
    WithdrawalOfCash: () => import('./withdrawal-of-cash.vue')
  },
  data() {
    return {
      active: 'bind',
      businessId: '',
      visible: false,
      info: {},
      title: 'Bind a bank card',
      visible1: false,
      cuitem: {},
      emit: false,
      visible2: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init () {
      const res = await consumerLookPromotionBusiness()
      if (res.code === 200) {
        this.info = res.data
      }
    },
    onclick (val) {
      this.businessId = ''
      this.active = val
    },
    search () {
      this.$refs.record.init()
    },
    records () {
      this.visible = true
    },
    // 查看绑定了银行卡没
    async information () {
      const res = await consumerLookBindBankInfo()
      // console.log(res, 'xxxxxxxxxxx')
      if (res.code === 200) {
        this.title = 'Modify bank card information'
        this.cuitem = res.data
        this.emit = true
      } else {
        this.title = 'Bind a bank card'
        this.emit = false
      }
      this.visible1 = true
    },
    async withdrawal () {
      const res = await consumerLookBindBankInfo()
      if (res.code === 200) {
        this.cuitem = res.data
      }
      this.visible2 = true
    },
    clsoe (flag) {
      if (flag) {
        this.$refs.widhtrawal.init()
      }
      this.visible1 = false
    },
    close2 (flag) {
      if (flag) {
        if (this.active === 'bind') {
          this.init()
        } else {
          this.$refs.widhtrawal.init()
        }
      }
      this.visible2 = false
    }
  }
}
</script>
<style scoped>
.essential_title {
  margin-left: calc(38 * 100vw / 1920);
  /* width: calc(180 * 100vw / 1920); */
  height: calc(105 * 100vw / 1920);
  white-space: nowrap;
  line-height: calc(105 * 100vw / 1920);
  /* border-bottom: 5px solid rgba(19, 175, 135, 1); */
  font-weight: bolder;
  font-size: 1.28vw;
  cursor: pointer;
}
.merchants {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 0 calc(20 * 100vw / 1920);
  height: 100%;
}
.merchants-flex {
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.flex-right {
  position: absolute;
  top: calc(30 * 100vw / 1920);
  right: calc(10 * 100vw / 1920);
}
.tabs {
  display: flex;
}
.hover {
  border-bottom: 5px solid rgba(19, 175, 135, 1);
}
.tabs-right {
  display: flex;
}
.search {
  margin-left: calc(16 * 100vw / 1920);
  height: calc(50 * 100vw / 1920);
  background: rgba(19, 175, 135, 1);
}
.search:focus, .search:hover {
  background: rgba(19, 175, 135, 1);
}

.btn {
  margin-left: calc(16 * 100vw / 1920);
  height: calc(40 * 100vw / 1920);
  background: rgba(19, 175, 135, 1);
  font-size: calc(18 * 100vw / 1920);
  line-height: calc(12 * 100vw / 1920);
}
.btn:focus, .btn:hover {
  background: rgba(19, 175, 135, 1);
}
.tabs-right /deep/ .el-input__inner {
  width: calc(360 * 100vw / 1920);
  height: calc(50 * 100vw / 1920);
  background: #ffffff;
  border: 1px solid #dddddd;
  font-size: calc(16 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
}
.right-view {
  position: absolute;
  right: calc(20 * 100vw / 1920);
  top: calc(-20 * 100vw / 1920);
}
.flex {
  display: flex;
}
.mt10 {
  margin-top: calc(10 * 100vw / 1920);
}
.title {
  margin-top: calc(10 * 100vw / 1920);
  font-size: calc(16 * 100vw / 1920);
  font-weight: bolder;
  line-height: calc(23 * 100vw / 1920);
}
.inputs {
  width: calc(360 * 100vw / 1920);
}

@media screen and (max-width: 1080px) {
  .merchants {
    width: 100vw;
    height: calc(1624 * 100vw / 1080);
  }
  .essential_title {
    margin-left: calc(38 * 100vw / 1920);
    /* width: calc(180 * 100vw / 1920); */
    height: calc(105 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(105 * 100vw / 1920);
    /* border-bottom: 5px solid rgba(19, 175, 135, 1); */
    font-weight: bolder;
    font-size: 3vw;
    cursor: pointer;
  }

  .flex-right {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: calc(20 * 100vw / 1920);
    padding-bottom: calc(30 * 100vw / 1920);
  }
  .right-view {
    position: static;
  }
  .btn {
    font-size: calc(48 * 100vw / 1920);
    line-height: calc(12 * 100vw / 1920);
  }
  .tabs-right /deep/ .el-input__inner {
    width: calc(650 * 100vw / 1920);
    height: calc(140 * 100vw / 1920);
    font-size: calc(48 * 100vw / 1920);
  }
  .inputs {
    width: calc(650 * 100vw / 1920);
  }
  .search  {
    line-height: 0;
  }
  .title {
    font-size: 3vw;
    line-height: 2;
  }

}
</style>
