let countryCode = [
{
"country": "埃及",
"countryEn": "Egypt",
"id": 1,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
31.14,
30.01
],
"code": "eg"
},
{
"country": "利比亚",
"countryEn": "Libya",
"id": 2,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
13.07,
32.49
],
"code": "ly"
},
{
"country": "突尼斯",
"countryEn": "Tunisia",
"id": 3,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
10.11,
36.50
],
"code": "tn"
},
{
"country": "阿尔及利亚",
"countryEn": "Algeria",
"id": 4,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
3.08,
36.42
],
"code": "dz"
},
{
"country": "摩洛哥",
"countryEn": "Morocco",
"id": 5,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-7.09,
31.79
],
"code": "ma"
},
{
"country": "西撒哈拉",
"countryEn": "Western Sahara",
"id": 6,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-12.88,
24.21
],
"code": "eh"
},
{
"country": "毛里塔尼亚",
"countryEn": "Mauritania",
"id": 7,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
57.30,
-20.10
],
"code": "mr"
},
{
"country": "塞内加尔",
"countryEn": "Senegal",
"id": 8,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-17.29,
14.34
],
"code": "sn"
},
{
"country": "佛得角",
"countryEn": "Cape Verde",
"id": 9,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-23.34,
15.02
],
"code": "cv"
},
{
"country": "冈比亚",
"countryEn": "Gambia",
"id": 10,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-16.40,
13.28
],
"code": "gm"
},
{
"country": "几内亚比绍",
"countryEn": "Guinea-Bissau",
"id": 11,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-15.45,
11.45
],
"code": "gw"
},
{
"country": "几内亚",
"countryEn": "Guinea",
"id": 12,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-13.49,
9.29
],
"code": "gn"
},
{
"country": "马里",
"countryEn": "Mali",
"id": 13,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-7.55,
12.34
],
"code": "ml"
},
{
"country": "布基纳法索",
"countryEn": "Burkina Faso",
"id": 14,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-1.30,
12.15
],
"code": "bf"
},
{
"country": "尼日尔",
"countryEn": "Niger",
"id": 15,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
2.06,
13.27
],
"code": "ne"
},
{
"country": "乍得",
"countryEn": "Chad",
"id": 16,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
14.59,
12.10
],
"code": "td"
},
{
"country": "苏丹",
"countryEn": "Sudan",
"id": 17,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
32.35,
15.31
],
"code": "sd"
},
{
"country": "厄立特里亚",
"countryEn": "Eritrea",
"id": 18,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
38.55,
15.19
],
"code": "er"
},
{
"country": "吉布提",
"countryEn": "Djibouti",
"id": 19,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
42.20,
11.08
],
"code": "dj"
},
{
"country": "索马里",
"countryEn": "Somalia",
"id": 20,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
45.25,
2.02
],
"code": "so"
},
{
"country": "埃塞俄比亚",
"countryEn": "Ethiopia",
"id": 21,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
38.42,
9.02
],
"code": "et"
},
{
"country": "中非",
"countryEn": "Central Africa",
"id": 22,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
18.35,
4.23
],
"code": "cf"
},
{
"country": "喀麦隆",
"countryEn": "Cameroon",
"id": 23,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
11.35,
3.50
],
"code": "cm"
},
{
"country": "尼日利亚",
"countryEn": "Nigeria",
"id": 24,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
7.32,
9.05
],
"code": "ng"
},
{
"country": "贝宁",
"countryEn": "Benin",
"id": 25,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
2.42,
6.23
],
"code": "bj"
},
{
"country": "多哥",
"countryEn": "Togo",
"id": 26,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
1.20,
6.09
],
"code": "tg"
},
{
"country": "加纳",
"countryEn": "Ghana",
"id": 27,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-0.06,
5.35
],
"code": "gh"
},
{
"country": "科特迪瓦（象牙海岸）",
"countryEn": "Cote d’lvoire（Ivory Coast）",
"id": 28,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-5.17,
6.49
],
"code": "ci"
},
{
"country": "塞拉利昂",
"countryEn": "Sierra Leone",
"id": 29,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-13.17,
8.30
],
"code": "sl"
},
{
"country": "利比里亚",
"countryEn": "Liberia",
"id": 30,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
-10.47,
6.18
],
"code": "lr"
},
{
"country": "赤道几内亚",
"countryEn": "Equatorial Guinea",
"id": 31,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
8.50,
3.45
],
"code": "gq"
},
{
"country": "圣多美和普林西比",
"countryEn": "Sao Tome and Principe",
"id": 32,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
6.39,
0.10
],
"code": "st"
},
{
"country": "加蓬",
"countryEn": "Gabon",
"id": 33,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
9.26,
0.25
],
"code": "ga"
},
{
"country": "刚果",
"countryEn": "Congo",
"id": 34,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
15.12,
-4.09
],
"code": "cg"
},
{
"country": "刚果（扎伊尔）",
"countryEn": "The Democratic Republic Of Congo （Zaire）",
"id": 35,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
15.15,
-4.20
],
"code": "cd"
},
{
"country": "乌干达",
"countryEn": "Uganda",
"id": 36,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
32.30,
0.20
],
"code": "ug"
},
{
"country": "卢旺达",
"countryEn": "Rwanda",
"id": 37,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
30.04,
-1.59
],
"code": "rw"
},
{
"country": "布隆迪",
"countryEn": "Burundi",
"id": 38,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
29.18,
-3.16
],
"code": "bi"
},
{
"country": "肯尼亚",
"countryEn": "Kenya",
"id": 39,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
36.48,
-1.17
],
"code": "ke"
},
{
"country": "坦桑尼亚",
"countryEn": "Tanzania",
"id": 40,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
35.45,
-6.08
],
"code": "tz"
},
{
"country": "莫桑比克",
"countryEn": "Mozambique",
"id": 41,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
32.32,
-25.58
],
"code": "mz"
},
{
"country": "马拉维",
"countryEn": "Malawi",
"id": 42,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
33.48,
-14.00
],
"code": "mw"
},
{
"country": "赞比亚",
"countryEn": "Zambia",
"id": 43,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
28.16,
-15.28
],
"code": "zm"
},
{
"country": "安哥拉",
"countryEn": "Angola",
"id": 44,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
13.15,
-8.50
],
"code": "ao"
},
{
"country": "纳米比亚",
"countryEn": "Namibia",
"id": 45,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
17.04,
-22.35
],
"code": "na"
},
{
"country": "博茨瓦纳",
"countryEn": "Botswana",
"id": 46,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
25.57,
-24.45
],
"code": "bw"
},
{
"country": "津巴布韦",
"countryEn": "Zimbabwe",
"id": 47,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
31.02,
-17.43
],
"code": "zw"
},
{
"country": "南非",
"countryEn": "South Africa",
"id": 48,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
22.93,
-30.55
],
"code": "za"
},
{
"country": "斯威士兰",
"countryEn": "Swaziland",
"id": 49,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
31.06,
-26.18
],
"code": "sz"
},
{
"country": "莱索托",
"countryEn": "Lesotho",
"id": 50,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
27.30,
-29.18
],
"code": "ls"
},
{
"country": "塞舌尔",
"countryEn": "Seychelles",
"id": 51,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
55.28,
-4.40
],
"code": "sc"
},
{
"country": "科摩罗",
"countryEn": "Comoro",
"id": 52,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
43.16,
-11.40
],
"code": "km"
},
{
"country": "马达加斯加",
"countryEn": "Madagascar",
"id": 53,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
47.31,
-18.55
],
"code": "mg"
},
{
"country": "毛里求斯",
"countryEn": "Mauritius",
"id": 54,
"region": "非洲",
"regionEn": "Africa",
"regionId": "5",
"coordinate": [
57.50,
-20.00
],
"code": "mu"
},
{
"country": "中国",
"countryEn": "China",
"id": 55,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
116.20,
39.55
],
"code": "cn"
},
{
"country": "蒙古",
"countryEn": "Mongolia",
"id": 56,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
106.92,
47.92
],
"code": "mn"
},
{
"country": "朝鲜",
"countryEn": "Korea",
"id": 57,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
125.30,
39.09
],
"code": "kp"
},
{
"country": "韩国",
"countryEn": "South Korea",
"id": 58,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
126.58,
37.31
],
"code": "kr"
},
{
"country": "日本",
"countryEn": "Japan",
"id": 59,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
139.44,
35.41
],
"code": "jp"
},
{
"country": "哈萨克斯坦",
"countryEn": "Kazakhstan",
"id": 60,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
71.30,
51.10
],
"code": "kz"
},
{
"country": "土库曼斯坦",
"countryEn": "Turkmenistan",
"id": 61,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
57.50,
38.00
],
"code": "tm"
},
{
"country": "乌兹别克斯坦",
"countryEn": "Uzbekistan",
"id": 62,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
69.10,
41.20
],
"code": "uz"
},
{
"country": "吉尔吉斯斯坦",
"countryEn": "Kyrgyzstan",
"id": 63,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
74.46,
42.54
],
"code": "kg"
},
{
"country": "塔吉克斯坦",
"countryEn": "Tajikistan",
"id": 64,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
68.48,
38.33
],
"code": "tj"
},
{
"country": "格鲁吉亚",
"countryEn": "Georgia",
"id": 65,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
44.50,
41.43
],
"code": "ge"
},
{
"country": "亚美尼亚",
"countryEn": "Armenia",
"id": 66,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
44.31,
40.10
],
"code": "am"
},
{
"country": "阿塞拜疆",
"countryEn": "Azerbaijan",
"id": 67,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
49.56,
40.29
],
"code": "az"
},
{
"country": "土耳其",
"countryEn": "Turkey",
"id": 68,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
32.54,
39.57
],
"code": "tr"
},
{
"country": "塞浦路斯",
"countryEn": "Cyprus",
"id": 69,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
33.25,
35.10
],
"code": "cy"
},
{
"country": "叙利亚",
"countryEn": "Syria",
"id": 70,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
36.18,
33.30
],
"code": "sy"
},
{
"country": "黎巴嫩",
"countryEn": "Lebanon",
"id": 71,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
35.31,
33.53
],
"code": "lb"
},
{
"country": "伊拉克",
"countryEn": "Iraq",
"id": 72,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
44.30,
33.20
],
"code": "iq"
},
{
"country": "科威特",
"countryEn": "Kuwait",
"id": 73,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
48.00,
29.30
],
"code": "kw"
},
{
"country": "约旦",
"countryEn": "Jordan",
"id": 74,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
35.52,
31.57
],
"code": "jo"
},
{
"country": "巴勒斯坦",
"countryEn": "Palestine",
"id": 75,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
34.28,
31.30
],
"code": "ps"
},
{
"country": "以色列",
"countryEn": "Israel",
"id": 76,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
35.12,
31.47
],
"code": "il"
},
{
"country": "沙特阿拉伯",
"countryEn": "Saudi Arabia",
"id": 77,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
46.42,
24.41
],
"code": "sa"
},
{
"country": "巴林",
"countryEn": "Bahrain",
"id": 78,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
50.30,
26.10
],
"code": "bh"
},
{
"country": "卡塔尔",
"countryEn": "Qatar",
"id": 79,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
51.35,
25.15
],
"code": "qa"
},
{
"country": "阿拉伯联合酋长国",
"countryEn": "United Arab Emirates",
"id": 80,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
54.22,
24.28
],
"code": "ae"
},
{
"country": "也门",
"countryEn": "Yemen",
"id": 81,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
48.51,
15.55
],
"code": "ye"
},
{
"country": "阿曼",
"countryEn": "Oman",
"id": 82,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
58.36,
23.37
],
"code": "om"
},
{
"country": "伊朗",
"countryEn": "Iran",
"id": 83,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
51.30,
35.44
],
"code": "ir"
},
{
"country": "阿富汗",
"countryEn": "Afghanistan",
"id": 84,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
69.11,
34.28
],
"code": "af"
},
{
"country": "巴基斯坦",
"countryEn": "Pakistan",
"id": 85,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
73.10,
33.40
],
"code": "pk"
},
{
"country": "印度",
"countryEn": "India",
"id": 86,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
77.13,
28.37
],
"code": "in"
},
{
"country": "斯里兰卡",
"countryEn": "Sri Lanka",
"id": 87,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
80.77,
7.87
],
"code": "lk"
},
{
"country": "马尔代夫",
"countryEn": "Maldives",
"id": 88,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
73.28,
4.00
],
"code": "mv"
},
{
"country": "尼泊尔",
"countryEn": "Nepal",
"id": 89,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
85.20,
27.45
],
"code": "np"
},
{
"country": "不丹",
"countryEn": "Bhutan",
"id": 90,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
89.45,
27.31
],
"code": "bt"
},
{
"country": "孟加拉国",
"countryEn": "Bangladesh",
"id": 91,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
90.26,
23.43
],
"code": "bd"
},
{
"country": "缅甸",
"countryEn": "Burma",
"id": 92,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
96.20,
16.45
],
"code": "mm"
},
{
"country": "泰国",
"countryEn": "Thailand",
"id": 93,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
100.35,
13.45
],
"code": "th"
},
{
"country": "老挝",
"countryEn": "Laos",
"id": 94,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
102.36,
17.58
],
"code": "la"
},
{
"country": "柬埔寨",
"countryEn": "Kampuchea Cambodia",
"id": 95,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
104.55,
11.33
],
"code": "kh"
},
{
"country": "越南",
"countryEn": "Vietnam",
"id": 96,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
105.55,
21.05
],
"code": "vn"
},
{
"country": "马来西亚",
"countryEn": "Malaysia",
"id": 97,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
101.41,
3.09
],
"code": "my"
},
{
"country": "新加坡",
"countryEn": "Singapore",
"id": 98,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
103.51,
1.18
],
"code": "sg"
},
{
"country": "文莱",
"countryEn": "Brunei",
"id": 99,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
115.00,
4.52
],
"code": "bn"
},
{
"country": "菲律宾",
"countryEn": "Philippines",
"id": 100,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
121.03,
14.40
],
"code": "ph"
},
{
"country": "印度尼西亚",
"countryEn": "Indonesia",
"id": 101,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
106.49,
-6.09
],
"code": "id"
},
{
"country": "东帝汶",
"countryEn": "East Timor",
"id": 102,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
125.34,
-8.29
],
"code": "tp"
},
{
"country": "格陵兰岛",
"countryEn": "Greenland",
"id": 103,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-51.43,
64.10
],
"code": "gl"
},
{
"country": "加拿大",
"countryEn": "Canada",
"id": 104,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-75.42,
45.27
],
"code": "ca"
},
{
"country": "圣皮埃尔和密克隆",
"countryEn": "St. Pierre and Miquelon",
"id": 105,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-56.12,
46.46
],
"code": "pm"
},
{
"country": "美国",
"countryEn": "The United States of America",
"id": 106,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-77.02,
39.91
],
"code": "us"
},
{
"country": "墨西哥",
"countryEn": "Mexico",
"id": 107,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-99.10,
19.20
],
"code": "mx"
},
{
"country": "危地马拉",
"countryEn": "Guatemala",
"id": 108,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-90.22,
14.40
],
"code": "gt"
},
{
"country": "伯利兹",
"countryEn": "Belize",
"id": 109,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-88.30,
17.18
],
"code": "bz"
},
{
"country": "萨尔瓦多",
"countryEn": "El Salvador",
"id": 110,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-89.10,
13.40
],
"code": "sv"
},
{
"country": "洪都拉斯",
"countryEn": "Honduras",
"id": 111,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-87.14,
14.05
],
"code": "hn"
},
{
"country": "尼加拉瓜",
"countryEn": "Nicaragua",
"id": 112,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-86.20,
12.06
],
"code": "ni"
},
{
"country": "哥斯达黎加",
"countryEn": "Costa Rica",
"id": 113,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-84.02,
9.55
],
"code": "cr"
},
{
"country": "百慕大",
"countryEn": "Bermuda",
"id": 114,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-64.75,
32.30
],
"code": "bm"
},
{
"country": "巴拿马",
"countryEn": "Panama",
"id": 115,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-79.25,
9.00
],
"code": "pa"
},
{
"country": "巴哈马",
"countryEn": "Bahamas",
"id": 116,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-77.20,
25.05
],
"code": "bs"
},
{
"country": "特克斯和凯科斯群岛",
"countryEn": "Turks and Caicos Islands ",
"id": 117,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-71.08,
21.28
],
"code": "tc"
},
{
"country": "古巴",
"countryEn": "Cuba",
"id": 118,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-82.22,
23.08
],
"code": "cu"
},
{
"country": "开曼群岛",
"countryEn": "Cayman Islands",
"id": 119,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-81.24,
19.20
],
"code": "ky"
},
{
"country": "牙买加",
"countryEn": "Jamaica",
"id": 120,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-76.50,
18.00
],
"code": "jm"
},
{
"country": "海地",
"countryEn": "Haiti",
"id": 121,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-72.20,
18.40
],
"code": "ht"
},
{
"country": "多米尼加",
"countryEn": "Dominican Republic",
"id": 122,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-61.24,
15.20
],
"code": "do"
},
{
"country": "波多黎各",
"countryEn": "Porto Rico",
"id": 123,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-66.07,
18.28
],
"code": "pr"
},
{
"country": "美属维尔京群岛",
"countryEn": "The United States Virgin Islands ",
"id": 124,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-64.56,
18.21
],
"code": "vi"
},
{
"country": "英属维尔京群岛",
"countryEn": "The British Virgin Islands ",
"id": 125,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-64.37,
18.27
],
"code": "vg"
},
{
"country": "安圭拉",
"countryEn": "Anguilla",
"id": 126,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-63.05,
18.22
],
"code": "ai"
},
{
"country": "蒙特塞拉特",
"countryEn": "Montserrat",
"id": 127,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-62.13,
16.47
],
"code": "ms"
},
{
"country": "安提瓜和巴布达",
"countryEn": "Antigua and Barbuda ",
"id": 128,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-61.48,
17.20
],
"code": "ag"
},
{
"country": "瓜德罗普",
"countryEn": "Guadeloupe",
"id": 129,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-61.44,
16.00
],
"code": "gp"
},
{
"country": "多米尼克",
"countryEn": "Dominica",
"id": 130,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-61.50,
15.50
],
"code": "dm"
},
{
"country": "马提尼克",
"countryEn": "Martinique",
"id": 131,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-61.02,
14.36
],
"code": "mq"
},
{
"country": "圣卢西亚",
"countryEn": "St. Lucia ",
"id": 132,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-60.58,
14.02
],
"code": "lc"
},
{
"country": "圣文森特和格林纳丁斯",
"countryEn": "St. Vincent and the Grenadines ",
"id": 133,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-61.10,
13.10
],
"code": "vc"
},
{
"country": "阿鲁巴",
"countryEn": "Aruba",
"id": 134,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-70.02,
12.32
],
"code": "aw"
},
{
"country": "巴巴多斯",
"countryEn": "Barbados",
"id": 135,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-59.30,
13.05
],
"code": "bb"
},
{
"country": "格林纳达",
"countryEn": "Grenada",
"id": 136,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-61.44,
12.04
],
"code": "gd"
},
{
"country": "特立尼达和多巴哥",
"countryEn": "Trinidad and Tobago ",
"id": 137,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-60.32,
11.18
],
"code": "tt"
},
{
"country": "安的列斯",
"countryEn": "Antilles",
"id": 138,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-69.00,
12.05
],
"code": "xx"
},
{
"country": "圣其茨和尼维斯",
"countryEn": "St. Kitts & Nevis",
"id": 139,
"region": "北美洲",
"regionEn": "North America",
"regionId": "3",
"coordinate": [
-62.43,
17.18
],
"code": "kn"
},
{
"country": "委内瑞拉",
"countryEn": "Venezuela",
"id": 140,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-66.55,
10.30
],
"code": "ve"
},
{
"country": "哥伦比亚",
"countryEn": "Columbia",
"id": 141,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-74.00,
4.34
],
"code": "co"
},
{
"country": "圭亚那",
"countryEn": "Guyana",
"id": 142,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-58.12,
6.50
],
"code": "gy"
},
{
"country": "苏里南",
"countryEn": "Suriname",
"id": 143,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-55.10,
5.50
],
"code": "sr"
},
{
"country": "法属圭亚那",
"countryEn": "French Guiana",
"id": 144,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-52.18,
5.05
],
"code": "gf"
},
{
"country": "厄瓜多尔",
"countryEn": "Ecuador",
"id": 145,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-78.35,
-0.15
],
"code": "ec"
},
{
"country": "秘鲁",
"countryEn": "Peru",
"id": 146,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-77.00,
-12.00
],
"code": "pe"
},
{
"country": "巴西",
"countryEn": "Brazil",
"id": 147,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-47.55,
-15.47
],
"code": "br"
},
{
"country": "玻利维亚",
"countryEn": "Bolivia",
"id": 148,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-68.10,
-16.20
],
"code": "bo"
},
{
"country": "巴拉圭",
"countryEn": "Paraguay",
"id": 149,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-57.30,
-25.10
],
"code": "py"
},
{
"country": "智利",
"countryEn": "Chile",
"id": 150,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-70.40,
-33.24
],
"code": "cl"
},
{
"country": "阿根廷",
"countryEn": "Argentina",
"id": 151,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-60.00,
-36.30
],
"code": "ar"
},
{
"country": "乌拉圭",
"countryEn": "Uruguay",
"id": 152,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-56.11,
-34.50
],
"code": "uy"
},
{
"country": "马尔维纳斯群岛",
"countryEn": "Falkland Islands",
"id": 153,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-59.51,
-51.40
],
"code": "fk"
},
{
"country": "福克兰群岛",
"countryEn": "Falkland Islands",
"id": 154,
"region": "南美洲",
"regionEn": "South America",
"regionId": "4",
"coordinate": [
-59.51,
-51.40
],
"code": "fk"
},
{
"country": "冰岛",
"countryEn": "Iceland",
"id": 155,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
-21.57,
64.10
],
"code": "is"
},
{
"country": "法罗群岛（丹）",
"countryEn": "Faeroe Islands",
"id": 156,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
-6.56,
62.05
],
"code": "fo"
},
{
"country": "挪威",
"countryEn": "Norway",
"id": 157,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
10.45,
59.55
],
"code": "no"
},
{
"country": "瑞典",
"countryEn": "Sweden",
"id": 158,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
18.03,
59.20
],
"code": "se"
},
{
"country": "芬兰",
"countryEn": "Finland",
"id": 159,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
25.03,
60.15
],
"code": "fi"
},
{
"country": "爱尔兰",
"countryEn": "Ireland",
"id": 160,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
-6.15,
53.21
],
"code": "ie"
},
{
"country": "英国",
"countryEn": "United Kingdom",
"id": 161,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
-0.05,
51.36
],
"code": "gb"
},
{
"country": "丹麦",
"countryEn": "Denmark",
"id": 162,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
12.34,
55.41
],
"code": "dk"
},
{
"country": "爱沙尼亚",
"countryEn": "Estonia",
"id": 163,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
24.48,
59.22
],
"code": "ee"
},
{
"country": "拉脱维亚",
"countryEn": "Latvia",
"id": 164,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
24.08,
56.53
],
"code": "lv"
},
{
"country": "立陶宛",
"countryEn": "Lithuania",
"id": 165,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
25.19,
54.38
],
"code": "lt"
},
{
"country": "白俄罗斯",
"countryEn": "White Russia",
"id": 166,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
27.30,
53.52
],
"code": "by"
},
{
"country": "俄罗斯",
"countryEn": "Russia",
"id": 167,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
37.35,
55.45
],
"code": "ru"
},
{
"country": "乌克兰",
"countryEn": "Ukraine",
"id": 168,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
30.28,
50.30
],
"code": "ua"
},
{
"country": "波兰",
"countryEn": "Poland",
"id": 169,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
21.00,
52.13
],
"code": "pl"
},
{
"country": "德国",
"countryEn": "Germany",
"id": 170,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
13.25,
52.30
],
"code": "de"
},
{
"country": "荷兰",
"countryEn": "Holland",
"id": 171,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
4.54,
52.23
],
"code": "nl"
},
{
"country": "比利时",
"countryEn": "Belgium",
"id": 172,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
4.21,
50.51
],
"code": "be"
},
{
"country": "卢森堡",
"countryEn": "Luxembourg",
"id": 173,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
6.09,
49.37
],
"code": "lu"
},
{
"country": "法国",
"countryEn": "France",
"id": 174,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
2.20,
48.50
],
"code": "fr"
},
{
"country": "摩纳哥",
"countryEn": "Monaco",
"id": 175,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
7.25,
43.44
],
"code": "mc"
},
{
"country": "捷克",
"countryEn": "Czech",
"id": 176,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
14.22,
50.05
],
"code": "cz"
},
{
"country": "斯洛伐克",
"countryEn": "Slovakia",
"id": 177,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
17.07,
48.10
],
"code": "sk"
},
{
"country": "奥地利",
"countryEn": "Austria",
"id": 178,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
16.22,
48.12
],
"code": "at"
},
{
"country": "列支敦士登",
"countryEn": "Liechtenstein",
"id": 179,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
9.31,
47.08
],
"code": "li"
},
{
"country": "圣马力诺",
"countryEn": "San Marino",
"id": 180,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
12.30,
43.55
],
"code": "sm"
},
{
"country": "意大利",
"countryEn": "Italy",
"id": 181,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
12.29,
41.54
],
"code": "it"
},
{
"country": "梵蒂冈",
"countryEn": "Vatican",
"id": 182,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
12.27,
41.54
],
"code": "va"
},
{
"country": "瑞士",
"countryEn": "Switzerland",
"id": 183,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
7.28,
46.57
],
"code": "ch"
},
{
"country": "匈牙利",
"countryEn": "Hungary",
"id": 184,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
19.05,
47.29
],
"code": "hu"
},
{
"country": "斯洛文尼亚",
"countryEn": "Slovenia",
"id": 185,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
14.33,
46.04
],
"code": "si"
},
{
"country": "摩尔多瓦",
"countryEn": "Moldova",
"id": 186,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
28.50,
47.02
],
"code": "md"
},
{
"country": "罗马尼亚",
"countryEn": "Romania",
"id": 187,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
26.10,
44.27
],
"code": "ro"
},
{
"country": "塞尔维亚和黑山（塞黑）",
"countryEn": "Serbia and Montenegro",
"id": 188,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
21.00,
44.01
],
"code": "rs"
},
{
"country": "克罗地亚",
"countryEn": "Croatia",
"id": 189,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
15.58,
45.50
],
"code": "hr"
},
{
"country": "波斯尼亚和黑塞哥维那（波黑）",
"countryEn": "Bosnia and Herzegovina",
"id": 190,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
18.26,
43.52
],
"code": "ba"
},
{
"country": "保加利亚",
"countryEn": "Bulgaria",
"id": 191,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
23.20,
42.45
],
"code": "bg"
},
{
"country": "马其顿",
"countryEn": "Macedonia",
"id": 192,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
21.26,
42.01
],
"code": "mk"
},
{
"country": "阿尔巴尼亚",
"countryEn": "Albania",
"id": 193,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
19.49,
41.18
],
"code": "al"
},
{
"country": "希腊",
"countryEn": "Greece",
"id": 194,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
23.46,
37.58
],
"code": "gr"
},
{
"country": "安道尔",
"countryEn": "Andorra",
"id": 195,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
1.32,
42.31
],
"code": "ad"
},
{
"country": "西班牙",
"countryEn": "Spain",
"id": 196,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
-3.45,
40.25
],
"code": "es"
},
{
"country": "葡萄牙",
"countryEn": "Portugal",
"id": 197,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
-9.10,
38.42
],
"code": "pt"
},
{
"country": "马耳他",
"countryEn": "Malta",
"id": 198,
"region": "欧洲",
"regionEn": "Europe",
"regionId": "2",
"coordinate": [
14.31,
35.54
],
"code": "mt"
},
{
"country": "澳大利亚",
"countryEn": "Australia",
"id": 199,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
149.08,
-35.15
],
"code": "au"
},
{
"country": "新西兰",
"countryEn": "New Zealand",
"id": 200,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
174.46,
-41.19
],
"code": "nz"
},
{
"country": "巴布亚新几内亚",
"countryEn": "Papua new guinea",
"id": 201,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
147.08,
-9.24
],
"code": "pg"
},
{
"country": "所罗门群岛",
"countryEn": "Solomon",
"id": 202,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
159.57,
-9.27
],
"code": "sb"
},
{
"country": "瓦努阿图",
"countryEn": "Vanuatu",
"id": 203,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
168.18,
-17.45
],
"code": "vu"
},
{
"country": "新喀里多尼亚",
"countryEn": "New Caledonia",
"id": 204,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
166.30,
-22.17
],
"code": "nc"
},
{
"country": "斐济群岛",
"countryEn": "Fiji",
"id": 205,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
178.30,
-18.06
],
"code": "fj"
},
{
"country": "基里巴斯",
"countryEn": "Kiribati",
"id": 206,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
173.00,
1.30
],
"code": "ki"
},
{
"country": "瑙鲁",
"countryEn": "Nauru",
"id": 207,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
166.55,
-0.33
],
"code": "nr"
},
{
"country": "密克罗尼西亚",
"countryEn": "Micronesia",
"id": 208,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
158.09,
6.55
],
"code": "fm"
},
{
"country": "马绍尔群岛",
"countryEn": "Marshall Islands",
"id": 209,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
171.12,
7.09
],
"code": "mh"
},
{
"country": "帕劳",
"countryEn": "Palau",
"id": 210,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
134.28,
7.20
],
"code": "pw"
},
{
"country": "北马里亚纳群岛",
"countryEn": "Northern Mariana Islands",
"id": 211,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
145.45,
15.12
],
"code": "mp"
},
{
"country": "关岛",
"countryEn": "Guam",
"id": 212,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
144.45,
13.29
],
"code": "gu"
},
{
"country": "图瓦卢",
"countryEn": "Tuvalu",
"id": 213,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
179.13,
-8.31
],
"code": "tv"
},
{
"country": "瓦利斯及富图纳",
"countryEn": "Wallis Islands and Futuna Islands",
"id": 214,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-176.10,
-13.20
],
"code": "wf"
},
{
"country": "西萨摩亚",
"countryEn": "Western Samoa",
"id": 215,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-171.50,
-13.50
],
"code": "ws"
},
{
"country": "美属萨摩亚",
"countryEn": "American Samoa",
"id": 216,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-170.43,
-14.16
],
"code": "as"
},
{
"country": "纽埃",
"countryEn": "Niue",
"id": 217,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-169.55,
-19.03
],
"code": "nu"
},
{
"country": "托克劳",
"countryEn": "Tokelau",
"id": 218,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-172.50,
-9.50
],
"code": "tk"
},
{
"country": "库克群岛",
"countryEn": "Cook Islands",
"id": 219,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-159.45,
-21.12
],
"code": "ck"
},
{
"country": "汤加",
"countryEn": "Tonga",
"id": 220,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-174.00,
-21.10
],
"code": "to"
},
{
"country": "法属波利尼西亚",
"countryEn": "French Polynesia",
"id": 221,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-149.34,
-17.32
],
"code": "pf"
},
{
"country": "皮特凯恩群岛",
"countryEn": "Pitcairn Islands",
"id": 222,
"region": "大洋洲",
"regionEn": "Oceania",
"regionId": "6",
"coordinate": [
-130.06,
-25.04
],
"code": "pn"
},
{
"country": "中国香港",
"countryEn": "Hongkong",
"id": 223,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
114.15,
22.15
],
"code": "hk"
},
{
"country": "中国澳门",
"countryEn": "Macao",
"id": 224,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
113.50,
22.20
],
"code": "mo"
},
{
"country": "中国台湾",
"countryEn": "Taiwan",
"id": 225,
"region": "亚洲",
"regionEn": "Asia",
"regionId": "1",
"coordinate": [
121.31,
25.03
],
"code": "tw"
}
]

export default countryCode