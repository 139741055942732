<template>
  <div class="businessceramicslist">
    <div class="businessceramics_router flex-row justify-between">
      <div class="titlepc">{{ titleName }}</div>
      <!-- <button
        class="businessceramics_management"
        @click="businessManagementClick"
      >
        Product Management
      </button> -->
      <!-- <button
        class="businessceramics_launch iconfont icon-icon_fabu"
        @click="businessLaunchClick"
      >
        Launch Products
      </button> -->
    </div>
    <div class="titlephone">{{ titleName }}</div>
    <div class="businessceramicslist_main">
      <div class="businessceramicslist_nav flex-row" v-if="parentShopList.length > 0">
        <div @click="navfun(index)" v-for="(item, index) in nav" :key="index" :style="{
            background: index == navcurrent ? '#13af87' : '',
            color: index == navcurrent ? '#fff' : '',
          }">
          {{ item }}
        </div>
      </div>
      <div class="goodslist flex-row justify-between">
        <div class="good flex-col justify-between" v-for="(item, index) in parentShopList" :key="index"
          @click="detailfun(item.id)">
          <div class="img flex-col">
            <img :src="baseUrl + item.image" alt="" />
          </div>
          <span class="text">{{ item.commodityName }}</span>
          <div class="price flex-row">
            <div class="priceDiv1">
              <div v-if="item.sellNumber != null && item.sellNumber > 0" class="priceLeft">
                Sell {{ item.sellNumber }}
              </div>
              <div v-if="item.minNum != null && item.minNum > 0" class="priceLeft">
                Min.order:{{ item.minNum }}
              </div>
            </div>
            <span class="price1">${{ item.presentPrice }}</span>
            <!-- <span class="price2" v-if="item.originalPrice&&item.originalPrice>0">${{ item.originalPrice }}</span> -->
          </div>
        </div>
      </div>
      <div class="page_nation" style="width: 100%; text-align: center;" v-if="parentShopList.length > 0">
        <el-pagination background :total="total.pageTotal" @current-change="currentChange" :pagerCount="3"
          :current-page="total.pageNum" :page-size="total.pageSize" layout="prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import PageNation from "@/components/pagenation/PageNation.vue";
  import { shopCommodityList } from "@/api/business";
  import { getconsumerCommodityDetail } from "../../../api/index";
  export default {
    name: "BusinessCeramicsList",
    data() {
      return {
        nav: ["Default", "By Price", "By Name"],
        navcurrent: 0,
        shopList: {},
        baseUrl: this.$baseUrl,
      };
    },
    props: {
      titleName: {
        type: String,
        default: "",
      },
      parentShopList: {
        type: Array,
        default: [],
      },
      categoryId: {
        type: Number,
        default: 0,
      },
      total: {
        type: Object,
        default: {
          pageTotal: 0,
          pageNum: 1,
          pageSize: 6,
        },
      },
      getSortType: {
        getSortType(e) {
          return e;
        },
      },
      numChange: {
        numChange(e) {
          return e;
        },
      },
    },
    components: {
      PageNation,
    },
    computed: {},
    watch: {
      // parentShopList: {
      //   handler(newName, oldName) {
      //     this.shopList = this.parentShopList;
      //   },
      //   immediate: true,
      // },
    },
    mounted() {
      // this.shopCommodityListApi();
    },
    methods: {
      detailfun(e) {
        // getconsumerCommodityDetail(e).then((res) => {
        //   if (res.code == 200) {
        //     const herf = this.$router.resolve({
        //       path: "/ceramics/fineceramics",
        //       query: {
        //         businessHomePath: "/businesshome",
        //         phoneHomeImg: false,
        //         box8: false,
        //         list: JSON.stringify(res.data),
        //         noBusiness: false,
        //       },
        //     });
        //     window.open(herf.href, "__blank");
        //   }
        // });
        const herf = this.$router.resolve({
          // name: "FineCeramics",
          path: "/ceramics/fineceramics",
          query: {
            noBusiness: false,
            businessHomePath: "/businesshome",
            phoneHomeImg: false,
            box8: false,
            id: e,
          },
        });
        window.open(herf.href, "__blank");
      },
      currentChange(e) {
        // this.total.pageNum = e;
        this.numChange(e);
        // this.shopCommodityListApi();
      },
      // shopCommodityListApi() {
      //   this.total.categoryId = this.categoryId;
      //   shopCommodityList(this.total).then((res) => {
      //     if (res.code == 200) {
      //       this.shopList = res.data.vos;
      //       this.total.pageTotal = res.data.total;
      //     } else if (res.code == 401) {
      //       this.$message({ message: res.msg, type: "error" });
      //       //未登录
      //       this.$router.push({
      //         path: "/login",
      //         query: {
      //           box8: false,
      //           phoneHomeImg: false,
      //           bool: false,
      //           // token: true,
      //         },
      //       });
      //     }
      //   });
      // },
      navfun(e) {
        this.navcurrent = e;
        // if (e == 0) {
        //   this.total.sortType = 0;
        // } else if (e == 1) {
        //   this.total.sortType = 1;
        // } else if (e == 2) {
        //   this.total.sortType = 2;
        // }
        // this.shopCommodityListApi();
        this.getSortType(e);
      },
      clickActive(e) {
        let childs = document.querySelector(
          ".businessceramicslist_nav"
        ).childNodes;
        for (let i = 0; i < childs.length; i++) {
          childs[i].classList.remove("active");
        }
        e.target.classList.add("active");
      },
      // businessLaunchClick() {
      //   this.$router.push({
      //     path: "/businesslaunch",
      //     query: {
      //       bool: false,
      //       phoneHomeImg: false,
      //       box8: false,
      //       noBusiness: false,
      //     },
      //   });
      // },
      // businessManagementClick() {
      //   this.$router.push({
      //     path: "/businessmanagement",
      //     query: {
      //       bool: false,
      //       phoneHomeImg: false,
      //       box8: false,
      //       noBusiness: false,
      //     },
      //   });
      // },
    },
  };
</script>

<style scoped>
  .businessceramicslist {
    /*width: calc(890 * 100vw / 1920);*/
    width: 70%;
    min-height: calc(1090 * 100vw / 1920);
    background: #ffffff;
    float: left;
    margin-bottom: calc(20 * 100vw / 1920);
  }

  .businessceramicslist .titlepc {
    margin-left: calc(35 * 100vw / 1920);
    /* width: calc(140 * 100vw / 1920); */
    /* height: calc(75 * 100vw / 1920); */
    /* white-space: nowrap; */
    /* line-height: calc(75 * 100vw / 1920); */
    padding: calc(20 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .businessceramics_router {
    align-items: center;
  }

  .businessceramics_management {
    font-size: calc(18 * 100vw / 1920);
    margin-left: calc(280 * 100vw / 1920);
    margin-top: calc(10 * 100vw / 1920);
    color: #13af87;
    text-decoration: underline;
  }

  .businessceramics_launch {
    font-size: calc(18 * 100vw / 1920);
    margin-right: calc(35 * 100vw / 1920);
    background-color: #13af87;
    color: #fff;
    width: calc(190 * 100vw / 1920);
    height: calc(38 * 100vw / 1920);
  }

  .titlephone {
    display: none;
  }

  .businessceramicslist_main {
    width: 100%;
    border-top: 1px solid #ddd;
    padding-top: calc(38 * 100vw / 1920);
  }

  .businessceramicslist_nav {
    margin-left: calc(35 * 100vw / 1920);
  }

  .businessceramicslist_nav div {
    width: calc(120 * 100vw / 1920);
    height: calc(40 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 20px;
    margin-right: calc(20 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
    line-height: calc(35 * 100vw / 1920);
    cursor: pointer;
  }

  .active {
    background: #13af87 !important;
    color: #ffffff !important;
  }

  .goodslist {
    margin: calc(53 * 100vw / 1920) calc(35 * 100vw / 1920);
    /* height: calc(700 * 100vw / 1920); */
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
  }

  .goodslist .good {
    width: 19.5%;
    min-height: calc(360 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    /* box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: calc(20 * 100vw / 1920);
    margin-right: calc(3 * 100vw / 1920);
  }

  /* .goodslist .good:nth-last-of-type(-n+5) {
    margin-right: 0;
  } */

  .goodslist .good .img {
    width: 100%;
    height: calc(260 * 100vw / 1920);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .goodslist .good .img img {
    max-width: 99%;
    max-height: 100%;
  }

  .goodslist .good .text {
    width: 100%;
    /* margin-left: calc(15 * 100vw / 1920); */
    padding: 0 calc(15 * 100vw / 1920);

    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    display: block;

    word-wrap: break-word;
  }

  .goodslist .good .price {
    width: 91%;
    flex-wrap: wrap;
    margin: calc(10 * 100vw / 1920) 0;
    margin-left: calc(15 * 100vw / 1920);
    display: flex;
    align-items: center;

    .priceDiv1 {
      display: inline-block;
      width: 50%;

      .priceLeft {
        display: inline-block;
        width: 100%;
        font-size: 12px;
      }
    }

    .price1 {
      font-size: calc(18 * 100vw / 1920);
      font-family: Roboto;
      font-weight: 500;
      /* color: #ff0000; */
      display: inline-block;
      width: 50%;
      text-align: right;
      padding-right: 10px;
      align-items: center;
    }
  }

  /* .goodslist .good .price .price1 {
    max-width: 90%;
    text-overflow: ellipsis;
    显示省略符号来代表被修剪的文本
    overflow: hidden;
    white-space: nowrap;
    不换行 控制单行文本
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #ff0000;
    margin-right: calc(22 * 100vw / 1920);
  }

  .goodslist .good .price .price2 {
    max-width: 90%;
    text-overflow: ellipsis;
    显示省略符号来代表被修剪的文本
    overflow: hidden;
    white-space: nowrap;
    不换行 控制单行文本
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 300;
    text-decoration: line-through;
    color: #aaaaaa;
  } */

  @media screen and (max-width: 1080px) {
    .businessceramicslist {
      /* width: calc(740 * 100vw / 1080); */
      width: 100%;
      height: calc(1210 * 100vw / 1080);
      background: #ffffff;
      float: right;
      margin-bottom: 0;
      /* border-top: 1px solid #ddd; */
    }

    .businessceramicslist .titlepc {
      display: none;
    }

    .businessceramicslist .titlephone {
      display: flex;
      margin-left: 0;
      width: auto;
      /* height: calc(145 * 100vw / 1080); */
      /* line-height: calc(145 * 100vw / 1080); */
      padding: calc(10 * 100vw / 1080) calc(40 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .businessceramics_router {
      width: 100%;
      height: calc(95 * 100vw / 1080);
      background-color: #f1f1f1;
      align-items: start;
      display: none;
    }

    .businessceramics_management {
      height: calc(60 * 100vw / 1080);
      font-size: calc(30 * 100vw / 1080);
      margin-left: calc(40 * 100vw / 1080);
      color: #13af87;
      text-decoration: underline;
      line-height: calc(60 * 100vw / 1080);
      margin-top: 0;
    }

    .businessceramics_launch {
      font-size: calc(30 * 100vw / 1080);
      margin-right: calc(40 * 100vw / 1080);
      background-color: #13af87;
      color: #fff;
      width: calc(320 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      line-height: calc(60 * 100vw / 1080);
    }

    .businessceramicslist_main {
      width: 100%;
      padding-top: 0;
    }

    .businessceramicslist_nav {
      display: none;
    }

    .goodslist {
      margin: 0;
      height: calc(975 * 100vw / 1080);
      padding: calc(30 * 100vw / 1080) calc(40 * 100vw / 1080) 0 calc(40 * 100vw / 1080);
      background-color: #fff;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow: hidden;
      overflow-y: scroll;
      margin-bottom: 10px;
    }

    .goodslist .good {
      /* width: calc(690 * 100vw / 1080); */
      width: calc(990 * 100vw / 1080);
      height: calc(240 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
      margin-bottom: calc(30 * 100vw / 1080);
      flex-wrap: wrap;
    }

    .goodslist .good .img {
      width: calc(240 * 100vw / 1080);
      height: calc(240 * 100vw / 1080);
    }

    .goodslist .good .text {
      width: calc(700 * 100vw / 1080);
      /* width: 70%; */
      max-height: calc(100 * 100vw / 1080);
      margin-left: calc(25 * 100vw / 1080);
      margin-right: calc(25 * 100vw / 1080);
      margin-top: calc(15 * 100vw / 1080);
      font-size: calc(38 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #333333;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .goodslist .good .price {
      display: inline-block;
      width: calc(700 * 100vw / 1080);
      margin-left: calc(25 * 100vw / 1080);
      margin-top: 0;
      margin-bottom: calc(10 * 100vw / 1080);
      font-size: calc(38 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;

      align-items: center;
      display: flex;
      justify-content: space-between;

      .priceDiv1 {
        width: calc(500 * 100vw / 1080);
        text-align: left;

        .priceLeft {
          display: inline-block;
          width: calc(500 * 100vw / 1080);
        }
      }

      .price1 {
        display: inline-block;
        width: calc(200 * 100vw / 1080);
        font-size: calc(36 * 100vw / 1080);
        font-family: Roboto;
        font-weight: 500;
      }
    }

    /* .goodslist .good .price .price1 {
      max-width: calc(400 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #ff0000;
      margin-right: 0;
    } */

    .goodslist .good .price .price2 {
      max-width: calc(400 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 300;
      text-decoration: line-through;
      color: #aaaaaa;
      padding-right: calc(40 * 100vw / 1080);
    }

    /* .page_nation {
    display: none;
  } */
  }
</style>