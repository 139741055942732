
import axios from 'axios'
import { getCookie } from '../utils/auth'
export const baseURL = 'https://etouch1api.etouch-b2b.com'
// export const baseURL = 'http://localhost:8080'
// export const baseURL = 'http://etouchapi2.xiaochengxukf.cn'
// create an axios instance
const service = axios.create({
  baseURL,
  //   baseURL: 'https://etouch1api.etouch-b2b.com', // url = base url + request url
  // baseURL: 'http://etouchapi2.xiaochengxukf.cn',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => config
)

// 添加请求拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做某事，比如说 设置token
  // config.headers['Authorization'] = 'token';
  //设置通用请求头格式
  config.headers['Authorization'] = getCookie('token');
  config.headers['Accept'] = '*/*';
  return config;
}, error => {
  // 请求错误时做些事
  return Promise.reject(error);
});
// response interceptor
service.interceptors.response.use(
  response => {
    return response.data;
  }
)

export default service

