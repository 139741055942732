import Cookie from 'js-cookie'

// 设置cookie
export const setCookie = (key='X-Token',val)=>{
    Cookie.set(key,val,{
        expires: 30
    })
}

// 获取cookie
export const getCookie = (key="X-Token")=>{
    return  Cookie.get(key);
}

// 删除cookie
export const removeCookie = (key="X-Token")=>{
    Cookie.remove(key)
}


// 设置cookie用户类型
export const setUserType = (key='X-Token',val)=>{
    Cookie.set(key,val,{
        expires: 30
    })
}

// 获取cookie
export const getUserType = (key="X-Token")=>{
    return  Cookie.get(key);
}

// 删除cookie
export const removeUserType = (key="X-Token")=>{
    Cookie.remove(key)
}