<template>
  <div class="market_page">
    <div class="market_title">应用市场</div>
    <div class="market_main">
      <!-- <div class="market_list">
        <div class="market_child" 
          :style="{'background-color':isAction==index?'#2E336A':'#212445'}" 
          v-for="(item,index) in navList" :key="index" 
          @click="urlClick(item)"
        >
          <span>{{item.title}}</span>
          <span style="color: #66606F;">></span>
        </div>
      </div> -->
      <div class="market_list">
        <div class="market_child" v-for="(item,index) in navList" :key="index">
          <p class="market_child_title">{{item.label}}</p>
          <div class="market_child_child">
            <span v-for="(item1,index1) in item.child" :key="index1" 
              @click="urlClick(item1)" 
              :style="item1.url?'cursor: pointer;':''"
            >{{item1.title}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNavigationList} from "@/api/news"
import { getDictLabel } from "@/api/other";
export default {
  name: "ApplicationMarket",
  data() {
    return {
      navList:[],
      isAction:null,
      navigationTypeList:[]
    };
  },
  components: {
    
  },
  computed: {},
  watch: {
    $route: {
      handler: function (val) {
        //这里的路由是页面上的路由
        if (val.path == "/businesspersonal/applicationmarket") {
          this.selectNavigationList()
        } else {
          this.router_show = false;
        }
      },
      // 深度观察监听，普通的watch方法无法监听到对象内部属性的改变，只有data中的数据才能够监听到变化，此时就需要deep属性对对象进行深度监听。
      deep: true,
    },
  },

  methods: {
    // selectNavigationList() {
    //   getNavigationList().then((res) => {
    //     if (res.code == 200) {
    //       this.navList = res.data
    //     } else if (res.code == 401) {
    //       this.$message({ message: res.msg, type: "error" });
    //       //未登录
    //       this.$router.push({
    //         path: "/login",
    //         query: {
    //           box8: false,
    //           phoneHomeImg: false,
    //           bool: false,
    //           // token: true,
    //         },
    //       });
    //     }
    //   });
    // }, 
    // 页面跳转
    urlClick(item){
      if(item.url){
        window.open(item.url, "__blank")
      }      
    },
    // getDictLabel() {
    //   getDictLabel("bj_navigation_type").then((res) => {
    //     if (res.code == 200) {
    //       this.navigationTypeList = res.data.map((item) => {
    //         return {
    //           label: item.dictLabel,
    //           value: item.dictValue,
    //         };
    //       });          
    //     }
    //   });
    // },
    async selectNavigationList() {
      let that=this
      let res = await getDictLabel("bj_navigation_type")
      let res1 = await getNavigationList()
      if(res.code==200 && res1.code==200){
        this.navList=[]
        res.data.forEach((item)=>{
          let arr=res1.data.filter(it=>it.typeId==item.dictValue)
          if(arr&&arr.length>0){
            let obj={
              label:item.dictLabel,
              child:arr
            }
            this.navList.push(obj)
          }else{
            let obj={
              label:item.dictLabel,
              child:[]
            }
            this.navList.push(obj)
          }
        })
        this.$nextTick(()=>{
          console.log(that.navList)
        })
      } else if (res.code == 401||res1.code == 401) {
        this.$message({ message: res.msg, type: "error" });
        //未登录
        this.$router.push({
          path: "/login",
          query: {
            box8: false,
            phoneHomeImg: false,
            bool: false,
            // token: true,
          },
        });
      }
    }, 
  },
  mounted() {
    this.selectNavigationList()
    // this.getDictLabel()
  },
  activated() {},
};
</script>

<style scoped>
.market_page {
  /*width: calc(890 * 100vw / 1920);*/

  width: 100%;
  /* height: calc(740 * 100vw / 1920); */
  min-height: 100%;
  background: #ffffff;
}
.market_title {
  margin-left: calc(38 * 100vw / 1920);
  width: calc(140 * 100vw / 1920);
  height: calc(75 * 100vw / 1920);
  white-space: nowrap;
  line-height: calc(75 * 100vw / 1920);
  border-bottom: 5px solid rgba(19, 175, 135, 1);
  font-weight: bolder;
  font-size: 1.28vw;
}
.market_main {
  border-top: 1px solid #ddd;
  padding-top: calc(40 * 100vw / 1920);
  padding-bottom: calc(20 * 100vw / 1920);
  height: 100%;
}
/* .market_list{
  width: calc(360 * 100vw / 1920);
}
.market_list .market_child{
  background-color: #212445;
  color: #ffffff;
  padding: calc(20 * 100vw / 1920);
  font-size: calc(20 * 100vw / 1920);
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.market_list{
  padding: 0 calc(40 * 100vw / 1920);
}
.market_list .market_child{
  margin-bottom: calc(10 * 100vw / 1920);
}
.market_child .market_child_title{
  font-size: calc(24 * 100vw / 1920);
  color: #C9C9C9;
  margin-bottom: calc(20 * 100vw / 1920);
}
.market_child .market_child_child{
  font-size: calc(26 * 100vw / 1920);
  color: #858585;
}
.market_child .market_child_child>span{
  width: calc(300 * 100vw / 1920);
  margin-right: calc(20 * 100vw / 1920);
  display: inline-block;
  margin-bottom: calc(30 * 100vw / 1920);
}

@media screen and (max-width: 1080px) {
  /* .market_list{
    width: calc(500 * 100vw / 1080);
  }
  .market_list .market_child{
    background-color: #212445;
    color: #ffffff;
    padding: calc(30 * 100vw / 1080);
    font-size: calc(30 * 100vw / 1080);
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */
  .market_title{
    margin-left: calc(40 * 100vw / 1080);
    width: calc(280 * 100vw / 1080);
    height: calc(150 * 100vw / 1080);
    white-space: nowrap;
    line-height: calc(170 * 100vw / 1080);
    border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: calc(48 * 100vw / 1080);
  }
  .market_list{
    padding: 0 calc(30 * 100vw / 1080);
    min-height: calc(1240 * 100vw / 1080);
  }
  .market_list .market_child{
    margin-bottom: calc(20 * 100vw / 1080);
  }
  .market_child .market_child_title{
    font-size: calc(34 * 100vw / 1080);
    color: #C9C9C9;
    margin-bottom: calc(20 * 100vw / 1080);
  }
  .market_child .market_child_child{
    font-size: calc(36 * 100vw / 1080);
    color: #858585;
  }
  .market_child .market_child_child>span{
    width: calc(300 * 100vw / 1080);
    margin-right: calc(20 * 100vw / 1080);
    display: inline-block;
    margin-bottom: calc(30 * 100vw / 1080);
  }
}
</style>
