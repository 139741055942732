import request from '../utils/request'

// 联系方式
export function getcontactInformation(data) {
  return request({
    url: '/other/contactInformation',
    method: 'post',
    data
  })
}

// 联系我们
export function getcontactUs(data) {
  return request({
    url: '/other/contactUs',
    method: 'post',
    data
  })
}

//广告列表
export function getbannerList(data) {
  return request({
    url: `/other/bannerList/` + data,
    method: 'post',
  })
}

// 支持与隐私
export function getsupportPrivacy(data) {
  return request({
    url: '/other/supportPrivacy',
    method: 'post',
    data
  })
}

//关键字搜索
export function keywordSearchApi(data) {
  return request({
    url: '/other/keywordSearch',
    method: 'post',
    data
  })
}

// 发询盘
export function addEnquiries(data) {
  return request({
    url: '/other/addEnquiries',
    method: 'post',
    data
  })
}

// 通用上传
export function upload(data) {
  return request({
    url: '/other/upload',
    method: 'post',
    data,
  })
}

// 商家帮助
export function merchantHelp(data) {
  return request({
    url: '/other/merchantHelp',
    method: 'post',
    data,
  })
}

// 七天无理由退货协议
export function sevenAgreement(data) {
  return request({
    url: '/other/sevenAgreement',
    method: 'post',
    data,
  })
}

// 联系我们
export function contactWe(data) {
  return request({
    url: '/other/contactWe',
    method: 'post',
    data,
  })
}

// 个人帮助中心
export function personalHelp(data) {
  return request({
    url: '/other/personalHelp',
    method: 'post',
    data,
  })
}

// 商家注册账号协议
export function personalAccountHelp(data) {
  return request({
    url: '/other/personalAccountHelp',
    method: 'post',
    data,
  })
}

// 普通用户注册账号协议
export function userAccountHelp(data) {
  return request({
    url: '/other/userAccountHelp',
    method: 'post',
    data,
  })
}

// 获取公司简介信息
export function companyIntroduction(data) {
  return request({
    url: '/other/companyIntroduction',
    method: 'post',
    data,
  })
}
// 通用字典
export function getDictLabel(data) {
  return request({
    url: `/other/getDictLabel/` + data,
    method: 'post',

  })
}

// 用户更新密码
export function userUpdatePwd(data) {
  return request({
    url: '/other/userUpdatePwd',
    method: 'post',
    data,
  })
}

// 修改密码发送邮箱验证码
export function pwdGetCode(data) {
  return request({
    url: `/other/pwdGetCode/` + data,
    method: 'post',
  })
}

// 用户忘记密码
export function userForgetPwd(data) {
  return request({
    url: '/other/userForgetPwd',
    method: 'post',
    data,
  })
}

// 消费者注册发送邮箱验证码
export function registerGetCode(data) {
  return request({
    url: `/other/registerGetCode/` + data,
    method: 'post',
  })
}

// 获取订单物流
export function searchTrackInfoApi(data) {
  return request({
    url: `/other/searchTrackInfo`,
    method: 'post',
    data
  })
}

//获取首页底部社交导航列表
export function getIndexBottomNavigation(data) {
  return request({
    url: '/index/getIndexBottomNavigation',
    method: 'post',
    data
  })
}
