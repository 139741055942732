import request from '../utils/request'

// 文章列表
export function getArticleList(data) {
  return request({
    url: `/other/articleList/${data.typeId}`,
    method: 'post',
    data
  })
}
// 文章详情
export function getArticleDetail(data) {
  return request({
    url: `/other/articleDetail/${data}`,
    method: 'post'
  })
}
// 获取新闻列表
export function getNewsList(data) {
    return request({
      url: `/other/getNewsList`,
      method: 'post',
      data
    })
}
// 获取新闻详情
export function getNewsDetail(data) {
  return request({
    url: `/other/getNewsDetail/${data}`,
    method: 'post'
  })
}
// 获取商家评价列表
export function getBusinessAppraiseList(data) {
    return request({
      url: `/other/businessAppraiseList/${data.businessId}`,
      method: 'post',
      data
    })
}
// 关键字搜索-搜索制造商
export function getKeywordSearchManufacturers(data) {
    return request({
      url: '/other/keywordSearchManufacturers',
      method: 'post',
      data
    })
}
// 发送采购信息
export function sendProcurement(data) {
    return request({
      url: '/other/sendProcurement',
      method: 'post',
      data
    })
}
// 获取导航列表
export function getNavigationList() {
  return request({
    url: '/other/getNavigationList',
    method: 'post'
  })
}
// 评价订单
export function addAppraiseOrder(data) {
  return request({
    url: '/consumer/appraiseOrder',
    method: 'post',
    data
  })
}
// 查看订单详情
export function getOrderSource(data) {
  return request({
    url: `/shop/source/order/${data}`,
    method: 'get'
  })
}
// 修改订单
export function updateOrderSource(data) {
  return request({
    url: '/shop/source/order/updateStatus',
    method: 'put',
    data
  })
}
// 商家标记所有反馈消息为已读
export function shopFeedbackRead(data) {
  return request({
    url: '/shop/shopFeedbackDel',
    method: 'post',
    data
  })
}
// 商家删除反馈消息
export function shopFeedbackDel(data) {
  return request({
    url: `/shop/shopFeedbackDel/${data}`,
    method: 'post'
  })
}
// 商家反馈消息详情
export function shopFeedbackDetail(data) {
  return request({
    url: `/shop/shopFeedbackDetail/${data}`,
    method: 'post'
  })
}
// 商家反馈消息管理列表
export function shopFeedbackList(data) {
  return request({
    url: '/shop/shopFeedbackList',
    method: 'post',
    data
  })
}
// 发送反馈消息
export function sendFeedback(data) {
  return request({
    url: '/other/sendFeedback',
    method: 'post',
    data
  })
}
// 获取首页推荐分类
export function recommendedClassification(data) {
  return request({
    url: '/index/recommendedClassification',
    method: 'post',
    data
  })
}
// 搜索商家商品
export function getSeywordSearch(data) {
  return request({
    url: '/index/keywordSearch',
    method: 'post',
    data
  })
}
// 商家修改订单产品的单价和数量
export function updateDetailOrder(data) {
  return request({
    url: '/shop/updateDetailOrder',
    method: 'put',
    data
  })
}
// 更新配送地址
export function updateDeliveryAddress(data) {
  return request({
    url: '/shop/updateDeliveryAddress',
    method: 'put',
    data
  })
}
// 更新配送信息
export function updateDeliveryInfo(data) {
  return request({
    url: '/shop/updateDeliveryInfo',
    method: 'put',
    data
  })
}
// 消费者-申请推广商家
export function consumerPromotionBusinessApi(data) {
  return request({
    url: '/promotion/consumer/consumerPromotionBusiness',
    method: 'post',
    data
  })
}
