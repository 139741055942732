<template>
  <div style="height: 100%">
    <swiper-box class="swiper1">
      <!-- <swiper-item>
        <img src="../../../assets/img/图层23.png" alt="" />
      </swiper-item>
      <swiper-item>
        <img src="../../../assets/img/图层23.png" alt="" />
      </swiper-item>
      <swiper-item>
        <img src="../../../assets/img/图层23.png" alt="" />
      </swiper-item> -->
      <swiper-item v-if="bannerimages != null && bannerimages.length > 0" v-for="(item, index) in bannerimages"
        :key="index">
        <img :src="$baseUrl + item" alt="" />
      </swiper-item>
    </swiper-box>
  </div>
</template>

<script>
  import { Swiper } from "swiper/bundle";
  import "swiper/css/bundle";
  import { getbannerList } from "@/api/other";
  import { shopMyInfo, businessIntroduction } from "../../../api/business";
  import { SwiperBox, SwiperItem } from "@/components/swiper";
  export default {
    name: "Swiperbotton",
    data() {
      return {
        businessTopLog: [], // 商家广告
        bannerimages: [],
        id: null,
      };
    },
    components: {
      SwiperBox,
      SwiperItem,
    },
    props: ["getShow"],
    computed: {},
    watch: {},
    methods: {
      businessIntroduction() {
        businessIntroduction(sessionStorage.getItem("businessId")).then((res) => {
          if (res.code == 200) {
            if (res.data.images) {
              this.bannerimages = res.data.images.split(",");
            }
            if (this.bannerimages.length > 0) {
              this.$emit("show", true);
            } else {
              this.$emit("show", false);
            }
          }
        });
      },
    },
    mounted() {
      this.id = sessionStorage.getItem("businessId");
      this.businessIntroduction();

      // 初始化
      const swiper1 = new Swiper(".swiper1", {
        // 轮播图方向，"vertical" 垂直，"horizontal" 水平
        direction: "horizontal",
        // 是否循环
        loop: true,

        // 环绕,在最后一张点击next，回到第一张，在第一张点击prev回到最后一张，不能与loop同用
        rewind: true,
        // 自动播放
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        // 切换持续时间
        speed: 1000,
        // 是否需要轮播图小圆点
        pagination: {
          el: ".swiper-pagination",
        },

        // 切换上下的箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // 滚条
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        // },

        // 可视窗口内，slide的数量
        slidesPerView: 1,

        // 一次性跳几个slide，当slidesPerView>1时配用很合适
        // slidesPerGroup: 1,

        // 设置间距
        // spaceBetween: 10,
        // 断点，自适应
      });
    },
  };
</script>

<style scoped>
  .swiper1 img {
    width: 100%;
    height: 100%;
  }
</style>