<template>
  <div>
    <div class="addresslist" v-for="(item,index) in list" :key="index">
      <div class="addresslist_text flex-row">
        <div class="addresslist_text_1">
          <span>Full name (First and Last name): {{item.contacts}}</span>
          <span>Phone Number: {{item.phone}}</span>
          <span>Email: {{item.email}}</span>
          <span>Country: {{item.country}}</span>
          <span>City: {{item.city}}</span>
          <span>Zip Code: {{item.postCode}}</span>
          <!-- <span>Postal Code:{{item.postCode}}</span> -->
        </div>
        <div>
          <button class="delete" @click="clickdelete(item.id)">
            <img src="../../../../assets/img/删除.png" alt="" />
          </button>
          <button class="revise" @click="update(item)">
            <img src="../../../../assets/img/编辑1.png" alt="" />
          </button>
        </div>
      </div>
      <!-- <div class="addresslist_fun flex-row">
        <div class="addresslist_text_1">
          <span>Country: {{item.country}}</span>
          <span>City: {{item.city}}</span>
          <span>Postal Code: {{item.postCode}}</span>
        </div>
      </div> -->
      <div class="addresslist_fun flex-row">
        <div class="addresslist_text_2">
          Address: {{item.address}}
        </div>
        <slot>
          <div class="default" v-if="item.isDefault==1">Default</div>
        </slot>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "AddressList",
    data() {
      return {

      };
    },
    props: {
      list: {
        type: Array,
        default: []
      },
      deletefun: {
        deletefun(e) {
          return e
        }
      }
    },
    components: {},
    computed: {},
    watch: {},
    created() {

    },

    methods: {
      clickdelete(e) {
        this.deletefun(e)

      },
      update(e) {
        this.$router.push({
          path: "/addaddress",
          query: {
            bool: false,
            box8: false,
            phoneHomeImg: false,
            main: e
          },
        });
      }
    },
  };
</script>

<style scoped>
  .addresslist {
    /* width: calc(820 * 100vw / 1920); */
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 1px solid #dddddd;
    justify-content: space-between;
    /* box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38); */
    margin-bottom: calc(10 * 100vw / 1920);
    padding-left: calc(40 * 100vw / 1920);
    padding-right: calc(16 * 100vw / 1920);
  }

  .addresslist_text {
    /* margin-left: calc(40 * 100vw / 1920); */
    justify-content: space-between;
  }

  .addresslist_text_1 {
    margin-top: calc(30 * 100vw / 1920);
  }

  .addresslist_text_1,
  .addresslist_text_2 {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 550;
    color: #333333;
    width: 90%;
  }

  /* .addresslist_text_1 span:first-child {
    margin-right: calc(78 * 100vw / 1920);
  }

  .addresslist_text_1 span:last-child {
    margin-left: calc(78 * 100vw / 1920);
  } */
  .addresslist_text_1 span {
    width: 30%;
    display: inline-block;
    border-bottom: 1px solid gainsboro;
    padding: calc(20 * 100vw / 1920) 0;
    margin: 0 calc(20 * 100vw / 1920);
  }

  .addresslist_text>button {
    width: calc(20 * 100vw / 1920);
    height: calc(20 * 100vw / 1920);
    border: 0;
  }

  .addresslist_text>button img {
    width: 100%;
    height: 100%;
  }

  .addresslist_text .delete {
    margin-right: calc(20 * 100vw / 1920);
    margin-top: calc(13 * 100vw / 1920);
  }

  .addresslist_fun {
    padding-top: calc(27 * 100vw / 1920);
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 calc(20 * 100vw / 1920);
    padding-bottom: calc(40* 100vw / 1920);
  }

  .addresslist_fun .default {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 550;
    color: #fda911;
    text-align: right;
  }

  @media screen and (max-width: 1080px) {
    .addresslist {
      width: 100%;
      height: auto;
      background: #ffffff;
      border: 1px solid #dddddd;
      justify-content: space-between;
      box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
      margin-bottom: calc(30 * 100vw / 1080);
      padding-left: calc(45 * 100vw / 1080);
      padding-right: calc(26 * 100vw / 1080);
    }

    .addresslist_text_1 {
      margin-top: calc(40 * 100vw / 1080);
    }

    .addresslist_text_1,
    .addresslist_text_2 {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 550;
      color: #333333;
      width: 80%;
    }

    .addresslist_text_1 span {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid gainsboro;
      padding: 2vw 0;
    }

    /* .addresslist_text_1 span:first-child {
      padding-top: calc(22 * 100vw / 1080);
      display: block;
      margin-right: 0;
    }

    .addresslist_text_1 span:last-child {
      padding-top: calc(22 * 100vw / 1080);
      display: block;
      margin-left: 0
    } */

    .addresslist_text>button {
      width: calc(40 * 100vw / 1080);
      height: calc(40 * 100vw / 1080);
      border: 0;
    }

    .addresslist_text .delete {
      margin-right: calc(50 * 100vw / 1080);
      margin-top: calc(38 * 100vw / 1080);
    }

    .addresslist_fun {
      padding-top: calc(22 * 100vw / 1080);
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      /* border-bottom: 1px solid gainsboro; */
      /* padding: 2vw 0; */
      /* margin-bottom: 6vw; */
    }

    .addresslist_fun .default {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 550;
      color: #fda911;
      margin-left: calc(785 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
    }
  }
</style>