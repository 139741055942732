<template>
  <div class="pagenation flex-row">
    <div class="num flex-row justify-between">
      <div>&lt;</div>
      <div class="pagenation_active">1</div>
      <div>2</div>
      <div>3</div>
      <div>......</div>
      <div>9</div>
      <div>10</div>
      <div>&gt;</div>
    </div>
    <div class="pagenation_nav">
      <input type="number" />
      <button>Go</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNation",
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.pagenation_active {
  background: #13af87 !important;
  color: #ffffff !important;
}
.pagenation {
  margin-left: calc(180 * 100vw / 1920);
}
.pagenation .num {
  width: calc(390 * 100vw / 1920);
}
.pagenation .num div {
  width: calc(40 * 100vw / 1920);
  height: calc(40 * 100vw / 1920);
  border: 1px solid #dddddd;
  font-size: calc(22 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #aaaaaa;
  text-align: center;
  line-height: calc(35 * 100vw / 1920);
}
.pagenation .num div:first-child {
  font-size: calc(30 * 100vw / 1920);
  line-height: calc(30 * 100vw / 1920);
}
.pagenation .num div:last-child {
  font-size: calc(30 * 100vw / 1920);
  line-height: calc(30 * 100vw / 1920);
}
.pagenation .num div:nth-child(5) {
  border: none;
  line-height: calc(25 * 100vw / 1920);
}
.pagenation_nav {
  margin-left: calc(20 * 100vw / 1920);
}
.pagenation_nav input {
  width: calc(60 * 100vw / 1920);
  height: calc(40 * 100vw / 1920);
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 6px 0px rgba(128, 128, 128, 0.48);
  border-radius: calc(4 * 100vw / 1920);
  margin-right: calc(10 * 100vw / 1920);
  padding: 0;
  text-align: center;
}
.pagenation_nav button {
  width: calc(40 * 100vw / 1920);
  height: calc(40 * 100vw / 1920);
  background-color: #13af87;
  border-radius: calc(4 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #fff;
  vertical-align: top;
  text-align: center;
  line-height: calc(35 * 100vw / 1920);
}
</style>
