<template>
  <div class="companyprofile_page">
    <div class="companyprofile">
      <p class="directory">
        <span @click="homeClick" class="directory_home">Home </span> > Company
        Profile
      </p>
      <div class="companyprofile_main">
        <div class="companyprofile_title">Company Profile</div>
        <div class="companyprofile_img">
          <img :src="$baseUrl + info.image" alt="" />
        </div>
        <!-- <div class="companyprofile_video">
          <video
            :src="$baseUrl + info.video"
            autoplay controls loop alt=""
          />
        </div>
        <div class="companyprofile_text" v-html="info.briefIntroduction"> </div>-->
        <div class="companyprofile_pc">
          <div class="companyprofile_text" v-html="info.briefIntroduction"></div>
          <div class="companyprofile_video">
            <video
              :src="$baseUrl + info.video"
              autoplay controls loop alt=""
            />
          </div>
        </div>
        <div class="companyprofile_phone">
          <div class="companyprofile_text" v-html="info.briefIntroduction"></div>
        </div>
        <div class="companyprofile_text_1" v-html="info.oneInput"></div>
        <div class="companyprofile_text_1" v-html="info.twoInput"> </div>
          <!-- {{contactWe.aboutUs}} -->
        
        <div class="companyprofile_title">Contact info</div>
        <!-- <div class="contact_info_img"></div> -->
        <div class="contact_info">
          <div class="contact_info_img">
            <maps
              :longitude="info.longitude"
              :latitude="info.latitude"
              v-if="info.longitude && info.latitude"
            ></maps>
          </div>
          <div class="contact_info_message">
            <div class="address">
              <img
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/ps6scfsp2rxl4oc0eo3eyygdp3e7u2vnxtd3ee886c-ba50-4047-bf46-557b93ae6aa0"
              />
              <span class="text1">Address:</span>
              <!-- <span class="text2"
              >Office:&nbsp;5/F.,&nbsp;ChaoShan&nbsp;Bldg.,1036&nbsp;Dongmen&nbsp;Rd,&nbsp;S.,&nbsp;Shenzhen</span
            > -->
              <span class="text2">{{ info.address }}</span>
            </div>
            <div class="phone">
              <img
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/psyxernbqvyltnb5354a0oydrt2ebwfve372643b-8be4-4b8a-a61e-7351f7eb5e8e"
              />
              <span class="text1">Phone:</span>
              <!-- <span class="text2">4001234567</span> -->
              <span class="text2">{{ info.phone }}</span>
            </div>
            <div class="email">
              <img
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/pspfwlik28j3qtu99zxjcm88ij9des04ofdd90824-97d4-439b-ae0b-7eb5cebde1a4"
              />
              <span class="text1">Email:</span>
              <!-- <span class="text2">123456&#64;123.com</span> -->
              <span class="text2">{{ info.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getcontactInformation,
  getcontactWe,
  companyIntroduction,
} from "../../api/other";
import { businessIntroduction } from "@/api/business";
import maps from "@/components/maps/map";
export default {
  name: "CompanyProfile",
  data() {
    return {
      contactInfo: {},
      contactWe: {},
      businessId: sessionStorage.getItem("businessId"),
      businessInfo: {},
      info: {},
    };
  },
  components: {
    maps,
  },
  computed: {},
  watch: {
    $route: {
      handler: function (val) {
        //这里的路由是页面上的路由
        if (this.accountId != null && val.path == "/companyprofile") {
          //调用你想用的方法
          this.businessIntroductionApi();
        } else {
          this.router_show = false;
        }
      },
      // 深度观察监听，普通的watch方法无法监听到对象内部属性的改变，只有data中的数据才能够监听到变化，此时就需要deep属性对对象进行深度监听。
      deep: true,
    },
  },
  methods: {
    // 决定pc端与移动端的显示
    companyProfileShow() {
      // 刷新时判断
      if (document.documentElement.clientWidth > 1080) {
        document.querySelector(".companyprofile_pc").style.display = "flex";
        document.querySelector(".companyprofile_phone").style.display = "none";
      } else {
        document.querySelector(".companyprofile_pc").style.display = "none";
        document.querySelector(".companyprofile_phone").style.display = "block";
      }
    },
    homeClick() {
      this.$router.push({
        path: "/",
        query: {
          bool: true,
          noBusiness: true,
        },
      });
    },
    companyIntroduction() {
      companyIntroduction().then((res) => {
        if (res.code == 200) {
          this.info = res.data;
        }
      });
    },
    //获取商家简介信息
    businessIntroductionApi() {
      businessIntroduction(this.businessId).then((res) => {
        if (res.code == 200) {
          this.businessInfo = res.data;
        } else if (res.code == 401) {
          this.$message({
            message: res.code,
            type: "error",
          });
          //未登录
          this.$router.push({
            path: "/login",
            query: {
              box8: false,
              phoneHomeImg: false,
              bool: false,
            },
          });
          return;
        } else {
          // this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    // // 联系方式
    // getcontactInformation(){
    //   getcontactInformation().then(res=>{
    //     if(res.code==200){
    //       this.contactInfo=res.data
    //     }
    //   })
    // },
    // //联系我们
    // getcontactWe(){
    //   getcontactWe().then(res=>{
    //     console.log(res,'联系我们');
    //     if(res.code==200){
    //       this.contactWe=res.data
    //     }
    //   })
    // }
  },
  mounted() {
    // this.businessIntroductionApi();
    this.companyIntroduction();
    let bottomnav = document.querySelector(".bottomnav");
    for (let i = 0; i < bottomnav.children.length; i++) {
      bottomnav.children[i].style.color = "#aaa";
    }
    document.querySelector(".bottomnav_about").style.color = "#13AF87";
    this.companyProfileShow()
    // 屏幕变化时判断
    window.addEventListener("resize", () => {
      this.companyProfileShow();
    });
  },
};
</script>

<style scoped>
.companyprofile_page {
  width: 100vw;
  background-color: #f1f1f1;
  padding: 0 calc(20 * 100vw / 1920);
}
.companyprofile {
  /*width: calc(1200 * 100vw / 1920);*/
  width: 100%;
  margin: calc(20 * 100vw / 1920) auto;
  background-color: #f1f1f1;
}
.companyprofile .directory {
  background-color: #f1f1f1;
  margin: 0;
  padding-bottom: 1.2vw;
  font-size: 0.93vw;
  font-family: Roboto;
  font-weight: 400;
  color: #999999;
}
.companyprofile .companyprofile_main {
  background-color: #fff;
  padding-bottom: calc(20 * 100vw / 1920);
}
.companyprofile_main .companyprofile_title {
  margin-left: calc(20 * 100vw / 1920);
  width: calc(140 * 100vw / 1920);
  height: calc(75 * 100vw / 1920);
  white-space: nowrap;
  line-height: calc(75 * 100vw / 1920);
  border-bottom: 5px solid rgba(19, 175, 135, 1);
  font-weight: bolder;
  font-size: 1.28vw;
}
.companyprofile_main .companyprofile_img {
  /* height: calc(360 * 100vw / 1920); */
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: calc(438 * 100vw / 1920) calc(194 * 100vw / 1920);
  /* background-image: url(../../assets/img/logo.png); */
}
.companyprofile_img img {
  max-width: 100%;
  max-height: 100%;
}
.companyprofile_main .companyprofile_pc{
  display: flex;
  padding: calc(20 * 100vw / 1920);
}
.companyprofile_main .companyprofile_pc>div{
  width: 50%;
}
.companyprofile_main .companyprofile_video>video{
  width: 100%;
  /* position: fixed;
  bottom: 0;
  right: 0;
  max-width: 70vw;
  z-index: 9999; */
}
.companyprofile_main .companyprofile_text,.companyprofile_main .companyprofile_text_1 {
  font-size: calc(20 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #666666;
  line-height: calc(38 * 100vw / 1920);
  margin-bottom: calc(24 * 100vw / 1920);
  padding: 0 calc(20 * 100vw / 1920);
}
.companyprofile_main .companyprofile_text p:last-of-type,.companyprofile_main .companyprofile_text_1 p:last-of-type {
  margin: 0 calc(30 * 100vw / 1920);
  /* padding-bottom: calc(30 * 100vw / 1920); */
}
@media screen and (min-width: 1080px) {
  .contact_info {
    display: flex;
  }
  .contact_info_img {
    flex: 1;
    height: auto;
    padding: 0 10px;
    order: 2;
  }
  .contact_info_message {
    padding: 0 calc(28 * 100vw / 1920);
    margin-top: calc(20 * 100vw / 1920);
    flex: 1;
  }
}
/* .companyprofile_main .contact_info_img {
  height: calc(620 * 100vw / 1920);
  border-top: 1px solid #ddd;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: calc(1144 * 100vw / 1920) calc(500 * 100vw / 1920);
  
  padding: 0 10px;
  margin-top: 10px;
}
.companyprofile_main .contact_info_message {
  margin-top: calc(30 * 100vw / 1920);
  padding: 0 calc(28 * 100vw / 1920);
} */
.companyprofile_main .contact_info_message .address img {
  width: calc(20 * 100vw / 1920);
  height: calc(20 * 100vw / 1920);
}
.companyprofile_main .contact_info_message .address,
.companyprofile_main .contact_info_message .phone {
  border-bottom: 1px solid #ddd;
  margin-bottom: calc(25 * 100vw / 1920);
}
.companyprofile_main .contact_info_message .phone img {
  width: calc(20 * 100vw / 1920);
  height: calc(20 * 100vw / 1920);
}
.companyprofile_main .contact_info_message .email img {
  width: calc(25 * 100vw / 1920);
  height: calc(20 * 100vw / 1920);
}
.companyprofile_main .contact_info_message .text1 {
  font-size: calc(26 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 500;
  color: #666666;
  margin-left: calc(16 * 100vw / 1920);
}
.companyprofile_main .contact_info_message .text2 {
  display: block;
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  line-height: calc(25 * 100vw / 1920);
  margin-top: calc(20 * 100vw / 1920);
  padding-bottom: calc(32 * 100vw / 1920);
}
@media screen and (max-width: 1080px) {
  .companyprofile_page {
    width: 100vw;
    background-color: #fff;
    border-top: 2px solid #ddd;
  }
  .companyprofile {
    width: 100%;
    margin: 0;
    background-color: #fff;
  }
  .companyprofile .directory {
    display: none;
  }
  .companyprofile_main .companyprofile_title {
    margin-left: calc(40 * 100vw / 1080);
    width: calc(280 * 100vw / 1080);
    height: calc(145 * 100vw / 1080);
    white-space: nowrap;
    line-height: calc(145 * 100vw / 1080);
    border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: calc(48 * 100vw / 1080);
  }
  .companyprofile_main .companyprofile_img {
    /* height: calc(388 * 100vw / 1080); */
    /* border-top: none; */
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(438 * 100vw / 1080) calc(194 * 100vw / 1080);
    /* background-image: url(../../assets/img/logo.png); */
  }
  .companyprofile_main .companyprofile_text,.companyprofile_main .companyprofile_text_1 {
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    line-height: calc(48 * 100vw / 1080);
    margin-bottom: calc(42 * 100vw / 1080);
    padding: 0 calc(40 * 100vw / 1080);
  }
  .companyprofile_main .companyprofile_text p,.companyprofile_main .companyprofile_text_1 p {
    margin: 0 calc(40 * 100vw / 1080);
    padding-bottom: calc(90 * 100vw / 1080);
  }
  .companyprofile_main .companyprofile_text p:nth-child(n + 4) {
    display: none;
  }
  .companyprofile_main .contact_info_img {
    height: calc(640 * 100vw / 1080);
    border-top: 1px solid #ddd;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin: 0 10px;
    margin-top: 10px;
    /* background-size: calc(1000 * 100vw / 1080) calc(520 * 100vw / 1080);
    background-image: url(../../assets/img/地图.png); */
  }
  .companyprofile_main .contact_info_message {
    padding: 0 calc(40 * 100vw / 1080);
    margin-bottom: calc(100 * 100vw / 1080);
    margin-top: calc(30 * 100vw / 1080);
  }
  .companyprofile_main .contact_info_message .address img {
    width: calc(40 * 100vw / 1080);
    height: calc(40 * 100vw / 1080);
  }
  .companyprofile_main .contact_info_message .address,
  .companyprofile_main .contact_info_message .phone {
    border-bottom: none;
    margin-bottom: calc(50 * 100vw / 1080);
  }
  .companyprofile_main .contact_info_message .phone img {
    width: calc(40 * 100vw / 1080);
    height: calc(40 * 100vw / 1080);
  }
  .companyprofile_main .contact_info_message .email img {
    width: calc(40 * 100vw / 1080);
    height: calc(30 * 100vw / 1080);
  }
  .companyprofile_main .contact_info_message .text1 {
    font-size: calc(36 * 100vw / 1080);
    margin-left: calc(24 * 100vw / 1080);
  }
  .companyprofile_main .contact_info_message .text2 {
    font-size: calc(36 * 100vw / 1080);
    line-height: calc(50 * 100vw / 1080);
    margin-top: calc(30 * 100vw / 1080);
    padding-bottom: 0;
  }
}
</style>
<style>
/* .companyprofile_main .companyprofile_text p:last-of-type,
.businessprofile_main .businessprofile_text p:last-of-type {
  text-indent: calc(100 * 100vw / 1920);
}
@media screen and (max-width: 1080px) {
  .companyprofile_main .companyprofile_text p:last-of-type,
  .businessprofile_main .businessprofile_text p:last-of-type {
    text-indent: calc(120 * 100vw / 1080);
  }
} */
.companyprofile_text p {
  width: 100%;
  margin-bottom: calc(20 * 100vw / 1920);
  text-indent: calc(32 * 100vw / 1920);
}
.companyprofile_text p img {
  max-width: 100%;
  max-height: 100%;
}
@media screen and (max-width: 1080px) {
  .companyprofile_text p,.companyprofile_text_1 p  {
    width: 100%;
    margin-bottom: calc(10 * 100vw / 1080);
    font-size: calc(40 * 100vw / 1080);
  }
}
</style>