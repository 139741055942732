<template>
  <div class="addaddress_page">
    <div class="addaddress">
      <p class="directory"><span @click="homeClick(true)">Home</span> > Personal Center</p>
      <div class="addaddress_title">Add New Address</div>
      <div class="addaddress_main">
        <div class="addaddress_name flex-row">
          <div class="title">Full name (First and Last name):</div>
          <input type="text" v-model="form.contacts" placeholder="Please enter yfour name" />
        </div>
        <div class="addaddress_phone flex-row">
          <div class="title">Phone number:</div>
          <input type="number" v-model="form.phone" placeholder="Please enter your Phone number" />
        </div>
        <div class="addaddress_phone flex-row">
          <div class="title">Country:</div>
          <input type="text" v-model="form.country" placeholder="Please enter your Country" />
        </div>
        <div class="addaddress_phone flex-row">
          <div class="title">City:</div>
          <input type="text" v-model="form.city" placeholder="Please enter your City" />
        </div>
        <div class="addaddress_address flex-row">
          <div class="title">Address:</div>
          <input type="text" v-model="form.address" placeholder="Please enter your address" />
        </div>
        <div class="addaddress_address flex-row">
          <div class="title">Zip Code:</div>
          <input type="text" v-model="form.postCode" placeholder="Please enter your Zip Code" />
        </div>
        <div class="addaddress_address flex-row">
          <div class="title">Email:</div>
          <input type="text" v-model="form.email" placeholder="Please enter your Email" />
        </div>
        <div class="addaddress_switch flex-row">
          <div class="title">Default</div>
          <el-switch v-model="form.isDefault"></el-switch>
        </div>
      </div>
      <button class="addaddress_confirm" @click="confirm(form)">Confirm</button>
    </div>
  </div>
</template>

<script>
  import { addAddressBook, editAddressBook } from "../../../../api/index";
  export default {
    name: "AddAddress",
    data() {
      return {
        form: {
          contacts: "",
          phone: "",
          address: "",
          isDefault: false,
          sort: 0,
          id: null,
          country: null,
          city: null,
          email: null,
        },
      };
    },
    components: {},
    computed: {},
    watch: {},
    created() {
      this.getid();
    },
    methods: {
      // 图表log跳转到首页
      homeClick(bool) {
        if (this.shareType) return
        this.$router.push({
          path: "/",
          query: {
            bool,
            noBusiness: true,
          },
        });
      },
      getid() {
        Object.assign(this.form, { ...this.$route.query.main });
        if (this.form.isDefault == 1) {
          this.form.isDefault = true;
        } else {
          this.form.isDefault = false;
        }
      },
      confirm(e) {
        if (!e.contacts) {
          this.$message({ message: "Please enter your Full name (First and Last name)", type: "warning" });
          return;
        }
        if (!e.phone) {
          this.$message({
            message: "Please enter your Phone number",
            type: "warning",
          });
          return;
        }
        if (!e.country) {
          this.$message({
            message: "Please enter your Country",
            type: "warning",
          });
          return;
        }
        if (!e.city) {
          this.$message({
            message: "Please enter your City",
            type: "warning",
          });
          return;
        }
        if (!e.address) {
          this.$message({
            message: "Please enter your Address",
            type: "warning",
          });
          return;
        }
        if (!e.postCode) {
          this.$message({
            message: "Please enter your Zip Code",
            type: "warning",
          });
          return;
        }
        if (!e.email) {
          this.$message({
            message: "Please enter your Email",
            type: "warning",
          });
          return;
        } else {
          if (e.isDefault) {
            e.isDefault = 1;
          } else {
            e.isDefault = 0;
          }
          if (this.form.id != null) {
            editAddressBook({ ...e }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "Modification succeeded",
                  type: "success",
                });
                // this.$router.back(0)
                this.$router.go(-1);
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            });
          } else {
            addAddressBook({ ...e }).then((res) => {
              if (res.code == 200) {
                this.$message({ message: "Successfully added", type: "success" });
                // this.$router.back(0)
                this.$router.go(-1);
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            });
          }
        }
      },
    },
    mounted() {
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
    },
  };
</script>

<style scoped>
  .addaddress_page {
    width: 100vw;
    background: #f1f1f1;
  }

  .addaddress {
    width: calc(1200 * 100vw / 1920);
    margin: calc(30 * 100vw / 1920) auto;
    background-color: #fff;
    padding-bottom: calc(50 * 100vw / 1920);
  }

  .addaddress .directory {
    background-color: #f1f1f1;
    margin: 0;
    padding-bottom: 1.2vw;
    font-size: 0.93vw;
    font-family: Roboto;
    font-weight: 400;
    color: #999999;
  }

  .addaddress_title {
    margin-left: calc(38 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .addaddress_main {
    border-top: 1px solid #ddd;
    /* padding-left: calc(38 * 100vw / 1920); */
    padding-top: calc(58 * 100vw / 1920);
    /* margin-bottom: calc(55 * 100vw / 1920); */
  }

  .addaddress_name,
  .addaddress_phone,
  .addaddress_mail,
  .addaddress_address {
    justify-content: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bolder;
    color: #333333;
    align-items: center;
    margin-bottom: calc(30 * 100vw / 1920);
  }

  .addaddress_switch {
    display: flex;
    justify-content: space-between;
    width: 53%;
    margin: 0 auto;
  }

  .addaddress_main .title {
    width: calc(150 * 100vw / 1920);
  }

  .addaddress_main input {
    width: calc(480 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-left: calc(18 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bolder;
    color: #333333;
    padding-left: calc(24 * 100vw / 1920);
  }

  .addaddress_main input::-webkit-input-placeholder {
    color: #aaaaaa;
  }

  .addaddress_confirm {
    width: calc(200 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #13af87;
    border-radius: calc(2 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bold;
    color: #ffffff;
    margin-left: calc(500 * 100vw / 1920);
  }

  @media screen and (max-width: 1080px) {
    .addaddress_switch {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
    }

    .addaddress_page {
      width: 100vw;
      height: calc(1624 * 100vw / 1080);
      background-color: #fff;
      border-top: 1px solid #ddd;
    }

    .addaddress {
      width: 100%;
      margin: 0;
    }

    .addaddress .directory {
      display: none;
    }

    .addaddress_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(150 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(170 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .addaddress_main {
      border-top: 1px solid #ddd;
      padding-left: calc(40 * 100vw / 1080);
      padding-left: calc(40 * 100vw / 1080);
      padding-right: calc(50 * 100vw / 1080);
      margin-bottom: calc(60 * 100vw / 1080);
    }

    .addaddress_name,
    .addaddress_phone,
    .addaddress_mail,
    .addaddress_address {
      height: calc(140 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bolder;
      color: #333333;
      align-items: flex-end;
      margin-bottom: 0;
      border-bottom: 1px solid #ddd;
      justify-content: space-between;
      /* padding-bottom: calc(35 * 100vw / 1080); */
    }

    .addaddress_main .title {
      width: calc(300 * 100vw / 1080);
      padding-bottom: calc(25 * 100vw / 1080);
    }

    .addaddress_main input {
      width: calc(600 * 100vw / 1080);
      height: calc(100 * 100vw / 1080);
      background: #ffffff;
      border: none;
      margin-left: 0;
      text-align: right;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bolder;
      color: #333333;
      padding-left: 0;
    }

    .addaddress_name::after,
    .addaddress_phone::after,
    .addaddress_mail::after,
    .addaddress_address::after {
      content: "";
      font-size: calc(36 * 100vw / 1080);
      padding-bottom: calc(25 * 100vw / 1080);
    }

    .addaddress_confirm {
      width: calc(640 * 100vw / 1080);
      height: calc(88 * 100vw / 1080);
      background: #13af87;
      border-radius: calc(2 * 100vw / 1080);
      font-size: calc(38 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bold;
      color: #ffffff;
      margin-left: calc(180 * 100vw / 1080);
    }
  }
</style>