<template>
    <div class="commentcomponent">
        <div v-for="(item, index) in apprises" :key="index" class="commentitem">
            <div class="comment_top">
                <img class="headpic"
                    :src="item.commodityImage ? baseURL + item.commodityImage : './person_default.png'" />
                <div class="content">
                    <div class="content_top">
                        <div class="">
                            <span class="cm_name">{{ item.commodityName ? item.commodityName : '匿名用户' }}</span>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div class="cm_rate">
                            评分
                            {{ item.comprehensiveScore }}
                        </div>
                        <span class="cm_time">{{ item.createTime }}</span>
                    </div>
                </div>
            </div>
            <div class="contents">
                <div class="cm_info_comment">
                    {{ item.userAppraise }}
                </div>
                <!-- <div class="cm_info">
					<text class="cm_info_text" v-for="(list, i) in item.appraises" :key="i"
						wx:key="index">{{list}}</text>
				</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { baseURL } from '@/utils/request.js'
export default {
    props: {
        apprises: {
            type: Array,
            value: []
        }
    },
    data() {
        return {
            baseURL
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.commentitem {
    margin-top: calc(24 * 100vw / 1920);
    border-bottom: 1px solid #f6f6f6;
    padding: 0 calc(30 * 100vw / 1920) calc(24 * 100vw / 1920);
}

.comment_top {
    display: flex;
    align-items: center;
}

.content {
    width: calc(100% - (100 * 100vw / 1920));
}

.content_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content_top .time {
    font-size: calc(24 * 100vw / 1920);
    color: #ccc;
}

.headpic {
    width: calc(87 * 100vw / 1920);
    height: calc(87 * 100vw / 1920);
    flex-shrink: 0;
    border-radius: calc(87 * 100vw / 1920);
    margin-right: calc(10 * 100vw / 1920);
}

.cm_name {
    font-size: calc(32 * 100vw / 1920);
    font-family: PingFang SC;
    line-height: calc(32 * 100vw / 1920);
    font-weight: 700;
    color: #4D4D4D;
}

.cm_time {
    font-size: calc(24 * 100vw / 1920);
    line-height: calc(24 * 100vw / 1920);
    font-family: PingFang SC;
    font-weight: 400;
    color: #999;
}

.cm_rate {
    display: flex;
    align-items: center;
    font-size: calc(24 * 100vw / 1920);
    font-family: PingFang SC;
    font-weight: 400;
    color: #4d4d4d;
}

.cm_info {
    margin-top: calc(20 * 100vw / 1920);
    font-size: calc(30 * 100vw / 1920);
    line-height: calc(30 * 100vw / 1920);
    flex-wrap: wrap;
    display: flex;
}

.cm_info text {
    background: #E0FCF6;
    color: #05db9b;
    font-size: calc(24 * 100vw / 1920);
    border-radius: calc(25 * 100vw / 1920);
    padding: calc(10 * 100vw / 1920) calc(26 * 100vw / 1920);
    margin: 0 calc(36 * 100vw / 1920) calc(20 * 100vw / 1920) 0;
}

.cm_info_comment {
    margin-top: calc(30 * 100vw / 1920);
    color: #4D4D4D;
    font-size: calc(30 * 100vw / 1920);
    line-height: calc(46 * 100vw / 1920);
}

.cm_info_text {
    color: #03DD98;
    padding: 0 calc(15 * 100vw / 1920);
    background: rgba(92, 201, 155, 0.1);
    margin: calc(8 * 100vw / 1920);
    border-radius: calc(8 * 100vw / 1920);
}

@media screen and (max-width: 1080px) {
    .commentitem {
        margin-top: calc(24 * 100vw / 1920);
        border-bottom: 1px solid #f6f6f6;
        padding: 0 calc(30 * 100vw / 1920) calc(24 * 100vw / 1920);
    }

    .comment_top {
        display: flex;
        align-items: center;
    }

    .content {
        width: calc(100% - (200 * 100vw / 1920));
    }

    .content_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .content_top .time {
        font-size: calc(48 * 100vw / 1920);
        color: #ccc;
    }

    .headpic {
        width: calc(160 * 100vw / 1920);
        height: calc(160 * 100vw / 1920);
        flex-shrink: 0;
        border-radius: calc(160 * 100vw / 1920);
        margin-right: calc(10 * 100vw / 1920);
    }

    .cm_name {
        font-size: calc(64 * 100vw / 1920);
        font-family: PingFang SC;
        line-height: calc(64 * 100vw / 1920);
        font-weight: 700;
        color: #4D4D4D;
    }

    .cm_time {
        font-size: calc(48 * 100vw / 1920);
        line-height: calc(48 * 100vw / 1920);
        font-family: PingFang SC;
        font-weight: 400;
        color: #999;
    }

    .cm_rate {
        display: flex;
        align-items: center;
        font-size: calc(48 * 100vw / 1920);
        font-family: PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
    }

    .cm_info {
        margin-top: calc(20 * 100vw / 1920);
        font-size: calc(60 * 100vw / 1920);
        line-height: calc(60 * 100vw / 1920);
        flex-wrap: wrap;
        display: flex;
    }

    .cm_info text {
        background: #E0FCF6;
        color: #05db9b;
        font-size: calc(48 * 100vw / 1920);
        border-radius: calc(50 * 100vw / 1920);
        padding: calc(10 * 100vw / 1920) calc(26 * 100vw / 1920);
        margin: 0 calc(36 * 100vw / 1920) calc(20 * 100vw / 1920) 0;
    }

    .cm_info_comment {
        margin-top: calc(30 * 100vw / 1920);
        color: #4D4D4D;
        font-size: calc(60 * 100vw / 1920);
        line-height: calc(100 * 100vw / 1920);
    }

    .cm_info_text {
        color: #03DD98;
        padding: 0 calc(15 * 100vw / 1920);
        background: rgba(92, 201, 155, 0.1);
        margin: calc(8 * 100vw / 1920);
        border-radius: calc(8 * 100vw / 1920);
    }
}
</style>
