<template>
    <div class="orderdetail_page">
        <div class="orderdetail_left">
            <!-- <div class="orderdetail_div">
                <p class="orderdetail_title">订单详情<span class="orderdetail_title_text" @click="submitHandle">修改</span>
                </p>
                <div class="orderdetail_no">{{orderInfo.orderNo}} <span>创建于{{orderInfo.createTime}}</span></div>
                <div class="orderdetail_child">
                    <div class="odc_div">
                        <div class="odc_div_child">
                            <div class="odcdc_title"><span>支付价格</span><label
                                    class="orderdetail_green">${{orderInfo.payPrice}}</label></div>
                            <div class="odcdc_div"><span>订单总价:</span>${{orderInfo.totalPrice}}</div>
                            <div class="odcdc_div"><span>运费:</span>${{orderInfo.freightPrice}}</div>
                        </div>
                    </div>
                    <div class="odc_div">
                        <div class="odc_div_child">
                            <div class="odcdc_title"><span>订单状态</span></div>
                            <div class="odcdc_redbtn">
                                <span>
                                    {{orderInfo.status==-1?'删除':
                                    orderInfo.status==0?'未支付':
                                    orderInfo.status==1?'已支付':
                                    orderInfo.status==2?'已退款':
                                    orderInfo.status==3?'已取消':''}}
                                </span>
                            </div>
                            <div class="odcdc_greenbtn">
                                <el-radio v-for="(item,index) in orderStatus" :key="index" v-model="orderInfo.status"
                                    :label="item.value">{{item.label}}</el-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="orderdetail_div">
                <p class="orderdetail_title">订单详情<span class="orderdetail_title_text" @click="submitHandle">提交</span>
                </p>
                <el-descriptions class="margin-top" :column="isPc == true ? 3 : 1" border>
                    <!-- <template slot="extra">
                        <el-button type="primary" size="small">操作</el-button>
                    </template> -->
                    <el-descriptions-item
                        :labelStyle="isPc == true ? 'width: calc(100 * 100vw / 1920);' : 'width: calc(500 * 100vw / 1920);'  ">
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            订单号
                        </template>
                        {{orderInfo.orderNo}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            订单总价
                        </template>
                        {{orderInfo.totalPrice}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-location-outline"></i>
                            优惠金额
                        </template>
                        {{orderInfo.discountPrice}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-mobile-phone"></i>
                            创建时间
                        </template>
                        {{orderInfo.createTime}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            支付价格
                        </template>
                        {{orderInfo.payPrice}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            支付方式
                        </template>

                        <el-tag size="small">
                            {{orderInfo.payMode==1?'PayPal支付':
                            orderInfo.payMode==2?'收款码':
                            orderInfo.payMode==3?'银行卡':
                            orderInfo.payMode==4?'PayPal支付统一收款':'未支付'}}
                        </el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item :span="2">
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            状态
                        </template>
                        <el-radio v-for="(item,index) in orderStatus" :key="index" v-model="orderInfo.status"
                            :label="item.value">{{item.label}}</el-radio>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            确认订单
                        </template>
                        <el-radio v-model="orderInfo.isComplete" :label="0">未完成</el-radio>
                        <el-radio v-model="orderInfo.isComplete" :label="1">已完成</el-radio>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="orderdetail_div">
                <p class="orderdetail_title">商品清单<!-- <span class="orderdetail_title_text">修改</span> --></p>
                <el-table :data="orderDetailList" tooltip-effect="dark" class="orderdetail_table">
                    <el-table-column label="序号" prop="no" width="50" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.$index+1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单号" prop="orderNo" width="200" align="center">
                    </el-table-column>
                    <el-table-column label="商品图片" prop="orderNo" width="140" align="center">
                        <template slot-scope="scope">
                            <div class="orderdetail_table_div">
                                <img class="orderdetail_table_div_img" :src="$baseUrl + scope.row.image" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" prop="commodityName" align="center">
                        <!-- <template slot-scope="scope">
                            <div class="orderdetail_table_div">
                                <img class="orderdetail_table_div_img" :src="$baseUrl + scope.row.image" />
                                <span>{{scope.row.commodityName}}</span>
                            </div>
                        </template> -->
                    </el-table-column>
                    <el-table-column label="商品SKU" prop="specifications" align="center">
                    </el-table-column>
                    <el-table-column label="购买数量" prop="quantity" width="100" align="center">
                    </el-table-column>
                    <el-table-column label="运费" prop="freightCharge" width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.freightCharge ? scope.row.freightCharge : 0}}
                        </template>
                    </el-table-column>
                    <el-table-column label="价格" prop="price" width="100" align="center">
                    </el-table-column>
                    <el-table-column label="小计" prop="price" width="100" align="center">
                        <template slot-scope="scope">
                            {{((scope.row.price*scope.row.quantity)+scope.row.freightCharge).toFixed(2)}}
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-table
                    :data="goodLists"
                    tooltip-effect="dark"
                    class="orderdetail_table"
                >
                    <el-table-column
                        label="序号"
                        prop="no"
                        width="55"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="产品"
                        prop="commodityPrice"
                    >
                        <template slot-scope="scope">
                            <div v-if="!scope.row.isEdit">
                                <img src="" />  
                                <div>
                                    <p>111111</p>
                                    <p>编号：00115</p>
                                </div>                         
                            </div>
                            <el-input
                                v-model="scope.row.quantity"
                                v-else
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="价格" 
                        prop="quantity" 
                        width="100"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <span v-if="!scope.row.isEdit">{{scope.row.price}}</span>
                            <el-input
                                v-model="scope.row.price"
                                @input="handleChange"
                                v-else
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="数量" 
                        prop="quantity" 
                        width="100"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <span v-if="!scope.row.isEdit">{{scope.row.quantity}}</span>
                            <el-input
                                v-model="scope.row.quantity"
                                @input="handleChange"
                                v-else
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="小计" prop="totalPrice" width="100" align="center">

                    </el-table-column>
                </el-table> -->
            </div>
        </div>
        <div class="orderdetail_right">
            <div class="orderdetail_div">
                <p class="orderdetail_title">配送地址<span class="orderdetail_title_text"
                        @click="editHandle('address')">{{isEditAddress?'提交':'修改'}}</span></p>
                <div class="odc_div">
                    <div class="odc_div_child">
                        <div class="odcdc_div_address" :style="{'align-items':isEditAddress?'center':''}">
                            <span>收货人:</span>
                            <label v-if="!isEditAddress">{{orderInfo.contacts}}</label>
                            <el-input v-model="orderInfo.contacts" v-else></el-input>
                        </div>
                        <div class="odcdc_div_address"><span>手机:</span>
                            <label v-if="!isEditAddress">{{orderInfo.phone}}</label>
                            <el-input v-model="orderInfo.phone" v-else></el-input>
                        </div>
                        <div class="odcdc_div_address"><span>国家:</span>
                            <label v-if="!isEditAddress">{{orderInfo.country}}</label>
                            <el-input v-model="orderInfo.country" v-else></el-input>
                        </div>
                        <div class="odcdc_div_address"><span>城市:</span>
                            <label v-if="!isEditAddress">{{orderInfo.city}}</label>
                            <el-input v-model="orderInfo.city" v-else></el-input>
                        </div>
                        <!-- <div class="odcdc_div_address"><span>公司名称:</span>
                            <label v-if="!isEditAddress">{{orderInfo.companyName}}</label>
                            <el-input v-model="orderInfo.companyName" v-else></el-input>
                        </div> -->
                        <div class="odcdc_div_address"><span>地址:</span>
                            <label v-if="!isEditAddress">{{orderInfo.address}}</label>
                            <el-input v-model="orderInfo.address" v-else></el-input>
                        </div>
                        <div class="odcdc_div_address"><span>邮政编码:</span>
                            <label v-if="!isEditAddress">{{orderInfo.postCode}}</label>
                            <el-input v-model="orderInfo.postCode" v-else></el-input>
                        </div>
                        <div class="odcdc_div_address"><span>邮箱:</span>
                            <label v-if="!isEditAddress">{{orderInfo.email}}</label>
                            <el-input v-model="orderInfo.email" v-else></el-input>
                        </div>
                        <!-- <div class="odcdc_div_address"><span>是否发货:</span>
                            <el-radio-group v-model="orderInfo.isDelivery">
                                <el-radio :label="0">未发货</el-radio>
                                <el-radio :label="1">已发货</el-radio>
                            </el-radio-group>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="orderdetail_div">
                <p class="orderdetail_title">
                    配送信息
                    <span class="orderdetail_title_text_new">
                        {{orderInfo.isDelivery === 1?'已发货':orderInfo.isDelivery === 0?'未发货':''}}
                    </span>
                    <span class="orderdetail_title_text" @click="showGps"
                        v-if="orderInfo&&orderInfo.logisticsInfo && orderInfo.logisticsCode && orderInfo.deliveryOrderNo">地图GPS轨迹</span>
                    <span class="orderdetail_title_text" @click="queryHandle"
                        v-if="orderInfo&&orderInfo.logisticsInfo && orderInfo.logisticsCode && orderInfo.deliveryOrderNo">查看物流信息</span>
                    <span class="orderdetail_title_text"
                        @click="editHandle('delivery')">{{isEditDelivery?'提交':'修改'}}</span>
                </p>
                <div class="odc_greydiv">
                    <div class="odcgd_title">
                        <label>是否发货：</label>
                        <el-radio-group :disabled="!isEditDelivery" v-model="orderInfo.isDelivery">
                            <el-radio :label="0">未发货</el-radio>
                            <el-radio :label="1">已发货</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="odcgd_title">
                        <label>物流方式：</label>
                        <label class="odcgd_title_label" v-if="!isEditDelivery">{{orderInfo.logisticsMode}}</label>
                        <el-input v-model="orderInfo.logisticsMode" v-else></el-input>
                        <!-- <label class="odcgd_title_label" v-if="!isEditLogistics">1</label>
                        <el-input v-model="orderInfo.email" v-else></el-input> -->
                        <!-- <span class="orderdetail_green" @click="editHandle('logistics')">{{isEditLogistics?'提交':'修改'}}</span> -->
                    </div>
                    <div class="odcgd_title" v-if="!isEditDelivery">
                        <label>最新物流信息：</label>
                        <label class="odcgd_title_label">{{orderInfo.logisticsInfo}}</label>
                    </div>
                    <div class="odcgd_title" v-if="!isEditDelivery">
                        <label>物流商简码：</label>
                        <label class="odcgd_title_label">{{orderInfo.logisticsCode}}</label>
                    </div>
                    <div class="odcgd_title" v-if="!isEditDelivery">
                        <label>物流信息更新时间：</label>
                        <label class="odcgd_title_label">{{orderInfo.logisticsUpdateTime}}</label>
                    </div>
                    <div class="odcgd_title">
                        <label>发送单号：</label>
                        <label class="odcgd_title_label" v-if="!isEditDelivery">{{orderInfo.deliveryOrderNo}}</label>
                        <el-input v-model="orderInfo.deliveryOrderNo" v-else></el-input>
                    </div>
                    <div class="odcgd_price">
                        <label>运费:</label>
                        <label class="odcgd_price_label" v-if="!isEditDelivery">${{orderInfo.freightPrice}}</label>
                        <el-input v-model="orderInfo.freightPrice" v-else></el-input>
                    </div>
                    <div class="odcgd_baoguo">
                        <p class="odcgd_baoguo_title">包裹</p>
                        <p class="odcgd_baoguo_content">
                            <span>重量:</span>
                            <label v-if="!isEditDelivery">{{orderInfo.parcelWeight}}KG</label>
                            <el-input v-model="orderInfo.parcelWeight" v-else></el-input>
                            <span style="margin-left:10px;">产品数量:</span>
                            <label v-if="!isEditDelivery">{{orderInfo.commodityNum}}</label>
                            <el-input v-model="orderInfo.commodityNum" v-else></el-input>
                        </p>
                    </div>
                </div>
            </div>
            <!-- <div class="orderdetail_div">
                <p class="orderdetail_title" style="justify-content:flex-start;">备注
                    <span class="orderdetail_title_text" style="color:#C4C4C4;">(此备注仅提供后端管理页查看)</span>
                </p>
                <div class="odc_div">
                    <div class="odc_div_child">
                        <div class="odcdc_edit odcdc_notes">
                            <el-input v-model="orderInfo.remark"></el-input>
                            <span class="odcdc_notes_btn" @click="submitHandle">发送</span>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <el-dialog title="物流信息" :visible.sync="dialogVisible" width="60%" :modal="false" class="dialogbox"
            :before-close="handleClose">
            <div>
                <el-timeline :reverse="true">
                    <el-timeline-item v-for="(activity, index) in activities" :key="index"
                        :timestamp="activity.timestamp" v-if="activity.content.indexOf('$TRACK718SHIPPINGDATA') == -1">
                        {{activity.content}}
                    </el-timeline-item>
                </el-timeline>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        shopOrderList,
        shopCancelOrder,
        confirmOrderPay,
        exportOrderList,
        shopAddOrEditOrder,
        shopAddOrEditOrderSendEmail,
        shopDelOrder,
        shopConfirmOrderPay,
        shopAddOrders,
    } from "@/api/business";
    import { getOrderSource, updateOrderSource, updateDeliveryAddress, updateDeliveryInfo } from "@/api/news"
    import { searchTrackInfoApi } from '@/api/other'

    export default {
        name: "BusinessOrderDetail",
        // props: ['orderDetailInfo','orderDetailVos'],
        props: {
            orderDetailInfo: {
                type: Object,
                default: 0,
            },
            orderDetailVos: {
                type: Array,
                default: 0,
            },
        },
        data() {
            return {
                orderInfo: {},
                orderDetailList: [],
                from: {
                    // ywy:'',
                    // isEditywy:true,
                    // isEditAddress:false,
                    // name:'Jamo LEE',
                    // company:'TOP GUY INTERNATIONAL TRADING,LLC.',
                    // address:'NEW YORK NEW YORK,New York,United States',
                    // email:'001222999',
                    // phone:'+1-1110334',
                    // notes:'',

                    id: "",
                    commodityId: "",
                    quantity: "",
                    remark: "",
                    totalPrice: "",
                    commodityPrice: "",
                    userEmail: "",
                    payPrice: "",
                    contacts: "",
                    phone: "",
                    address: "",
                    email: "",
                    city: "",
                    country: "",
                    deliveryOrderNo: ""
                },
                goodLists: [],
                isEditStatus: false, //订单详情
                isEditAddress: false,    //配送地址
                isEditDelivery: false,   //配送信息
                isEditLogistics: false,  //物流方式
                idEditNotes: false,  //备注
                // orderStatus:['删除','未支付','已支付','已退款','已取消'],
                orderStatus: [
                    { value: -1, label: '删除' },
                    { value: 0, label: '未支付' },
                    { value: 1, label: '已支付' },
                    { value: 2, label: '已退款' },
                    { value: 3, label: '已取消' },
                ],
                orderStatusVal: '未支付',
                deliveryStatus: ['未发货', '已发货'],
                deliveryStatusVal: '未发货',
                dialogVisible: false,
                activities: [],
                isPc: true,
            };
        },
        computed: {

        },
        watch: {

        },

        methods: {
            businessPersonalShow() {
                // 刷新时判断
                if (document.documentElement.clientWidth > 1080) {
                    this.isPc = true;
                } else {
                    this.isPc = false;
                }
            },
            searchTrackInfo() {
                searchTrackInfoApi([{
                    trackNum: this.orderInfo.deliveryOrderNo,
                    logisticsCode: this.orderInfo.logisticsCode,
                }]).then(res => {
                    if (res?.code == 200) {
                        let activities = []
                        res?.data?.data?.list[0].fromDetail.forEach(item => {
                            activities.push({
                                timestamp: item.date,
                                content: item.status
                            })
                        })
                        this.activities = activities
                    } else {
                        this.$message({ message: res.msg, type: "error" });
                    }
                })
            },
            queryHandle() {
                this.searchTrackInfo()
                this.dialogVisible = true
            },
            showGps() {
                if (this.orderInfo && this.orderInfo.logisticsInfo && this.orderInfo.logisticsCode && this.orderInfo.deliveryOrderNo) {
                    var url = "https://www.track718.com/zh-CN/gpsmaps?trackNum=" + this.orderInfo.deliveryOrderNo + "&code=" + this.orderInfo.logisticsCode;
                    window.open(url, "_blank");
                }
            },
            selectOrderSource() {
                // this.orderDetailInfo.id
                getOrderSource(this.orderDetailInfo.id).then(res => {
                    console.log("返回的订单详情为：", res)
                    if (res.code == 200) {
                        this.orderInfo = res.data
                        if (res.data.orderDetailList && res.data.orderDetailList.length > 0) {
                            this.orderDetailList = res.data.orderDetailList
                        }
                    }
                })
            },
            // 选择订单状态
            chooseOrderStatus(item) {
                this.orderInfo.status = item.value
                this.orderStatusVal = item.label
            },
            // 选择发货状态
            chooseDeliveryStatus() {
                this.deliveryStatusVal = item
            },
            // 修改
            editHandle(status) {
                if (this.isEditAddress) {
                    this.submitAddressHandle()
                    return
                }
                if (this.isEditDelivery) {
                    this.submitDeliveryHandle()
                    return
                }
                if (this.isEditLogistics) {
                    this.submitDeliveryHandle()
                    return
                }
                if (status == 'address') {
                    this.isEditAddress = !this.isEditAddress
                } else if (status == 'delivery') {
                    this.isEditDelivery = !this.isEditDelivery
                } else if (status == 'logistics') {
                    this.isEditLogistics = !this.isEditLogistics
                }

            },
            // 提交修改
            submitHandle() {
                updateOrderSource(this.orderInfo).then(res => {
                    if (res.code == 200) {
                        this.$message({ message: res.msg, type: "success" });
                        this.$emit('orderDetailClose', true)
                    } else {
                        this.$message({ message: res.msg, type: "error" });
                    }
                })
            },
            submitAddressHandle() {
                let data = {
                    id: this.orderInfo.id,
                    contacts: this.orderInfo.contacts,
                    deliveryOrderNo: this.orderInfo.deliveryOrderNo,
                    freightPrice: this.orderInfo.freightPrice,
                    phone: this.orderInfo.phone,
                    country: this.orderInfo.country,
                    city: this.orderInfo.city,
                    address: this.orderInfo.address,
                    logisticsMode: this.orderInfo.logisticsMode,
                    parcelWeight: this.orderInfo.parcelWeight,
                    postCode: this.orderInfo.postCode,
                    email: this.orderInfo.email,
                    commodityNum: this.orderInfo.commodityNum,
                    companyName: this.orderInfo.companyName,
                    isDelivery: this.orderInfo.isDelivery
                }
                updateDeliveryAddress(data).then(res => {
                    if (res.code == 200) {
                        this.$message({ message: res.msg, type: "success" });
                        this.$emit('orderDetailClose', true)
                    } else {
                        this.$message({ message: res.msg, type: "error" });
                    }
                })
            },
            submitDeliveryHandle() {
                let data = {
                    id: this.orderInfo.id,
                    contacts: this.orderInfo.contacts,
                    deliveryOrderNo: this.orderInfo.deliveryOrderNo,
                    freightPrice: this.orderInfo.freightPrice,
                    phone: this.orderInfo.phone,
                    country: this.orderInfo.country,
                    city: this.orderInfo.city,
                    address: this.orderInfo.address,
                    logisticsMode: this.orderInfo.logisticsMode,
                    parcelWeight: this.orderInfo.parcelWeight,
                    postCode: this.orderInfo.postCode,
                    email: this.orderInfo.email,
                    commodityNum: this.orderInfo.commodityNum,
                    companyName: this.orderInfo.companyName,
                    isDelivery: this.orderInfo.isDelivery
                }
                updateDeliveryInfo(data).then(res => {
                    if (res.code == 200) {
                        this.$message({ message: res.msg, type: "success" });
                        this.$emit('orderDetailClose', true)
                    } else {
                        this.$message({ message: res.msg, type: "error" });
                    }
                })
            },
            handleClose() {
                this.activities = []
                this.dialogVisible = false
            }
        },
        mounted() {
            this.selectOrderSource()
            this.businessPersonalShow()
        },
        activated() { },
    };
</script>


<style scoped>
    ::v-deep .el-table__body-wrapper {
        height: 600px;
        overflow-y: auto;
        scrollbar-width: thin;
        background-color: #F7F6FF;
    }

    ::v-deep .dialogbox .el-dialog__body {
        background: #fff !important;
    }

    .orderdetail_green {
        color: #13af87 !important;
        font-size: calc(18 * 100vw / 1920);
    }

    .orderdetail_page {
        display: flex;
        padding: calc(20 * 100vw / 1920) calc(30 * 100vw / 1920);
    }

    .orderdetail_left {
        width: 70%;
        margin-right: calc(20 * 100vw / 1920);
    }

    .orderdetail_div {
        background-color: #ffffff;
        padding: calc(30 * 100vw / 1920) calc(20 * 100vw / 1920);
        border-radius: 5px;
        margin-bottom: calc(20 * 100vw / 1920);
    }

    .orderdetail_div .orderdetail_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: calc(24 * 100vw / 1920);
        font-weight: bolder;
        margin-bottom: calc(30 * 100vw / 1920);
        color: #585858;
    }

    .orderdetail_div .orderdetail_title_text {
        color: #13af87;
        font-size: calc(18 * 100vw / 1920);
        font-weight: 500;
        cursor: pointer;
    }

    .orderdetail_div .orderdetail_title_text_new {
        color: #13af87;
        font-size: calc(18 * 100vw / 1920);
        font-weight: 500;
    }

    /* 订单详情 */
    .orderdetail_div .orderdetail_no {
        color: #9C9C9C;
        font-size: calc(22 * 100vw / 1920);
        margin-bottom: calc(15 * 100vw / 1920);
    }

    .orderdetail_div .orderdetail_no>span {
        font-size: calc(16 * 100vw / 1920);
        color: #C4C4C4;
        margin-left: calc(20 * 100vw / 1920);
    }

    .orderdetail_child {
        display: flex;
        justify-content: space-between;
        margin-left: calc(30 * 100vw / 1920);
    }

    .orderdetail_child .odc_div {
        width: calc(50% - 2px);
    }

    .odc_div .odc_div_child {
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        padding: calc(20 * 100vw / 1920) calc(24 * 100vw / 1920);
        margin-bottom: 8px;
    }

    .odc_div .odc_div_child .odcdc_title {
        font-size: calc(22 * 100vw / 1920);
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .odc_div .odc_div_child .odcdc_div {
        font-size: calc(16 * 100vw / 1920);
        margin-bottom: 15px;
    }

    .odc_div .odc_div_child .odcdc_title>span {
        font-weight: 600;
        color: #6C6C6C;
    }

    .odc_div .odc_div_child .odcdc_title>span,
    .odc_div .odc_div_child .odcdc_div>span {
        width: calc(95 * 100vw / 1920);
        display: inline-block;
    }

    /* 订单状态 */
    .odc_div .odc_div_child .odcdc_redbtn,
    .odc_div .odc_div_child .odcdc_greenbtn {
        display: flex;
    }

    .odc_div .odc_div_child .odcdc_redbtn {
        margin-bottom: calc(20 * 100vw / 1920);
    }

    .odc_div .odc_div_child .odcdc_redbtn>span {
        color: #F56C6C;
        background: rgba(245, 108, 108, .3);
        width: calc(100 * 100vw / 1920);
        height: calc(36 * 100vw / 1920);
        line-height: calc(36 * 100vw / 1920);
        font-size: calc(19 * 100vw / 1920);
        border-radius: 20px;
        margin-right: 8px;
        text-align: center;
        /* font-weight: 600; */
    }

    .odc_div .odc_div_child .odcdc_greenbtn>span {
        color: #ffffff;
        background: #13af87;
        width: calc(85 * 100vw / 1920);
        height: calc(40 * 100vw / 1920);
        line-height: calc(40 * 100vw / 1920);
        font-size: calc(21 * 100vw / 1920);
        border-radius: 20px;
        margin-right: 8px;
        text-align: center;
    }

    /* 业务员 */
    .odc_div .odc_div_child .odcdc_edit {
        display: flex;
        align-items: center;
    }

    .odc_div .odc_div_child .odcdc_edit .el-input,
    .odc_div .odc_div_child .odcdc_edit>div {
        width: calc(100% - calc(60 * 100vw / 1920));
    }

    .odc_div .odc_div_child .odcdc_edit>span {
        width: calc(60 * 100vw / 1920);
        font-size: calc(18 * 100vw / 1920);
        text-align: right;
    }

    /* 无名 */
    .odc_div .odc_div_child .odcdc_email {
        font-size: calc(26 * 100vw / 1920);
    }

    .odc_div .odc_div_child .odcdc_ip {
        font-size: calc(16 * 100vw / 1920);
        color: #C4C4C4;
        margin-left: calc(20 * 100vw / 1920);
        margin-bottom: calc(30 * 100vw / 1920);
        margin-top: calc(20 * 100vw / 1920);
    }

    .odc_div .odc_div_child .odcdc_pay {
        font-size: calc(24 * 100vw / 1920);
    }

    /* 商品详情 */
    /deep/.orderdetail_div .orderdetail_table .el-table__cell {
        padding: 0;
    }

    .orderdetail_div .orderdetail_table .orderdetail_table_div {
        display: flex;
    }

    .orderdetail_table_div .orderdetail_table_div_img {
        width: calc(100 * 100vw / 1920);
        height: calc(100 * 100vw / 1920);
        margin-right: calc(20 * 100vw / 1920);
    }

    .orderdetail_table_div>span {
        width: calc(100% - calc(150 * 100vw / 1920));
        display: inline-block;
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .orderdetail_right {
        width: 30%;
    }

    /* 配送地址 */
    .odc_div .odc_div_child .odcdc_div_address {
        font-size: calc(20 * 100vw / 1920);
        margin-bottom: 15px;
        display: flex;
        align-items: center;
    }

    .odc_div .odc_div_child .odcdc_div_address>span,
    .odc_div .odc_div_child .odcdc_div_address>label {
        display: inline-block;
    }

    .odc_div .odc_div_child .odcdc_div_address>span {
        font-weight: 600;
        color: #6C6C6C;
        width: calc(95 * 100vw / 1920);
    }

    .odc_div .odc_div_child .odcdc_div_address .el-input,
    .odc_div .odc_div_child .odcdc_div_address>label {
        width: calc(100% - calc(95 * 100vw / 1920));
    }

    /* 配送信息 */
    .orderdetail_div .odc_greydiv {
        background-color: #F6F6F6;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        padding: calc(20 * 100vw / 1920) calc(24 * 100vw / 1920);
        margin-bottom: 8px;
    }

    .odc_greydiv .odcgd_title {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        margin-bottom: calc(20 * 100vw / 1920);
    }

    .odc_greydiv .odcgd_title .odcgd_title_label,
    .odc_greydiv .odcgd_title .el-input {
        /* width: calc(100% - calc(170 * 100vw / 1920)); */
        width: calc(100% - calc(130 * 100vw / 1920));
    }

    .odc_greydiv .odcgd_price,
    .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content {
        font-size: calc(16 * 100vw / 1920);
        color: #C4C4C4;
        margin-bottom: calc(20 * 100vw / 1920);
    }

    .odc_greydiv .odcgd_price {
        display: flex;
        align-items: center;
    }

    .odc_greydiv .odcgd_price>label {
        display: inline-block;
    }

    .odc_greydiv .odcgd_price .odcgd_price_label,
    .odc_greydiv .odcgd_price .el-input {
        width: calc(100% - calc(50 * 100vw / 1920));
    }

    .odc_greydiv .odcgd_baoguo {
        background-color: #ffffff;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        padding: calc(20 * 100vw / 1920) calc(24 * 100vw / 1920);
    }

    .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content {
        margin-top: calc(20 * 100vw / 1920);
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;
    }

    .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content>label,
    .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content .el-input {
        width: calc(100% - calc(255 * 100vw / 1920));
    }

    /* 备注 */
    .orderdetail_div .odcdc_notes {
        background-color: #F6F6F6;
        padding: calc(20 * 100vw / 1920);
    }

    /deep/.orderdetail_div .odcdc_notes .el-input__inner {
        background-color: #F6F6F6;
    }

    .orderdetail_div .odcdc_notes .odcdc_notes_btn {
        color: #ffffff;
        background: #13af87;
        width: calc(80 * 100vw / 1920) !important;
        height: calc(50 * 100vw / 1920);
        line-height: calc(50 * 100vw / 1920);
        font-size: calc(21 * 100vw / 1920);
        border-radius: 8px;
        margin-left: 8px;
        text-align: center !important;
    }

    .orderdetail_div .odcdc_notes .el-input {
        width: calc(100% - calc(88 * 100vw / 1920)) !important;
    }

    @media screen and (max-width: 1080px) {
        .orderdetail_page {
            display: block;
            padding: calc(30 * 100vw / 1080) calc(40 * 100vw / 1080);
        }

        .orderdetail_left {
            width: 100%;
            margin-right: 0;
        }

        .orderdetail_div {
            background-color: #ffffff;
            padding: calc(40 * 100vw / 1080) calc(30 * 100vw / 1080);
            border-radius: 5px;
            margin-bottom: calc(30 * 100vw / 1080);
        }

        .orderdetail_div .orderdetail_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: calc(24 * 100vw / 1920);
            font-weight: bolder;
            margin-bottom: calc(40 * 100vw / 1080);
            color: #585858;
            font-size: 4vw;
        }

        .orderdetail_div .orderdetail_title_text {
            color: #13af87;
            font-size: calc(28 * 100vw / 1080);
            font-weight: 500;
        }

        /* 订单详情 */
        .orderdetail_div .orderdetail_no {
            color: #9C9C9C;
            font-size: calc(32 * 100vw / 1080);
            margin-bottom: calc(25 * 100vw / 1080);
        }

        .orderdetail_div .orderdetail_no>span {
            font-size: calc(26 * 100vw / 1080);
            color: #C4C4C4;
            margin-left: calc(30 * 100vw / 1080);
        }

        .orderdetail_child {
            display: block;
            justify-content: space-between;
            margin-left: 0;
        }

        .orderdetail_child .odc_div {
            width: 100%;
        }

        .odc_div .odc_div_child {
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            padding: calc(30 * 100vw / 1080) calc(34 * 100vw / 1080);
            margin-bottom: 8px;
        }

        .odc_div .odc_div_child .odcdc_title {
            font-size: calc(32 * 100vw / 1080);
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        .odc_div .odc_div_child .odcdc_div {
            font-size: calc(26 * 100vw / 1080);
            margin-bottom: 15px;
        }

        .odc_div .odc_div_child .odcdc_title>span {
            font-weight: 600;
            color: #6C6C6C;
        }

        .odc_div .odc_div_child .odcdc_title>span,
        .odc_div .odc_div_child .odcdc_div>span {
            width: calc(160 * 100vw / 1080);
            display: inline-block;
        }

        /* 订单状态 */
        .odc_div .odc_div_child .odcdc_redbtn,
        .odc_div .odc_div_child .odcdc_greenbtn {
            display: flex;
        }

        .odc_div .odc_div_child .odcdc_redbtn {
            margin-bottom: calc(30 * 100vw / 1080);
        }

        .odc_div .odc_div_child .odcdc_redbtn>span {
            color: #F56C6C;
            background: rgba(245, 108, 108, .3);
            width: calc(160 * 100vw / 1080);
            height: calc(66 * 100vw / 1080);
            line-height: calc(66 * 100vw / 1080);
            font-size: calc(29 * 100vw / 1080);
            border-radius: 20px;
            margin-right: 8px;
            text-align: center;
            /* font-weight: 600; */
        }

        .odc_div .odc_div_child .odcdc_greenbtn>span {
            color: #ffffff;
            background: #13af87;
            width: calc(120 * 100vw / 1080);
            height: calc(70 * 100vw / 1080);
            line-height: calc(70 * 100vw / 1080);
            font-size: calc(31 * 100vw / 1080);
            border-radius: 20px;
            margin-right: 8px;
            text-align: center;
        }

        /* 业务员 */
        .odc_div .odc_div_child .odcdc_edit {
            display: flex;
            align-items: center;
        }

        .odc_div .odc_div_child .odcdc_edit .el-input,
        .odc_div .odc_div_child .odcdc_edit>div {
            width: calc(100% - calc(70 * 100vw / 1080));
        }

        .odc_div .odc_div_child .odcdc_edit>span {
            width: calc(70 * 100vw / 1080);
            font-size: calc(28 * 100vw / 1080);
            text-align: right;
        }

        /* 无名 */
        .odc_div .odc_div_child .odcdc_email {
            font-size: calc(36 * 100vw / 1080);
        }

        .odc_div .odc_div_child .odcdc_ip {
            font-size: calc(26 * 100vw / 1080);
            color: #C4C4C4;
            margin-left: calc(30 * 100vw / 1080);
            margin-bottom: calc(40 * 100vw / 1080);
            margin-top: calc(30 * 100vw / 1080);
        }

        .odc_div .odc_div_child .odcdc_pay {
            font-size: calc(34 * 100vw / 1080);
        }

        /* 商品详情 */
        /deep/.orderdetail_div .orderdetail_table .el-table__cell {
            padding: 0;
        }

        .orderdetail_div .orderdetail_table .orderdetail_table_div {
            display: flex;
        }

        .orderdetail_table_div .orderdetail_table_div_img {
            width: calc(140 * 100vw / 1080);
            height: calc(140 * 100vw / 1080);
            margin-right: calc(30 * 100vw / 1080);
        }

        .orderdetail_table_div>span {
            width: calc(100% - calc(160 * 100vw / 1080));
            display: inline-block;
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .orderdetail_right {
            width: 100%;
        }

        /* 配送地址 */
        .odc_div .odc_div_child .odcdc_div_address {
            font-size: calc(30 * 100vw / 1080);
            margin-bottom: 15px;
            display: flex;
        }

        .odc_div .odc_div_child .odcdc_div_address>span,
        .odc_div .odc_div_child .odcdc_div_address>label {
            display: inline-block;
        }

        .odc_div .odc_div_child .odcdc_div_address>span {
            font-weight: 600;
            color: #6C6C6C;
            width: calc(175 * 100vw / 1080);
        }

        .odc_div .odc_div_child .odcdc_div_address .el-input,
        .odc_div .odc_div_child .odcdc_div_address>label {
            width: calc(100% - calc(175 * 100vw / 1080));
        }

        /* 配送信息 */
        .orderdetail_div .odc_greydiv {
            background-color: #F6F6F6;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            padding: calc(30 * 100vw / 1080) calc(34 * 100vw / 1080);
            margin-bottom: 8px;
        }

        .odc_greydiv .odcgd_title {
            display: flex;
            align-items: center;
            /* justify-content: space-between; */
            margin-bottom: calc(30 * 100vw / 1080);
        }

        .odc_greydiv .odcgd_title .odcgd_title_label,
        .odc_greydiv .odcgd_title .el-input {
            /* width: calc(100% - calc(280 * 100vw / 1080)); */
            width: calc(100% - calc(210 * 100vw / 1080));
        }

        .odc_greydiv .odcgd_price,
        .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content {
            font-size: calc(26 * 100vw / 1080);
            color: #C4C4C4;
            margin-bottom: calc(30 * 100vw / 1080);
        }

        .odc_greydiv .odcgd_price {
            display: flex;
            align-items: center;
        }

        .odc_greydiv .odcgd_price>label {
            display: inline-block;
        }

        .odc_greydiv .odcgd_price .odcgd_price_label,
        .odc_greydiv .odcgd_price .el-input {
            width: calc(100% - calc(80 * 100vw / 1080));
        }

        .odc_greydiv .odcgd_baoguo {
            background-color: #ffffff;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            padding: calc(30 * 100vw / 1080) calc(34 * 100vw / 1080);
        }

        .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content {
            margin-top: calc(30 * 100vw / 1080);
            margin-bottom: 0 !important;
            display: flex;
            align-items: center;
        }

        .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content>label,
        .odc_greydiv .odcgd_baoguo .odcgd_baoguo_content .el-input {
            width: calc(100% - calc(500 * 100vw / 1080)) !important;
        }

        /* 备注 */
        .orderdetail_div .odcdc_notes {
            background-color: #F6F6F6;
            padding: calc(30 * 100vw / 1080);
        }

        /deep/.orderdetail_div .odcdc_notes .el-input__inner {
            background-color: #F6F6F6;
        }

        .orderdetail_div .odcdc_notes .odcdc_notes_btn {
            color: #ffffff;
            background: #13af87;
            width: calc(90 * 100vw / 1080) !important;
            height: calc(60 * 100vw / 1080);
            line-height: calc(60 * 100vw / 1080);
            font-size: calc(31 * 100vw / 1080);
            border-radius: 8px;
            margin-left: 8px;
            text-align: center !important;
        }

        .orderdetail_div .odcdc_notes .el-input {
            width: calc(100% - calc(98 * 100vw / 1080)) !important;
        }
    }
</style>