<template>
  <div id="app">
    <div class="page flex-col">
      <!-- 移动端显示 -->
      <div class="nav-bar_1 flex-col" :style="{'height':noBusiness=='true'||noBusiness == null?'24.97vw':'12.97vw'}">
        <div class="group_choose" v-if="noBusiness == 'true' || noBusiness == null">
          <!-- selectVal -->
          <span :class="selectVal=='Products'?'isactive':''" @click="typeClick('Products')">Products</span>
          <span :class="selectVal=='Manufacturers'?'isactive':''"
            @click="typeClick('Manufacturers')">Manufacturers</span>
        </div>
        <div class="group_1 flex-row main">
          <input class="text_1" placeholder="Search" v-model="form.keyword" />
          <img @click="searchfun" class="icon_1" referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/psd2ufkj7jefd1xy0iz8taebxk821n9ovi096ce988-428c-4d3c-a6d8-399d10bdfca4" />
        </div>
      </div>
      <div class="nav-bar_2 flex-row justify-between">
        <div class="phone_home_img" v-if="phoneHomeImg" @click="homeChooseClick(true)">
          <img class="image_1" referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/psn8au70ezjxep7omhhs6b9qgzapx1bxxo7649388a-6018-4a13-95b4-08e2386b0549" />
        </div>
        <div class="phone_back" @click="goBack" v-else>Back</div>
        <img @click="homeClick(true)" class="image_2" referrerpolicy="no-referrer" src="./assets/img/applogo.png" />
        <div class="block flex-col" v-if="!shareType">
          <div class="image-wrapper flex-row justify-between">
            <!-- <img
              class="icon_1"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/psd2ufkj7jefd1xy0iz8taebxk821n9ovi096ce988-428c-4d3c-a6d8-399d10bdfca4"
            /> -->
            <div></div>
            <img @click="navBarRightClick" class="icon_2" referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/psnx75b2d4itregzq3ajnbernkgjszsles844d0196a-f872-4edb-a646-3a529319e533" />
          </div>
          <div class="phone_nav" v-if="navBarRight"
            :style="{'top':noBusiness=='true'||noBusiness == null?'calc(370 * 100vw / 1080)':'calc(240 * 100vw / 1080)'}">
            <ul class="flex-col justify-between" v-if="noBusiness == 'true' || noBusiness == null">
              <li @click="homeClick(true)">Home</li>
              <li @click="ceramicsClick(item.id)" v-for="(item, index) in commondata" :key="index">
                {{ item.categoryName }}
              </li>
            </ul>
            <ul class="flex-col justify-between" v-else>
              <li @click="businesshomeClick">Home</li>
              <!--<li @click="businessprofileClick">Profile</li>-->
              <li>
                <el-dropdown placement="bottom-start" @command="businessnewsClick">
                  <!-- <span
                    class="el-dropdown-link" @click="businessprofileClick"
                  >
                    Profile 
                  </span> -->
                  <span class="el-dropdown-link">Profile</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="CompanyOverview">Company Overview</el-dropdown-item>
                    <el-dropdown-item command="RatingsReviews">Ratings & Reviews</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li @click="businessfeedbackClick">Feedback</li>
              <li @click="contactUsClick">ContactUs</li>
              <li @click="businessceramicsClick(item.id, false)" v-for="(item, index) in businessNav" :key="index">
                {{ item.categoryName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- pc端 -->
      <!-- <div class="box_1" v-if="userType === 1 || noBusiness">
        <div class="flex-row justify-between main">
          <nav class="left flex-row">
            <div @click="privacyClick(false)">
              Support&nbsp;&nbsp;&nbsp;&nbsp;|
            </div>
            <div @click="privacyClick(false)">
              &nbsp;&nbsp;&nbsp;&nbsp;Privacy&nbsp;&nbsp;&nbsp;&nbsp;|
            </div>
            <div @click="residenceClick(false)" class="merchantSettlement">
              &nbsp;&nbsp;&nbsp;&nbsp;Merchant&nbsp;settlement
            </div>
          </nav>
          <nav class="right flex-row" v-if="loginshow">
            <div class="right_1" @click="loginClick(false)">Login</div>
            <div class="right_2" @click="registerClick(false)">Register</div>
          </nav>
          <div class="right_else" v-else>
            <span @click="personalcenterClick">Hi, {{ userName }} !</span
            >&nbsp;&nbsp;&nbsp;&nbsp;<span
              style="cursor: pointer"
              @click="exitLogin()"
              >exit</span
            >
          </div>
        </div>
      </div> -->
      <!-- 进入商家页面显示 -->
      <!-- <div class="box_1" v-else>
        <div class="flex-row justify-between main business_box1">
          <div>Welcome to my store !</div>
          <nav class="right flex-row" v-if="loginshow">
            <div class="right_1" @click="loginClick(false)">Login</div>
            <div class="right_2" @click="registerClick(false)">Register</div>
          </nav>
          <div style="display: flex" v-else>
            <div @click="businesspersonalClick" v-if="userType == 2">
              Personal Center &gt;
            </div>
            <div v-else @click="businesspersonalClick">
              Hi, {{ userName }} !
            </div>
            <div style="margin-left: 10px" @click="exitLogin()">exit</div>
          </div>
        </div>
      </div> -->

      <div class="box_1" v-if="!shareType">
        <div class="flex-row justify-between main">
          <nav class="left flex-row" v-if="noBusiness == 'true' || noBusiness == null">
            <div>
              <span @click="helpCenterClick('c')">Help center</span>
              <!-- <el-dropdown @command="helpCenterClick" placement="bottom-start">
                <div>
                  Help center<i class="el-icon-arrow-down el-icon--right"></i
                  >&nbsp;&nbsp;|
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a"
                    >Buyer Help center</el-dropdown-item
                  >
                  <el-dropdown-item command="b"
                    >Supplier Help center</el-dropdown-item
                  >
                  <el-dropdown-item command="c"
                    >Article List</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
            <div @click="privacyClick(false)">
              &nbsp;&nbsp;&nbsp;&nbsp;Privacy&nbsp;&nbsp;&nbsp;&nbsp;|
            </div>
            <div @click="residenceClick(false)" class="merchantSettlement">
              &nbsp;&nbsp;&nbsp;&nbsp;Supplier to join
            </div>
          </nav>
          <div class="left flex-row" v-else>
            <div>Welcome to my store !</div>
          </div>

          <div class="right_else" v-if="userType == 1 && userName">
            <span class="showRed">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  Notice<i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="clearfix" @click.native="viewCartClick">
                    Shopping Cart
                    <!-- <el-badge class="mark" :value="deliveryOrderCount" /> -->
                  </el-dropdown-item>
                  <el-dropdown-item class="clearfix" v-if="deliveryOrderCount"
                    @click.native="xfzOrderClick($event, false)">
                    Shipped order
                    <el-badge class="mark" :value="deliveryOrderCount" />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <span @click="personalcenterClick">Hi, {{ userName }} !</span><span
              style="cursor: pointer; margin-left: 10px" @click="exitLogin()">exit</span>
          </div>
          <div class="right_else" v-else-if="userType == 2 && userName">
            <span class="showRed" v-if="enquiriesCount || feedbackCount || orderDeliveryCount || promotionApplyCount">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  通知信息<i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="clearfix" v-if="enquiriesCount" @click.native="fxpclick()">
                    待审核询盘
                    <el-badge class="mark" :value="enquiriesCount" />
                  </el-dropdown-item>
                  <el-dropdown-item class="clearfix" v-if="feedbackCount" @click.native="feedbackClick($event, false)">
                    未读反馈
                    <el-badge class="mark" :value="feedbackCount" />
                  </el-dropdown-item>
                  <el-dropdown-item class="clearfix" v-if="orderDeliveryCount"
                    @click.native="orderClick($event, false)">
                    待发货订单
                    <el-badge class="mark" :value="orderDeliveryCount" />
                  </el-dropdown-item>
                  <el-dropdown-item class="clearfix" v-if="promotionApplyCount"
                    @click.native="promotionClick($event, false)">
                    待审核分销
                    <el-badge class="mark" :value="promotionApplyCount" />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <span @click="businesspersonalClick">Hi, {{ userName }} !</span>
            <span style="cursor: pointer; margin-left: 10px" @click="exitLogin()">
              exit
            </span>
          </div>
          <nav class="right flex-row" v-else>
            <div class="right_1" @click="loginClick(false)">Login</div>
            <div class="right_2" @click="registerClick(false)">
              Register for buyer
            </div>
          </nav>
        </div>
      </div>

      <div class="box_2">
        <div class="flex-row justify-between align-center main">
          <div class="left" @click="homeClick(true)">
            <img class="image_1" referrerpolicy="no-referrer" src="./assets/img/applogo.png" />
            <!-- E-touch makes it easier for Boss to Boss! -->
          </div>
          <div class="right" v-if="!shareType">

            <el-input placeholder="Search keywords ..." v-model="form.keyword" type="search" class="box_input"
              v-if="noBusiness == 'true' || noBusiness == null">
              <el-select v-model="selectVal" slot="append" placeholder="请选择">
                <el-option label="Products" value="Products"></el-option>
                <el-option label="Manufacturers" value="Manufacturers"></el-option>
              </el-select>
            </el-input>
            <input type="search" v-model="form.keyword" placeholder="Search keywords ..." v-else />
            <button @click="searchfun">
              <img class="thumbnail_1" referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/pswvfbw4rj03mwtd8pe9myjo7nmkgnok0o4fbdee42-9b9e-44c9-b9a4-fd0916fefc3f" />
            </button>
          </div>
        </div>
      </div>
      <div class="box_3" v-if="noBusiness == 'true' || noBusiness == null">
        <nav class="main">
          <ul class="flex-row justify-between gundong">
            <li @click="homeClick(true)">Home</li>
            <!-- <li @click="ceramicsClick(false)">Ceramics</li>
            <li @click="ceramicsClick(false)">Clothe</li>
            <li @click="ceramicsClick(false)">Lifestyle</li>
            <li @click="ceramicsClick(false)">Electrical</li>
            <li @click="ceramicsClick(false)">Others</li> -->
            <!-- <li
              @click="ceramicsClick(item.id)"
              v-for="(item, index) in commondata"
              :key="index"
            >
              {{ item.categoryName }}
            </li> -->
            <li v-for="(item, index) in commondata" :key="index">
              <el-dropdown @command="ceramicsClick" placement="bottom-start">
                <span class="el-dropdown-link" @click="ceramicsClick(item.id)">
                  {{ item.categoryName
                  }}<i class="el-icon-arrow-down el-icon--right" v-if="item.children && item.children.length > 0"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item2, index2) in item.children" :key="index2" :command="item2.id">
                    <!-- {{ item2.categoryName }} -->
                    <el-dropdown @command="ceramicsClick" placement="bottom-start">
                      <span class="el-dropdown-link" @click="ceramicsClick(item2.id)" id="linkWhite1">
                        {{ item2.categoryName
                        }}<i class="el-icon-arrow-down el-icon--right"
                          v-if="item2.children && item2.children.length > 0"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item3, index3) in item2.children" :key="index3" :command="item3.id">
                          {{ item3.categoryName }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </nav>
      </div>
      <div class="business_box_3" v-else>
        <!-- <div class="business_img" v-if="show"> -->
        <!-- <img :src="this.$baseUrl + bannerTop" alt="" v-if="bannerTop" /> -->
        <!-- <img src="../src/assets/img/图层24.png" alt="" v-else /> -->
        <!-- <span>{{businessTopLog[0]}}</span> -->
        <!-- </div> -->
        <div class="business_topimg" v-if="show">
          <img style="height: 200px;width: auto;"
            :src="this.$baseUrl + (businessTopLog&&businessTopLog.length>0?businessTopLog[0].image:'')"
            class="bg_img" />
          <div class="business_topimg_content">
            <div class="business_topimg_content_left" style="text-align: left;margin-left: calc(180* 100vw / 1920);">
              <!-- <div class="business_topimg_content_left_img">
                <img :src="this.$baseUrl + businessInfo.logoImage" v-if="businessInfo.logoImage" />
              </div> -->
              <div class="business_topimg_content_left_details">
                <p style="font-size:15px;font-weight:600;">
                  <span style="cursor: pointer;"
                    @click="businesshomeClick">{{businessInfo.businessName?businessInfo.businessName:''}}</span>
                </p>
                <p style="font-size:13px;">
                <p v-if="businessInfo.producer || businessInfo.establishment" class="maxDis">
                  <img src="../src/assets/img/location.png"
                    v-if="businessInfo.producer || businessInfo.establishment" />
                  <span>{{businessInfo.producer?businessInfo.producer:''}}</span>
                  <span>{{businessInfo.establishment?businessInfo.establishment:''}}</span>
                </p>
                <p v-if="businessInfo.phone" class="maxDis">
                  <img src="../src/assets/img/phone.png" v-if="businessInfo.phone" />
                  {{businessInfo.phone?businessInfo.phone:''}}
                </p>
                <p v-if="businessInfo.wxAccount" class="maxDis">
                  <img src="../src/assets/img/wechat1.png" v-if="businessInfo.wxAccount" />
                  {{businessInfo.wxAccount?businessInfo.wxAccount:''}}
                </p>
                <p v-if="businessInfo.whatsapp" class="maxDis">
                  <img src="../src/assets/img/telephone.png" v-if="businessInfo.whatsapp" />
                  {{businessInfo.whatsapp?businessInfo.whatsapp:''}}
                  <label></label>
                </p>
                <p v-if="businessInfo.email" class="maxDis">
                  <img src="../src/assets/img/youxiang.png" v-if="businessInfo.email" />
                  {{businessInfo.email?businessInfo.email:''}}
                </p>
                </p>
                <div v-if="businessInfo.address"
                  style="font-size:12px;display:flex;align-items: center;line-height:16px;">
                  <img src="../src/assets/img/location.png" v-if="businessInfo.address" />
                  {{businessInfo.address?businessInfo.address:''}}
                </div>
              </div>
            </div>
            <div class="business_topimg_content_right"
              style="position: relative;left: calc(-400* 100vw / 1920);text-align: center;top: calc(15* 100vw / 1080);">
              <img :src="this.$baseUrl + businessInfo.codeImage" v-if="businessInfo.codeImage" />
              <p class="maxDis" style="margin-top: calc(20* 100vw / 1080);">
                <span
                  v-if="businessInfo.comprehensiveScore&&businessInfo.comprehensiveScore>0">{{businessInfo.comprehensiveScore}}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 供应商顶部图片-->
        <!-- :style="{ backgroundImage: 'url(' + this.$baseUrl + businessInfo.image + ')'}" -->
        <div class="business_topimg"
          :style="{'background-color':businessTopLog&&businessTopLog.length>0&&businessTopLog[0].image?'':'#CCC'}"
          v-else>
          <!-- <img :src="this.$baseUrl + businessInfo.image" class="bg_img" /> -->
          <img :src="this.$baseUrl + (businessTopLog&&businessTopLog.length>0?businessTopLog[0].image:'')"
            class="bg_img" />
          <div class="business_topimg_content">
            <div class="business_topimg_content_left">
              <div class="business_topimg_content_left_img">
                <img :src="this.$baseUrl + businessInfo.logoImage" v-if="businessInfo.logoImage" />
              </div>
              <div class="business_topimg_content_left_details">
                <p style="font-size:15px;font-weight:600;">
                  <span style="cursor: pointer;"
                    @click="businesshomeClick">{{businessInfo.businessName?businessInfo.businessName:''}}</span>
                </p>
                <p style="font-size:13px;">
                  <img src="../src/assets/img/phone.png" v-if="businessInfo.phone" />
                  {{businessInfo.phone?businessInfo.phone:''}}
                  <span v-if="businessInfo.phone">/</span>
                  <img src="../src/assets/img/wechat1.png" v-if="businessInfo.wxAccount" />
                  {{businessInfo.wxAccount?businessInfo.wxAccount:''}}
                  <span v-if="businessInfo.wxAccount">/</span>
                  <img src="../src/assets/img/telephone.png" v-if="businessInfo.whatsapp" />
                  <!-- whatsapp -->
                  {{businessInfo.whatsapp?businessInfo.whatsapp:''}}
                  <label></label>
                  <img src="../src/assets/img/youxiang.png" v-if="businessInfo.email" />
                  {{businessInfo.email?businessInfo.email:''}}
                  <!-- <label></label> -->
                  <span
                    v-if="businessInfo.comprehensiveScore&&businessInfo.comprehensiveScore>0">{{businessInfo.comprehensiveScore}}</span>
                  <span>{{businessInfo.producer?businessInfo.producer:''}}</span>
                  <span>{{businessInfo.establishment?businessInfo.establishment:''}}</span>
                </p>
                <div style="font-size:12px;display:flex;align-items: center;line-height:16px;">
                  <!-- <img src="../src/assets/img/phone.png" v-if="businessInfo.phone" />
                  {{businessInfo.phone?businessInfo.phone:''}}
                  <span v-if="businessInfo.phone">/</span>
                  <img src="../src/assets/img/wechat1.png" v-if="businessInfo.wxAccount" />
                  {{businessInfo.wxAccount?businessInfo.wxAccount:''}}
                  <span v-if="businessInfo.wxAccount">/</span>
                  <img src="../src/assets/img/telephone.png" v-if="businessInfo.phone" />
                  {{businessInfo.phone?businessInfo.phone:''}}
                  <label></label>
                  <img src="../src/assets/img/youxiang.png" v-if="businessInfo.email" />
                  {{businessInfo.email?businessInfo.email:''}}
                  <label></label> -->
                  <img src="../src/assets/img/location.png" v-if="businessInfo.address" />
                  {{businessInfo.address?businessInfo.address:''}}
                </div>
              </div>
            </div>
            <div class="business_topimg_content_right">
              <img :src="this.$baseUrl + businessInfo.codeImage" v-if="businessInfo.codeImage" />
            </div>
          </div>
        </div>
        <nav class="main" style="height: 4vw" v-if="!shareType">
          <ul class="flex-row justify-between gundong">
            <li @click="businesshomeClick">Home</li>
            <li v-for="(item, index) in businessNav" :key="index">
              <el-dropdown @command="businessceramicsClick" placement="bottom-start">
                <span class="el-dropdown-link" @click="businessceramicsClick(item.id, false)">
                  {{ item.categoryName }} </span><i class="el-icon-arrow-down el-icon--right"
                  v-if="item.children && item.children.length > 0"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item2, index2) in item.children" :key="index2" :command="item2.id">
                    <el-dropdown @command="businessceramicsClick" placement="bottom-start">
                      <span class="el-dropdown-link" @click="businessceramicsClick(item2.id)" id="linkWhite">
                        {{ item2.categoryName
                        }}<i class="el-icon-arrow-down el-icon--right"
                          v-if="item2.children && item2.children.length > 0"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" v-if="item2.children">
                        <el-dropdown-item v-for="(item3, index3) in item2.children" :key="index3" :command="item3.id">
                          {{ item3.categoryName }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <!--<li @click="businessprofileClick">Profile</li>-->
            <li>
              <el-dropdown placement="bottom-start" @command="businessnewsClick">
                <!-- <span
                  class="el-dropdown-link" @click="businessprofileClick"
                >
                  Profile 
                </span> -->
                <span class="el-dropdown-link">Profile</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="CompanyOverview">Company Overview</el-dropdown-item>
                  <el-dropdown-item command="RatingsReviews">Ratings & Reviews</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li @click="businessfeedbackClick">Feedback</li>
            <li @click="contactUsClick">ContactUs</li>
            <!-- <li class="business_box3_ceramics" @click="businessceramicsClick">
              Ceramics1
            </li>
            <li>Ceramics2</li>
            <li>Ceramics3</li>
            <li class="business_box3_ceramics">Ceramics4</li>
            <li>Ceramics5</li> -->
            <!-- <li
              @click="businessceramicsClick(item.id, false)"
              v-for="(item, index) in businessNav"
              :key="index"
            >
              {{ item.categoryName }}
            </li> -->
          </ul>
        </nav>
      </div>

      <keep-alive include="Ceramics,PersonalCenter,FineCeramics,BusinessPersonal">
        <router-view></router-view></keep-alive>
      <div class="box_8" v-if="(noBusiness == 'true' || noBusiness == null) && box8">
        <div class="conent justify-between main">
          <div class="left">
            <span>Buyer join for free</span>
            <div class="email flex-row">
              <div class="img">
                <img src="../src/assets/img/邮件拷贝.png" alt="" />
              </div>
              <input v-model="registerUser.email" type="text" placeholder="Please fill in your email address" />
            </div>
            <div class="code flex-row justify-between">
              <input v-model="registerUser.code" type="text" placeholder="Fill in the verification code" />
              <button @click="sendEmailCodeApi">Verify&nbsp;Mailbox</button>
            </div>
            <div class="email flex-row">
              <div class="img">
                <img src="../src/assets/img/密码.png" alt="" />
              </div>
              <input v-model="registerUser.password" type="password" placeholder="Set Password" />
            </div>
            <div class="register">
              <button @click="registerEmailApi">Buyer Register Now !</button>
            </div>
          </div>
          <div class="mid">
            <span>CONTACT&nbsp;INFO</span>
            <div class="message flex-col justify-between">
              <div class="address">
                <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/ps6scfsp2rxl4oc0eo3eyygdp3e7u2vnxtd3ee886c-ba50-4047-bf46-557b93ae6aa0" />
                <span class="text1">Address:</span>
                <!-- <span class="text2"
                  >Office:&nbsp;5/F.,&nbsp;ChaoShan&nbsp;Bldg.,1036&nbsp;Dongmen&nbsp;Rd,&nbsp;S.,&nbsp;<br />Shenzhen</span
                > -->
                <span class="text2">{{ contactInfo.address }}</span>
              </div>
              <div class="phone">
                <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/psyxernbqvyltnb5354a0oydrt2ebwfve372643b-8be4-4b8a-a61e-7351f7eb5e8e" />
                <span class="text1">Phone/Wechat:</span>
                <!-- <span class="text2">4001234567</span> -->
                <span class="text2">{{ contactInfo.phone }}</span>
              </div>
              <div class="phone">
                <div style="display: flex; margin-top: 0.25vw; align-items: center">
                  <img src="@/assets/img/QQ.png" alt="" class="qq" />
                  <span class="text1">QQ:</span>
                </div>
                <!-- <span class="text2">4001234567</span> -->
                <span class="text2">{{ contactInfo.qq }}</span>
              </div>
              <div class="email">
                <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/pspfwlik28j3qtu99zxjcm88ij9des04ofdd90824-97d4-439b-ae0b-7eb5cebde1a4" />
                <span class="text1">Email:</span>
                <!-- <span class="text2">123456&#64;123.com</span> -->
                <span class="text2">{{ contactInfo.email }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <span class="span1">CONTACT&nbsp;US</span>
            <span class="span3" @click="aboutUsClick(false)">
              <!-- <router-link
                :to="{ path: '/companyprofile', query: { bool: false } }"
                >About&nbsp;Us</router-link
              > -->
              <div>About&nbsp;Us</div>
              <button>></button>
            </span>
            <!-- <span class="span2">
              <div>Contact&nbsp;Us</div>
              <button>></button>
            </span> -->

            <span class="span3" @click="helpClick()">
              <div>Buyer Help center</div>
              <button>></button>
            </span>
            <span class="span3" @click="merchantHelpClick()">
              <div>Supplier Help center</div>
              <button>></button>
            </span>
            <span class="span3 span4" @click="helpCenterClick('c')">
              <div>Article help Center</div>
              <button>></button>
            </span>
            <span class="span3" @click="quotationsClick()">
              <div>Want to get quotations?</div>
              <button>></button>
            </span>
            <button class="button" @click="residenceClick(false)">
              供应商免费注册
            </button>
          </div>
          <div class="right2">
            <span class="span1">FOLLOW&nbsp;US</span>
            <span class="span3 right2Height">
              <div class="buttom_share_loge">
                <div class="shareDiv" v-for="(item,index) in indexBottomNavigation" :key="index">
                  <img :src="$baseUrl+item.navigationImage" @click="buttomOpenNewPage(item.navigationUrl)" alt="" />
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="box_9">
        <img src="@/assets/img/homeLogo.png" referrerpolicy="no-referrer" alt="" class="logo-img" />
        <span>©&nbsp;2022&nbsp;E-Touch&nbsp;All&nbsp;rights&nbsp;reserved</span>
        <!-- <img
          src="@/assets/img/logo1.png"
          referrerpolicy="no-referrer"
          @click="shareEmail"
          alt=""
          class="logo-img"
        />
        <img
          @click="facebookClick"
          class="logo-img"
          referrerpolicy="no-referrer"
          src="@/assets/img/logo3.png"
        />
        <img
          @click="tuiteClick"
          class="logo-img"
          referrerpolicy="no-referrer"
          src="@/assets/img/logo2.png"
        />
        <img
          src="@/assets/img/logo4.png"
          class="logo-img"
          referrerpolicy="no-referrer"
          @click="shareLinkedin"
          alt=""
        />
        <img
          src="@/assets/img/logo5.png"
          class="logo-img"
          referrerpolicy="no-referrer"
          @click="shareWhatsApp"
          alt=""
        /> -->
        <!-- <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/psq5magvgxv6hs0vxpfbh9n9zryl0dgcdc627b56-dc9f-4eb9-8613-1dc713c9079f"
        />
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/pslaajxtugwd420n9srabzlihuu3vf5f3p5de1c5ec-e06c-40a1-8e18-56d3fd552b4c"
        /> -->
      </div>
      <!-- 移动端 -->
      <div class="bottomnav flex-row" v-if="userType == 1 || noBusiness == 'true' || noBusiness == null">
        <div class="bottomnav_home" @click="bottomnavClick($event, '/', true)">
          <div class="iconfont icon-home"></div>
          <div class="bottomnav-text">Home</div>
        </div>
        <div class="bottomnav_classify" @click="bottomnavClick($event, '/ceramics', false)">
          <div class="iconfont icon-classify"></div>
          <div class="bottomnav-text">CLASSIFY</div>
        </div>
        <div class="bottomnav_about" @click="bottomnavClick($event, '/companyprofile', false)">
          <div class="iconfont icon-about"></div>
          <div class="bottomnav-text">ABOUT</div>
        </div>
        <div class="bottomnav_me" @click="bottomnavMeClick($event, personalCenterPath, false)">
          <div class="iconfont icon-me"></div>
          <div class="bottomnav-text">ME</div>
        </div>
      </div>
      <div class="bottomnav flex-row" v-else>
        <div class="bottomnav_home" @click="businesshomeClick">
          <div class="iconfont icon-home"></div>
          <div class="bottomnav-text">Home</div>
        </div>
        <div class="bottomnav_classify" @click="businessceramicsClick(businessId, true)">
          <div class="iconfont icon-chanpin"></div>
          <div class="bottomnav-text">PRODUCTS</div>
        </div>
        <div class="bottomnav_about" @click="businessprofileClick">
          <div class="iconfont icon-about"></div>
          <div class="bottomnav-text">ABOUT</div>
        </div>
        <div class="bottomnav_me" @click="bottomnavMeClick($event, personalCenterPath, false)">
          <div class="iconfont icon-me"></div>
          <div class="bottomnav-text">ME</div>
        </div>
      </div>
      <!-- 弹框 -->
      <!-- <div class="frame">
        <div class="frame_box flex-col">
          <h1>Add to Cart</h1>
          <p>This item has been successfully added to the shopping cart !</p>
          <button @click="okButtonClick">OK</button>
          <button @click="viewCartClick">View Cart</button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  // import { Swiper, SwiperItem } from "./components/swiper";
  // import e from "express";
  // import businessNav from "@/components/public/index";
  import "../node_modules/vue-social-sharing/dist/vue-social-sharing";
  import {
    getcontactInformation,
    keywordSearchApi,
    getbannerList,
    getcontactUs,
    getIndexBottomNavigation,
  } from "../src/api/other";

  import { getcommonCategory, getconsumerCommodityDetail } from "../src/api/index";
  import {
    getCookie,
    getUserType,
    removeCookie,
    removeUserType,
  } from "./utils/auth";
  import {
    shopMyInfo,
    shopCategoryListApi,
    businessIntroduction,
  } from "../src/api/business";
  import { getmyInfo } from "../src/api/index";
  import { getCode, registerEmail } from "@/api/login";
  import { getKeywordSearchManufacturers, getBusinessAppraiseList, getSeywordSearch } from "@/api/news";
  export default {
    data() {
      return {
        loginshow: false, //判断展示登录注册按钮
        // 事件参数
        goback: false, // 决定是否点击了回退

        // 路由参数
        noBusiness: true, // 决定是否为商家页面
        bool: false, // 决定box1右侧显示
        box8: true, // 决定box8显示
        phoneHomeImg: true, // 决定移动端状态下，nav-bar_2左侧显示Back
        personalCenterPath: "/personalcenter/essential", // personalcenter页面路径
        businessHomePath: "/", // 控制商家页面路径的变量
        businesspersonalPath: "/businesspersonal/businessessential", // businesspersonal页面路径

        token: false, // 登录状态
        // 页面参数
        urlArray: [], // 路由历史记录
        contactInfo: {}, //联系方式
        form: {
          keyword: "", //关键字
          pageNum: 1,
          pageSize: 8
        },
        selectVal: 'Products',
        userName: null,
        userType: null,
        commondata: [],
        registerUser: {
          email: null,
          password: null,
          code: null,
        },
        businessId: "",
        businessNav: [], // 商家顶部导航栏
        businessTopLog: [], // 商家广告
        navBarRight: false, // 移动端nav_Bar_2右端展开
        bannerimages: [],
        bannerTop: "",
        customerServiceHotlink: "",
        indexBottomNavigation: {},
        share_id: "",
        share_url: "www.horamite.com",
        share_title: "horamite",
        share_img: "",
        show: false, //pc端显示自定义顶部布局
        shareType: 0,
        businessInfo: {},

        //供应商
        enquiriesCount: null,//未审核询盘数量
        feedbackCount: null,//未读反馈数量
        orderDeliveryCount: null,//已支付待发货的订单数量
        promotionApplyCount: null,//待审核中的分销数量
        //消费者
        deliveryOrderCount: null,//待审核中的分销数量

        showParent: false,
        showName1: null,
        showName2: null,

      };
    },
    created() {
      // this.getLoginUserInfo();
      this.getcommonCategory();
      this.getShopCommonCategory();
      this.$bus.$on("search", (res) => {
        if (res) {
          this.form.pageNum = res.num;
          this.form.keyword = res.value;
          this.searchfun();
        }
      });
      this.$bus.$on("nav", (res) => {
        if (res) {
          this.businessNav = res;
        }
      });
      this.$bus.$on("username", (res) => {
        if (res) {
          this.userName = res;
        }
      });
      this.$bus.$on("deliveryOrderCount", (res) => {
        if (res) {
          this.deliveryOrderCount = res;
        }
      });
      this.$bus.$on("enquiriesCount", (res) => {
        if (res) {
          this.enquiriesCount = res;
        }
      });
      this.$bus.$on("feedbackCount", (res) => {
        if (res) {
          this.feedbackCount = res;
        }
      });
      this.$bus.$on("orderDeliveryCount", (res) => {
        if (res) {
          this.orderDeliveryCount = res;
        }
      });
      this.$bus.$on("promotionApplyCount", (res) => {
        if (res) {
          this.promotionApplyCount = res;
        }
      });
      this.$bus.$on("image", (res) => {
        if (res) {
          this.bannerTop = res;
        }
      });
      if (document.documentElement.clientWidth > 1080) {
        this.show = false
      } else {
        this.show = true
      }
      // this.shopMyInfo();
      // this.businessIntroduction(null, null);
      console.log(this.$route.fullPath)
      if (this.$route.fullPath.indexOf('/ceramics/finePromotionCeramics') > -1) {
        sessionStorage.setItem('share', 1)
        this.shareType = 1
      } else {
        sessionStorage.setItem('share', 0)
        this.shareType = 0
      }
    },
    methods: {
      //消费者跳转到订单
      xfzOrderClick(e, bool) {
        this.$router.push({
          path: "/personalcenter/order",
          query: {
            bool,
            personalCenterPath: "/personalcenter/order",
            box8: false,
            phoneHomeImg: false,
            // personalCenterPath: "/personalcenter/order"
          }
        })
        this.personalCenterShow()
      },
      // 决定pc端与移动端的显示
      personalCenterShow() {
        // 刷新时判断
        if (document.documentElement.clientWidth > 1080) {
          document.querySelector(".personalcenter_nav").style.display = "block"
          document.querySelector(".personalcenter_view").style.display = "block"
        } else {
          if (this.$route.query.phoneHomeImg === "false") {
            document.querySelector(".personalcenter_nav").style.display = "none"
            document.querySelector(".personalcenter_view").style.display = "block"
            let bottomnav = document.querySelector(".bottomnav")
            for (let i = 0; i < bottomnav.children.length; i++) {
              bottomnav.children[i].style.color = "#aaa"
            }
          } else {
            document.querySelector(".personalcenter_view").style.display = "none"
            document.querySelector(".personalcenter_nav").style.display = "block"
          }
        }
      },
      //跳转到询盘
      fxpclick() {
        this.$router.push({
          path: "/inquirycenter",
          query: {
            bool: false,
            phoneHomeImg: false,
            box8: false,
            noBusiness: false,
            home: true,
          },
        });
      },
      // 跳转到未读反馈
      feedbackClick(e, noBusiness) {
        // this.activeClick(e);
        this.$router.push({
          path: "/businesspersonal/businessfeedback",
          query: {
            noBusiness,
            pageType: 'list',
            phoneHomeImg: false,
            businesspersonalPath: "/businesspersonal/businessfeedback",
          },
        });
        this.businessPersonalShow();
      },
      //跳转到待发货订单
      orderClick(e, noBusiness) {
        this.$router.push({
          path: "/businesspersonal/businessorder",
          query: {
            noBusiness,
            businesspersonalPath: "/businesspersonal/businessorder",
            phoneHomeImg: false,
          },
        });
        this.businessPersonalShow();
      },
      // 跳转到推广人员
      promotionClick(e, noBusiness) {
        this.$router.push({
          path: "/businesspersonal/businesspromotion",
          query: {
            noBusiness,
            pageType: 'list',
            phoneHomeImg: false,
            businesspersonalPath: "/businesspersonal/businesspromotion",
          },
        });
        this.businessPersonalShow();
      },
      // 决定pc端与移动端的显示
      businessPersonalShow() {
        // 刷新时判断
        if (document.documentElement.clientWidth > 1080) {
          document.querySelector(".businesspersonal_nav").style.display = "block";
          document.querySelector(".businesspersonal_view").style.display =
            "block";
        } else {
          if (this.$route.query.phoneHomeImg === "false") {
            document.querySelector(".businesspersonal_nav").style.display =
              "none";
            document.querySelector(".businesspersonal_view").style.display =
              "block";
          } else {
            document.querySelector(".businesspersonal_view").style.display =
              "none";
            document.querySelector(".businesspersonal_nav").style.display =
              "block";
          }
        }
      },
      typeClick(val) {
        this.selectVal = val
      },
      helpCenterClick(command) {
        if (command == "a") {
          this.helpClick();
        } else if (command == "b") {
          this.merchantHelpClick();
        } else {
          this.articleListClick()
        }
      },
      merchantHelpClick() {
        this.$router.push({
          path: "/merchanthelp",
          query: {
            bool: false,
            phoneHomeImg: false,
            box8: false,
            noBusiness: true,
            home: true,
          },
        });
      },
      articleListClick() {
        this.$router.push({
          path: "/articlelist",
          query: {
            bool: false,
            phoneHomeImg: false,
            box8: false,
            noBusiness: true,
            home: true,
          },
        });
      },
      quotationsClick() {
        this.$router.push({
          path: "/quotations",
          query: {
            bool: false,
            phoneHomeImg: false,
            box8: false,
            noBusiness: true,
            home: true,
          },
        });
      },
      scrollInit() {
        // 获取要绑定事件的元素
        const nav = document.getElementsByClassName("gundong")[0];
        var flag; // 鼠标按下
        var downX; // 鼠标点击的x下标
        var scrollLeft; // 当前元素滚动条的偏移量
        nav.addEventListener("mousedown", function (event) {
          flag = true;
          downX = event.clientX; // 获取到点击的x下标
          scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
        });
        nav.addEventListener("mousemove", function (event) {
          if (flag) {
            // 判断是否是鼠标按下滚动元素区域
            var moveX = event.clientX; // 获取移动的x轴
            var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
            this.scrollLeft = scrollLeft - scrollX; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
          }
        });
        // 鼠标抬起停止拖动
        nav.addEventListener("mouseup", function () {
          flag = false;
        });
        // 鼠标离开元素停止拖动
        nav.addEventListener("mouseleave", function (event) {
          flag = false;
        });
      },
      businessIntroduction(info, businessId) {
        let bId = businessId && businessId != null ? businessId : this.$route.query.businessId ? this.$route.query.businessId : sessionStorage.getItem("businessId")
        businessIntroduction(bId).then(res => {
          if (res.code == 200) {
            // let obj=Object.assign({...info,...res.data})
            // res.data.businessName = info.businessName
            this.getBusinessAppraiseList(res.data)
            // this.bannerTop=res.data.image
          }
        })
      },
      getBusinessAppraiseList(info) {
        let data = {
          pageTotal: 0,
          pageNum: 1,
          pageSize: 1,
          isPage: 1,
          businessId: this.$route.query.businessId ? this.$route.query.businessId : sessionStorage.getItem("businessId")
        }
        getBusinessAppraiseList(data).then(res => {
          if (res.code == 200) {
            info.comprehensiveScore = res.data.comprehensiveScore ? res.data.comprehensiveScore.toFixed(1) : 0
            this.businessInfo = info
            console.log('商家详情信息：', info)
          }
        })
      },
      isWeixinBrowser() {
        let ua = navigator.userAgent.toLowerCase();
        return /micromessenger/.test(ua) ? true : false;
      },
      facebookClick() {
        function popupwindow(url, title) {
          return window.open(
            "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
            //  +
            // +"&t=" +
            // encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      tuiteClick() {
        function popupwindow(url, title) {
          return window.open(
            "https://twitter.com/intent/tweet?url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      shareEmail() {
        const subject = this.share_title;
        const body = `http://www.etouch-b2b.com`;
        window.open(
          `mailto:?subject=${subject}&body=` + encodeURIComponent(body)
        );
      },
      shareLinkedin() {
        function popupwindow(url, title) {
          return window.open(
            "https://www.linkedin.com/shareArticle?mini=true" +
            "&url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      shareWhatsApp() {
        function popupwindow(url, title) {
          return window.open(
            "https://api.whatsapp.com/send?url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      contactWe() {
        getcontactUs().then((res) => {
          if (res.code == 200) {
            this.customerServiceHotlink = res.data.customerServiceHotlink;
          }
        });
      },
      getIndexBottomNavigation() {
        getIndexBottomNavigation().then((res) => {
          if (res.code == 200) {
            this.indexBottomNavigation = res.data;
          }
        });
      },
      buttomOpenNewPage(url) {
        if (window.innerWidth <= 1080) {
          window.open(url);
        } else {
          var leftPx = (window.innerWidth - 500) / 2;
          var topPx = (window.innerHeight - 500) / 2;
          // 创建一个新的窗口，设置宽度和高度为原窗口的一半，left和top属性使其居中
          const newWindow = window.open(url, '_blank', 'width=500,height=500,left=' + leftPx + ',top=' + topPx);
          // 如果你想要限制新窗口的行为（例如不允许关闭、不允许弹出其他页面），可以添加更多的特性，如下面的示例：
          if (typeof newWindow !== 'undefined') { // 确保新窗口成功创建
            newWindow.addEventListener('beforeunload', function (e) {
              e.preventDefault(); // 阻止默认行为（关闭窗口）
              e.returnValue = '';
            });
          }
        }
      },
      // 获取商家信息
      // shopMyInfo() {
      //   shopMyInfo().then((res) => {
      //     if(res.code==200){
      //       this.businessId = res.data.businessId;
      //       this.bannerTop = res.data.image;
      //     }
      //   });
      // },
      // 移动端nav_Bar_2右端展开
      navBarRightClick() {
        this.navBarRight = !this.navBarRight;
      },
      // 广告列表
      getbannerList(n) {
        getbannerList(n).then((res) => {
          if (res.code == 200 && n == 3 && res.data.length > 0) {
            this.businessTopLog = res.data;
          }
        });
      },
      sendEmailCodeApi() {
        getCode(this.registerUser.email).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "The verification code was sent successfully.",
              type: "success",
            });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      registerEmailApi() {
        if (this.registerUser.email == null) {
          this.$message({ message: "Mailbox cannot be empty", type: "error" });
          return;
        }
        if (this.registerUser.code == null) {
          this.$message({ message: "CAPTCHA cannot be empty", type: "error" });
          return;
        }
        if (this.registerUser.password == null) {
          this.$message({ message: "Password cannot be empty", type: "error" });
          return;
        }
        registerEmail(this.registerUser).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "Registered successfully",
              type: "success",
            });
            this.registerUser = {
              email: null,
              password: null,
              code: null,
            };
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      // 获取消费者首页公共商品分类
      getcommonCategory() {
        getcommonCategory().then((res) => {
          if (res.code == 200) {
            this.commondata = res.data;
            this.scrollInit();
          }
        });
      },
      getShopCommonCategory() {
        var id = sessionStorage.getItem("businessId");
        if (id != null && id > 0) {
          shopCategoryListApi(id).then((res) => {
            if (res.code == 200) {
              this.businessNav = res.data;
            }
          });
        }
      },
      exitLogin() {
        removeCookie("token");
        removeUserType("userType");
        this.userType = null;
        this.userName = null;
        this.enquiriesCount = null;
        this.feedbackCount = null;
        this.orderDeliveryCount = null;
        this.promotionApplyCount = null;
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("enquiriesCount");
        sessionStorage.removeItem("feedbackCount");
        sessionStorage.removeItem("orderDeliveryCount");
        sessionStorage.removeItem("promotionApplyCount");
        sessionStorage.removeItem("businessId");
        sessionStorage.removeItem("businessInfo");
        this.deliveryOrderCount = null;
        sessionStorage.removeItem("deliveryOrderCount");
        this.getLoginUserInfo();
      },
      getLoginUserInfo() {
        var userType = getUserType("userType");
        this.userType = userType;
        if (userType == null) {
          //未登录
          this.$router.push({
            path: "/login",
            query: {
              box8: false,
              // phoneHomeImg: false,
              // bool: false,
              // token: true,
            },
          });
        } else if (userType == 1) {
          //消费者用户，跳转购物页
          getmyInfo().then((res) => {
            if (res.code == 200) {
              // this.userName = res.data.userName;
              sessionStorage.setItem("userName", res.data.userName);
              this.userName = sessionStorage.getItem("userName");

              sessionStorage.setItem("deliveryOrderCount", res.data.deliveryOrderCount);
              this.deliveryOrderCount = sessionStorage.getItem("deliveryOrderCount");

            }
          });
          // this.$router.push({
          //   path: "/",
          //   query: {
          //     box8: false,
          //     phoneHomeImg: false,
          //     bool: false,
          //     noBusiness: true,
          //   },
          // });
        } else if (userType == 2) {
          //商家
          shopMyInfo().then((res) => {
            // console.log(res, "11111");
            // if (res.code == 401) {
            //   //未登录
            //   this.$router.push({
            //     path: "/login",
            //     query: {
            //       box8: false,
            //       // phoneHomeImg: false,
            //       // bool: false,
            //       // token: true,
            //     },
            //   });
            // }
            if (res.code == 200) {
              sessionStorage.setItem("businessId", res.data.businessId);
              this.userName = res.data.userName;

              sessionStorage.setItem("enquiriesCount", res.data.enquiriesCount);
              this.enquiriesCount = res.data.enquiriesCount;

              sessionStorage.setItem("feedbackCount", res.data.feedbackCount);
              this.feedbackCount = res.data.feedbackCount;

              sessionStorage.setItem("orderDeliveryCount", res.data.orderDeliveryCount);
              this.orderDeliveryCount = res.data.orderDeliveryCount;

              sessionStorage.setItem("promotionApplyCount", res.data.promotionApplyCount);
              this.promotionApplyCount = res.data.promotionApplyCount;

              if (res.data.images) {
                this.bannerimages = res.data.images.split(",");
              }

              this.bannerTop = res.data.image;
              this.businessId = res.data.businessId;
              this.businessIntroduction(res.data)
            }
          });
        }
      },
      // 关键字搜索
      searchfun() {
        if (this.$route.query.type == 'business') {
          this.form.businessId = sessionStorage.getItem("businessId")
          getSeywordSearch({ ...this.form }).then((res) => {
            if (res.code == 200) {
              this.$router.push({
                path: "/search",
                query: {
                  bool: false,
                  value: JSON.stringify(this.form.keyword),
                  total: JSON.stringify(res.data.total),
                  noBusiness: false,
                  box8: false,
                  list: JSON.stringify(res.data.vos),
                  type: 'business',
                  businessId: sessionStorage.getItem("businessId")
                },
              });
            }
          });
          return
        } else {
          if (this.selectVal == 'Manufacturers') {
            getKeywordSearchManufacturers({ ...this.form }).then((res) => {
              if (res.code == 200) {
                this.$router.push({
                  path: "/search",
                  query: {
                    bool: false,
                    value: JSON.stringify(this.form.keyword),
                    total: JSON.stringify(res.data.total),
                    noBusiness: true,
                    box8: false,
                    list: JSON.stringify(res.data.vos),
                    type: this.selectVal
                  },
                });
              }
            });
            return
          }
          keywordSearchApi({ ...this.form }).then((res) => {
            if (res.code == 200) {
              this.$router.push({
                path: "/search",
                query: {
                  bool: false,
                  value: JSON.stringify(this.form.keyword),
                  total: JSON.stringify(res.data.total),
                  noBusiness: true,
                  box8: false,
                  list: JSON.stringify(res.data.vos),
                  type: this.selectVal
                },
              });
            }
          });
        }
      },
      //联系方式
      getcontactInformation() {
        getcontactInformation().then((res) => {
          if (res.code == 200) {
            this.contactInfo = res.data;
          }
        });
      },
      goBack() {
        if (document.querySelector(".fineceramics_page")) {
          if (this.isWeixinBrowser()) {
            this.$router.go(-1);
          } else {
            window.close();
          }
        }
        let lasterPath = this.urlArray.pop();
        this.$router.push({
          path: lasterPath.path,
          query: lasterPath.query,
        });
        this.goback = true;
      },
      // 图表log跳转到首页
      homeClick(bool) {
        if (this.shareType) return
        this.$router.push({
          path: "/",
          query: {
            bool,
            noBusiness: true,
          },
        });
      },
      // 跳转到ceramics商品页
      ceramicsClick(id) {
        this.$router.push({
          path: "/ceramics/ceramicslist",
          query: {
            bool: false,
            box8: false,
            phoneHomeImg: false,
            id,
          },
        });
      },
      lifestyleClick() {
        this.$router.push({
          path: "/ceramics/lifestylelist",
          query: {
            bool: false,
            box8: false,
            noBusiness: false,
          },
        });
      },
      // 跳转到公司介绍about us页
      aboutUsClick(bool) {
        this.$router.push({
          path: "/companyprofile",
          query: {
            bool,
            box8: false,
            phoneHomeImg: false,
          },
        });
        // document.querySelector(".bottomnav_about").click();
      },
      contactUsClick() {
        this.$router.push({
          path: "/contactus",
          query: {
            bool: false,
            box8: false,
            phoneHomeImg: false,
            noBusiness: false,
          },
        });
      },
      helpClick() {
        this.$router.push({
          path: "/help",
          query: {
            bool: false,
            box8: false,
            phoneHomeImg: false,
          },
        });
      },
      // 跳转到个人中心
      personalcenterClick(bool) {
        this.$router.push({
          path: this.personalCenterPath,
          query: {
            bool,
            box8: false,
          },
        });
        // document.querySelector(".bottomnav_me").click();
      },
      // 底部导航栏点击事件
      bottomnavClick(e, path, box8) {
        let bottomnav = document.querySelector(".bottomnav");
        for (let i = 0; i < bottomnav.children.length; i++) {
          bottomnav.children[i].style.color = "#aaa";
        }
        e.currentTarget.style.color = "#13AF87";
        if (document.documentElement.clientWidth <= 1080) {
          let bool = path === "/" ? true : false;
          this.$router.push({
            path,
            query: {
              box8,
              bool,
              isbottomnav: true, // 防止在移动端点击时activated里面的路由触发
            },
          });
        }
        this.navBarRight = false
      },
      bottomnavMeClick(e, path, box8) {
        let bottomnav = document.querySelector(".bottomnav");
        for (let i = 0; i < bottomnav.children.length; i++) {
          bottomnav.children[i].style.color = "#aaa";
        }
        e.currentTarget.style.color = "#13AF87";
        if (this.userType == 1) {
          let bool = path === "/" ? true : false;
          this.$router.push({
            path,
            query: {
              box8,
              bool,
              isbottomnav: true, // 防止在移动端点击时activated里面的路由触发
            },
          });
        } else if (this.userType == 2) {
          this.$router.push({
            path: this.businesspersonalPath,
            query: {
              noBusiness: false,
              isbottomnav: true,
            },
          });
        } else {
          //未登录
          this.$router.push({
            path: "/login",
            query: {
              box8: false,
              // phoneHomeImg: false,
              // bool: false,
              // token: true,
            },
          });
        }
      },
      // 跳转到登录页面
      loginClick(box8) {
        this.$router.push({
          path: "/login",
          query: {
            box8,
          },
        });
      },
      // 跳转到注册页面
      registerClick(box8) {
        this.$router.push({
          path: "/register",
          query: {
            box8,
            phoneHomeImg: false,
          },
        });
      },
      // 跳转到privay页面
      privacyClick(bool) {
        this.$router.push({
          path: "/privacy",
          query: {
            bool,
            box8: false,
            phoneHomeImg: false,
          },
        });
      },
      // 跳转到residence页
      residenceClick(bool) {
        this.$router.push({
          path: "/residence",
          query: {
            bool,
            box8: false,
            phoneHomeImg: false,
          },
        });
      },
      // 移动端左上角home图标跳转
      homeChooseClick() {
        if (this.noBusiness == "false") {
          this.$router.push({
            path: "/businesshome",
            query: {
              noBusiness: false,
            },
          });
        } else {
          this.$router.push({
            path: "/",
            query: {
              bool: false,
              // box8: false,
              // phoneHomeImg: false,
            },
          });
        }
      },
      // 商家首页
      businesshomeClick() {
        this.$router.push({
          path: "/businesshome",
          query: {
            noBusiness: false,
            businessId: sessionStorage.getItem("businessId"),
          },
        });
      },
      // 跳转到公司介绍
      businessprofileClick() {
        this.$router.push({
          path: "/businessprofile",
          query: {
            noBusiness: false,
          },
        });
      },
      // 跳转到feedback页面
      businessfeedbackClick() {
        this.$router.push({
          path: "/businessfeedback",
          query: {
            noBusiness: false,
            pageType: 'add'
          },
        });
      },
      businessnewsClick(command) {
        // console.log(command)
        if (command == 'CompanyOverview') {
          // this.$router.push({
          //   path: "/businessprofile",
          //   query: {
          //     noBusiness: false,
          //   },
          // });
          // this.aboutUsClick(false)
          this.businessprofileClick()
        } else {
          this.$router.push({
            path: "/ratingsreviews",
            query: {
              noBusiness: false,
            },
          });
        }
      },
      // 商家产品列表页
      businessceramicsClick(id, isbusinessbottomnav) {
        if (isbusinessbottomnav._uid) {
          isbusinessbottomnav === null;
          isbusinessbottomnav = false;
        }
        // isbusinessbottomnav = null;
        this.$router.push({
          path: "/businessceramics",
          query: {
            noBusiness: false,
            id,
            isbusinessbottomnav,
          },
        });
        // if(document.documentElement.clientWidth > 1080){

        // }
        // this.$router.push({
        //   path: "/businessceramics/businessceramicslist",
        //   query: {
        //     noBusiness: false,
        //     id,
        //   },
        // });
      },
      // 商家个人中心页
      businesspersonalClick(isbottomnav) {
        this.getLoginUserInfo();
        this.$router.push({
          path: this.businesspersonalPath,
          query: {
            noBusiness: false,
            isbottomnav,
          },
        });
      },
      // 跳转到购物车页面
      viewCartClick() {
        // document.querySelector(".frame").style.display = "none";
        this.$router.push({
          path: "/cart",
          query: {
            phoneHomeImg: false,
            box8: false,
            bool: false,
          },
        });
      },
      // 点击确定加入购物车按钮
      okButtonClick() {
        document.querySelector(".frame").style.display = "none";
      },
      // 接收路由传过来的参数
      chooseShow() {
        if (getCookie("token")) {
          this.bool = false;
        } else {
          this.bool = this.$route.query.bool === "false" ? false : true;
        }
        // phoneHomeImg为false显示字体Back
        this.phoneHomeImg =
          this.$route.query.phoneHomeImg === "false" ? false : true;
        // noBusiness传false,显示商家页面
        // if(this.$route.query.noBusiness==false){
        //   this.noBusiness=false
        // }else{
        //   this.noBusiness=true
        // }
        // this.noBusiness = this.$route.query.noBusiness === "false" ? false : true;
        this.noBusiness = this.$route.query.noBusiness;
        // console.log(this.$route.query.noBusiness,'接受路由跳转参数====----');
        if (document.documentElement.clientWidth > 1080) {
          this.box8 = true;
        } else {
          this.box8 = this.$route.query.box8 === "false" ? false : true;
        }
        // // 通过接收到的personalCenterPath参数，决定personalcenter页面的路径，解决重复点击出现初始化现象
        if (this.$route.query.personalCenterPath !== undefined) {
          this.personalCenterPath = this.$route.query.personalCenterPath;
        }
        // // 通过接收到的businesspersonalPath参数，决定businesspersonal页面的路径，解决重复点击出现初始化现象
        if (this.$route.query.businesspersonalPath !== undefined) {
          this.businesspersonalPath = this.$route.query.businesspersonalPath;
        }
        // // 通过通过接收到的businessHomePath参数，决定点击home的跳转路径
        if (this.$route.query.businessHomePath !== undefined) {
          this.businessHomePath = this.$route.query.businessHomePath;
        } else {
          this.businessHomePath = "/";
        }
      },
      getParams() {
        // this.list = JSON.parse(this.$route.query.list);      
        let id = sessionStorage.getItem("bId") ? sessionStorage.getItem("bId") : this.$route.query.id;
        console.log(id)
        if (!id) {
          return
        }
        getconsumerCommodityDetail(id).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.businessIntroduction(res.data, res.data.businessId)
          }
        });
      },
    },
    watch: {
      // 监听路由跳转
      $route(to, from) {
        // if (this.$route.path === "/ceramics/fineceramics"||this.$route.path === "/ratingsreviews"||this.$route.path === "/businesshome") {
        if (this.$route.path === "/ceramics/fineceramics" || this.$route.path === "/ratingsreviews") {
          if (this.$route.query.id) {
            sessionStorage.setItem("bId", this.$route.query.id)
          }
          // else if(this.$route.query.businessId){
          //   sessionStorage.setItem("bId",this.$route.query.businessId)
          // }      
          this.getParams()
        }
        // this.businessIntroduction()
        this.businessIntroduction(null, null);
        if (this.$route.query.businessId) {
          this.businessId = this.$route.query.businessId;
        }
        // this.businessInfo=sessionStorage.getItem("businessInfo")?JSON.parse(sessionStorage.getItem("businessInfo")):{};
        // console.log(this.businessInfo)
        // debugger
        this.form.keyword = "";
        this.getShopCommonCategory();
        // this.shopMyInfo();
        // this.getShopCommonCategory();
        if (getCookie("token") == undefined) {
          this.loginshow = true;
          if (this.$route.path === "/businesshome") {
            this.businessIntroduction({}, this.$route.query.businessId)
          }
        } else {
          this.loginshow = false;
          this.getLoginUserInfo();
        }
        // this.getLoginUserInfo();
        if (
          to.query.isbottomnav &&
          document.querySelector(".businesspersonal_nav")
        ) {
          document.querySelector(".businesspersonal_nav").style.display = "block";
          document.querySelector(".businesspersonal_view").style.display = "none";
        }
        // 触发移动端回退后，pc端nav显示
        if (this.goback) {
          // ceramics页面的回退
          if (document.querySelector(".ceramics")) {
            // console.log(document.querySelector(".ceramics"));
            // 判断是pc还是移动端
            if (document.documentElement.clientWidth > 1080) {
              document.querySelector(".ceramics_nav").style.display = "block";
              document.querySelector(".ceramics_view").style.display = "block";
              this.show = false
            } else {
              document.querySelector(".ceramics_nav").style.display = "block";
              document.querySelector(".ceramics_view").style.display = "none";
              this.show = true
            }
            document.querySelector(".bottomnav_classify").style.color = "#13AF87";
            this.$route.query.phoneHomeImg = true;
            // this.phoneHomeImg = true;
          }
          // personalcenter页面的回退
          if (document.querySelector(".personalcenter")) {
            // 判断回去后的路径
            if (this.$route.path === "/personalcenter/essential") {
              // document.querySelector(".router_essential").click();
              let childrens = document.querySelector(
                ".personalcenter_nav_router"
              ).children;
              for (let i = 0; i < childrens.length; i++) {
                childrens[i].style.backgroundColor = "#fff";
              }
              document.querySelector(".router_essential").style.backgroundColor =
                "#d3f5ec";
            }
            if (this.$route.path === "/personalcenter/order") {
              // document.querySelector(".router_order").click();
              let childrens = document.querySelector(
                ".personalcenter_nav_router"
              ).children;
              for (let i = 0; i < childrens.length; i++) {
                childrens[i].style.backgroundColor = "#fff";
              }
              document.querySelector(".router_order").style.backgroundColor =
                "#d3f5ec";
            }
            if (this.$route.path === "/personalcenter/address") {
              // document.querySelector(".router_address").click();
              let childrens = document.querySelector(
                ".personalcenter_nav_router"
              ).children;
              for (let i = 0; i < childrens.length; i++) {
                childrens[i].style.backgroundColor = "#fff";
              }
              document.querySelector(".router_address").style.backgroundColor =
                "#d3f5ec";
            }
            // 判断是pc还是移动端
            if (document.documentElement.clientWidth > 1080) {
              document.querySelector(".personalcenter_nav").style.display =
                "block";
              document.querySelector(".personalcenter_view").style.display =
                "block";
              this.show = false
            } else {
              document.querySelector(".personalcenter_nav").style.display =
                "block";
              document.querySelector(".personalcenter_view").style.display =
                "none";
              this.show = true
            }
            document.querySelector(".bottomnav_me").style.color = "#13AF87";
            this.$route.query.phoneHomeImg = true;
            // this.phoneHomeImg = true;
          }
          // businesspersonal页面的回退
          if (document.querySelector(".businesspersonal")) {
            // 判断回去后的路径
            if (
              this.$route.path === "/businesspersonal/businessessential/merchant"
            ) {
              // document.querySelector(".router_essential").click();
              let childrens = document.querySelector(
                ".businesspersonal_nav_router"
              ).children;
              for (let i = 0; i < childrens.length; i++) {
                childrens[i].style.backgroundColor = "#fff";
              }
              document.querySelector(".router_essential").style.backgroundColor =
                "#d3f5ec";
            }
            if (this.$route.path === "/businesspersonal/businessorder") {
              // document.querySelector(".router_order").click();
              let childrens = document.querySelector(
                ".businesspersonal_nav_router"
              ).children;
              for (let i = 0; i < childrens.length; i++) {
                childrens[i].style.backgroundColor = "#fff";
              }
              document.querySelector(".router_order").style.backgroundColor =
                "#d3f5ec";
            }
            // 判断是pc还是移动端
            if (document.documentElement.clientWidth > 1080) {
              document.querySelector(".businesspersonal_nav").style.display =
                "block";
              document.querySelector(".businesspersonal_view").style.display =
                "block";
              this.show = false
            } else {
              if (
                ((to.path === "/businesspersonal/businessessential/merchant" &&
                  from.path !== "/businesspersonal/businessorder") ||
                  to.path === "/businesspersonal/businessessential/employee" ||
                  to.path === "/businesspersonal/businessessential/coupon") &&
                to.query.phoneHomeImg === "false"
              ) {
                document.querySelector(".businesspersonal_nav").style.display =
                  "none";
                document.querySelector(".businesspersonal_view").style.display =
                  "block";
              } else {
                document.querySelector(".businesspersonal_nav").style.display =
                  "block";
                document.querySelector(".businesspersonal_view").style.display =
                  "none";
              }
              this.show = true
            }
            if (
              ((to.path === "/businesspersonal/businessessential/merchant" &&
                from.path !== "/businesspersonal/businessorder") ||
                to.path === "/businesspersonal/businessessential/employee" ||
                to.path === "/businesspersonal/businessessential/coupon") &&
              to.query.phoneHomeImg === "false"
            ) {
              this.$route.query.phoneHomeImg = "false";
            } else {
              this.$route.query.phoneHomeImg = "true";
            }
            // this.phoneHomeImg = true;
            // console.log(this.phoneHomeImg);
          }
        } else {
          // 将跳转前的路由数据添加到urlArray数组最后面，作为历史记录
          this.urlArray.push({ path: from.path, query: from.query });
        }
        this.goback = false;

        // console.log(this.urlArray);
        this.chooseShow();
      },
      // businessInfo:(val)=>{
      //   if(val){
      //     return val
      //   }
      // }
    },
    components: {},
    // router.beforeEach((to,from,next)=>{}),
    mounted() {
      // document.onkeydown = () => {
      //   //禁用F12
      //   if (window.event && window.event.keyCode == 123) {
      //     return false;
      //     //禁用ctrl+shift+i,
      //   } else if (
      //     window.event.ctrlKey &&
      //     window.event.shiftKey &&
      //     window.event.keyCode == 73
      //   ) {
      //     return false;
      //     //屏蔽Shift+F10
      //   } else if (window.event.shiftKey && window.event.keyCode == 121) {
      //     return false;
      //   }
      // };
      // document.oncontextmenu = new Function("event.returnValue=false");    //禁用鼠标右键
      // this.contactWe();
      // this.shopMyInfo();
      // if (this.$route.path === "/ceramics/fineceramics"||this.$route.path === "/ratingsreviews"||this.$route.path === "/businesshome") {
      var bussinessId = this.$route.query.businessId || sessionStorage.getItem("businessId");
      if (bussinessId) {
        this.businessIntroduction({}, bussinessId)
      }
      if (this.$route.path === "/ceramics/finePromotionCeramics" || this.$route.path === "/ceramics/fineceramics" || this.$route.path === "/ratingsreviews") {
        if (this.$route.query.id) {
          sessionStorage.setItem("bId", this.$route.query.id)
        }
        // else if(this.$route.query.businessId){
        //   sessionStorage.setItem("bId",this.$route.query.businessId)
        // }        
        this.getParams()
      }
      this.getbannerList(3);
      this.getIndexBottomNavigation();
      // console.log(sessionStorage.getItem('userName'));
      this.userName = sessionStorage.getItem("userName");
      this.enquiriesCount = sessionStorage.getItem("enquiriesCount");
      this.feedbackCount = sessionStorage.getItem("feedbackCount");
      this.orderDeliveryCount = sessionStorage.getItem("orderDeliveryCount");
      this.promotionApplyCount = sessionStorage.getItem("promotionApplyCount");

      if (getCookie("token") == undefined) {
        this.loginshow = true;
        if (this.$route.path === "/businesshome") {
          this.businessIntroduction({}, this.$route.query.businessId)
        }
      } else {
        this.loginshow = false;
        this.getLoginUserInfo();
      }
      // this.businessNav = businessNav.data ? this.businessNav : businessNav.data; // 商家顶部导航栏
      // console.log("this.businessNav", businessNav.data);
      this.chooseShow();
      this.getcontactInformation();
      // 监听浏览器刷新
      window.addEventListener("load", this.chooseShow());

      // 监听浏览器宽度变化
      window.addEventListener("resize", () => {
        if (document.documentElement.clientWidth > 1080) {
          this.box8 = true;
          this.show = false
        } else {
          this.box8 = this.$route.query.box8 === "false" ? false : true;
          this.show = true
        }
      });
      // 监听浏览器前进后退;
      window.addEventListener(
        "popstate",
        () => {
          this.goback = true;
          this.urlArray.pop();
        },
        false
      );
    },
  };
</script>

<style src="./assets/css/common.css" />
<style src="./assets/css/iconfont.css" />
<style src="./assets/css/index_pc.css" />
<style src="./assets/css/index_phone.css" />
<style scoped>
  .box_8 .password input {
    width: calc(340 * 100vw / 1920);
    height: calc(40 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-top: calc(20 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    padding-left: calc(23 * 100vw / 1920);
  }

  .box_8 .passwordinput::-webkit-input-placeholder {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 300;
    color: #aaaaaa;
  }

  .logo-img {
    width: calc(35 * 100vw / 1920);
    height: calc(35 * 100vw / 1920);
    margin-right: calc(23 * 100vw / 1920);
  }

  .showRed {
    margin-right: 20px;

    .el-dropdown-link {
      font-size: calc(14 * 100vw / 1920);
      font-family: Roboto;
      font-weight: 400;
      color: #B8B8B8 !important;
      line-height: 1.88vw;
    }
  }

  .el-dropdown-menu {
    background-color: #333 !important;
  }

  .el-dropdown-menu__item {
    color: white !important;
  }

  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #333 !important;
    background-color: white !important;
  }


  /* 设置分类菜单下拉列表的样式 */
  #linkWhite {
    color: white !important;
  }

  #linkWhite1 {
    color: white !important;
  }

  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    #linkWhite {
      color: #333 !important;
      background-color: white !important;
    }

    #linkWhite1 {
      color: #333 !important;
      background-color: white !important;
    }
  }


  @media screen and (max-width: 1080px) {

    .buttom_share_loge {
      display: flex;
      flex-wrap: wrap;
    }

    .buttom_share_loge .shareDiv {
      display: inline-block;
    }

    .buttom_share_loge img {
      width: calc(100 * 100vw / 1080);
      height: calc(100 * 100vw / 1080);
      margin-right: calc(18 * 100vw / 1080);
    }

    .logo-img {
      width: calc(70 * 100vw / 1080);
      height: calc(70 * 100vw / 1080);
      margin-right: calc(53 * 100vw / 1080);
    }

    .phone_nav {
      width: calc(240 * 100vw / 1080);
      position: absolute;
      z-index: 100;
      top: calc(240 * 100vw / 1080);
      right: calc(40 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      border: 1px solid #ddd;
      background-color: #fff;
      padding: calc(30 * 100vw / 1080) calc(10 * 100vw / 1080);
    }

    .phone_nav li {
      width: 100%;
      margin-bottom: calc(30 * 100vw / 1080);
      background-color: #f1f1f1;
      padding: calc(10 * 100vw / 1080) 0;
      text-align: center;
    }

    .box_8 .password input {
      width: calc(1000 * 100vw / 1080);
      height: calc(75 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
      margin-top: calc(20 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      padding: 0;
    }

    .box_8 .passwordinput::-webkit-input-placeholder {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 300;
      color: #aaaaaa;
    }
  }
</style>
<style>
  .buttom_share_loge {
    display: flex;
    flex-wrap: wrap;
  }

  .buttom_share_loge .shareDiv {
    display: inline-block;
  }

  .buttom_share_loge img {
    width: calc(45 * 100vw / 1920);
    height: calc(45 * 100vw / 1920);
    margin-right: calc(18 * 100vw / 1920);
  }

  .el-popper {
    display: flex;
    flex-direction: column;
  }

  .directory_home {
    cursor: pointer;
  }

  .directory_home:hover {
    padding-bottom: calc(5 * 100vw / 1920);
    border-bottom: 5px solid rgb(182, 182, 182);
  }

  .el-dropdown-link {
    color: #000;
    /* font-size: 1.1vw; */
    font-size: 1.0vw;
  }

  .qq {
    width: 1.04vw !important;
    height: 1.04vw !important;
  }

  @media screen and (max-width: 1080px) {
    .qq {
      width: 3.44vw !important;
      height: 3.44vw !important;
    }
  }
</style>