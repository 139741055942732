<template>
  <div class="order_page">
    <div class="order_title">订单信息</div>
    <div class="order_main">
      <div class="order-tag">
        <div class="order_nav flex-row">
          <!-- <div @click="clickActive" class="order_active">ALL</div>
        <div @click="clickActive">Unpaid</div>
        <div @click="clickActive">Paid</div> -->
          <div @click="navfun(index)" v-for="(item, index) in nav" :key="index" :style="{
              background: index == navcurrent ? '#13af87' : '',
              color: index == navcurrent ? '#fff' : '',
            }">
            {{ item }}
          </div>
        </div>
        <div class="order-tag-btn">
          <!-- <div>
            <input
              type="checkbox"
              :checked="checked"
              @click="checkfunAll"
            />全选
          </div> -->
          <div @click="exportOrder">
            <el-button type="warning">导出</el-button>
          </div>
          <div @click="addOrder">
            <el-button type="primary">添加</el-button>
          </div>
          <div @click="updateOrder">
            <el-button type="success">修改</el-button>
          </div>
          <div @click="deleteOrder">
            <el-button type="danger">删除</el-button>
          </div>
        </div>
      </div>
      <div class="order_list flex-col">
        <el-table :data="orderList" tooltip-effect="dark" @selection-change="handleSelectionChange1"
          class="order_list_table">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="expand" width="25" align="center">
            <template slot-scope="props">
              <div class="order_list_drop_down">
                <div class="orderlist_main">
                  <div class="orderlist_li flex-row" v-for="(item2, index2) in props.row.orderDetailVos" :key="index2">
                    <div class="orderlist_img" style="text-align: center">
                      <img :src="$baseUrl + item2.image" />
                    </div>
                    <div class="orderlist_right">
                      <div style="display: flex;justify-content: space-between;width: 100%;">
                        <div class="orderlist_name">
                          {{ item2.commodityName }} <span>*{{ item2.quantity }}</span>
                        </div>
                        <div class="orderlist_price">
                          <p style="text-align: center;margin-bottom: 10px;">${{ item2.price }}</p>
                        </div>
                      </div>
                      <div style="display: flex;justify-content: space-between;width: 100%;">
                        <div class="orderlist_name">
                          <span>SKU规格:{{ item2.specifications }}</span>
                          <span>购买数量:{{ item2.quantity }}</span>
                          <span>价格:{{ item2.price }}</span>
                          <span>小计:{{ item2.totalPrice }}</span>
                          <span>运费:{{item2.freightCharge ? item2.freightCharge : 0}}</span>
                        </div>
                        <div class="orderlist_price">
                          <div>
                            <el-button type="success" size="mini"
                              @click="editPriceClick(props.row.id,item2)">修改价格</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单号" prop="orderNo" align="center"></el-table-column>
          <el-table-column label="是否代下单" prop="isBusinessAdd" align="center">
            <template slot-scope="scope">
              {{scope.row.isBusinessAdd==1?'商城代下单':scope.row.isBusinessAdd==2?'妙手代下单':scope.row.isBusinessAdd==3?'后台下单':'用户下单'}}
            </template>
          </el-table-column>
          <el-table-column label="用户" prop="contacts" align="center">
          </el-table-column>
          <el-table-column label="订单总额" prop="totalPrice" width="80" align="center">
          </el-table-column>
          <el-table-column label="优惠金额" prop="discountPrice" width="80" align="center">
          </el-table-column>
          <el-table-column label="支付金额" prop="payPrice" width="100" align="center">
          </el-table-column>
          <el-table-column label="付款状态" width="80" align="center">
            <template slot-scope="scope">
              <span
                class="order_status">{{scope.row.status==0?'未支付':scope.row.status==1?'已支付':scope.row.status==2?'退款':''}}</span>
            </template>
          </el-table-column>
          <el-table-column label="发货单号" prop="orderNo" align="center">
          </el-table-column>
          <el-table-column label="付款时间" prop="createTime" align="center">
          </el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <div class="order_list_table_btn">
                <el-button type="success" size="mini" @click="seeOrderDetail(scope.row)">详情</el-button>
                <el-button type="primary" size="mini" v-if="scope.row.isBusinessAdd == 1 && scope.row.status == 0"
                  @click="sendEmail(scope.row.orderNo,scope.row.userEmail)">发送邮件</el-button>
                <el-button type="primary" size="mini" v-if="scope.row.payVoucher != null"
                  @click="lookpz(scope.row.payVoucher)">查看凭证</el-button>
                <el-button type="danger" size="mini"
                  v-if=" (scope.row.status == 0 && scope.row.isBusinessAdd == 0) || (scope.row.status != 1)"
                  @click.stop="shopCancelOrderApi(scope.row)">取消订单</el-button>
                <el-button type="primary" size="mini" v-if="scope.row.status == 1 &&
                  (scope.row.payMode == 2  || scope.row.payMode == 3) && 
                  scope.row.payVoucher != null && scope.row.confirmPay == 0"
                  @click="confirmOrderPayApi(scope.row)">确认支付</el-button>
                <el-button type="primary" size="mini"
                  v-if="scope.row.isBusinessAdd == 1 && scope.row.status == 0 && scope.row.isEmail == 1"
                  @click="shopConfirmOrderPay(scope.row.orderNo)">确认支付</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>


        <!-- <div class="orderlist" 
          v-for="(item, index) in orderList" :key="index"
          @click="seeOrderDetail(item)"
        >
          <div class="orderlist_info">
            <div class="orderlist_id flex-row">
              <div>
                <input
                  type="checkbox"
                  :checked="item.checked"
                  @click.stop="checkfun($event, item.id)"
                />
                <span class="orderlist_no">订单号:{{ item.orderNo }}</span>
              </div>
              <el-button
                type="primary"
                size="mini"
                v-if="item.isBusinessAdd == 1 && item.status == 0"
                @click.stop="sendEmail(item.orderNo)"
                >发送邮件</el-button
              >
              <div>
                <span
                  style="margin-right: 5px; font-size: 14px"
                  @click.stop="lookpz(item.payVoucher)"
                  v-if="item.payVoucher != null"
                  >查看凭证</span
                >
                <span
                  class="order_list1_state"
                  slot="orderlist_state"
                  v-if="item.status == 0"
                  >未支付</span
                >
                <span
                  class="order_list1_state"
                  slot="orderlist_state"
                  v-if="item.status == 1"
                  >已支付</span
                >
                <span
                  class="order_list1_state"
                  slot="orderlist_state"
                  v-if="item.status == 2"
                  >退款</span
                >
              </div>
            </div>
            <div class="ordinfo flex-row">
              <span class="contacts">联系人:{{ item.contacts }}</span>
              <span>电话:{{ item.phone }}</span>
              <span>地址:{{ item.address }}</span>
            </div>
            <div class="orderlist_main">
              <div
                class="orderlist_li flex-row"
                v-for="(item2, index2) in item.orderDetailVos"
                :key="index2"
              >
                <div class="orderlist_img" style="text-align: center">
                  <img :src="$baseUrl + item2.image" />
                </div>
                <div class="orderlist_right">
                  <div class="orderlist_name">
                    {{ item2.commodityName }} <span>*{{ item2.quantity }}</span>
                  </div>
                  <div class="orderlist_price">${{ item2.price }}</div>
                </div>
              </div>
            </div>
            <div
              class="open_orderlist iconfont"
              @click.stop="switchOrderlist"
              v-if="item.orderDetailVos.length > 1"
            >
              点击查看更多
            </div>
            <div class="orderlist_info_bottom flex-row">
              <div class="orderlist_time">下单时间:{{ item.createTime }}</div>
              <div style="color: #fda911">总金额：{{ item.totalPrice }}</div>
              <div>
                <button
                  v-if="
                    (item.status == 0 && item.isBusinessAdd == 0) ||
                    item.isBusinessAdd == null
                  "
                  @click.stop="shopCancelOrderApi(item)"
                >
                  取消订单
                </button>
                <button
                  v-if="
                    item.status == 1 &&
                    (item.payMode == 2  || item.payMode == 3) && 
                    item.payVoucher != null &&
                    item.confirmPay == 0
                  "
                  @click.stop="confirmOrderPayApi(item)"
                >
                  确认支付
                </button>
                <button
                  v-if="
                    item.isBusinessAdd == 1 &&
                    item.status == 0 &&
                    item.isEmail == 1
                  "
                  @click.stop="shopConfirmOrderPay(item.orderNo)"
                >
                  确认支付
                </button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="page_nation" style="width: 100%; text-align: center" v-if="orderList.length > 0">
        <el-pagination background :total="total.pageTotal" @current-change="currentChange"
          @size-change="handleSizeChange" :current-page="total.pageNum" :page-size="total.pageSize"
          :page-sizes="[5, 10, 20]" layout="sizes,prev, pager, next, jumper"></el-pagination>
        <!-- <page-nation></page-nation> -->
      </div>
      <div class="page_nation2" style="width: 100%; text-align: center" v-if="orderList.length > 0">
        <el-pagination small background :total="total.pageTotal" @current-change="currentChange"
          @size-change="handleSizeChange" :current-page="total.pageNum" :page-size="total.pageSize" :pager-count="5"
          :page-sizes="[5, 10, 20]" layout="sizes,prev, pager, next, jumper"></el-pagination>
        <!-- <page-nation></page-nation> -->
      </div>
      <el-dialog title="查看凭证" :visible.sync="pzVisible">
        <img style="width: 100%;" :src="pzImg" alt="" />
      </el-dialog>
      <el-dialog title="添加订单" :visible.sync="addVisble" @close="closeAdd">
        <div class="addDig">
          <span>搜索产品:</span>
          <el-select style="width: 200px" v-model="form.commodityId" placeholder="请输入关键词" filterable remote
            reserve-keyword @change="selectaddChange" ref="elSelect" @blur="blurfn" :popper-append-to-body="false"
            popper-class="my-select">
            <el-option :label="item.label" :value="item.value" v-for="(item, index) in options"
              :key="index"></el-option>
          </el-select>
          <!-- <el-select
            style="width: 200px"
            v-model="form.commodityId"
            placeholder="请输入关键词"
            filterable
            remote
            reserve-keyword
            :loading="loading"
            :remote-method="remoteMethod"
            @change="selectaddChange"
            ref="elSelect"
            @blur="blurfn"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in options"
              :key="index"
            ></el-option>
          </el-select> -->
          <!-- <button class="list_selete" @click="listSelete">删除</button> -->
        </div>
        <div style="margin-bottom: 20px">
          <div>
            <el-table :data="listnation" tooltip-effect="dark" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="25"> </el-table-column>
              <el-table-column label="id" prop="commodityId" width="55"></el-table-column>
              <el-table-column label="商品名称" prop="commodityName" width="200"
                :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="价格" prop="commodityPrice"></el-table-column>
              <el-table-column label="数量" prop="quantity">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.quantity" @input="handleChange"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="总价" prop="totalPrice">
              </el-table-column>
              <el-table-column label="规格" prop="quantity">
                <template slot-scope="scope">
                  <!-- <el-select v-model="scope.row.specificationsId" placeholder="请选择">
                    <el-option v-for="item in scope.row.sizeList" :key="item.id" :label="item.specifications"
                      :value="item.id">
                    </el-option>
                  </el-select> -->
                  <el-link type="warning" @click="showChangeSpecification(scope.row,scope.$index)">
                    {{scope.row.skuInfo != null ? scope.row.skuInfo : '选择规格' }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <button class="list_selete" @click="listSelete(scope.row.commodityId,scope.$index)"
                    style="margin-left:0;">删除</button>
                </template>
              </el-table-column>
            </el-table>
            <div class="order_page_nation_2" v-if="pageshow" style="width: 100%; text-align: center; margin-top: 20px">
              <el-pagination background :total="list.length" @current-change="listChange"
                :current-page.sync="currentPage" :page-size="4" layout="prev, pager, next"></el-pagination>
              <!-- <page-nation></page-nation> -->
            </div>
          </div>
        </div>
        <div class="addDig">
          <span>备注:</span>
          <el-input v-model="form.remark" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>消费者邮箱:</span>
          <!-- <el-input v-model="form.userEmail" style="width: 200px; margin-left: 20px"></el-input> -->
          <!-- userList -->
          <el-select v-model="form.userEmail" filterable clearable allow-create placeholder="消费者邮箱"
            style="width: 200px; margin-left: 20px">
            <el-option v-for="item in userList" :key="item.email" :label="item.email" :value="item.email">
            </el-option>
          </el-select>
        </div>
        <div class="addDig">
          <span>联系人:</span>
          <el-input v-model="form.contacts" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>电话:</span>
          <el-input v-model="form.phone" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>国家:</span>
          <el-input v-model="form.country" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>城市:</span>
          <el-input v-model="form.city" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>地址:</span>
          <el-input type="textarea" v-model="form.address" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>收货邮箱:</span>
          <el-input v-model="form.email" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>邮政编码:</span>
          <el-input v-model="form.postCode" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div style="text-align: center" @click="addClick(list)">
          <el-button type="primary">添加 </el-button>
        </div>
      </el-dialog>

      <el-dialog title="选择商品规格" width="30%" :visible.sync="skuVisble">
        <div class="skuAll">
          <div class="parentSku" v-for="(parentItem,parentIndex) in dialogSKu.skuParentList">
            <div class="buy_size_title">{{parentItem.skuName}}:</div>
            <div class="buy_sizebox">
              <div class="bug_size_item" v-for="(childItem, childIndex) in parentItem.skuChildList" :key="childIndex"
                v-on:click="specificationBtn(dialogSKu,childItem.skuValueId,parentIndex,$event,childIndex)"
                v-bind:class="[childItem.isShow? '' : 'buy_size_itemDisabled',dialogSKu.subIndex[parentIndex] == childIndex?'buy_size_itemActive':'']">
                <div class="guigeColor" v-if="childItem.skuImgUrl  && childItem.skuImgUrl != null">
                  <img :style="childItem.isShow ? 'width: 100%;height: 100%;' : 'width: 100%;height: 100%;opacity: .3;'"
                    :src="$baseUrl+childItem.skuImgUrl.split(',')[0]" />
                </div>
                <div class="guige">
                  {{ childItem.skuValue }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <p style="text-align: center;">
          <el-button type="success" @click="confirmChangeSpecification">确认</el-button>
        </p>
      </el-dialog>

      <el-dialog title="修改订单" :visible.sync="reviseVisble" @close="closeRevise">
        <!-- <div class="addDig" v-if="!addShow">
          <span>订单id:</span>
          <el-input v-model="form.id" :disabled="true" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>搜索产品:</span>
          <el-select style="margin-left: 20px; width: 200px" v-model="form.commodityId" placeholder="请输入关键词" filterable
            remote reserve-keyword @change="selectChange" ref="elSelect" @blur="blurfn" :popper-append-to-body="false"
            popper-class="my-select">
            <el-option :label="item.label" :value="item.value" v-for="(item, index) in options"
              :key="index"></el-option>
          </el-select>
        </div>
        <div class="addDig">
          <span>购买数量:</span>
          <el-input v-model="form.quantity" @input="quantityfun(form.quantity)"
            style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>商品价格:</span>
          <el-input v-model="form.commodityPrice" @input="pricefun(form.commodityPrice)"
            style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>商品规格:</span>
          <el-select v-model="form.specificationsId" placeholder="请选择" style="width: 200px; margin-left: 20px">
            <el-option v-for="item in editSizeList" :key="item.id" :label="item.specifications" :value="item.id">
            </el-option>
          </el-select>
        </div> -->
        <div style="margin-bottom: 20px">
          <div>
            <el-table :data="listnation" tooltip-effect="dark" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="25"> </el-table-column>
              <el-table-column label="id" prop="commodityId" width="55"></el-table-column>
              <el-table-column label="商品名称" prop="commodityName" width="200"
                :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="价格" prop="price"></el-table-column>
              <el-table-column label="数量" prop="quantity">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.quantity" @change="handleChange2(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="总价" prop="totalPrice"></el-table-column>
              <!-- <el-table-column label="总价" prop="totalPrice">
                <template slot-scope="scope">
                  {{(scope.row.quantity*scope.row.price).toFixed(2)}}
                </template>
              </el-table-column> -->
              <el-table-column label="规格" prop="quantity" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-link type="warning" @click="showChangeSpecification(scope.row,scope.$index)">
                    {{scope.row.skuInfo != null ? scope.row.skuInfo : '选择规格' }}
                  </el-link>
                  <!-- {{scope.row.specifications}} -->
                </template>
              </el-table-column>
            </el-table>
            <div class="order_page_nation_2" v-if="pageshow" style="width: 100%; text-align: center; margin-top: 20px">
              <el-pagination background :total="list.length" @current-change="listChange"
                :current-page.sync="currentPage" :page-size="4" layout="prev, pager, next"></el-pagination>
              <!-- <page-nation></page-nation> -->
            </div>
          </div>
        </div>
        <div class="addDig">
          <span>运费:</span>
          <el-input v-model="form.freightPrice" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>总价:</span>
          <el-input v-model="form.totalPrice" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>备注:</span>
          <el-input v-model="form.remark" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>消费者邮箱:</span>
          <el-input v-model="form.userEmail" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>支付价格:</span>
          <el-input v-model="form.payPrice" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>联系人:</span>
          <el-input v-model="form.contacts" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>电话:</span>
          <el-input v-model="form.phone" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>国家:</span>
          <el-input v-model="form.country" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>城市:</span>
          <el-input v-model="form.city" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>地址:</span>
          <el-input v-model="form.address" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>收货邮箱:</span>
          <el-input v-model="form.email" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>邮政编码:</span>
          <el-input v-model="form.postCode" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div style="text-align: center" @click="reviseClick(form)">
          <el-button type="primary">修改</el-button>
        </div>
      </el-dialog>
      <el-dialog title="发送收款信息邮箱" :visible.sync="emailVisible" @close="emailClose">
        <div class="addDig">
          <span>订单号:</span>
          <el-input v-model="emailForm.orderNo" :disabled="true" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>买家收件邮箱:</span>
          <el-input v-model="emailForm.toUser" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div class="addDig">
          <span>买家回件邮箱:</span>
          <el-input v-model="emailForm.backEmail" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div style="text-align: center">
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading"
            @click="emailfun(emailForm)">发送</el-button>
        </div>
      </el-dialog>

      <!-- 立即评价 -->
      <el-dialog title="Appraise" :visible.sync="evaluateVisible" @close="evaluateClose">
        <div class="addDig addDig_2">
          <div class="orderlist_img" style="text-align: center">
            <img :src="$baseUrl + goodInfo.image" />
          </div>
          <div>
            <div class="orderlist_name">{{ goodInfo.commodityName }}</div>
            <div style="margin-top:10px;">
              <el-checkbox v-model="isAnonymous">Anonymous</el-checkbox>
            </div>
          </div>

        </div>
        <div class="addDig addDig_1">
          <span>Supplier service:</span>
          <el-rate v-model="evaluateForm.supplierServiceScore"></el-rate>
        </div>
        <div class="addDig addDig_1">
          <span>On-time shipment:</span>
          <el-rate v-model="evaluateForm.timeShipmentScore"></el-rate>
        </div>
        <div class="addDig addDig_1">
          <span>Product quality:</span>
          <el-rate v-model="evaluateForm.productQuality"></el-rate>
        </div>
        <div class="addDig addDig_1" style="align-items: self-start;">
          <span>Appraise:</span>
          <el-input v-model="evaluateForm.userAppraise" type="textarea" :rows="4" style="width: 200px;"></el-input>
        </div>
        <!-- <div class="addDig addDig_1" style="align-items: self-start;">
          <span>是否匿名:</span>
          <el-radio v-model="evaluateForm.isAnonymous" label="2">是</el-radio>
            <el-radio v-model="evaluateForm.isAnonymous" label="1">否</el-radio>
        </div> -->
        <div style="text-align: center">
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading"
            @click="evaluatefun(evaluateForm)">Send evaluation
          </el-button>
        </div>
      </el-dialog>

      <!-- 订单详情 -->
      <el-dialog :title="''" :visible.sync="orderDetailVisible" width="95%" class="orderdetail_dialog"
        @close="orderDetailClose">
        <order-detail :orderDetailInfo="orderDetailInfo" :orderDetailVos="orderDetailVos"
          @orderDetailClose="orderDetailClose" v-if="orderDetailVisible"></order-detail>
      </el-dialog>
      <!-- 修改价格 -->
      <el-dialog title="修改价格" :visible.sync="priceVisible" @close="priceClose">
        <div class="addDig">
          <span>价格:</span>
          <!-- <el-input v-model="priceForm.price" type="number" style="width: 200px; margin-left: 20px"></el-input> -->
          <el-input-number v-model="priceForm.price" :precision="2" :step="1"
            style="width: 200px; margin-left: 20px"></el-input-number>
        </div>
        <div class="addDig">
          <span>数量:</span>
          <el-input v-model="priceForm.quantity" style="width: 200px; margin-left: 20px"></el-input>
        </div>
        <div style="text-align: center" @click="priceClick(priceForm)">
          <el-button type="primary">修改</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import OrderList from "./orderlist/OrderList.vue";
  import PageNation from "@/components/pagenation/PageNation.vue";
  import OrderDetail from "./OrderDetail";
  import { keywordSearchApi, getconsumerCommodityDetail, getAddOrderUserEmail } from "../../../api/index";
  import { shopCommodityDetailByIdApi } from "../../../api/business";
  import {
    shopOrderList,
    shopCancelOrder,
    confirmOrderPay,
    exportOrderList,
    shopAddOrEditOrder,
    shopAddOrEditOrderSendEmail,
    shopDelOrder,
    shopConfirmOrderPay,
    shopAddOrders,
    shopEditOrders,
    shopOrdersDetail,
  } from "@/api/business";
  import { addAppraiseOrder, updateDetailOrder } from "@/api/news"

  import {
    shopMyInfo,
  } from "@/api/business";

  export default {
    name: "BusinessOrder",
    data() {
      return {
        nav: ["全部", "未支付", "已支付"],
        navcurrent: 0,
        orderList: [],
        total: {
          pageTotal: 0,
          pageNum: 1,
          pageSize: 5,
          orderStatus: 0,
          isSettlement: 0,
        },
        openOrderlist: false,
        pzVisible: false,
        pzImg: "",
        addVisble: false,
        reviseVisble: false,
        form: {
          id: "",
          commodityId: "",
          quantity: "",
          remark: "",
          totalPrice: "",
          commodityPrice: "",
          userEmail: "",
          payPrice: "",
          contacts: "",
          phone: "",
          country: "",
          city: "",
          address: "",
          email: "",
          postCode: "",
          specificationsId: ""
        },
        loading: false,
        keyform: {
          businessId: 0,
          pageNum: 1,
          pageSize: 1000,
          keyword: "",
        },
        options: [],
        addShow: false,
        emailVisible: false,
        emailForm: {
          orderNo: "",
          toUser: "",
          backEmail: "",
        },
        fullscreenLoading: false,
        list: [],
        listnation: [],
        search: "",
        multipleSelection: [],
        currentPage: 1,
        pageshow: true,
        checked: false,  //是否全选
        evaluateVisible: false,
        evaluateForm: {
          orderId: "",
          orderDetailId: "",
          supplierServiceScore: 0,
          timeShipmentScore: 0,
          productQuality: 0,
          userAppraise: "",
          isAnonymous: 2
        },
        isAnonymous: true,
        goodInfo: {},
        orderDetailVisible: false,  //是否详情弹窗
        orderDetailInfo: {},
        orderDetailVos: [],
        chooseList: [],

        priceForm: {
          id: 0,
          detailId: 0,
          price: 0,
          quantity: 0
        },
        priceVisible: false,
        editSizeList: [],

        skuVisble: false,
        skuChangeIndex: null,
        dialogSKu: {},
        isAdd: false,

        userList: [],
      };
    },
    props: {
      numChange: {
        numChange(e) {
          return e;
        },
      },
    },
    components: {
      // OrderList,
      PageNation,
      OrderDetail
    },
    computed: {},
    watch: {
      $route: {
        handler: function (val) {
          //这里的路由是页面上的路由
          if (val.path == "/businesspersonal/businessorder") {
            //调用你想用的方法
            this.shopOrderListApi();
          } else {
            this.router_show = false;
          }
        },
        // 深度观察监听，普通的watch方法无法监听到对象内部属性的改变，只有data中的数据才能够监听到变化，此时就需要deep属性对对象进行深度监听。
        deep: true,
      },
    },

    methods: {
      // 跳转到inquiry详情-发询盘页面
      addInquiryClick(id, price, name, image, bool, num) {
        this.$router.push({
          path: "/inquiry",
          query: {
            id,
            price,
            name,
            num: num,
            image,
            bool,
            box8: false,
            phoneHomeImg: false,
            noBusiness: false,
          },
        });
        // console.log(totalPrice);
      },
      handleSizeChange(val) {
        this.total.pageSize = val
        this.shopOrderListApi();
      },
      blurfn() {
        setTimeout(() => {
          this.$refs.elSelect.blur();
        }, 100);
      },
      handleSelectionChange1(val) {
        console.log('111:', val)
        this.chooseList = val
      },
      //选择框事件
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      listSelete(commodityId, index1) {
        if (commodityId) {
          // this.list.remove(index1);
          let index = this.list.findIndex(
            (item2) => item2.commodityId == commodityId
          );
          this.list.remove(index);
        } else {
          this.multipleSelection.forEach((item) => {
            let index = this.list.findIndex(
              (item2) => item2.commodityId == item.commodityId
            );
            this.list.remove(index);
          });
        }

        this.listChange(this.currentPage);
      },
      sizeChange(e) {
        // console.log(e);
      },
      listChange(e) {
        this.currentPage = e;
        let arr = JSON.parse(JSON.stringify(this.list));
        this.listnation = arr.splice((e - 1) * 4, 4);
      },
      handleChange(e) {
        this.listnation.forEach((item) => {
          item.totalPrice = (item.commodityPrice * item.quantity).toFixed(2);
          let index = this.list.findIndex(
            (item2) => item2.commodityId == item.commodityId
          );

          this.list[index].quantity = item.quantity;
          this.list[index].totalPrice = item.totalPrice;
          this.list[index].payPrice = this.list[index].totalPrice;
        });
      },
      handleChange2(e) {
        // console.log(e)
        // this.listnation.forEach((item) => {
        //   console.log(item)
        //   item.totalPrice = (item.price * item.quantity).toFixed(2);
        //   let index = this.list.findIndex(
        //     (item2) => item2.commodityId == item.commodityId
        //   );
        //   this.list[index].quantity = item.quantity;
        //   this.list[index].totalPrice = item.totalPrice;
        //   this.list[index].payPrice = this.list[index].totalPrice;
        // });
        e.totalPrice = (e.price * e.quantity).toFixed(2);
        let index = this.list.findIndex(
          (item2) => item2.commodityId == e.commodityId
        );
        this.list[index].quantity = e.quantity;
        this.list[index].totalPrice = e.totalPrice;
        this.list[index].payPrice = this.list[index].totalPrice;
      },
      async selectaddChange() {
        let listObj = {};
        listObj.commodityId = this.form.commodityId;
        listObj.quantity = 1;
        let { price } = this.options.find(
          (item) => item.value == this.form.commodityId
        );
        let { label } = this.options.find(
          (item) => item.value == this.form.commodityId
        );
        label = label.trim().split(/\s+/);
        label.shift();
        label = label.join(" ");
        listObj.commodityName = label;
        listObj.commodityPrice = price;
        listObj.totalPrice = price;
        listObj.payPrice = listObj.totalPrice;
        listObj.selectArr = [];     //存放被选中的值
        listObj.shopItemInfo = {};  //存放要和选中的值进行匹配的数据，map集合 item列表
        listObj.subIndex = [];     //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
        listObj.skuInfo = null;     //存放被选中的值
        listObj.isShow = null;
        listObj.sizeList = [];
        let result = await shopCommodityDetailByIdApi(this.form.commodityId)
        if (result?.code == 200) {
          listObj.sizeList = [{ id: 0, specifications: result.data.specifications }, ...result.data.specificationsVos]
          listObj.skuItemList = result.data.skuItemList
          listObj.skuParentList = result.data.skuParentList
          if (listObj.skuItemList) {
            //存储到map集合中
            for (var i in listObj.skuItemList) {
              listObj.shopItemInfo[listObj.skuItemList[i].skuValueArray] = listObj.skuItemList[i];
            }
            this.checkItem(listObj);
          }
        }
        listObj.userEmail = this.form.userEmail;
        listObj.contacts = this.form.contacts;
        listObj.phone = this.form.phone;
        listObj.country = this.form.country;
        listObj.city = this.form.city;
        listObj.address = this.form.address;
        listObj.email = this.form.email;
        listObj.postCode = this.form.postCode;
        listObj.remark = this.form.remark;
        listObj.specificationsId = 0
        this.list.push(listObj);
        let arr = JSON.parse(JSON.stringify(this.list));
        this.listnation = arr.splice(0, 4);
        this.currentPage = Math.ceil(this.list.length / 4);
        this.listChange(this.currentPage);
        // console.log(this.list)
      },
      //显示商品规格
      showChangeSpecification(row, index) {
        this.skuVisble = true;
        this.skuChangeIndex = index;
        this.dialogSKu = JSON.parse(JSON.stringify(row));
        console.log(row)
      },
      //确定商品规格
      confirmChangeSpecification() {
        var isClose = true;
        if (this.dialogSKu && this.dialogSKu.subIndex && this.dialogSKu.selectArr && this.dialogSKu.skuParentList) {
          if (this.dialogSKu.selectArr.length !== this.dialogSKu.skuParentList.length) {
            this.$message({ message: '请选择正确的商品规格信息', type: "error" });
          } else {
            isClose = false;
            for (var i = 0; i < this.dialogSKu.selectArr?.length; i++) {
              var element = this.dialogSKu.selectArr[i];
              if (element == '') {
                isClose = true;
                break;
              }
            }
            if (isClose) {
              this.$message({ message: '请选择正确的商品规格信息', type: "error" });
            }
            this.skuVisble = isClose;
          }
        } else {
          this.$message({ message: '网络异常获取规格信息失败，请刷新后重新', type: "error" });
        }
        this.skuVisble = isClose;
        if (!this.skuVisble) {
          this.setSkuInfo(this.skuChangeIndex, this.dialogSKu);
          this.changeSkuList(this.skuChangeIndex, this.dialogSKu);
          this.skuChangeIndex = null;
          this.dialogSKu = {};
        }
      },
      //选中规格后更新详情数据
      changeSkuList(skuChangeIndex, listObj) {
        var addOrUpdateArry = null;
        var addListOther = null;
        if (this.isAdd === true) {
          addOrUpdateArry = this.list;
        } else if (this.isAdd === false) {
          addOrUpdateArry = this.listnation;
        }
        if (skuChangeIndex != null && addOrUpdateArry && addOrUpdateArry.length > 0) {
          var commodityInfo = addOrUpdateArry[skuChangeIndex];
          if (this.isAdd === true) {
            addListOther = this.listnation[skuChangeIndex];
          }
          var findSku = false;
          //判断是否选中好所有规格项
          if (commodityInfo.selectArr?.length === commodityInfo.skuParentList?.length) {
            var isNullResult = commodityInfo.selectArr.every(element => {
              return element != null && element.length > 0
            });
            if (isNullResult === true) {
              var skuItemArray = commodityInfo.selectArr.filter(function (item) { return item; }).join(";")
              var findArray = commodityInfo.skuItemList.filter(item => {
                return item.skuValueArray == skuItemArray && item.isDel != 1
              })
              if (findArray != null && findArray.length > 0 && findArray[0]) {
                findSku = true;
                var findItem = findArray[0];
                var commodityPrice = findItem.presentPrice;
                commodityInfo.minNum = findItem.minNum;
                commodityInfo.stock = findItem.stock;
                commodityInfo.commodityPrice = commodityPrice;
                commodityInfo.presentPrice = commodityInfo.commodityPrice;
                commodityInfo.payPrice = commodityInfo.commodityPrice;
                commodityInfo.originalUnit = findItem.originalUnit;
                commodityInfo.minNumUnit = findItem.minNumUnit;
                if (addListOther != null) {
                  addListOther.minNum = findItem.minNum;
                  addListOther.stock = findItem.stock;
                  addListOther.commodityPrice = commodityPrice;
                  addListOther.presentPrice = listObj.commodityPrice;
                  addListOther.payPrice = listObj.commodityPrice;
                  addListOther.originalUnit = findItem.originalUnit;
                  addListOther.minNumUnit = findItem.minNumUnit;
                  addListOther.totalPrice = listObj.commodityPrice;
                }
                if (this.isAdd === true) {
                  this.handleChange();
                }
                else if (this.isAdd === false) {
                  // this.handleChange2();
                  commodityInfo.price = commodityPrice;
                  listObj.price = commodityPrice;
                }
              }
            }
          }
          // if (findSku === false) {
          //   this.list = JSON.parse(JSON.stringify(this.oldList));
          // }
        }
      },
      //更新商品列表的SKU显示信息
      setSkuInfo(skuChangeIndex, listObj) {
        if (listObj.selectArr?.length === listObj.skuParentList?.length) {
          var isNull = false;
          for (var i = 0; i < listObj.selectArr?.length; i++) {
            var element = listObj.selectArr[i];
            if (element == '') {
              isNull = true;
              break;
            }
          }
          if (isNull == false) {
            listObj.skuInfo = null;
            listObj.selectArr.forEach(element => {
              for (var i = 0; i < listObj.skuParentList.length; i++) {
                var parentSku = listObj.skuParentList[i];
                var find = false;
                for (var j = 0; j < parentSku.skuChildList.length; j++) {
                  var childSku = parentSku.skuChildList[j];
                  if (childSku.skuValueId == element) {
                    if (listObj.skuInfo == null || listObj.skuInfo.length <= 0) {
                      listObj.skuInfo = childSku.skuValue
                    } else {
                      listObj.skuInfo += '-' + childSku.skuValue
                    }
                    find = true;
                    break;
                  }
                }
                if (find) {
                  break;
                }
              }
            });
            //保留 sku规格 信息到显示商品的列表
            if (this.isAdd === true && skuChangeIndex != null && this.list && this.list.length > 0) {
              this.list[skuChangeIndex].skuInfo = listObj.skuInfo;
              this.list[skuChangeIndex].selectArr = listObj.selectArr;
              this.list[skuChangeIndex].subIndex = listObj.subIndex;
              this.listnation[skuChangeIndex].skuInfo = listObj.skuInfo;
              this.listnation[skuChangeIndex].selectArr = listObj.selectArr;
              this.listnation[skuChangeIndex].subIndex = listObj.subIndex;
            } else if (this.isAdd === false && skuChangeIndex != null && this.listnation && this.listnation.length > 0) {
              this.listnation[skuChangeIndex].specifications = listObj.skuInfo;
              this.listnation[skuChangeIndex].skuInfo = listObj.skuInfo;
              this.listnation[skuChangeIndex].selectArr = listObj.selectArr;
              this.listnation[skuChangeIndex].subIndex = listObj.subIndex;
            }
          }
        }
      },
      //改变规格
      specificationBtn: function (listObj, skuValueId, parentIndex, event, childIndex) {
        var isClick = listObj?.skuParentList[parentIndex]?.skuChildList[childIndex]?.isShow;
        if (isClick && isClick == true) {
          if (listObj.selectArr[parentIndex] != skuValueId) {
            listObj.selectArr[parentIndex] = skuValueId;
            listObj.subIndex[parentIndex] = childIndex;
          } else {
            listObj.selectArr[parentIndex] = "";
            listObj.subIndex[parentIndex] = -1; //去掉选中的颜色 
          }
          this.checkItem(listObj);
        }
      },
      checkItem: function (listObj) {
        var option = listObj.skuParentList;
        var result = [];  //定义数组存储被选中的值
        for (var i in option) {
          result[i] = listObj.selectArr[i] ? listObj.selectArr[i] : '';
        }
        for (var i in option) {
          var last = result[i];  //把选中的值存放到字符串last去
          for (var k in option[i].skuChildList) {
            result[i] = option[i].skuChildList[k].skuValueId; //赋值，存在直接覆盖，不存在往里面添加name值
            // this.$set(listObj.skuParentList[i].skuChildList[k], "isShow", this.isMay(listObj, result));//在数据里面添加字段isShow来判断是否可以选择
            option[i].skuChildList[k].isShow = this.isMay(listObj, result); //在数据里面添加字段isShow来判断是否可以选择
          }
          result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时避免被覆盖

        }
        this.$forceUpdate(); //重绘
      },
      isMay: function (listObj, result) {
        for (var i in result) {
          if (result[i] == '') {
            return true; //如果数组里有为空的值，那直接返回true
          }
        }
        return listObj.shopItemInfo[result?.filter(function (item) { return item; }).join(";")]?.stock <= 0 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
      },
      closeAdd() {
        this.list = [];
        this.listnation = [];
        Object.keys(this.form).forEach((key) => (this.form[key] = ""));
      },
      closeRevise() {
        this.editSizeList = []
        Object.keys(this.form).forEach((key) => (this.form[key] = ""));
      },
      emailClose() {
        Object.keys(this.emailForm).forEach((key) => (this.emailForm[key] = ""));
      },
      reviseClick(e) {
        this.listnation.forEach(ele => {
          ele.commodityPrice = ele.price;
        })
        this.form.commodityList = this.listnation;
        if (this.form.commodityList == null || this.form.commodityList.length <= 0) {
          this.$message({ message: '请选择需要下单的商品', type: "error" });
          return;
        } else {
          var skuIsChange = true;
          for (var i = 0; i < this.form.commodityList.length; i++) {
            var element = this.form.commodityList[i];
            if (element.selectArr?.length !== element.skuParentList?.length) {
              this.$message({ message: "商品id为'" + element.commodityId + "'的规格未选择", type: "error" });
              return;
            } else {
              var isNullResult = element.selectArr.every(element => {
                return element != null && element.length > 0
              });
              if (isNullResult === false) {
                this.$message({ message: "商品id为'" + element.commodityId + "'的规格未选择", type: "error" });
                return;
              } else {
                var skuItemArray = element.selectArr.filter(function (item) { return item; }).join(";")
                var findArray = element.skuItemList.filter(item => {
                  return item.skuValueArray == skuItemArray && item.isDel != 1
                })
                if (findArray == null || findArray == undefined || findArray.length <= 0) {
                  this.$message({ message: "商品id为'" + element.commodityId + "'所选择规格已下架，请重新选择", type: "error" });
                  return;
                }
              }
            }
          }
        }
        this.form.commodityList.forEach(element => {
          if (element.selectArr?.length && element?.skuItemList?.length) {
            var specificationItemValue = element.selectArr.filter(function (item) { return item; }).join(";");
            this.$set(element, 'specificationItemValue', specificationItemValue);
          }
        });
        //判断 拼接的valueId和规格id是否相等，若不相等则更新为拼接的valueid的id
        for (var i = 0; i < this.form.commodityList.length; i++) {
          if (this.form.commodityList[i].skuItemList?.length) {
            for (var j = 0; j < this.form.commodityList[i].skuItemList?.length; j++) {
              if (this.form.commodityList[i].specificationItemValue == this.form.commodityList[i].skuItemList[j].skuValueArray) {
                this.form.commodityList[i].specificationsId = this.form.commodityList[i].skuItemList[j].id;
                break;
              }

            }
          }
        }
        shopEditOrders({ ...this.form }).then((res) => {
          if (res.code == 200) {
            if (typeof res.data.orderNo == 'string') {
              this.emailForm.orderNo = res.data.orderNo;
            } else {
              if (typeof res.data.orderNo == 'object' && res.data.orderNo && res.data.orderNo.length > 0) {
                this.emailForm.orderNo = res.data.orderNo[0]
              } else {
                this.emailForm.orderNo = res.data.orderNo;
              }
            }
            // this.emailForm.orderNo = res.data.orderNo;
            this.$message({ message: "操作成功", type: "success" });
            Object.keys(this.form).forEach((key) => (this.form[key] = ""));
            this.shopOrderListApi();
            this.addVisble = false;
            this.reviseVisble = false;
            this.emailVisible = true;
          } else {
            this.addVisble = false;
            this.reviseVisble = false;
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      shopConfirmOrderPay(e) {
        this.$confirm("是否确认支付?", {
          confirmButtonText: "确定",
          showCancelButton: true,
        })
          .then(() => {
            shopConfirmOrderPay(e).then((res) => {
              if (res.code == 200) {
                this.$message({ message: "操作成功", type: "success" });
                this.shopOrderListApi();
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            });
          })
          .catch(() => { });
      },
      // 立即评价
      evaluateClick(e) {
        this.evaluateForm.orderId = e.id
        if (e.orderDetailVos && e.orderDetailVos.length > 0) {
          this.evaluateForm.orderDetailId = e.orderDetailVos[0].id
          this.goodInfo = e.orderDetailVos[0]
        }
        this.evaluateVisible = true
      },
      // 评价弹窗关闭
      evaluateClose() {
        Object.keys(this.evaluateForm).forEach((key) => (this.evaluateForm[key] = ""));
        this.evaluateVisible = false
      },
      // 评价提交
      evaluatefun(e) {
        e.isAnonymous = this.isAnonymous ? 2 : 1
        this.fullscreenLoading = true;
        addAppraiseOrder({ ...e }).then(res => {
          if (res.code == 200) {
            this.$message({ message: "提交成功", type: "success" });
            this.shopOrderListApi();
            this.evaluateVisible = false;
            this.fullscreenLoading = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
            this.evaluateVisible = false;
            this.fullscreenLoading = false;
          }
        })
      },
      emailfun(e) {
        this.fullscreenLoading = true;
        shopAddOrEditOrderSendEmail({ ...e }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "发送成功", type: "success" });
            this.shopOrderListApi();
            this.emailVisible = false;
            this.fullscreenLoading = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
            this.emailVisible = false;
            this.fullscreenLoading = false;
          }
        });
      },
      // 查看订单详情
      seeOrderDetail(e) {
        this.orderDetailInfo = e
        this.orderDetailVos = e.orderDetailVos
        this.orderDetailVisible = true
      },
      // 关闭订单详情弹窗
      orderDetailClose(val) {
        this.orderDetailInfo = {}
        this.orderDetailVos = []
        this.orderDetailVisible = false
        if (val) {
          this.shopOrderListApi();
        }
      },
      // 修改价格
      editPriceClick(id, item2) {
        console.log(id, item2)
        this.priceForm.id = id
        this.priceForm.detailId = item2.id
        this.priceForm.price = item2.price
        this.priceForm.quantity = item2.quantity
        this.priceVisible = true
      },
      priceClick(e) {
        updateDetailOrder({ ...this.priceForm }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "操作成功", type: "success" });
            Object.keys(this.priceForm).forEach((key) => (this.priceForm[key] = 0));
            this.shopOrderListApi();
            this.priceVisible = false;
          } else {
            this.priceVisible = false;
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      // 评价弹窗关闭
      priceClose() {
        Object.keys(this.priceForm).forEach((key) => (this.priceForm[key] = 0));
        this.priceVisible = false
      },

      addClick(e) {
        var list = JSON.parse(JSON.stringify(this.list))
        list.forEach((item) => {
          item.payPrice = item.totalPrice;
          delete item.sizeList
        });
        var param = {
          userEmail: this.form.userEmail,
          contacts: this.form.contacts,
          phone: this.form.phone,
          country: this.form.country,
          city: this.form.city,
          address: this.form.address,
          email: this.form.email,
          postCode: this.form.postCode,
          remark: this.form.remark,
          commodityList: list
        }
        if (param.commodityList == null || param.commodityList.length <= 0) {
          this.$message({ message: '请选择需要下单的商品', type: "error" });
          return;
        } else {
          var skuIsChange = true;
          for (var i = 0; i < param.commodityList.length; i++) {
            var element = param.commodityList[i];
            if (element.selectArr?.length !== element.skuParentList?.length) {
              this.$message({ message: "商品id为'" + element.commodityId + "'的规格未选择", type: "error" });
              return;
            } else {
              var isNullResult = element.selectArr.every(element => {
                return element != null && element.length > 0
              });
              if (isNullResult === false) {
                this.$message({ message: "商品id为'" + element.commodityId + "'的规格未选择", type: "error" });
                return;
              } else {
                var skuItemArray = element.selectArr.filter(function (item) { return item; }).join(";")
                var findArray = element.skuItemList.filter(item => {
                  return item.skuValueArray == skuItemArray && item.isDel != 1
                })
                if (findArray == null || findArray == undefined || findArray.length <= 0) {
                  this.$message({ message: "商品id为'" + element.commodityId + "'所选择规格已下架，请重新选择", type: "error" });
                  return;
                }
              }
            }
          }
        }
        param.commodityList.forEach(element => {
          if (element.selectArr?.length && element?.skuItemList?.length) {
            var specificationItemValue = element.selectArr.filter(function (item) { return item; }).join(";");
            this.$set(element, 'specificationItemValue', specificationItemValue);
          }
        });
        shopAddOrders(param).then((res) => {
          if (res.code == 200) {
            if (typeof res.data.orderNo == 'string') {
              this.emailForm.orderNo = res.data.orderNo;
            } else {
              if (typeof res.data.orderNo == 'object' && res.data.orderNo && res.data.orderNo.length > 0) {
                this.emailForm.orderNo = res.data.orderNo[0]
              } else {
                this.emailForm.orderNo = res.data.orderNo;
              }
            }
            // this.emailForm.orderNo = res.data.orderNo;
            this.$message({ message: "操作成功", type: "success" });
            this.list = [];
            this.shopOrderListApi();
            this.addVisble = false;
            this.reviseVisble = false;
            this.emailVisible = true;
          } else {
            this.list = [];
            this.addVisble = false;
            this.reviseVisble = false;
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      selectChange() {
        let { price } = this.options.find(
          (item) => item.value == this.form.commodityId
        );
        this.form.commodityPrice = price;
        this.$forceUpdate();
      },
      selectChange1() {
        this.$forceUpdate();
      },
      quantityfun(e) {
        let totalPrice = e * this.form.commodityPrice, payPrice = e * this.form.commodityPrice
        this.form.totalPrice = totalPrice ? totalPrice : ''
        this.form.payPrice = payPrice ? payPrice : ''
        // this.form.totalPrice = e * this.form.commodityPrice;
        // this.form.payPrice = e * this.form.commodityPrice;
      },
      pricefun(e) {
        let totalPrice = e * this.form.quantity, payPrice = e * this.form.quantity
        this.form.totalPrice = totalPrice ? totalPrice : ''
        this.form.payPrice = payPrice ? payPrice : ''
        this.$forceUpdate();
      },
      remoteMethod(e) {
        this.keyform.keyword = e;
        this.keyform.businessId = sessionStorage.getItem("businessId");
        keywordSearchApi({ ...this.keyform }).then((res) => {
          if (res.code == 200) {
            let arr = [];
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.options = res.data.vos.map((item) => {
                return {
                  value: item.id,
                  label: `id:${item.id}      ${item.commodityName}`,
                  price: item.presentPrice,
                };
              });
            }, 300);
          }
        });
      },
      //获取消费者列表
      getuserList() {
        getAddOrderUserEmail().then((res) => {
          this.userList = res.data;
        })
      },
      sendEmail(e, userEmail) {
        this.emailVisible = true;
        this.emailForm.orderNo = e;
        this.emailForm.toUser = userEmail;
        shopMyInfo().then(res => {
          if (res.code == 200) {
            this.emailForm.backEmail = res.data.email;
          }
        });
      },
      exportOrder() {
        // let arr = [];
        let obj = {
          ids: []
        };
        if (this.checked) {
          obj.isAll = 0
        }
        this.chooseList.find((item) => {
          // arr.push(item.id);
          obj.ids.push(item.id);
        });
        // this.orderList.find((item) => {
        //   if (item.checked) {
        //     obj.ids.push(item.id);
        //   }
        // });
        if (obj.ids.length > 0) {
          exportOrderList({ ...obj }).then((res) => {
            let objectUrl = window.URL.createObjectURL(new Blob([res]));
            const elink = document.createElement("a");
            elink.download = `订单.xlsx`;
            elink.style.display = "none";
            elink.href = objectUrl;
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            window.URL.revokeObjectURL(elink.href);
          });
        } else {
          this.$message({ message: "请先选择要导出的订单", type: "warning" });
        }
      },
      addOrder() {
        this.getuserList();
        this.isAdd = true;
        this.remoteMethod('')
        this.addVisble = true;
        this.reviseVisble = false;
        this.addShow = true;
        this.list = [];
        this.listnation = [];
        Object.keys(this.form).forEach((key) => (this.form[key] = ""));
      },
      async updateOrder() {
        this.getuserList();
        this.isAdd = false;
        let arr = [];
        let obj = {};
        if (this.chooseList && this.chooseList.length > 0) {
          arr = this.chooseList
          obj = this.chooseList[0]
        }
        // this.orderList.find((item) => {
        //   if (item.checked) {
        //     arr.push(item.id);
        //     obj = item;
        //   }
        // });
        if (arr.length > 1) {
          this.$message({ message: "一次只能修改一个订单", type: "warning" });
        } else if (arr.length < 1) {
          this.$message({ message: "请选择要修改的订单", type: "warning" });
        } else if (obj.isBusinessAdd != 1) {
          this.$message({ message: "只能修改卖家帮下订单", type: "warning" });
        } else {
          let result = await shopOrdersDetail(obj.id)
          if (result?.code == 200) {
            if (result?.data?.orderDetailVos) {
              this.listnation = result?.data?.orderDetailVos;
            }
            // this.editSizeList = [{ id: 0, specifications: result.data.specifications }, ...result.data.specificationsVos]
          }
          if (this.listnation) {
            this.listnation.forEach(commodity => {
              getconsumerCommodityDetail(commodity.commodityId).then(commodityResult => {
                console.log(commodityResult)
                if (commodityResult?.code == 200) {
                  commodity.sizeList = commodityResult.data?.specificationsVos;
                  this.$set(commodity, 'skuItemList', commodityResult.data.skuItemList);
                  this.$set(commodity, 'skuParentList', commodityResult.data.skuParentList);
                  this.$set(commodity, 'shopItemInfo', {});
                  this.$set(commodity, 'selectArr', []);
                  this.$set(commodity, 'subIndex', []);
                  this.$set(commodity, 'skuInfo', commodity.specifications);
                  if (commodity.skuItemList && commodity.skuItemList.length > 0) {
                    //设置slectArr选中的valueId
                    for (var i = 0; i < commodity.skuItemList.length; i++) {
                      if (commodity.skuItemList[i].id == commodity.specificationsId) {
                        commodity.selectArr = commodity.skuItemList[i].skuValueArray.split(';');
                        break;
                      }
                    }
                    //设置subIndex所选中下标
                    if (commodity.selectArr && commodity.selectArr.length > 0) {
                      commodity.selectArr.forEach(element => {
                        for (var j = 0; j < commodity.skuParentList?.length; j++) {
                          var childFind = false;
                          for (var x = 0; x < commodity.skuParentList[j]?.skuChildList?.length; x++) {
                            if (commodity.skuParentList[j]?.skuChildList[x].skuValueId == element) {
                              commodity.subIndex[j] = x;
                              childFind = true;
                              break;
                            }
                          }
                          if (childFind === true) {
                            break;
                          }
                        }
                      });
                    }
                    //存储到map集合中
                    for (var i in commodity.skuItemList) {
                      commodity.shopItemInfo[commodity.skuItemList[i].skuValueArray] = commodity.skuItemList[i];
                    }
                    this.checkItem(commodity);
                  }
                }
              });
            })
          }
          this.remoteMethod('')
          this.addVisble = false;
          this.reviseVisble = true;
          this.addShow = false;
          if (result?.data) {
            this.form = {
              id: result?.data.id,
              commodityId: result?.data.commodityId,
              specificationsId: result?.data.specificationsId,
              // quantity: obj.quantity,

              remark: result?.data.remark,

              totalPrice: result?.data.totalPrice,
              // commodityPrice: obj.orderDetailVos[0].price,
              userEmail: result?.data.userEmail,
              payPrice: result?.data.payPrice,
              contacts: result?.data.contacts,
              phone: result?.data.phone,
              country: result?.data.country,
              city: result?.data.city,
              address: result?.data.address,
              email: result?.data.email,
              postCode: result?.data.postCode,
              freightPrice: result?.data.freightPrice,
            };
          } else {
            this.form = {
              id: obj.id,
              commodityId: "",
              specificationsId: 0,
              // quantity: obj.quantity,

              remark: obj.remark,

              totalPrice: obj.totalPrice,
              // commodityPrice: obj.orderDetailVos[0].price,
              userEmail: obj.userEmail,
              payPrice: obj.payPrice,
              contacts: obj.contacts,
              phone: obj.phone,
              country: obj.country,
              city: obj.city,
              address: obj.address,
              email: obj.email,
              postCode: obj.postCode,
            };
          }
        }
      },
      deleteOrder() {
        let arr = [];
        let obj = {};
        console.log(this.chooseList)
        if (this.chooseList && this.chooseList.length > 0) {
          this.chooseList.find((item) => {
            arr.push(item.id);
          });
          obj = this.chooseList[0]
        }

        // this.orderList.find((item) => {
        //   if (item.checked) {
        //     arr.push(item.id);
        //     obj = item;
        //   }
        // });
        if (arr.length > 0 && arr.length < 2 && obj.isBusinessAdd == 1) {
          this.$confirm("是否删除?", {
            confirmButtonText: "确定",
            showCancelButton: true,
          })
            .then(() => {
              shopDelOrder(arr.toString()).then((res) => {
                if (res.code == 200) {
                  this.$message({ message: "删除成功", type: "success" });
                  // this.orderList.forEach((item) => {
                  //   console.log(item);
                  //   item.checked = false;
                  // });
                  this.shopOrderListApi();
                } else {
                  this.$message({ message: res.msg, type: "error" });
                }
              });
            })
            .catch(() => { });
        } else if (obj.isBusinessAdd != 1 && arr.length > 0) {
          this.$message({ message: "只能删除卖家帮下订单", type: "warning" });
        } else if (arr.length > 1) {
          this.$message({ message: "一次只能删除一个订单", type: "warning" });
        } else {
          this.$message({ message: "请选择要删除的订单", type: "warning" });
        }
      },
      checkfun(e, id) {
        this.orderList.map((item) => {
          if (item.id == id) {
            item.checked = e.target.checked;
          }
          return item.checked;
        });
        // if(this.ids.length<1){
        //   this.ids.push(e.id)
        // }else{
        //   this.ids.forEach(item=>{
        //     if(item!=e.id){
        //       this.ids.push(e.id)
        //     }
        //   })
        // }
        // console.log(this.ids);
      },
      // 全选以及取消全选
      checkfunAll() {
        this.orderList.forEach((item) => {
          item.checked = !item.checked
        });
        this.$forceUpdate()
        console.log('全选：', this.orderList)
      },
      lookpz(e) {
        this.pzVisible = true;
        this.pzImg = this.$baseUrl + e;
      },
      //确认订单已支付
      confirmOrderPayApi(item) {
        // var orderNo = item.payMode == 1 ? item.orderNo : item.carOrderNo;
        var orderNo = item.orderNo;
        this.$confirm(
          "此操作将确认离线付款订单 " + orderNo + " 已付款，是否继续?",
          "提示",
          {
            confirmButtonText: "继续",
            cancelButtonText: "取消",
            type: "warning",
            confirmButtonClass: "colorClass",
          }
        )
          .then(() => {
            var form = {
              orderNo: orderNo,
              orderType: item.orderType,
            };
            confirmOrderPay(form).then((res) => {
              if (res.code == 200) {
                this.$message({ message: "操作成功", type: "success" });
                this.shopOrderListApi();
              } else if (res.code == 401) {
                this.$message({ message: res.msg, type: "error" });
                //未登录
                this.$router.push({
                  path: "/login",
                  query: {
                    box8: false,
                    phoneHomeImg: false,
                    bool: false,
                    // token: true,
                  },
                });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            });
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "Cancel confirmation",
            // });
          });
      },
      //取消订单
      shopCancelOrderApi(item) {
        // var orderNo = item.payMode == 1 ? item.orderNo : item.carOrderNum;
        var orderNo = item.orderNo;
        this.$confirm(
          "此操作将取消订单号 " + orderNo + ". 是否要继续??",
          "提示",
          {
            confirmButtonText: "继续",
            cancelButtonText: "取消",
            type: "warning",
            confirmButtonClass: "colorClass",
          }
        )
          .then(() => {
            var from = {
              orderNo: orderNo,
              orderType: item.orderType,
            };
            shopCancelOrder(from).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "取消成功",
                  type: "success",
                });
                this.shopOrderListApi();
              } else if (res.code == 401) {
                this.$message({ message: res.msg, type: "error" });
                //未登录
                this.$router.push({
                  path: "/login",
                  query: {
                    box8: false,
                    phoneHomeImg: false,
                    bool: false,
                    // token: true,
                  },
                });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            });
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "Cancel the operation",
            // });
          });
      },
      navfun(e) {
        this.navcurrent = e;
        if (e == 0) {
          this.total.orderStatus = 0;
        } else if (e == 1) {
          this.total.orderStatus = 1;
        } else if (e == 2) {
          this.total.orderStatus = 2;
        }
        this.total.pageNum = 1;
        this.shopOrderListApi();
      },
      currentChange(e) {
        this.checked = false
        this.total.pageNum = e;
        this.shopOrderListApi();
      },
      // navfun(e) {
      //   this.navcurrent = e;
      //   // this.getSortType(e);
      // },
      //商家订单列表
      shopOrderListApi() {
        shopOrderList(this.total).then((res) => {
          if (res.code == 200) {
            this.orderList = res.data.vos;
            this.total.pageTotal = res.data.total;
            this.orderList.forEach((item) => {
              item.checked = false;
            });
          } else if (res.code == 401) {
            this.$message({ message: res.msg, type: "error" });
            //未登录
            this.$router.push({
              path: "/login",
              query: {
                box8: false,
                phoneHomeImg: false,
                bool: false,
                // token: true,
              },
            });
          }
        });
      },
      clickActive(e) {
        let childs = document.querySelector(".order_nav").childNodes;
        for (let i = 0; i < childs.length; i++) {
          childs[i].classList.remove("order_active");
        }
        // if(i==0){
        //     this.total.orderStatus = 0;
        //   }else if(i==1){
        //     this.total.orderStatus = 1;
        //   }else if(i==2){
        //     this.total.orderStatus = 2;
        //   }
        //   this.shopOrderListApi();
        e.target.classList.add("order_active");
      },
      // 点击收放
      switchOrderlist(e) {
        this.openOrderlist = !this.openOrderlist;
        if (this.openOrderlist) {
          e.currentTarget.classList.replace("open_orderlist", "close_orderlist");
          e.currentTarget.previousElementSibling.classList.remove(
            "orderlist_main"
          );
        } else {
          e.currentTarget.classList.replace("close_orderlist", "open_orderlist");
          e.currentTarget.previousElementSibling.classList.add("orderlist_main");
        }
      },
    },
    mounted() {
      // 自定义删除方法
      Array.prototype.remove = function (from, to) {
        var rest = this.slice((to || from) + 1 || this.length);
        this.length = from < 0 ? this.length + from : from;
        return this.push.apply(this, rest);
      };
      this.shopOrderListApi();
    },
    activated() { },
  };
</script>
<style>
  .order_list_drop_down {
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: thin;
    background-color: #F7F6FF;
  }

  .colorClass {
    background-color: #13af87 !important;
  }

  .el-select-dropdown__list {
    display: flex;
    flex-direction: column;
  }


  @media screen and (max-width: 1080px) {

    .order_page_nation_2 .el-pagination.is-background .btn-next,
    .order_page_nation_2 .el-pagination.is-background .btn-prev,
    .order_page_nation_2 .el-pagination.is-background .el-pager li {
      min-width: 20px;
    }
  }
</style>

<style scoped>
  /deep/ .my-select .el-select-dropdown__item {
    width: calc(700 * 100vw / 1920);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /deep/ .orderdetail_dialog .el-dialog__body {
    background-color: #ECEEEF;
  }

  /deep/ .orderdetail_dialog .el-dialog__footer {
    background-color: #ECEEEF;
  }

  .list_selete {
    padding: calc(10 * 100vw / 1920) calc(15 * 100vw / 1920);
    border: 1px solid #ddd;
    border-radius: calc(10 * 100vw / 1920);
    margin-left: calc(20 * 100vw / 1920);
    font-size: calc(26 * 100vw / 1920);
    background-color: #409eff;
    color: #fff;
  }

  /* /deep/.el-dialog {
  width: 35%;
} */
  .addDig {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .addDig span {
    width: 20%;
  }

  .addDig_1 span {
    /* width: 30%; */
    width: calc(200 * 100vw / 1920);
  }

  .addDig_2 {
    align-items: flex-start;
  }

  /* .addDig_2 .el-checkbox{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
} */
  .order-tag {
    display: flex;
  }

  .order-tag-btn {
    display: flex;
    align-items: center;
  }

  .order-tag-btn div {
    margin-right: 15px;
    /* width: calc(80 * 100vw / 1920); */
    width: calc(95 * 100vw / 1920);
  }

  .order_page {
    /*width: calc(890 * 100vw / 1920);*/

    width: 100%;
    /* height: calc(740 * 100vw / 1920); */
    min-height: 100%;
    background: #ffffff;
  }

  .order_title {
    margin-left: calc(38 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .order_main {
    border-top: 1px solid #ddd;
    padding-top: calc(40 * 100vw / 1920);
    padding-bottom: calc(20 * 100vw / 1920);
  }

  .order_nav {
    margin-left: calc(22 * 100vw / 1920);
  }

  .order_nav>div {
    width: calc(120 * 100vw / 1920);
    height: calc(40 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 20px;
    margin-right: calc(20 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
    line-height: calc(35 * 100vw / 1920);
  }

  .order_active {
    background: #13af87 !important;
    color: #ffffff !important;
  }

  .order_list {
    /*width: calc(820 * 100vw / 1920);*/
    width: calc(100% - calc(70 * 100vw / 1920));
    /* height: calc(440 * 100vw / 1920); */
    min-height: calc(440 * 100vw / 1920);
    margin: calc(40 * 100vw / 1920) calc(35 * 100vw / 1920);
    overflow: hidden;
  }

  .order_status {
    color: #E54C5E;
    background: rgba(229, 76, 94, .2);
    border-radius: 30px;
    padding: 5px 10px;
    font-size: 13px;
  }

  .order_list_table .order_list_table_btn .el-button {
    margin: 5px;
  }

  /deep/.order_list_table th.el-table__cell>.cell {
    padding-left: 14px;
  }

  .order_list1_state {
    color: #fda911;
  }

  .order_list2_state {
    color: #aaaaaa;
  }

  .order_list1_button {
    background: #13af87 !important;
    color: #ffffff !important;
  }

  .order_list2_button {
    border: 1px solid #13af87 !important;
    color: #13af87 !important;
  }


  .page_nation {
    display: inline-block;
  }

  .page_nation2 {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    /deep/ .my-select .el-select-dropdown__item {
      width: calc(900 * 100vw / 1080);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    /deep/ .orderdetail_dialog .el-dialog__body {
      padding: 0 !important;
    }

    .list_selete {
      padding: calc(15 * 100vw / 1080) calc(20 * 100vw / 1080);
      border: 1px solid #ddd;
      border-radius: calc(10 * 100vw / 1080);
      margin-left: calc(20 * 100vw / 1080);
      font-size: calc(44 * 100vw / 1080);
      background-color: #409eff;
      color: #fff;
    }

    .el-input-number {
      width: calc(170 * 100vw / 1080);
    }

    .order-tag {
      flex-direction: column;
      align-items: center;
    }

    .order-tag-btn div {
      margin-top: 10px;
      width: calc(170 * 100vw / 1080);
    }

    /deep/.el-dialog {
      width: 90%;
    }

    .addDig_1 span {
      width: calc(335 * 100vw / 1080);
    }

    .order_page {
      width: 100vw;
      /* height: calc(1504 * 100vw / 1080); */
      background-color: #fff;
      overflow: hidden;
    }

    .order_title {
      margin-left: calc(40 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(150 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(170 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .order_main {
      border-top: 1px solid #ddd;
      padding-top: calc(70 * 100vw / 1080);
      margin-bottom: calc(0);
    }

    .order_nav {
      margin-left: calc(58 * 100vw / 1080);
    }

    .order_nav>div {
      width: calc(240 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: calc(30 * 100vw / 1080);
      margin-right: calc(60 * 100vw / 1080);
      font-size: calc(32 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
      text-align: center;
      line-height: calc(60 * 100vw / 1080);
    }

    .order_list {
      width: 100%;
      /* height: calc(1000 * 100vw / 1080); */
      margin: 0;
      margin-top: calc(60 * 100vw / 1080);
      padding: calc(70 * 100vw / 1080) calc(40 * 100vw / 1080) 0;
      background-color: #f6f6f6;
      /* justify-content: space-between; */
      overflow: hidden;
      overflow-y: scroll;
    }

    .phoneShow {
      display: block;
    }

    .order_list1_state {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #fda911;
    }

    .order_list2_state {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #aaaaaa;
    }
  }

  /*  */
  .orderlist {
    width: 100%;
    /* height: calc(210 * 100vw / 1920); */
    border: 1px solid #dddddd;
    margin-bottom: calc(20 * 100vw / 1920);
    padding-bottom: calc(10 * 100vw / 1920);
  }

  .orderlist_main {
    /* height: calc(150 * 100vw / 1920); */
    height: auto;
    overflow: hidden;
  }

  .orderlist_li {
    margin: 0 calc(40 * 100vw / 1920);
    padding: calc(20 * 100vw / 1920) 0;
    border-bottom: 1px solid #ddd;
  }

  .orderlist_li:last-child {
    border-bottom: none !important;
  }

  .orderlist_img {
    width: calc(130 * 100vw / 1920);
    height: calc(130 * 100vw / 1920);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: calc(20 * 100vw / 1920);
  }

  .orderlist_img img {
    max-width: 99%;
    max-height: 99%;
  }

  .orderlist_right {
    width: calc(500 * 100vw / 1920);
    display: flex;
    flex-wrap: wrap;
    font-size: calc(20 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 600;
    justify-content: space-between;
    align-content: space-between;
    padding: calc(10 * 100vw / 1920) 0;
    flex-grow: 1;
    flex-direction: column;
  }

  .orderlist_info .orderlist_id {
    width: 100%;
    height: calc(55 * 100vw / 1920);
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 0 calc(20 * 100vw / 1920);
  }

  .ordinfo {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px calc(40 * 100vw / 1920);
    flex-wrap: wrap;
  }

  .ordinfo .contacts {
    margin-bottom: 5px;
  }

  .orderlist_id .orderlist_no {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    margin-left: 10px;
  }

  .orderlist_id .orderlist_state {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 600;
  }

  .orderlist_name {
    width: calc(1100 * 100vw / 1920);
    word-wrap: break-word;
    color: #333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /*控制文字行数*/
    text-overflow: ellipsis;
    /*显示省略符号来代表被修剪的文本*/
    overflow: hidden;
  }

  .orderlist_name>span {
    margin-right: calc(20 * 100vw / 1080);
  }

  .orderlist_price {
    /* max-width: calc(400 * 100vw / 1920); */
    margin-bottom: calc(8 * 100vw / 1920);
    color: #ff0000;
  }

  .orderlist_buyer {
    margin-bottom: calc(8 * 100vw / 1920);

    color: #333;
  }

  .open_orderlist {
    width: 100%;
    height: calc(72 * 100vw / 1920);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .open_orderlist::after {
    content: "\ec0b";
    margin-left: calc(11 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    margin-top: calc(5 * 100vw / 1920);
  }

  .close_orderlist {
    width: 100%;
    height: calc(72 * 100vw / 1920);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .close_orderlist::after {
    content: "\ec0c";
    margin-left: calc(11 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    margin-top: calc(5 * 100vw / 1920);
  }

  .orderlist_info_bottom {
    justify-content: space-between;
    align-items: center;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    padding: 0 calc(30 * 100vw / 1920);
    margin-top: calc(15 * 100vw / 1920);
  }

  .orderlist_info_bottom button {
    padding: 5px;
    width: calc(120 * 100vw / 1920);
    /* height: calc(36 * 100vw / 1920); */
    background: #ffffff;
    border: 1px solid #aaaaaa;
    border-radius: 18px;
    margin-left: calc(16 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .orderlist_info_bottom .evaluate_btn {
    background: #ffffff;
    border: 1px solid #ff0000;
    color: #ff0000;
  }

  .skuAll {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .parentSku {
      width: 100%;
      display: flex;
      margin: 10px 0;

      .buy_size_title {
        font-size: calc(18* 100vw / 1920);
        font-family: Roboto;
        font-weight: 400;
        color: #666666;
        line-height: calc(36* 100vw / 1920);
        margin-right: calc(30* 100vw / 1920);
        display: inline-block;
      }

      .buy_sizebox {
        display: flex;
        flex-wrap: wrap;
        width: 80%;

        .bug_size_item {
          height: calc(40* 100vw / 1920);
          line-height: calc(40* 100vw / 1920);
          font-size: calc(18* 100vw / 1920);
          margin-bottom: calc(10* 100vw / 1920);
          display: flex;
          justify-content: space-around;
          margin-right: calc(10* 100vw / 1920);
          border: 1px solid #13af87;
          max-width: 90%;

          .guige {
            display: inline-block;
            padding: 0 calc(10 * 100vw / 1920);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 90%;
          }

          .guigeColor {
            display: inline-block;
            width: calc(40* 100vw / 1920);
          }
        }

        .buy_size_itemActive {
          border: 1px solid rgb(250, 1, 1);
        }

        .buy_size_itemDisabled {
          cursor: not-allowed;
          color: #b9b0b0;
          border-color: #dadde0;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .orderlist_main {
      /* height: calc(280 * 100vw / 1080); */
      height: auto;
      overflow: hidden;
    }

    .open_orderlist {
      width: 100%;
      height: calc(144 * 100vw / 1080);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
    }

    .open_orderlist::after {
      content: "\ec0b";
      margin-left: calc(22 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      margin-top: calc(10 * 100vw / 1080);
    }

    .close_orderlist {
      width: 100%;
      height: calc(144 * 100vw / 1080);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
    }

    .close_orderlist::after {
      content: "\ec0c";
      margin-left: calc(22 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      margin-top: calc(10 * 100vw / 1080);
    }

    .orderlist {
      width: 100%;
      /* height: calc(400 * 100vw / 1080); */
      border: none;
      background-color: #fff;
      box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
      margin-bottom: calc(30 * 100vw / 1080);
      padding-bottom: calc(30 * 100vw / 1080);
    }

    .orderlist_img {
      width: calc(250 * 100vw / 1080);
      height: calc(250 * 100vw / 1080);
      margin-right: calc(20 * 100vw / 1080);
    }

    .orderlist_info .orderlist_id {
      /* width: calc(684 * 100vw / 1080); */
      height: calc(83 * 100vw / 1080);
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding: 0 calc(40 * 100vw / 1080);
    }

    .ordinfo {
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding: 4px calc(40 * 100vw / 1080);
      font-size: calc(30 * 100vw / 1080);
      flex-wrap: wrap;
    }

    .orderlist_main {
      height: calc(260 * 100vw / 1080);
      overflow: hidden;
    }

    .ordinfo .contacts {
      width: 60%;
      margin-bottom: 5px;
    }

    .orderlist_id .orderlist_no {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
    }

    .orderlist_id .orderlist_state {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
    }

    .orderlist_name {
      width: calc(450 * 100vw / 1080);
      margin-top: calc(10 * 100vw / 1080);
      margin-bottom: calc(10 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #333;
    }

    .orderlist_price {
      margin-bottom: calc(7 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #ff0000;
    }

    .orderlist_buyer {
      margin-bottom: calc(14 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #333;
    }

    .orderlist_info_bottom {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
      padding: 0 calc(40 * 100vw / 1080);
      margin-top: calc(20 * 100vw / 1080);
      flex-wrap: wrap;
    }

    .orderlist_info_bottom button {
      width: calc(180 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #aaaaaa;
      border-radius: calc(30 * 100vw / 1080);
      margin-top: calc(5 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    .page_nation {
      display: none;
    }

    .page_nation2 {
      display: inline-block;
    }

    .skuAll {
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }

    .skuAll .parentSku {
      width: 100%;
      display: flex;
      margin: calc(15 * 100vw / 1080) 0;
    }

    .skuAll .parentSku .buy_size_title {
      font-size: calc(32 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #666666;
      line-height: calc(36* 100vw / 1920);
      margin-right: calc(30* 100vw / 1920);
      display: inline-block;
    }

    .skuAll .parentSku .buy_sizebox {
      display: flex;
      flex-wrap: wrap;
      width: 80%;

    }

    .skuAll .parentSku .buy_sizebox .bug_size_item {
      height: calc(100 * 100vw / 1080);
      line-height: calc(40* 100vw / 1920);
      font-size: calc(18* 100vw / 1920);
      margin-bottom: calc(30* 100vw / 1920);
      display: flex;
      justify-content: space-around;
      margin-right: calc(30* 100vw / 1920);
      border: 1px solid #13af87;
      max-width: calc(650* 100vw / 1080);
    }

    .skuAll .parentSku .buy_sizebox .bug_size_item .guige {
      display: inline-block;
      padding: 0 calc(30* 100vw / 1920);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: calc(32* 100vw / 1080);
      font-family: Roboto;
      font-weight: 600;
      color: #666666;
      line-height: calc(100* 100vw / 1080);
    }

    .skuAll .parentSku .buy_sizebox .bug_size_item .guigeColor {
      display: inline-block;
      width: calc(100 * 100vw / 1080);
    }

    .skuAll .parentSku .buy_sizebox .buy_size_itemActive {
      border: 1px solid rgb(250, 1, 1);
    }

    .skuAll .parentSku .buy_sizebox .buy_size_itemDisabled {
      cursor: not-allowed;
      color: #b9b0b0;
      border-color: #dadde0;
    }
  }
</style>