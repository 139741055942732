<template>
  <div class="inquiry_page">
    <div class="inquiry">
      <p class="directory">
        <span @click="goback" class="directory_home">Fine Ceramics </span>> Add
        to Inquiry
      </p>
      <div class="inquiry_main">
        <div class="goodsInfo flex-row">
          <div class="goodsInfo_img">
            <img :src="$baseUrl + image" alt="" />
          </div>
          <div class="goodsInfo_message">
            <div class="goodsInfo_message_title">
              {{ name }}
            </div>
            <div class="goodsInfo_message_price">
              Price:<span>${{ price }}</span>
            </div>
            <div class="goodsInfo_message_num">
              Quantity:&nbsp;&nbsp;&nbsp; {{ form.quantity }}
            </div>
          </div>
        </div>
        <div class="personInfo">
          <!--<div class="personInfo_email flex-row">
            <div class="personInfo_email_title">First Name:</div>
            <input
              type="text"
              v-model="form.firstName"
              placeholder="First Name"
            />
          </div>
          <div class="personInfo_email flex-row">
            <div class="personInfo_email_title">Last Name:</div>
            <input
              type="text"
              v-model="form.lastName"
              placeholder="Last Name"
            />
          </div>-->
          <div class="personInfo_email flex-row">
            <div class="personInfo_email_title">Email:</div>
            <input type="email" v-model="form.email" placeholder="Email" />
          </div>
          <div class="personInfo_counery flex-row">
            <div class="personInfo_counery_title">Country:</div>
            <select v-model="form.address" placeholder="Address">
              <option :label="item.countryEn" :value="item.countryEn" v-for="(item,index) in countryList" :key="index">
              </option>
            </select>
          </div>
          <!--<div class="personInfo_email flex-row">
            <div class="personInfo_email_title">Address:</div>
            <input type="text" v-model="form.address" placeholder="Address" />
          </div>
          <div class="personInfo_phone flex-row">
            <div class="personInfo_phone_title">Phone:</div>
            <input type="number" v-model="form.phone" placeholder="Phone" />
          </div>-->
          <div class="personInfo_message flex-row">
            <div class="personInfo_message_title">Message:</div>
            <textarea v-model="form.message" placeholder="Message" />
          </div>
          <!-- <div class="personInfo_code flex-row">
            <div class="personInfo_code_title">Verification Code:</div>
            <input type="text" />
            <div class="personInfo_code_img">
              <img src="../../../../assets/img/图片验证.png" alt="" />
            </div>
          </div> -->
          <button class="personInfo_submit" @click="submit(form)">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { addEnquiries } from "../../../../api/other";
  import countryCode from "@/utils/country"
  export default {
    name: "Inquiry",
    data() {
      return {
        form: {
          commodityId: 0,
          // firstName: "",
          // lastName: "",
          address: "",
          email: "",
          // phone: "",
          message: "",
          quantity: 0,
        },
        name: "",
        price: 0,
        image: "",
        countryList: countryCode
      };
    },
    components: {},
    computed: {},
    watch: {},
    created() {
      this.getId();
      this.sortContory();
    },
    methods: {
      sortContory() {
        this.countryList.sort((a, b) => a.countryEn.charCodeAt(0) - b.countryEn.charCodeAt(0));
      },
      goback() {
        this.$router.go(-1);
      },
      submit(e) {
        // if (!e.firstName) {
        //   this.$message({
        //     message: "Please fill in your firstName",
        //     type: "warning",
        //   });
        //   return;
        // }
        // if (!e.lastName) {
        //   this.$message({
        //     message: "Please fill in your lastName",
        //     type: "warning",
        //   });
        //   return;
        // }
        if (!e.email) {
          this.$message({
            message: "Please fill in your email address",
            type: "warning",
          });
          return;
        }
        if (!e.address) {
          this.$message({
            message: "Please select a country",
            type: "warning",
          });
          return;
        }
        // if (!e.phone) {
        //   this.$message({
        //     message: "Please enter your phone number",
        //     type: "warning",
        //   });
        //   return;
        // }
        if (!e.message) {
          this.$message({
            message: "Please enter your phone message",
            type: "warning",
          });
          return;
        } else {
          addEnquiries({ ...e }).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "Operation succeeded", type: "success" });
              this.$router.go(-1);
            } else if (res.code == 401) {
              this.$message({
                message: "Please operate after login",
                type: "error",
              });
              this.$router.push({
                path: "/login",
                query: {
                  bool: false,
                  box8: false,
                  phoneHomeImg: false,
                },
              });
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          });
        }
      },
      getId() {
        this.form.commodityId = this.$route.query.id;
        this.price = this.$route.query.price;
        this.name = this.$route.query.name;
        this.image = this.$route.query.image;
        this.form.quantity = this.$route.query.num;
      },
    },
    mounted() {
      this.totalPrice = this.$route.query.inquiryPrice;
      this.form.quantity = this.$route.query.num;
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
    },
  };
</script>

<style scoped>
  /* WebKit, Blink, Edge */
  ::-webkit-input-placeholder {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 300;
    color: #aaaaaa;
    padding-left: calc(24 * 100vw / 1920);
  }

  /* Mozilla Firefox 4 to 18 */
  :-moz-placeholder {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 300;
    color: #aaaaaa;
    padding-left: calc(24 * 100vw / 1920);
  }

  /* Mozilla Firefox 19+ */
  ::-moz-placeholder {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 300;
    color: #aaaaaa;
    padding-left: calc(24 * 100vw / 1920);
  }

  /* Internet Explorer 10-11 */
  :-ms-input-placeholder {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 300;
    color: #aaaaaa;
    padding-left: calc(24 * 100vw / 1920);
  }

  .inquiry_page {
    width: 100vw;
    background-color: #f1f1f1;
  }

  .inquiry {
    /*width: calc(1200 * 100vw / 1920);*/
    width: 90%;
    margin: calc(20 * 100vw / 1920) auto;
  }

  .inquiry .directory {
    background-color: #f1f1f1;
    margin: 0;
    padding-bottom: calc(20 * 100vw / 1920);
    font-size: 0.93vw;
    font-family: Roboto;
    font-weight: 400;
    color: #999999;
  }

  .inquiry_main {
    padding-left: calc(22 * 100vw / 1920);
    padding-right: calc(38 * 100vw / 1920);
    padding-top: calc(25 * 100vw / 1920);
    background: #ffffff;
  }

  .inquiry_main .goodsInfo_img {
    width: calc(300 * 100vw / 1920);
    height: calc(280 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    /* background-image: url(../../../../assets/img/图层2.png); */
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(148 * 100vw / 1920) calc(210 * 100vw / 1920);
    margin-right: calc(40 * 100vw / 1920);
  }

  .goodsInfo_img img {
    width: 100%;
    height: 100%;
  }

  .inquiry_main .goodsInfo_message {
    margin-bottom: calc(55 * 100vw / 1920);
  }

  .goodsInfo_message .goodsInfo_message_title {
    width: calc(800 * 100vw / 1920);
    font-size: calc(24 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #333333;
    line-height: calc(30 * 100vw / 1920);
    padding-bottom: calc(39 * 100vw / 1920);
    border-bottom: 1px solid #ddd;
  }

  .goodsInfo_message .goodsInfo_message_price {
    margin-top: calc(30 * 100vw / 1920);
    font-size: calc(30 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
  }

  .goodsInfo_message .goodsInfo_message_price span {
    color: #ff0000;
    margin-left: calc(75 * 100vw / 1920);
  }

  .goodsInfo_message .goodsInfo_message_num {
    margin-top: calc(30 * 100vw / 1920);
    font-size: calc(28 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
  }

  .personInfo {
    margin-top: calc(55 * 100vw / 1920);
  }

  .personInfo_email,
  .personInfo_phone,
  .personInfo_code,
  .personInfo_counery,
  .personInfo_counery {
    align-items: center;
    margin-bottom: calc(30 * 100vw / 1920);
  }

  .personInfo_message {
    margin-bottom: calc(30 * 100vw / 1920);
  }

  .personInfo_email_title,
  .personInfo_phone_title,
  .personInfo_message_title,
  .personInfo_code_title,
  .personInfo_counery_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    position: relative;
    padding-left: calc(7 * 100vw / 1920);
    width: calc(220 * 100vw / 1920);
  }

  .personInfo_email_title::before,
  .personInfo_phone_title::before,
  .personInfo_message_title::before,
  .personInfo_code_title::before,
  .personInfo_counery_title::before {
    content: "*";
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #ff0000;
    left: calc(-7 * 100vw / 1920);
    position: absolute;
    top: calc(-7 * 100vw / 1920);
  }

  .personInfo_email input,
  .personInfo_phone input,
  .personInfo_counery select {
    width: calc(480 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
  }

  .personInfo_message_title {
    margin-top: calc(17 * 100vw / 1920);
  }

  .personInfo_message textarea {
    width: calc(480 * 100vw / 1920);
    height: calc(200 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    /* 禁止拉伸 */
    resize: none;
    /* 轮廓线不显示 */
    outline: none;
    /* text-indent: calc(36 * 100vw / 1920); */
    font-family: Roboto;
  }

  .personInfo_message textarea::-webkit-input-placeholder {
    padding-top: calc(17 * 100vw / 1920);
  }

  .personInfo_code input {
    width: calc(330 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
  }

  .personInfo_code .personInfo_code_img {
    width: calc(100 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    margin-left: calc(52 * 100vw / 1920);
  }

  .personInfo_code .personInfo_code_img img {
    width: 100%;
    height: 100%;
  }

  .personInfo_submit {
    width: calc(200 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #13af87;
    border-radius: calc(2 * 100vw / 1920);
    margin-left: calc(217 * 100vw / 1920);
    margin-bottom: calc(60 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: calc(50 * 100vw / 1920);
  }

  @media screen and (max-width: 1080px) {

    /* WebKit, Blink, Edge */
    ::-webkit-input-placeholder {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 300;
      color: #aaaaaa;
      padding-left: calc(26 * 100vw / 1080);
    }

    /* Mozilla Firefox 4 to 18 */
    :-moz-placeholder {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 300;
      color: #aaaaaa;
      padding-left: calc(26 * 100vw / 1080);
    }

    /* Mozilla Firefox 19+ */
    ::-moz-placeholder {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 300;
      color: #aaaaaa;
      padding-left: calc(26 * 100vw / 1080);
    }

    /* Internet Explorer 10-11 */
    :-ms-input-placeholder {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 300;
      color: #aaaaaa;
      padding-left: calc(26 * 100vw / 1080);
    }

    .inquiry_page {
      width: 100vw;
      background-color: #fff;
      border-top: 2px solid #ddd;
    }

    .inquiry {
      width: 100%;
      margin: 0;
    }

    .inquiry .directory {
      display: none;
    }

    .inquiry_main {
      padding-left: calc(40 * 100vw / 1080);
      padding-right: calc(40 * 100vw / 1080);
      padding-top: calc(80 * 100vw / 1080);
      background: #ffffff;
    }

    .inquiry_main .goodsInfo_img {
      width: calc(332 * 100vw / 1080);
      height: calc(310 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
      background-image: url(../../../../assets/img/图层2.png);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: calc(170 * 100vw / 1080) calc(240 * 100vw / 1080);
      margin-right: calc(58 * 100vw / 1080);
    }

    .goodsInfo_img img {
      width: 100%;
      height: 100%;
    }

    .inquiry_main .goodsInfo_message {
      margin-bottom: 0;
      margin-top: calc(33 * 100vw / 1080);
    }

    .goodsInfo_message .goodsInfo_message_title {
      width: calc(620 * 100vw / 1080);
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #333333;
      line-height: calc(36 * 100vw / 1080);
      padding-bottom: calc(50 * 100vw / 1080);
      border-bottom: 1px solid #ddd;
    }

    .goodsInfo_message .goodsInfo_message_price {
      margin-top: calc(30 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
    }

    .goodsInfo_message .goodsInfo_message_price span {
      color: #ff0000;
      margin-left: calc(99 * 100vw / 1080);
    }

    .goodsInfo_message .goodsInfo_message_num {
      margin-top: calc(30 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
    }

    .personInfo {
      margin-top: calc(126 * 100vw / 1080);
    }

    .personInfo_email,
    .personInfo_phone,
    .personInfo_code,
    .personInfo_counery {
      align-items: center;
      margin-top: calc(50 * 100vw / 1080);
    }

    .personInfo_message {
      margin-top: calc(50 * 100vw / 1080);
    }

    .personInfo_email_title,
    .personInfo_phone_title,
    .personInfo_message_title,
    .personInfo_code_title,
    .personInfo_counery_title {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #333333;
      position: relative;
      padding-left: calc(11 * 100vw / 1080);
      width: calc(380 * 100vw / 1080);
    }

    .personInfo_email_title::before,
    .personInfo_email_phone::before,
    .personInfo_message_title::before,
    .personInfo_code_title::before,
    .personInfo_counery_title::before {
      content: "*";
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #ff0000;
      left: calc(-11 * 100vw / 1080);
      position: absolute;
      top: calc(-11 * 100vw / 1080);
    }

    .personInfo_email input,
    .personInfo_phone input,
    .personInfo_counery select {
      width: calc(620 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
    }

    .personInfo_message_title {
      margin-top: calc(23 * 100vw / 1080);
    }

    .personInfo_message textarea {
      width: calc(620 * 100vw / 1080);
      height: calc(210 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
      /* 禁止拉伸 */
      resize: none;
      /* 轮廓线不显示 */
      outline: none;
      /* text-indent: calc(36 * 100vw / 1920); */
      font-family: Roboto;
    }

    .personInfo_message textarea::-webkit-input-placeholder {
      padding-top: calc(23 * 100vw / 1080);
    }

    .personInfo_code input {
      width: calc(460 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #ffffff;
      border: 1px solid #dddddd;
    }

    .personInfo_code .personInfo_code_img {
      width: calc(118 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      margin-left: calc(42 * 100vw / 1080);
    }

    .personInfo_code .personInfo_code_img img {
      width: 100%;
      height: 100%;
    }

    .personInfo_submit {
      width: calc(640 * 100vw / 1080);
      height: calc(88 * 100vw / 1080);
      background: #13af87;
      border-radius: calc(2 * 100vw / 1080);
      margin-left: calc(220 * 100vw / 1080);
      margin-top: calc(190 * 100vw / 1080);
      margin-bottom: calc(350 * 100vw / 1080);
      font-size: calc(38 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: calc(88 * 100vw / 1080);
    }
  }
</style>