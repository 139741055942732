import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=1d5a5656&scoped=true"
import script from "./Menu.vue?vue&type=script&lang=js"
export * from "./Menu.vue?vue&type=script&lang=js"
import style0 from "./Menu.vue?vue&type=style&index=0&id=1d5a5656&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d5a5656",
  null
  
)

export default component.exports