<template>
  <div class="feedback_page">
    <p class="directory" v-if="pageType=='add'">
      <span @click="businesshomeClick" class="directory_home">Home </span>>
      Feedback
    </p>
    <div class="feedback">
      <div class="feedback_title feedback_pc">{{pageType=='add'?'Feedback':'客户反馈'}}</div>
      <div class="feedback_title feedback_phone">{{pageType=='add'?'Feedback':'客户反馈'}}</div>
      <div class="feedback_main feedback_main_1" v-if="pageType=='add'">
        <p><span style="color:red;">*</span>Email</p>
        <input type="text" v-model="form.email" placeholder="Please input your E-mail" :disabled="isShow" />
        <p><span style="color:red;">*</span>Country</p>
        <select v-model="form.country" placeholder="Country" :disabled="isShow">
          <option :label="item.countryEn" :value="item.countryEn" v-for="(item,index) in countryList" :key="index">
          </option>
        </select>
        <p><span style="color:red;">*</span>Message</p>
        <el-input v-model="form.message" type="textarea" :rows="4"
          placeholder="Please tell us the following information about your requirements;product model(product series),size,quantity."
          :disabled="isShow"></el-input>
        <div style="display:flex;justify-content:center;" class="h5Send">
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitfun(form)"
            v-if="!isShow">Send</el-button>
        </div>
      </div>
      <div class="businesscontactus_main" v-else>
        <!-- <div class="feedback_btn">
          <div @click="addClick">
            <el-button type="primary">添加</el-button>
          </div>
          <div @click="readClick">
            <el-button type="warning">已读</el-button>
          </div>          
        </div> -->
        <div>
          <el-table :data="list" :header-cell-style="headStyle" :cell-style="headStyle" style="width: 100%">
            <el-table-column prop="email" label="邮件"></el-table-column>
            <el-table-column prop="country" label="国家/地区"></el-table-column>
            <el-table-column prop="message" label="消息"></el-table-column>
            <el-table-column fixed="right" prop="isRead" label="操作" width="120">
              <template slot-scope="scope">
                <!-- isRead:1未读，2已读 -->
                <el-button @click="readClick(scope.row)" type="text" size="small"
                  v-if="scope.row.isRead==1">已读</el-button>
                <el-button @click="detailfun(scope.row)" type="text" size="small">详情</el-button>
                <el-button @click="deletefun(scope.row.id)" type="text" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-pagination align="center" background @current-change="handleCurrentChange" :current-page="form.pageNum"
              :page-size="form.pageSize" layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog :title="title" :visible.sync="feedbackVisible" class="feedback_dialog" @close="feedbackClose">

        <div class="feedback_main">
          <p><span style="color:red;">*</span>Email</p>
          <input type="text" v-model="form.email" placeholder="Please input your E-mail" :disabled="isShow" />
          <p><span style="color:red;">*</span>Country</p>
          <select v-model="form.country" placeholder="Country" :disabled="isShow">
            <option :label="item.countryEn" :value="item.countryEn" v-for="(item,index) in countryList" :key="index">
            </option>
          </select>
          <p><span style="color:red;">*</span>Message</p>
          <el-input v-model="form.message" type="textarea" :rows="4"
            placeholder="Please tell us the following information about your requirements;product model(product series),size,quantity."
            :disabled="isShow"></el-input>
          <div style="display:flex;justify-content:center;">
            <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitfun(form)"
              v-if="!isShow">Send</el-button>
          </div>
        </div>
      </el-dialog>
      <!--  -->
    </div>
  </div>
</template>

<script>
  import { shopFeedbackList, shopFeedbackDetail, sendFeedback, shopFeedbackRead, shopFeedbackDel } from "@/api/news.js";
  import message from "@/utils/message";
  import countryCode from "@/utils/country"
  export default {
    name: "feedback",
    data() {
      return {
        form: {
          businessId: sessionStorage.getItem("businessId"),
          email: "",
          country: "",
          message: ""
        },
        list: [],
        form1: {
          pageNum: 1,
          pageSize: 5,
          isRead: 0
        },
        total: 0,
        feedbackVisible: false,
        fullscreenLoading: false,
        countryList: countryCode,
        title: '新增反馈',
        isShow: false,
        pageType: 'add'
      };
    },
    components: {},
    computed: {},
    watch: {},
    methods: {
      sortContory() {
        this.countryList.sort((a, b) => a.countryEn.charCodeAt(0) - b.countryEn.charCodeAt(0));
      },
      businesshomeClick() {
        this.$router.push({
          path: "/businesshome",
          query: {
            noBusiness: false,
            businessId: sessionStorage.getItem("businessId"),
          },
        });
      },
      selectshopFeedbackList() {
        shopFeedbackList({ ...this.form1 }).then((res) => {
          if (res.code == 200) {
            this.list = res.data.vos;
            this.total = res.data.total;
          }
        });
      },
      handleCurrentChange(e) {
        this.form1.pageNum = e;
        this.selectshopFeedbackList();
      },
      headStyle() {
        return "text-align:center";
      },
      // 新增反馈信息
      submitfun(e) {
        if (!e.email) {
          this.$message({
            message: "Please fill in email",
            type: "warning",
          });
          return;
        }
        if (!e.country) {
          this.$message({
            message: "Please select country",
            type: "warning",
          });
          return;
        }
        if (!e.message) {
          this.$message({
            message: "Please fill in message",
            type: "warning",
          });
          return;
        }
        this.fullscreenLoading = true;
        sendFeedback({ ...e }).then(res => {
          if (res.code == 200) {
            this.$message({ message: "提交成功", type: "success" });
            this.selectshopFeedbackList();
            this.feedbackVisible = false;
            this.fullscreenLoading = false;
            this.feedbackClose()
          } else {
            this.$message({ message: res.msg, type: "error" });
            this.feedbackVisible = false;
            this.fullscreenLoading = false;
          }
        })
      },
      // 关闭反馈信息弹窗
      feedbackClose() {
        this.form.email = ""
        this.form.country = this.countryList[0].countryEn
        this.form.message = ""
        this.feedbackVisible = false;
      },
      // 已读
      readClick(row) {
        shopFeedbackRead().then(res => {
          if (res.code == 200) {
            this.$message({ message: res.msg, type: "success" });
            this.selectshopFeedbackList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
      },
      // 查看详情
      detailfun(row) {
        this.form = row
        this.isShow = true
        this.title = "反馈详情"
        this.feedbackVisible = true
      },
      // 删除
      deletefun(id) {
        this.$confirm(
          "确定要删除该条反馈吗？ ",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            confirmButtonClass: "colorClass",
          }
        ).then(() => {
          shopFeedbackDel(id).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.selectshopFeedbackList();
            } else if (res.code == 401) {
              this.$message({ message: res.msg, type: "error" });
              //未登录
              this.$router.push({
                path: "/login",
                query: {
                  box8: false,
                  phoneHomeImg: false,
                  bool: false,
                  // token: true,
                },
              });
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          });
        })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "Cancel confirmation",
            // });
          });
      },
      // 新增反馈信息
      addClick() {
        this.isShow = false
        this.title = "新增反馈"
        this.feedbackVisible = true;
      },
      submitForm(e) {
        if (!e.firstName) {
          this.$message({
            message: "Please fill in your firstName",
            type: "warning",
          });
          return;
        }
        registerApi({ ...e }).then((res) => {
          if (res.code == 200) {
            this.$router.push({
              path: "/",
              query: {
                bool: true,
                // token: true,
              },
            });
            this.$message({ message: "注册成功", type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
    },
    created() {
      this.pageType = this.$route.query.pageType
      this.sortContory();
    },
    mounted() {
      this.form.country = this.countryList[0].countryEn
      this.selectshopFeedbackList()
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }

      document.querySelector(".bottomnav_me").style.color = "#13AF87";
    },
  };
</script>

<style scoped>
  .feedback_page {
    width: 82vw;
    background-color: #f1f1f1;
  }

  .feedback_page .directory {
    background-color: #f1f1f1;
    margin: 0;
    padding-bottom: 1.2vw;
    font-size: 0.93vw;
    font-family: Roboto;
    font-weight: 400;
    color: #999999;
  }

  .feedback {
    /*width: calc(1200 * 100vw / 1920);
  margin: 2.08vw auto;*/
    width: 100%;
    margin: auto;
    background-color: #fff;
    min-height: calc(740 * 100vw / 1920);
  }

  .feedback_btn {
    display: flex;
    align-items: center;
    margin-left: calc(20 * 100vw / 1920);
    margin-top: calc(20 * 100vw / 1920);
  }

  .feedback_btn div {
    margin-right: 15px;
    /* width: calc(95 * 100vw / 1920); */
  }

  .feedback .feedback_title {
    margin-left: calc(20 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .feedback_phone {
    display: none;
  }

  .feedback .feedback_main {
    padding-left: calc(20 * 100vw / 1920);
    padding-top: calc(60 * 100vw / 1920);
    padding-bottom: calc(40 * 100vw / 1920);
    /* border-top: 1px solid #dddddd; */
  }

  .feedback .feedback_main_1 {
    width: 50%;
  }

  .feedback .feedback_main>p {
    font-size: 1.2vw;
    margin-bottom: calc(10 * 100vw / 1920);
  }

  /* /deep/ .el-input__inner{
  width: calc(480 * 100vw / 1920);
  height: calc(50 * 100vw / 1920);
} */
  .feedback .feedback_main input,
  .feedback .feedback_main select {
    display: block;
    border: 1px solid #dddddd;
    width: 100%;
    height: calc(50 * 100vw / 1920);
    margin-bottom: calc(30 * 100vw / 1920);
    outline: none;
    font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  }

  .feedback .feedback_main textarea,
  .feedback .feedback_main .el-textarea {
    display: block;
    /* border: 1px solid #dddddd; */
    width: 100%;
    height: calc(150 * 100vw / 1920);
    margin-bottom: calc(30 * 100vw / 1920);
    outline: none;
    font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  }

  .feedback .feedback_main .feedback_checkbox {
    display: inline-block;
    border: 1px solid #dddddd;
    width: calc(25 * 100vw / 1920);
    height: calc(25 * 100vw / 1920);
    margin: 0;
    margin-right: calc(15 * 100vw / 1920);
    vertical-align: middle;
  }

  label {
    font-size: calc(19 * 100vw / 1920);
    color: rgb(143, 141, 141);
  }

  .feedback input::-webkit-input-placeholder,
  .feedback select::-webkit-input-placeholder,
  .feedback textarea::-webkit-input-placeholder,
  .feedback .el-textarea::-webkit-input-placeholder {
    color: rgba(170, 170, 170, 1);
    font-size: calc(20 * 100vw / 1920);
    padding-left: calc(23 * 100vw / 1920);
    font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  }

  .feedback .feedback_main .el-button {
    display: block;
    background-color: rgba(19, 175, 135, 1);
    width: calc(300 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    line-height: calc(50 * 100vw / 1920);
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.2vw;
    font-family: Roboto-Regular;
    text-align: center;
    margin-top: calc(30 * 100vw / 1920);
    padding: 0;
  }

  .choose {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(30 * 100vw / 1920);
    width: calc(480 * 100vw / 1920);
  }

  .choose input {
    margin-bottom: 0 !important;
    width: calc(325 * 100vw / 1920) !important;
    font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  }

  .choose select {
    display: block;
    border: 1px solid #dddddd;
    width: calc(135 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  }

  .businesscontactus_main {
    background: #fff;
  }

  .dia-span {
    width: 20%;
  }

  @media screen and (max-width: 1080px) {
    .dia-span {
      width: 20%;
    }

    /deep/.el-input__inner {
      width: 70%;
    }

    /deep/.el-dialog {
      width: 90%;
    }

    .feedback_page {
      width: 100vw;
      background-color: #fff;
      /* border-top: 1px solid #ddd; */
    }

    .feedback_page .directory {
      display: none;
    }

    .feedback {
      width: 100%;
      margin: 0;
      background-color: #fff;
      min-height: 70vh;
    }

    .feedback .feedback_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(145 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(145 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .feedback_phone {
      display: block;
    }

    .feedback_pc {
      display: none;
    }

    .feedback .feedback_main {
      /* padding-left: calc(200 * 100vw / 1080);
    padding-top: calc(172 * 100vw / 1080);
    padding-bottom: calc(481 * 100vw / 1080); */
      padding: 0;
    }

    .feedback .feedback_main_1 {
      width: 100%;
      padding: 3vw;
    }

    .h5Send {
      top: calc(200 * 100vw / 1080);
      position: relative;
    }

    .feedback .feedback_main>p {
      font-size: 4vw;
      margin-bottom: calc(20 * 100vw / 1080);
    }

    .feedback .feedback_main input,
    .feedback .feedback_main select {
      display: block;
      border: 1px solid #dddddd;
      width: 100%;
      /* width: calc(700 * 100vw / 1080); */
      height: calc(80 * 100vw / 1080);
      margin-bottom: calc(60 * 100vw / 1080);
    }

    .feedback .feedback_main textarea {
      display: block;
      border: 1px solid #dddddd;
      /* width: calc(700 * 100vw / 1080); */
      height: calc(160 * 100vw / 1080);
      margin-bottom: calc(60 * 100vw / 1080);
    }

    .feedback .feedback_main div {
      /* width: calc(700 * 100vw / 1080); */
      width: 100%;
      /* height: calc(80 * 100vw / 1080); */
      /* margin-bottom: calc(60 * 100vw / 1080); */
    }

    .feedback input::-webkit-input-placeholder,
    .feedback select::-webkit-input-placeholder,
    .feedback textarea::-webkit-input-placeholder {
      color: #aaa;
      font-size: calc(30 * 100vw / 1080);
      padding-left: calc(42 * 100vw / 1080);
    }

    .feedback .feedback_main .feedback_checkbox {
      display: inline-block;
      border: 1px solid #aaa;
      width: calc(30 * 100vw / 1080);
      height: calc(30 * 100vw / 1080);
      margin: 0;
      margin-right: calc(15 * 100vw / 1080);
      transform: translateY(15%);
    }

    label {
      display: block;
      width: calc(660 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      color: #aaa;
      word-wrap: break-word;
    }

    .feedback .feedback_main .el-button {
      display: block;
      background-color: rgba(19, 175, 135, 1);
      width: calc(700 * 100vw / 1080);
      height: calc(80 * 100vw / 1080);
      line-height: calc(80 * 100vw / 1080);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: calc(38 * 100vw / 1080);
      font-family: Roboto-Regular;
      text-align: center;
      margin-top: calc(35 * 100vw / 1080);
    }

    .choose {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: calc(60 * 100vw / 1080);
      width: calc(700 * 100vw / 1080);
    }

    .choose input {
      margin-bottom: 0 !important;
      width: calc(495 * 100vw / 1080) !important;
    }

    .choose select {
      margin-top: 0 !important;
      font-size: calc(28 * 100vw / 1080) !important;
      display: block;
      border: 1px solid #dddddd;
      width: calc(200 * 100vw / 1080);
      height: calc(80 * 100vw / 1080);
    }
  }
</style>