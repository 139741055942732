<template>
  <div class="essential_page">
    <div class="essential_title">Basic Information</div>
    <div class="essential_main">
      <div class="essential_name flex-row">
        <div class="title">First Name</div>
        <input type="text" v-model="myInfo.firstName" placeholder="" />
      </div>
      <div class="essential_name flex-row">
        <div class="title">Last Fame</div>
        <input type="text" v-model="myInfo.userName" placeholder="" />
      </div>
      <div class="essential_phone flex-row">
        <div class="title">Phone Number</div>
        <input type="number" v-model="myInfo.phone" placeholder="" />
      </div>
      <div class="essential_mail flex-row">
        <div class="title">Mail Address</div>
        <input type="email" v-model="myInfo.email" :disabled="myInfo.email ? true : false" placeholder="" />
      </div>
      <div class="essential_address flex-row">
        <div class="title">Country</div>
        <input type="text" v-model="myInfo.address" placeholder="" />
      </div>
      <div class="essential_address flex-row">
        <div class="title">Promotion Balance</div>
        <input type="text" v-model="myInfo.promotionBalance" placeholder="" />
      </div>
    </div>
    <button class="essential_confirm" @click="updatefun">Confirm</button>
  </div>
</template>

<script>
  import { getmyInfo, editMyInfo } from "../../../api/index";
  export default {
    name: "Essential",
    data() {
      return {
        myInfo: {},
      };
    },
    components: {},
    computed: {},
    watch: {
      $route: {
        handler: function (val) {
          //这里的路由是页面上的路由
          if (val.path == "/personalcenter/essential") {
            //调用你想用的方法
            this.getmyInfoApi();
          } else {
            this.router_show = false;
          }
        },
        // 深度观察监听，普通的watch方法无法监听到对象内部属性的改变，只有data中的数据才能够监听到变化，此时就需要deep属性对对象进行深度监听。
        deep: true,
      },
    },
    created() { },
    methods: {
      getmyInfoApi() {
        getmyInfo().then((res) => {
          if (res.code == 200) {
            this.myInfo = res.data;
          }
        });
      },
      updatefun() {
        editMyInfo({ ...this.myInfo }).then((res) => {
          if (res.code == 200) {
            this.$bus.$emit("brotherEvent", true);
            this.$bus.$emit("username", this.myInfo.userName);
            this.$bus.$emit("email", this.myInfo.email);
            this.$message({ message: "succeeded", type: "success" });
          }
        });
      },
    },
    mounted() {
      this.getmyInfoApi();
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      let childrens = document.querySelector(
        ".personalcenter_nav_router"
      ).children;
      for (let i = 0; i < childrens.length; i++) {
        // 给兄弟元素添加属性
        // childrens[i].classList.remove("nav_active");
        childrens[i].style.backgroundColor = "#fff";
      }
      childrens[0].style.backgroundColor = "#d3f5ec";
    },
    activated() {
      let childrens = document.querySelector(
        ".personalcenter_nav_router"
      ).children;
      for (let i = 0; i < childrens.length; i++) {
        // 给兄弟元素添加属性
        // childrens[i].classList.remove("nav_active");
        childrens[i].style.backgroundColor = "#fff";
      }
      childrens[0].style.backgroundColor = "#d3f5ec";
    },
  };
</script>

<style scoped>
  .essential_page {
    /*width: calc(890 * 100vw / 1920);*/
    width: 100%;
    /* height: calc(620 * 100vw / 1920); */
    background: #ffffff;
  }

  .essential_title {
    margin-left: calc(38 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .essential_main {
    border-top: 1px solid #ddd;
    padding-left: calc(38 * 100vw / 1920);
    padding-top: calc(58 * 100vw / 1920);
    margin-bottom: calc(55 * 100vw / 1920);
  }

  .essential_name,
  .essential_phone,
  .essential_mail,
  .essential_address {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bolder;
    color: #333333;
    align-items: center;
    margin-bottom: calc(30 * 100vw / 1920);
  }

  .essential_main .title {
    width: calc(180 * 100vw / 1920);
  }

  .essential_main input {
    width: calc(480 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-left: calc(18 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bolder;
    color: #333333;
    padding-left: calc(24 * 100vw / 1920);
  }

  .essential_main input::-webkit-input-placeholder {
    color: #aaaaaa;
  }

  .essential_confirm {
    width: calc(200 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #13af87;
    border-radius: calc(2 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bold;
    color: #ffffff;
    margin-left: calc(345 * 100vw / 1920);
  }

  @media screen and (max-width: 1080px) {
    .essential_page {
      width: 100vw;
      height: calc(1624 * 100vw / 1080);
      background-color: #fff;
      border-top: 1px solid #ddd;
    }

    .essential_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(150 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(170 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .essential_main {
      border-top: 1px solid #ddd;
      padding-left: calc(40 * 100vw / 1080);
      padding-left: calc(40 * 100vw / 1080);
      padding-right: calc(50 * 100vw / 1080);
      margin-bottom: calc(100 * 100vw / 1080);
    }

    .essential_name,
    .essential_phone,
    .essential_mail,
    .essential_address {
      height: calc(140 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bolder;
      color: #333333;
      align-items: flex-end;
      margin-bottom: 0;
      border-bottom: 1px solid #ddd;
      justify-content: space-between;
      /* padding-bottom: calc(35 * 100vw / 1080); */
    }

    .essential_main .title {
      width: calc(400 * 100vw / 1080);
      padding-bottom: calc(25 * 100vw / 1080);
    }

    .essential_main input {
      width: calc(600 * 100vw / 1080);
      height: calc(100 * 100vw / 1080);
      background: #ffffff;
      border: none;
      margin-left: 0;
      text-align: right;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bolder;
      color: #333333;
      padding-left: 0;
    }

    .essential_name::after,
    .essential_phone::after,
    .essential_mail::after,
    .essential_address::after {
      content: ">";
      font-size: calc(36 * 100vw / 1080);
      padding-bottom: calc(25 * 100vw / 1080);
    }

    .essential_confirm {
      width: calc(640 * 100vw / 1080);
      height: calc(88 * 100vw / 1080);
      background: #13af87;
      border-radius: calc(2 * 100vw / 1080);
      font-size: calc(38 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bold;
      color: #ffffff;
      margin-left: calc(180 * 100vw / 1080);
    }
  }
</style>