<template>
  <div class="menu">
    <!-- <div v-for="(item,index) in menus" :key="index">
        <div class="top" @click="clickActive">
          <slot name="topText"><span>{{item.categoryName}}</span></slot>
        </div>
        <div class="content">
            <ul v-for="(item2,index) in item.chchildren" :key="index">
              <li @click="liClick">
                <slot name="contentli1"><div>{{item2.categoryName}}</div></slot>
              </li>
               <li @click="liClick">
                <slot name="contentli2"><div>Ceramics2</div></slot>
              </li>
              <li @click="liClick">
                <slot name="contentli3"><div>Ceramics3</div></slot>
              </li>
              <li @click="liClick">
                <slot name="contentli4"><div>Ceramics4</div></slot>
              </li>
              <li v-if="isshow" @click="liClick">
                <slot name="contentli5"><div>Ceramics5</div></slot>
              </li>
              <li v-if="isshow" @click="liClick">
                <slot name="contentli6"><div>Ceramics6</div></slot>
              </li> 
            </ul>
        </div>
    </div> -->
    <div>
      <div
        class="top"
        v-for="(item, index) in menus"
        :key="index"
        @click="menufun(index)"
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>{{ item.categoryName }}</span>
          <label
            :style="{ transform: menusindex == index ? 'rotateZ(90deg)' : '' }"
            >></label
          >
        </div>
        <div
          class=""
          style="padding: 20px 0 0 15px"
          :style="{ display: menusindex == index ? 'block' : 'none' }"
        >
          <label
            @click="chilfun(item.id, item2.id)"
            v-for="(item2, index2) in item.children"
            :key="index2"
            style="display: block; margin-bottom: 10px"
          >
            {{ item2.categoryName }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcommonCategory } from "../../../api/index";
export default {
  name: "Menu",
  data() {
    return {
      isshow: false, // 决定最后两个在移动端与pc端显示与否
      menus: [],
      menusindex: 0,
    };
  },
  components: {},
  computed: {},
  watch: {},
  mounted() {
    this.getcommonCategory();
    // let top = document.querySelectorAll(".top");
    // top[0].children[0].classList.add("spanclick");
    // top[0].nextSibling.style.display = "block";

    // for (let i = 1; i < top.length; i++) {
    //   // 添加类
    //   top[i].children[0].classList.add("span");
    // }
    if (document.documentElement.clientWidth <= 1080) {
      this.isshow = true;
    } else {
      this.isshow = false;
    }
    window.addEventListener("resize", () => {
      if (document.documentElement.clientWidth <= 1080) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
    });
  },
  methods: {
    // menufun(e){
    //   this.menusindex=e
    // },
    chilfun(e,b){
    },
    getcommonCategory() {
      getcommonCategory().then((res) => {
        if (res.code == 200) {
          this.menus = res.data;
        }
      });
    },
    // clickActive(e) {
    //   let top = document.querySelectorAll(".top");
    //   for (let i = 0; i < top.length; i++) {
    //     // 移除类
    //     top[i].classList.remove("active");
    //     top[i].children[0].classList.remove("spanclick");
    //     top[i].children[0].classList.add("span");

    //     // 给兄弟元素添加属性
    //     top[i].nextSibling.style.display = "none";
    //   }
    //   // e.currentTarget 绑定事件的元素
    //   let currentTarget = e.currentTarget;
    //   currentTarget.children[0].classList.remove("span");
    //   // 添加类
    //   currentTarget.classList.add("active");
    //   currentTarget.children[0].classList.add("spanclick");
    //   currentTarget.nextSibling.style.display = "block";
    // },
    menufun() {
      this.$router.push({
        path: "/ceramics/ceramicslist",
        query: {
          bool: false,
          phoneHomeImg: false,
          box8: false,
        },
      });
      // 点击时判断
      if (document.documentElement.clientWidth > 1080) {
        document.querySelector(".ceramics_nav").style.display = "block";
        document.querySelector(".ceramics_view").style.display = "block";
      } else {
        document.querySelector(".ceramics_nav").style.display = "none";
        document.querySelector(".ceramics_view").style.display = "block";
      }
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
    },
  },
};
</script>

<style scoped>
.menu {
  width: 100%;
}
.top {
  width: calc(300 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  padding: 0 calc(20 * 100vw / 1920);
  position: relative;
}
/* .active {
  background: #d3f5ec;
} */
.top span {
  display: block;
  height: calc(60 * 100vw / 1920);
  line-height: calc(60 * 100vw / 1920);
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.top .span::after {
  content: ">";
  position: absolute;
  right: calc(20 * 100vw / 1920);
  font-size: calc(28 * 100vw / 1920);
}
.top .spanclick::after {
  content: ">";
  position: absolute;
  right: calc(20 * 100vw / 1920);
  font-size: calc(28 * 100vw / 1920);
  transform: rotateZ(90deg);
}
.content {
  width: calc(300 * 100vw / 1920);
  display: none;
}
.content li {
  display: block;
  width: calc(300 * 100vw / 1920);
  height: calc(40 * 100vw / 1920);
  padding-left: calc(40 * 100vw / 1920);
  padding-right: calc(20 * 100vw / 1920);
}
.content li:hover {
  background-color: #b7ecde;
}
.content li div {
  width: 100%;
  height: 100%;
  font-size: calc(18 * 100vw / 1920);
  line-height: calc(40 * 100vw / 1920);
  border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 1080px) {
  .top {
    width: 100%;
    /* height: calc(150 * 100vw / 1080); */
    font-size: calc(48 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 500;
    color: #333333;
    padding: 0 calc(40 * 100vw / 1080);
    padding-top: calc(44 * 100vw / 1080);
    position: relative;
    border-bottom: 1px solid #ddd;
  }
  .active {
    background: #fff;
  }
  .top span {
    display: block;
    width: calc(280 * 100vw / 1080);
    height: calc(100 * 100vw / 1080);
    line-height: calc(100 * 100vw / 1080);
    border-bottom: calc(6 * 100vw / 1080) solid #13af87;
  }
  .top .span::after {
    content: ">";
    position: absolute;
    right: calc(40 * 100vw / 1080);
    font-size: calc(48 * 100vw / 1080);
  }
  .top .spanclick::after {
    content: ">";
    position: absolute;
    right: calc(40 * 100vw / 1080);
    font-size: calc(48 * 100vw / 1080);
    transform: rotateZ(90deg);
  }
  .content {
    width: 100%;
    padding-left: calc(40 * 100vw / 1080);
    padding-top: calc(52 * 100vw / 1080);
    /* border-top: 1px solid #ddd; */
  }
  .content ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .content li {
    display: block;
    width: calc(500 * 100vw / 1080);
    height: calc(100 * 100vw / 1080);
    padding-left: 0;
    padding-right: 0;
  }

  .content li div {
    font-size: calc(36 * 100vw / 1080);
    line-height: calc(100 * 100vw / 1080);
    border-bottom: none;
  }
}
</style>
