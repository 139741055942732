<template>
  <div class="businessceramics">
    <div class="businessceramics_page">
      <div class="box_4">
        <swiper-top></swiper-top>
      </div>
      <div class="businessceramics_main clearfix" id="shopDetail">
        <p class="directory">
          <span @click="businesshomeClick" class="directory_home">Home </span>>
          <span>{{ titleName }}</span>
        </p>

        <CeramicsDetail v-if="isShowProductList === false"></CeramicsDetail>

        <div class="bottonContent">
          <div class="nav">
            <div class="pc_nav_title">Product Classification</div>
            <div class="phone_nav_title" v-if="isPc == true">All Product</div>
            <!-- <Menu> </Menu> -->
            <!--  :style="{'height':businessId?'calc(280 * 100vw / 1920)':'calc(530 * 100vw / 1920)'}" -->
            <div class="menu" style="width: 100%" id="pcnav" v-if="isPc == true">
              <div>
                <div class="top" v-for="(item, index) in shopmenus" :key="index"
                  @click="menufun(item.categoryName, index, item.id)">
                  <div style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;
                      border-bottom: 1px solid #ddd;
                    ">
                    <span>{{ item.categoryName }}</span>
                    <label :style="{
                        transform: menusindex == item.id ? 'rotateZ(90deg)' : '',
                      }">></label>
                  </div>
                  <div class="" style="padding: 0px 0 0 15px"
                    :style="{ display: menusindex == item.id ? 'block' : 'none' }">
                    <div v-for="(item2, index2) in item.children" :key="index2" @click.stop="
                        pcchilfun(item2.categoryName, index2, item2.id)
                      " style="display: block">
                      <div style="display: flex; align-items: center" :style="{
                          color: childrenindex == item2.id ? '#13af87' : '',
                        }">
                        <span>{{ item2.categoryName }}</span>
                        <label v-if="item2.children" :style="{
                            transform: twoindex == index2 ? 'rotateZ(90deg)' : '',
                          }">></label>
                      </div>
                      <div v-for="(item3, index3) in item2.children" :key="index3" @click.stop="
                          threeMenufun(item3.categoryName, index3, item3.id)
                        " :style="{
                          display: twoindex == index2 ? 'block' : 'none',
                        }" style="margin-bottom: 10px">
                        <div style="
                            display: flex;
                            align-items: center;
                            padding-left: 20px;
                          ">
                          <label :style="{
                              color: childrenindex == item3.id ? '#13af87' : '',
                            }">{{ item3.categoryName }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  :style="{'height':businessId?'calc(500 * 100vw / 1080)':'calc(1000 * 100vw / 1080)'}" -->
            <div class="menu" style="width: 100%" id="ydnav" v-if="isPc == true">
              <div>
                <div class="top" v-for="(item, index) in shopmenus" :key="index"
                  @click="menufun(item.categoryName, index, item.id)">
                  <div style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;
                      border-bottom: 1px solid #ddd;
                    ">
                    <span>{{ item.categoryName }}</span>
                    <label :style="{
                        transform: menusindex == item.id ? 'rotateZ(90deg)' : '',
                      }">></label>
                  </div>
                  <div class="" style="padding: 0px 0 0 15px"
                    :style="{ display: menusindex == item.id ? 'block' : 'none' }">
                    <div v-for="(item2, index2) in item.children" :key="index2" @click.stop="
                        pcchilfun(item2.categoryName, index2, item2.id)
                      " style="display: block; padding: 3px 0">
                      <div style="display: flex; align-items: center" :style="{
                          color: childrenindex == item2.id ? '#13af87' : '',
                        }">
                        <span>{{ item2.categoryName }}</span>
                        <label v-if="item2.children">></label>
                      </div>
                      <div v-for="(item3, index3) in item2.children" :key="index3" @click.stop="
                          threeMenufun(item3.categoryName, index3, item3.id)
                        " :style="{
                          display: twoindex == index2 ? 'block' : 'none',
                        }" style="margin-bottom: 10px">
                        <div style="
                            display: flex;
                            align-items: center;
                            padding-left: 20px;
                          ">
                          <label :style="{
                              color: childrenindex == item3.id ? '#13af87' : '',
                            }">{{ item3.categoryName }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="menu" style="width: 100%" id="menuhot" v-if="businessId&&hotCommodity&&hotCommodity.length>0">
              <div class="menuhot_title">Hot Products</div>
              <div class="menuhot_list">
                <div class="menuhot_list_child" @click="detailfun(item)" v-for="(item,index) in hotCommodity"
                  :key="index">
                  <img :src="baseUrl+item.image" />
                  <label>{{item.commodityTitle}}</label>
                </div>
              </div>
            </div>
            <div class="menu" style="width: 100%" id="menuhot"
              v-if="businessId&&otherCommodity&&otherCommodity.length>0">
              <div class="menuhot_title">Other Products</div>
              <div class="menuhot_list">
                <div class="menuhot_list_child" @click="detailfun(item)" v-for="(item,index) in otherCommodity"
                  :key="index">
                  <img :src="baseUrl+item.image" />
                  <label>{{item.commodityTitle}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="description" v-if="isShowProductList === false">
            <div class="description_title">
              <span :class="activeIndex == 0 ? 'description_title_active' : ''"
                @click="changeActiveIndex(0)">Description</span>
              <span :class="activeIndex == 1 ? 'description_title_active' : ''" @click="changeActiveIndex(1)">Ratings
                Reviews</span>
            </div>
            <div class="description_main" v-html="list.introduce" v-if="activeIndex == 0">
            </div>
            <div class="commit_main" v-else-if="activeIndex == 1">
              <newCommentList />
            </div>
          </div>
          <!-- <router-view></router-view> -->
          <business-ceramics-list v-else :getSortType="getSortType" :categoryId="categoryId"
            :parentShopList="parentShopList" :total="{ pageNum, pageSize, pageTotal }" :numChange="numChange"
            :titleName="titleName"></business-ceramics-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Menu from "./../menu/Menu.vue";
  import PageNation from "@/components/pagenation/PageNation.vue";
  import SwiperTop from "@/pages/businesshome/swipertop/SwiperTop.vue";
  import CeramicsDetail from "../ceramicsdetail/CeramicsDetail.vue";
  import BusinessCeramicsList from "@/pages/businessceramics/businessceramicslist/BusinessCeramicsList.vue";
  import { getShopCommonCategory, getshopCommodityList, getShopHotCommodity, getShopOhterCommodity } from "../../../api/index";
  import { shopAddCategory, shopCategoryListApi } from "../../../api/business";
  import { sevenAgreement, getDictLabel } from "../../../api/other";
  import newCommentList from '@/components/piaoyi-commentList/new-commentList'
  import { getconsumerCommodityDetail, consumerAppraiseListApi } from "../../../api/index";

  export default {
    name: "FineCeramics",
    data() {
      return {
        // isshow: false, // 决定最后两个在移动端与pc端显示与否
        shopmenus: [],
        menus: [], //分类列表 一维数组
        childrenlist: [], //子级  二维数组
        threelist: [], //三维数组
        menusindex: null,
        twoindex: null,
        threeindex: null,
        categoryId: null,
        childrenindex: 0, //子分类id
        pageNum: 1, //页数
        pageSize: 10, //每页数量
        sortType: 0, //状态值
        pageTotal: 0, //商品总数量
        parentShopList: [], //商品列表
        dialogVisible: false,
        onecurrent: null,
        twocurrent: null,
        threecurrent: null,
        name: "",
        parid: null,
        businessId: sessionStorage.getItem("businessId"),
        titleName: "Products",
        match: false,
        baseUrl: this.$baseUrl,

        hotCommodity: [],  //热门商品
        otherCommodity: [],  //其他商品
        isPc: true,
        isShowProductList: this.$route.query.isShowProductList || false,


        activeIndex: 0,
        list: {},
      };
    },
    components: {
      Menu,
      PageNation,
      SwiperTop,
      BusinessCeramicsList,
      CeramicsDetail,
      newCommentList
    },
    computed: {},
    watch: {
      $route() {
        // this.childrenindex = this.$route.query.id;
        // alert(1111);
        // this.menusindex = this.$route.query.id;
        // let { categoryName } = this.shopmenus.find(
        //   (item) => item.id == this.childrenindex
        // );
        // this.titleName = categoryName;
        // this.getShopCommonCategory()
        this.getShopCommonCategory();
        this.getQuery();
        this.getShopHotCommodity();
        this.getShopOhterCommodity();
        if (this.$route.query.id) {
          this.isShowProductList = false;
          if (this.$route.path == '/ceramics/fineceramics') {
            this.getParams();
          }
          this.$nextTick(() => { this.toLocal() })
        }
      },
    },
    methods: {
      getParams() {
        // this.list = JSON.parse(this.$route.query.list);
        let id = this.$route.query.id;
        if (id) {
          getconsumerCommodityDetail(id).then(async (res) => {
            if (res.code == 200) {
              this.list = res.data;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          });
        }

      },
      // 商家热门商品
      getShopHotCommodity() {
        this.businessId = this.$route.query.businessId ? this.$route.query.businessId : sessionStorage.getItem("businessId") ? sessionStorage.getItem("businessId") : this.businessId;
        getShopHotCommodity({
          pageNum: 1,
          pageSize: 100,
          businessId: this.businessId,
        }).then((res) => {
          if (res.code == 200 && res.data.vos.length !== 0) {
            this.hotCommodity = res.data.vos;
            // console.log(this.hotCommodity);
          }
        });
      },
      // 商家其他商品
      getShopOhterCommodity() {
        this.businessId = this.$route.query.businessId ? this.$route.query.businessId : sessionStorage.getItem("businessId") ? sessionStorage.getItem("businessId") : this.businessId;
        getShopOhterCommodity({
          pageNum: 1,
          pageSize: 100,
          businessId: this.businessId,
          commodityId: this.$route.query.id,
          isNoHot: 0,
        }).then((res) => {
          if (res.code == 200 && res.data.vos.length !== 0) {
            this.otherCommodity = res.data.vos;
            // console.log(this.hotCommodity);
          }
        });
      },
      detailfun(e) {
        const herf = this.$router.resolve({
          // name: "FineCeramics",
          path: "/ceramics/fineceramics",
          query: {
            noBusiness: false,
            businessHomePath: "/businesshome",
            phoneHomeImg: false,
            box8: false,
            id: e.id,
            isShowProductList: false,
          },
        });
        window.open(herf.href, "__blank");
      },
      matching(menuslist) {
        let bool = false;
        menuslist.some((item) => {
          if (item.id.toString() === this.menusindex) {
            bool = true;
            return true;
          }
        });
        if (bool) {
          menuslist.some((item) => {
            if (item.id.toString() === this.menusindex) {
              this.titleName = item.categoryName;
              this.match = true;
              bool = false;
              return true;
            }
          });
        } else {
          let menuslist2 = [];
          menuslist2 = menuslist;
          menuslist = [];
          menuslist2.forEach((item) => {
            if (item.children) {
              menuslist.push(item.children);
            }
          });
          menuslist.some((item) => {
            if (this.match) {
              return true;
            } else {
              this.matching(item);
            }
          });
        }
      },
      getQuery() {
        if (this.$route.query.isbusinessbottomnav) {
          if (this.$route.query.isbusinessbottomnav === "false") {
            this.childrenindex = this.$route.query.id;
            this.menusindex = this.$route.query.id;
          } else {
            this.childrenindex = this.shopmenus[0].id;
            this.menusindex = this.shopmenus[0].id;
          }
          // let { categoryName } = this.shopmenus.find(
          //   (item) => item.id == this.childrenindex
          // );
          // this.titleName = categoryName;
          this.match = false;
          this.matching(this.shopmenus);
          this.getshopCommodityList();
        }
      },
      businesshomeClick() {
        this.$router.push({
          path: "/businesshome",
          query: {
            noBusiness: false,
            businessId: sessionStorage.getItem("businessId"),
          },
        });
      },
      //获取商家分类
      getShopCommonCategory() {
        this.businessId = this.$route.query.businessId ? this.$route.query.businessId : sessionStorage.getItem("businessId") ? sessionStorage.getItem("businessId") : this.businessId;
        shopCategoryListApi(this.businessId).then((res) => {
          if (res.code == 200) {
            this.shopmenus = res.data;
          }
        });
      },
      //改变页数
      numChange(e) {
        this.pageNum = e;
        this.getshopCommodityList();
      },
      getSortType(e) {
        this.sortType = e;
        this.getshopCommodityList();
      },
      //获取商品列表
      getshopCommodityList() {
        let obj = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          categoryId: this.categoryId,
          sortType: this.sortType,
          businessId: sessionStorage.getItem("businessId"),
        };
        getshopCommodityList({ ...obj }).then((res) => {
          if (res.code == 200) {
            this.pageTotal = res.data.total;
            this.parentShopList = res.data.vos;
          }
        });
      },
      //公共分类父级点击
      menufun(name, e, id) {
        this.$router.push({
          // name: "FineCeramics",
          path: "/ceramics/fineceramics",
          query: {
            noBusiness: false,
            businessHomePath: "/businesshome",
            phoneHomeImg: false,
            box8: false,
            isShowProductList: true,
            // id: e.id,
          },
        });
        this.menusindex = id;
        this.childrenindex = id;
        this.categoryId = id;
        this.twoindex = null;
        this.titleName = name;
        this.getshopCommodityList();
        this.isShowProductList = true;
      },
      //公共分类子级点击
      pcchilfun(name, e, id) {
        this.$router.push({
          // name: "FineCeramics",
          path: "/ceramics/fineceramics",
          query: {
            noBusiness: false,
            businessHomePath: "/businesshome",
            phoneHomeImg: false,
            box8: false,
            isShowProductList: true,
            // id: e.id,
          },
        });
        this.twoindex = e;
        this.childrenindex = id;
        this.categoryId = id;
        this.titleName = name;
        this.getshopCommodityList();
        this.isShowProductList = true;
      },
      threeMenufun(name, e, id) {
        this.$router.push({
          // name: "FineCeramics",
          path: "/ceramics/fineceramics",
          query: {
            noBusiness: false,
            businessHomePath: "/businesshome",
            phoneHomeImg: false,
            box8: false,
            isShowProductList: true,
            // id: e.id,
          },
        });
        this.childrenindex = id;
        this.categoryId = id;
        this.titleName = name;
        this.getshopCommodityList();
        this.isShowProductList = true;
      },
      // 决定pc端与移动端的显示
      companyProfileShow() {
        // 刷新时判断
        if (document.documentElement.clientWidth > 1080) {
          document.querySelector(".companyprofile_pc").style.display = "flex";
          document.querySelector(".companyprofile_phone").style.display = "none";
        } else {
          document.querySelector(".companyprofile_pc").style.display = "none";
          document.querySelector(".companyprofile_phone").style.display = "block";
        }
      },
      changeActiveIndex(index) {
        this.activeIndex = index;
        if (this.$route.query.id) {
          this.isShowProductList = false;
          this.getParams();
        }
      },
      //定位到置顶位置
      toLocal() {
        //查找存储的锚点id
        let Id = 'shopDetail';
        let toElement = document.getElementById(Id);
        //锚点存在跳转
        if (Id) {
          toElement.scrollIntoView()
        }
      }
    },
    created() {
      this.$nextTick(() => { this.toLocal() })
      this.getShopCommonCategory();
      this.getShopHotCommodity();
      this.getShopOhterCommodity();
      this.getshopCommodityList();
      this.getParams();

      // 刷新时判断
      if (document.documentElement.clientWidth > 1080) {
        this.isPc = true;
      } else {
        this.isPc = false;
      }
      if (this.$route.query.id) {
        this.isShowProductList = false;
      }
    },
    mounted() {

      //定位到置顶位置
      let _this = this;
      _this.$nextTick(function () {
        window.addEventListener('scroll', _this.handleScroll)
      })

      // this.getQuery();
      // this.getshopCommodityList();
      this.getShopCommonCategory();
      this.getShopHotCommodity()
      this.getShopOhterCommodity()

      if (this.$route.query.isbusinessbottomnav) {
        if (this.$route.query.isbusinessbottomnav === "false") {
          this.childrenindex = this.$route.query.id;
          this.menusindex = this.$route.query.id;
        } else {
          setTimeout(() => {
            // this.childrenindex = this.shopmenus[0].id;
            this.menusindex = this.shopmenus[0].id;
          }, 200);
        }
        setTimeout(() => {
          this.getshopCommodityList();
          let { categoryName } = this.shopmenus.find(
            (item) => item.id == this.childrenindex
          );
          this.titleName = categoryName;
        }, 1100);
      }

      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      document.querySelector(".bottomnav_classify").style.color = "#13AF87";
      // let childs = document.querySelector(".menuUl").childNodes;
      // childs[0].classList.add("active");
      if (document.documentElement.clientWidth <= 1080) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
      // 屏幕变化时判断
      window.addEventListener("resize", () => {
        if (document.documentElement.clientWidth <= 1080) {
          this.isshow = true;
        } else {
          this.isshow = false;
        }
      });
    },
  };
</script>

<style scoped>
  .digtop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid black;
    padding-bottom: 5px;
  }

  .digmain {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  #pcnav {
    display: block;
    height: calc(530 * 100vw / 1920);
    /* height: calc(280 * 100vw / 1920); */
    overflow: hidden;
    overflow-y: scroll;
  }

  #ydnav {
    display: none;
  }

  /* 商品详情 和 评论 */


  .description {
    width: 70%;
    padding-bottom: calc(150 * 100vw / 1920);
    background-color: #fff;
    /* width: 74vw; */
    display: inline-block;
    background-color: #f1f1f1;
  }

  .description .description_title {
    margin-left: calc(20 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    font-weight: bolder;
    font-size: 1.28vw;
    color: #aaa;

  }

  .description .description_title .description_title_active {
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    color: #000;
  }

  .description .description_title span:last-child {
    margin-left: calc(40 * 100vw / 1080);
  }

  .description .description_main {
    border-top: 1px solid #ddd;
    padding: calc(38 * 100vw / 1920) 20px;
    font-size: calc(16 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    line-height: calc(24 * 100vw / 1920);
    word-wrap: break-word;
  }

  /* 商品详情和评论 */

  .top {
    /* width: calc(400 * 100vw / 1920); */
    width: 100%;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    padding: 0 calc(20 * 100vw / 1920);
    position: relative;
  }

  .top span {
    display: flex;
    /* min-height: calc(60 * 100vw / 1920); */
    padding: calc(10 * 100vw / 1920) 0;
    /* line-height: calc(60 * 100vw / 1920); */
    align-items: center;
    width: 88%;
  }

  .businessceramics_page {
    width: 100vw;
    background-color: #f1f1f1;
  }

  .clearfix:after,
  .clearfix:before {
    content: "";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  .clearfix {
    *zoom: 1;
  }

  .businessceramics_main {
    /*width: calc(1200 * 100vw / 1920);*/
    width: 85%;
    margin: 0 auto;
    background-color: #f1f1f1;
  }

  .businessceramics_main .directory {
    background-color: #f1f1f1;
    margin: 0;
    margin-top: 1.2vw;
    padding-bottom: 1.2vw;
    font-size: 0.93vw;
    font-family: Roboto;
    font-weight: 400;
    color: #999999;
  }

  .bottonContent {
    width: 100%;
    display: inline-block;
    border: 1px solid #dddddd;
    background-color: white;
  }

  .nav {
    /* width: calc(400 * 100vw / 1920); */
    width: 30%;
    /* height: calc(620 * 100vw / 1920); */
    background-color: #fff;
    float: left;
    overflow: hidden;
  }

  .pc_nav_title {
    /* width: calc(400 * 100vw / 1920); */
    width: 100%;
    height: calc(74 * 100vw / 1920);
    background: #13af87;
    padding-left: calc(24 * 100vw / 1920);
    line-height: calc(74 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: calc(25 * 100vw / 1920);
  }

  .phone_nav_title {
    display: none;
  }

  .list {
    width: calc(890 * 100vw / 1920);
    height: calc(1040 * 100vw / 1920);
    background: #ffffff;
    float: left;
    margin-bottom: calc(20 * 100vw / 1920);
  }

  .list .title {
    margin-left: calc(20 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .list_main {
    width: 100%;
    border-top: 1px solid #ddd;
    padding-top: calc(38 * 100vw / 1920);
  }

  .list_nav {
    margin-left: calc(22 * 100vw / 1920);
  }

  .list_nav div {
    width: calc(120 * 100vw / 1920);
    height: calc(40 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 20px;
    margin-right: calc(20 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
    line-height: calc(35 * 100vw / 1920);
  }

  .active {
    background: #13af87 !important;
    color: #ffffff !important;
  }

  .goodslist {
    margin: calc(53 * 100vw / 1920) calc(35 * 100vw / 1920);
    height: calc(700 * 100vw / 1920);
    flex-wrap: wrap;
    align-content: space-between;
  }

  .goodslist .good {
    width: calc(260 * 100vw / 1920);
    min-height: calc(360 * 100vw / 1920);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #dddddd;
    /* box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38); */
  }

  .goodslist .good .img {
    width: calc(260 * 100vw / 1920);
    height: calc(260 * 100vw / 1920);
  }

  .goodslist .good .img img {
    max-width: 99%;
    max-height: 100%;
  }

  .goodslist .good .text {
    padding: 0 calc(15 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    display: block;
    width: 100%;
    word-wrap: break-word;
  }

  .goodslist .good .price {
    margin-left: calc(15 * 100vw / 1920);
  }

  .goodslist .good .price .price1 {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #ff0000;
    margin-right: calc(22 * 100vw / 1920);
  }

  .goodslist .good .price .price2 {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 300;
    text-decoration: line-through;
    color: #aaaaaa;
  }

  #menuhot {
    /* height: calc(250 * 100vw / 1920); */
    overflow: hidden;
  }

  .menuhot_title {
    /* width: calc(400 * 100vw / 1920); */
    width: 100%;
    height: calc(74 * 100vw / 1920);
    background: #13af87;
    padding-left: calc(24 * 100vw / 1920);
    line-height: calc(74 * 100vw / 1920);
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: calc(25 * 100vw / 1920);
  }

  .menuhot_list {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    overflow-y: scroll;
    max-height: calc(400 * 100vw / 1920);
  }

  .menuhot_list_child {
    padding: calc(15 * 100vw / 1920);
    margin-bottom: calc(10 * 100vw / 1920);
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }

  .menuhot_list_child>img {
    width: calc(60 * 100vw / 1920);
    height: calc(60 * 100vw / 1920);
    margin-right: calc(10 * 100vw / 1920);
  }

  .menuhot_list_child>label {
    width: calc(100% - calc(70 * 100vw / 1920));
  }

  @media screen and (max-width: 1080px) {
    #pcnav {
      display: none;
    }

    #ydnav {
      width: 110% !important;
      display: block;
      overflow: hidden;
      overflow-y: scroll;
      /* height: calc(1000 * 100vw / 1080); */
      height: calc(500 * 100vw / 1080);
      padding-right: calc(30 * 100vw / 1080);
    }

    .top {
      width: 100%;
      /* height: calc(150 * 100vw / 1080); */
      font-size: calc(37 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #333333;
      /* padding: 0 calc(40 * 100vw / 1080); */
      padding-top: calc(44 * 100vw / 1080);
      position: relative;
      /* border-bottom: 1px solid #ddd; */
    }

    .top span {
      display: flex;
      width: calc(200 * 100vw / 1080);
      /* min-height: calc(100 * 100vw / 1080); */
      /* line-height: calc(50 * 100vw / 1080); */
      padding: calc(10 * 100vw / 1080) 0;
      border-bottom: none;
      justify-content: center;
      align-items: center;
      /* white-space: nowrap; */
    }

    .businessceramics_page {
      width: 100vw;
      /* height: calc(1200 * 100vw / 1080); */
    }

    .businessceramics_page .box_4 {
      width: 100%;
      height: calc(280 * 100vw / 1080);
    }

    .businessceramics_main {
      width: 100%;
      margin: 0;
      background-color: #f1f1f1;
      margin-top: calc(20 * 100vw / 1080);
    }

    .businessceramics_main .directory {
      display: none;
    }

    .nav {
      width: 30%;
      height: calc(1200 * 100vw / 1080);
      /* margin-right: calc(10 * 100vw / 1080); */
      background-color: #fff;
      float: left;
      overflow: hidden;
      display: none;
    }

    .pc_nav_title {
      display: none;
    }

    .phone_nav_title {
      display: block;
      width: 100%;
      height: calc(80 * 100vw / 1080);
      background: #13af87;
      text-align: center;
      line-height: calc(80 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #ffffff;
    }

    /* 商品详情富文本 和 评论 */

    .description {
      width: 100%;
      padding-bottom: 0;
      background-color: #fff;
    }

    .description .description_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(145 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(145 * 100vw / 1080);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
      color: #aaa;
    }

    .description .description_title .description_title_active {
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      color: #000;
    }

    .description .description_title span:last-child {
      margin-left: calc(40 * 100vw / 1080);
    }

    .description .description_main {
      border-top: 1px solid #ddd;
      padding: calc(50 * 100vw / 1080) calc(40 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
      line-height: calc(48 * 100vw / 1080);
      word-wrap: break-word;
    }

    /* 商品详情富文本 和 评论 end */

    #menuhot {
      /* padding-right: calc(30 * 100vw / 1080); */
      height: calc(560 * 100vw / 1080);
      overflow: hidden;
    }

    .menuhot_title {
      width: 100%;
      height: calc(80 * 100vw / 1080);
      background: #13af87;
      text-align: center;
      line-height: calc(80 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #ffffff;
    }

    .menuhot_list {
      width: 100%;
      font-size: calc(37 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #333333;
      height: calc(700 * 100vw / 1080);
      max-height: calc(800* 100vw / 1920);
      overflow-y: scroll;
    }

    .menuhot_list_child {
      padding: calc(15 * 100vw / 1080);
      margin-bottom: calc(10 * 100vw / 1080);
      display: flex;
      align-items: center;
    }

    .menuhot_list_child>img {
      width: calc(100 * 100vw / 1080);
      height: calc(100 * 100vw / 1080);
      margin-right: calc(20 * 100vw / 1080);
    }

    .menuhot_list_child>label {
      width: calc(100% - calc(120 * 100vw / 1080));
    }
  }
</style>
<style scoped>
  .addmenu {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #9ff4a6; */
    padding: calc(10 * 100vw / 1920);
  }

  .addmenu button {
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    border: 1px solid #ddd;
    background-color: #13af87;
    border-radius: calc(30 * 100vw / 1920);
    color: #fff;
    padding: calc(5 * 100vw / 1920) calc(20 * 100vw / 1920);
  }

  @media screen and (max-width: 1080px) {

    .description_main {
      width: 100%;
      margin-bottom: calc(10 * 100vw / 1080);
      font-size: calc(40 * 100vw / 1080);
    }

    .addmenu {
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-color: #ddd; */
      padding: calc(20 * 100vw / 1080);
    }

    .addmenu button {
      font-size: calc(22 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      border: 1px solid #ddd;
      background-color: #13af87;
      border-radius: calc(50 * 100vw / 1080);
      color: #fff;
      padding: calc(15 * 100vw / 1080) calc(20 * 100vw / 1080);
    }
  }
</style>